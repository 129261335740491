import React, { useState, useEffect } from 'react';
import { DatePicker as TrussworksDatePicker } from '@trussworks/react-uswds';
import { Label } from '../../atoms/Label/Label';
import './DatePicker.scss';
import moment from 'moment/moment';
import { ErrorMessage } from '../../lib/util/index';

export const DatePicker = ({
  id,
  name,
  className,
  defaultValue,
  disabled,
  required,
  minDate,
  maxDate,
  rangeDate,
  onChange,
  onBlur,
  label,
  labelClass,
  hint,
  error,
  errorMessage,
  format,
  ...props
}) => {
  const defaultMinDate = '01-01-0001';
  if (!minDate) {
    minDate = defaultMinDate;
  }
  if (!id) id = '';

  const [isValidDate, setIsValidDate] = useState(true);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (
      defaultValue &&
      (minDate !== defaultMinDate || maxDate) &&
      !isDateInValidRange(defaultValue)
    ) {
      setIsValidDate(false);
      setErrorText('Please enter a valid date.');
    }
  }, [defaultValue, maxDate, minDate]);

  const handleChange = (e, isValue = false) => {
    if (!e.target?.value && !isValue) return;
    const value = (isValue ? e : e.target.value).trim();

    if (value === '') {
      setIsValidDate(true);
      return;
    }

    if (!isValidYear(value)) {
      setIsValidDate(false);
      setErrorText('Please enter a valid year.');
      return;
    }

    if (minDate || maxDate) {
      if (!isDateInValidRange(value)) {
        setIsValidDate(false);
        setErrorText('Please enter a valid date.');
        return false;
      }
    }
    const dateFormat = format || 'MM/DD/YYYY';

    const sanitizeInput = moment(value, dateFormat, true);

    if (!sanitizeInput.isValid()) {
      setIsValidDate(false);
      setErrorText('Please enter correct date format.');
      return false;
    }
    const instance = moment(sanitizeInput, dateFormat, true);
    const selectedDate = instance.locale(instance);
    if (!selectedDate) {
      setIsValidDate(false);
      setErrorText('Please enter correct date format.');
      return false;
    }
    setIsValidDate(true);
    return true;
  };

  const isValidYear = (value) => {
    const year = Number(value.split('/')[2]);
    return year > 1900;
  };

  const isDateInValidRange = (inputDate) => {
    const selectedDate = moment(inputDate).startOf('day');
    const maxDateInput = maxDate && moment(maxDate).startOf('day');
    const minDateInput = minDate && moment(minDate).startOf('day');
    return !(selectedDate > maxDateInput || selectedDate < minDateInput);
  };
  const handleBlurEvent = (e) => {
    handleChange(e);
    if (onBlur && typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  const hasError = () => !isValidDate || errorMessage;

  if (label) {
    return (
      <div
        className={[
          'usa-form-group',
          hasError() ? 'usa-form-group--error' : '',
        ].join(' ')}
      >
        <Label
          htmlFor={id}
          id={`${id}-label`}
          className={labelClass ? labelClass : ''}
        >
          {label}
        </Label>
        {hasError() && <ErrorMessage text={errorMessage || errorText} />}
        {hint && (
          <div className="usa-hint" id={`${id}-hint`}>
            {hint}
          </div>
        )}
        <TrussworksDatePicker
          id={id}
          name={name}
          className={className}
          defaultValue={defaultValue}
          disabled={disabled}
          required={required}
          minDate={minDate ? minDate : defaultMinDate}
          maxDate={maxDate}
          rangeDate={rangeDate}
          onChange={onChange}
          onBlur={handleBlurEvent}
          {...props}
        />
      </div>
    );
  } else {
    return (
      <>
        {hasError() && <ErrorMessage text={errorMessage || errorText} />}
        {hint && (
          <div className="usa-hint" id={`${id}-hint`}>
            {hint}
          </div>
        )}
        <TrussworksDatePicker
          id={id}
          name={name}
          className={className}
          defaultValue={defaultValue}
          disabled={disabled}
          required={required}
          minDate={minDate ? minDate : defaultMinDate}
          maxDate={maxDate}
          rangeDate={rangeDate}
          onChange={onChange}
          onBlur={handleBlurEvent}
          {...props}
        />
      </>
    );
  }
};
