import React from 'react';
import { Tooltip as UswdsTooltip } from '@trussworks/react-uswds';

export const Tooltip = ({
  label,
  position,
  children,
  className,
  ...otherProps
}) => {
  return (
    <UswdsTooltip
      position={position}
      label={label}
      className={className}
      {...otherProps}
    >
      {children}
    </UswdsTooltip>
  );
};
