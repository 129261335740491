import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typeahead from '../../molecules/Typeahead/Typeahead';
import { useFilter } from './FilterProvider';

// FIXME: this is a hacky solution
let userTyped = false;

const FilterItemTypeAhead = ({ id, delay, ariaLabel }) => {
  const {
    filterState,
    filterOptions,
    onFilterItemChange,
    onFilterItemSearch,
    resetTypeAhead,
    setResetTypeAhead
  } = useFilter();

  const value = useMemo(() => {
    return filterState[id] ?? '';
  }, [filterState[id]]);

  const options = useMemo(() => {
    return filterOptions[id] ?? [];
  }, [filterOptions[id]]);

  return (
    <Typeahead
      key={id}
      name={ariaLabel}
      accessor={id}
      filterValue={value}
      onFilterKeyDown={() => {
        setResetTypeAhead(false)
        userTyped = true;
        // Do nothing
      }}
      onOptionEnter={(selected) => {
        // FIXME: this hacky solution is added to prevent onFilter from firing
        // when the user selects a an item from the list of options
        // userTyped is only set to true when the user starts typing.
        userTyped = false;

        if (typeof onFilterItemChange === 'function' && !userTyped)
          onFilterItemChange({ id, value: selected });
        setResetTypeAhead(false)
      }}
      typeaheadValues={options}
      fetchTypeaheadValues={(_, search) => {
        const keyword = search.trim();

        // Clears type ahead filter value.
        if (!keyword.length) {
          onFilterItemChange({ id, value: null });
          return;
        }

        // onFilterItemSearch only fires if user typed something.
        if (typeof onFilterItemSearch === 'function' && userTyped) {
          // Trim value '  text  ' => 'text'
          onFilterItemSearch({ key: id, search: keyword });
        }
        userTyped = false;
      }}
      debounceDelay={delay}
      inputCharNum={0}
      reset={resetTypeAhead}
    />
  );
};

FilterItemTypeAhead.defaultProps = {
  delay: 500,
};

FilterItemTypeAhead.propTypes = {
  id: PropTypes.string.isRequired,
  delay: PropTypes.number,
};

export default FilterItemTypeAhead;
