import React from 'react';
import PropTypes from 'prop-types';
import './DetailsTable.scss';

const DetailsTable = ({ data, placeholder, className, bordered, wrapText }) => {
  const printValue = (val) => {
    if (!val && typeof val !== 'number') {
      return placeholder;
    }
    return val;
  };

  const rowClass = bordered ? 'border-gray-10 border-bottom-1px' : null;
  const bodyClass = wrapText ? 'wrapText' : null;

  return (
    <table
      aria-describedby="dataTable"
      role="presentation"
      className={`afp-details-table ${className}`}
    >
      <tbody>
        {data.map(([label, value]) => (
          <tr key={label} className={rowClass}>
            <th className="text-left" scope="row">
              {label}
            </th>
            <td className={bodyClass}>{printValue(value)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

DetailsTable.defaultProps = {
  placeholder: '\u2014', // enDash
  className: '',
  bordered: false,
  wrapText: false,
};

DetailsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.number]),
    ),
  ).isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  bordered: PropTypes.bool,
  wrapText: PropTypes.bool,
};

export default DetailsTable;
