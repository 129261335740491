import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './SideNav.scss';
import SideNavItem from './SideNavItem';

const SideNav = ({ items, isSubnav = false }) => {
  const classes = classnames('afp-sidenav', 'border-0', {
    'usa-sidenav': !isSubnav,
    'usa-sidenav__sublist': isSubnav,
  });

  return (
    <nav aria-label="secondary navigation">
      <ul className={classes} data-testid="sidenav">
        {items.map((item, i) => (
          <SideNavItem item={item} key={`sidenav_item_${i}`} />
        ))}
      </ul>
    </nav>
  );
};
export default SideNav;

SideNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ 
      id: PropTypes.string,
      body: PropTypes.node,
      count: PropTypes.number,
      active: PropTypes.bool,
    })
  ).isRequired,
  isSubnav: PropTypes.bool,
};

