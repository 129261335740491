import React from 'react';

export const MegaMenuSection = ({
  label,
  isCurrent,
  sectionId,
  onClick,
  primaryIdx,
}) => {
  return (
    <button
      type="button"
      className={`usa-accordion__button usa-nav__link ${
        isCurrent ? 'usa-current' : ''
      }`}
      aria-controls={sectionId}
      aria-expanded={isCurrent}
      onClick={onClick}
      data-primary-idx={primaryIdx}
    >
      <span>{label}</span>
    </button>
  );
};

export default MegaMenuSection;
