import React from 'react';
import { Button } from '../../atoms/Button/Button';
import PropTypes from 'prop-types';

export const Typography = ({ type }) => {
  return (
    <div className="grid-container">
      {type === 'titles' && (
        <>
          <h1>Typography</h1>
          <div className="grid-row" data-testid="headerSection">
            <div className="grid-col-6">
              <h2>Headers</h2>
              <hr />
              <div className="grid-row grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">title-xl</span>
                </div>
                <div className="grid-col-9">
                  <h1 className="title-xl">
                    In sem justo, commodo ut, suscipit at, pharetra vitae
                  </h1>
                </div>
              </div>
              <div className="grid-row grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">title-l</span>
                </div>
                <div className="grid-col-9">
                  <h1 className="title-l">
                    In sem justo, commodo ut,
                    <br /> suscipit at, pharetra vitae
                  </h1>
                </div>
              </div>
              <div className="grid-row grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">title-m</span>
                </div>
                <div className="grid-col-9">
                  <h1 className="title-m">
                    In sem justo, commodo ut,
                    <br /> suscipit at, pharetra vitae
                  </h1>
                </div>
              </div>
              <div className="grid-row grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">title-m-bold</span>
                </div>
                <div className="grid-col-9">
                  <h1 className="title-m-bold">
                    In sem justo, commodo ut,
                    <br /> suscipit at, pharetra vitae
                  </h1>
                </div>
              </div>
              <div className="grid-row grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">title-s</span>
                </div>
                <div className="grid-col-9">
                  <h1 className="title-s">
                    In sem justo, commodo ut,
                    <br /> suscipit at, pharetra vitae
                  </h1>
                </div>
              </div>
              <div className="grid-row grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">title-s-caps</span>
                </div>
                <div className="grid-col-9">
                  <h1 className="title-s-caps">
                    In sem justo, commodo ut,
                    <br /> suscipit at, pharetra vitae
                  </h1>
                </div>
              </div>
              <div className="grid-row grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">title-xs</span>
                </div>
                <div className="grid-col-9">
                  <h1 className="title-xs">
                    In sem justo, commodo ut,
                    <br /> suscipit at, pharetra vitae
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {type === 'text' && (
        <>
          <h1>Typography</h1>
          <div className="grid-row" data-testid="headerText">
            <div className="grid-col-6">
              <h2>Text</h2>
              <hr />
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">body</span>
                  <p>default paragraph tag</p>
                </div>
                <div className="grid-col-9">
                  <p>
                    In sem justo, commodo ut, suscipit at, pharetra vitae, orci.
                    Duis sapien nunc, commodo et, interdum suscipit,
                    sollicitudin et, dolor. Pellentesque habitant morbi
                    tristique senectus et netus et malesuada fames ac turpis
                    egestas.
                  </p>
                  <p>
                    Aliquam id dolor. Class aptent taciti sociosqu ad litora
                    torquent per conubia nostra, per inceptos hymenaeos. Mauris
                    dictum facilisis augue. Fusce tellus. Pellentesque arcu.
                    Maecenas fermentum, sem in pharetra pellentesque, velit
                    turpis volutpat ante, in pharetra metus odio a lectus. Sed
                    elit dui, pellentesque a, faucibus vel, interdum nec, diam.
                    Mauris dolor felis, sagittis at, luctus sed, aliquam non,
                    tellus. Etiam ligula pede, sagittis quis, interdum
                    ultricies.
                  </p>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">body-tight</span>
                  <p>Base size, smaller line-height</p>
                </div>
                <div className="grid-col-9">
                  <p className="body-tight">
                    In sem justo, commodo ut, suscipit at, pharetra vitae, orci.
                    Duis sapien nunc, commodo et, interdum suscipit,
                    sollicitudin et, dolor. Pellentesque habitant morbi
                    tristique senectus et netus et malesuada fames ac turpis
                    egestas.
                  </p>
                  <p className="body-tight">
                    Aliquam id dolor. Class aptent taciti sociosqu ad litora
                    torquent per conubia nostra, per inceptos hymenaeos. Mauris
                    dictum facilisis augue. Fusce tellus. Pellentesque arcu.
                    Maecenas fermentum, sem in pharetra pellentesque, velit
                    turpis volutpat ante, in pharetra metus odio a lectus. Sed
                    elit dui, pellentesque a, faucibus vel, interdum nec, diam.
                    Mauris dolor felis, sagittis at, luctus sed, aliquam non,
                    tellus. Etiam ligula pede, sagittis quis, interdum
                    ultricies.
                  </p>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">body-tight</span>
                  <p>
                    base size, smaller line-height, no spacing between
                    paragraphs
                  </p>
                </div>
                <div className="grid-col-9">
                  <p className="body-block">
                    In sem justo, commodo ut, suscipit at, pharetra vitae, orci.
                    Duis sapien nunc, commodo et, interdum suscipit,
                    sollicitudin et, dolor. Pellentesque habitant morbi
                    tristique senectus et netus et malesuada fames ac turpis
                    egestas.
                  </p>
                  <p className="body-block">
                    Aliquam id dolor. Class aptent taciti sociosqu ad litora
                    torquent per conubia nostra, per inceptos hymenaeos. Mauris
                    dictum facilisis augue. Fusce tellus. Pellentesque arcu.
                    Maecenas fermentum, sem in pharetra pellentesque, velit
                    turpis volutpat ante, in pharetra metus odio a lectus. Sed
                    elit dui, pellentesque a, faucibus vel, interdum nec, diam.
                    Mauris dolor felis, sagittis at, luctus sed, aliquam non,
                    tellus. Etiam ligula pede, sagittis quis, interdum
                    ultricies.
                  </p>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">body-bold</span>
                  <p>base size, font-weight 700</p>
                </div>
                <div className="grid-col-9">
                  <p className="body-bold">
                    In sem justo, commodo ut, suscipit at, pharetra vitae, orci.
                  </p>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">body-linked</span>
                  <p>base size, text-underline</p>
                </div>
                <div className="grid-col-9">
                  <p className="body-underline">
                    In sem justo, commodo ut, suscipit at, pharetra vitae, orci.
                  </p>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">body-mono</span>
                </div>
                <div className="grid-col-9">
                  <p className="body-mono">
                    This is body text but with monospaced numerals 0123456789
                  </p>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">body-small</span>
                </div>
                <div className="grid-col-9">
                  <p className="body-small">
                    In sem justo, commodo ut, suscipit at, pharetra vitae, orci.
                    Duis sapien nunc, commodo et, interdum suscipit,
                    sollicitudin et, dolor. Pellentesque habitant morbi
                    tristique senectus et netus et malesuada fames ac turpis
                    egestas.
                  </p>
                  <p className="body-small">
                    Aliquam id dolor. Class aptent taciti sociosqu ad litora
                    torquent per conubia nostra, per inceptos hymenaeos. Mauris
                    dictum facilisis augue. Fusce tellus. Pellentesque arcu.
                    Maecenas fermentum, sem in pharetra pellentesque, velit
                    turpis volutpat ante, in pharetra metus odio a lectus. Sed
                    elit dui, pellentesque a, faucibus vel, interdum nec, diam.
                    Mauris dolor felis, sagittis at, luctus sed, aliquam non,
                    tellus. Etiam ligula pede, sagittis quis, interdum
                    ultricies.
                  </p>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">body-small-tight</span>
                </div>
                <div className="grid-col-9">
                  <p className="body-small-tight">
                    In sem justo, commodo ut, suscipit at, pharetra vitae, orci.
                    Duis sapien nunc, commodo et, interdum suscipit,
                    sollicitudin et, dolor. Pellentesque habitant morbi
                    tristique senectus et netus et malesuada fames ac turpis
                    egestas.
                  </p>
                  <p className="body-small-tight">
                    Aliquam id dolor. Class aptent taciti sociosqu ad litora
                    torquent per conubia nostra, per inceptos hymenaeos. Mauris
                    dictum facilisis augue. Fusce tellus. Pellentesque arcu.
                    Maecenas fermentum, sem in pharetra pellentesque, velit
                    turpis volutpat ante, in pharetra metus odio a lectus. Sed
                    elit dui, pellentesque a, faucibus vel, interdum nec, diam.
                    Mauris dolor felis, sagittis at, luctus sed, aliquam non,
                    tellus. Etiam ligula pede, sagittis quis, interdum
                    ultricies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {type === 'weights' && (
        <>
          <h1>Typography</h1>
          <div className="grid-row" data-testid="fontWeight">
            <div className="grid-col-6">
              <h2>Font Weight</h2>
              <hr />
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">text-thin (200)</span>
                  <p>Extra light (special case only)</p>
                </div>
                <div className="grid-col-9">
                  <span className="text-thin">Tallahassee</span>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">text-normal (400)</span>
                  <p>Regular (text default)</p>
                </div>
                <div className="grid-col-9">
                  <span className="text-normal">Tallahassee</span>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">text-bold (700)</span>
                  <p>Bold (use for bold)</p>
                </div>
                <div className="grid-col-9">
                  <span className="text-bold">Tallahassee</span>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">text-heavy (900)</span>
                  <p>Black (special cases only)</p>
                </div>
                <div className="grid-col-9">
                  <div className="grid-col-9">
                    <span className="text-heavy">Tallahassee</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {type === 'listItems' && (
        <>
          <h1>Typography</h1>
          <div className="grid-row" data-testid="listItems">
            <div className="grid-col-7">
              <h2>List items</h2>
              <hr />
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">
                    Unstyled list (add-list-reset)
                  </span>
                </div>
                <div className="grid-col-9">
                  <ul className="add-list-reset">
                    <li>
                      <p>In sem justo, commodo uxt</p>
                    </li>
                    <li>
                      <p>Suscipit at, pharetra vitae, orci.</p>
                    </li>
                    <li>
                      <p>
                        Duis sapien nunc, commodo et, interdum suscipit,
                        sollicitudin et, dolor.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">
                    Nested unordered lists
                  </span>
                </div>
                <div className="grid-col-9">
                  <ul>
                    <li>
                      <p>Unordered list item</p>
                      <ul>
                        <li>
                          <p>Unordered list item</p>
                          <ul>
                            <li>
                              <p>Unordered list item</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">
                    Nested ordered lists
                  </span>
                </div>
                <div className="grid-col-9">
                  <ol>
                    <li>
                      <p>Ordered list item</p>
                      <ol>
                        <li>
                          <p>Ordered list item</p>
                          <ol>
                            <li>
                              <p>Ordered list item</p>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {type === 'monospacedNumerals' && (
        <>
          <h1>Typography</h1>
          <div className="grid-row" data-testid="monospacedNumerals">
            <div className="grid-col-7">
              <h2>Monospaced numerals</h2>
              <hr />
              <div className="grid-row margin-bottom-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">text-tabular</span>
                  <p>Easier to compare within a column</p>
                </div>
                <div className="grid-col-8 margin-top-5">
                  <div className="grid-row padding-1 border-bottom border-base-lighter">
                    <div className="grid-col-8">
                      <div className="text-bold">Monthly Rate</div>
                    </div>
                    <div className="grid-col-4">
                      <div className="text-right text-tabular">$250.00</div>
                    </div>
                  </div>
                  <div className="grid-row padding-1 border-bottom border-base-lighter">
                    <div className="grid-col-8">
                      <div className="text-bold">Daily Rate</div>
                    </div>
                    <div className="grid-col-4">
                      <div className="text-right text-tabular">$110.12</div>
                    </div>
                  </div>
                  <div className="grid-row padding-1 border-bottom border-base-lighter">
                    <div className="grid-col-8">
                      <div className="text-bold">Mileage Rate</div>
                    </div>
                    <div className="grid-col-4">
                      <div className="text-right text-tabular">$1.50</div>
                    </div>
                  </div>
                  <div className="grid-row padding-1 border-bottom border-base-lighter">
                    <div className="grid-col-8">
                      <div className="text-bold">AFV Surcharge</div>
                    </div>
                    <div className="grid-col-4">
                      <div className="text-right text-tabular">$10.00</div>
                    </div>
                  </div>
                  <div className="grid-row padding-1 border-bottom border-base-lighter">
                    <div className="grid-col-8">
                      <div className="text-bold">Optional Equipment Rate</div>
                    </div>
                    <div className="grid-col-4">
                      <div className="text-right text-tabular">$75.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {type === 'buttonText' && (
        <>
          <h1>Typography</h1>
          <div className="grid-row" data-testid="headerButtonText">
            <div className="grid-col-7">
              <h2>Button text</h2>
              <hr />
              <div className="grid-row margin-y-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">font-sans-2xs</span>
                </div>
                <div className="grid-col-8">
                  <Button type="button" variant="primary" disabled={false}
                    label="Button"
                    labelClass="font-sans-2xs"
                  />
                </div>
              </div>
              <div className="grid-row margin-y-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">font-sans-md</span>
                </div>
                <div className="grid-col-8">
                  <Button type="button" variant="primary" disabled={false}
                   label="Button"
                   labelClass="font-sans-md"
                  />
                </div>
              </div>
              <div className="grid-row margin-y-3 grid-gap flex-justify flex-align-center">
                <div className="grid-col-3">
                  <span className="text-red text-bold">font-sans-lg</span>
                </div>
                <div className="grid-col-8">
                  <Button type="button" variant="primary" disabled={false}
                    label="Button"
                    labelClass="font-sans-lg"
                 />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Typography.propTypes = {
  type: PropTypes.string,
};

export default Typography;
