import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SelectDropdown from '../../atoms/SelectDropdown/SelectDropdown';
import { countryList, countryListWithUsTerritories } from './helpers';

const CountrySelect = ({
  className,
  placeholder,
  defaultUSA,
  withUsTerritories,
  ...restProps
}) => {

  const options = withUsTerritories ? countryListWithUsTerritories : countryList

  const countryOptions = Object.entries(
    options,
  ).map(([value, label]) => ({ label, value })).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <SelectDropdown
      className={classnames(className, 'afp-country-select')}
      options={[
        ...(placeholder ? [{ label: placeholder, value: '' }] : []),
        ...countryOptions,
      ]}
      defaultValue={defaultUSA ? 'US' : undefined}
      {...restProps}
    />
  );
};

CountrySelect.propTypes = {
  defaultUSA: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  withUsTerritories: PropTypes.bool,
};

CountrySelect.defaultProps = {
  className: undefined,
  defaultUSA: false,
  placeholder: undefined,
  withUsTerritories: true,
};

export default CountrySelect;
