import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Button,
  Spinner,
  connectModal,
  Modal,
  useModal,
  Icon,
  Alert,
} from '../..';
import { CommentPropType } from './types';

const DeleteModal = ({
  closeModal,
  pending,
  onSubmit,
  formErrorMessage,
  onClose,
}) => (
  <Modal
    id="delete-comment-modal"
    title={<h2>Delete Comment</h2>}
    onClose={() => {
      closeModal();
      onClose();
    }}
    actions={
      <>
        <Button
          type="button"
          variant="outline"
          onClick={() => {
            closeModal();
            onClose();
          }}
          label="Cancel"
        />
        <Button
          disabled={pending}
          type="submit"
          onClick={onSubmit}
          variant="secondary"
          label="Delete comment"
        />
      </>
    }
  >
    {formErrorMessage && (
      <Alert type="error" className="margin-bottom-1">
        {formErrorMessage}
      </Alert>
    )}
    Are you sure you want to delete this comment?
  </Modal>
);

DeleteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  formErrorMessage: PropTypes.string,
  onClose: PropTypes.func,
};

DeleteModal.defaultProps = {
  formErrorMessage: null,
  onClose: () => {
    // noop
  },
};

export default function DeleteCommentButton({
  comment,
  allowChanges,
  currentUser,
  deleteComment,
  deletingComment,
  submissionStatus,
}) {
  const { isOpen, openModal, closeModal } = useModal();
  const [formErrorMessage, setFormErrorMessage] = useState(null);
  if (currentUser !== comment.createdBy) {
    return null;
  }

  useEffect(() => {
    if (
      !_.isEmpty(submissionStatus) &&
      _.get(submissionStatus, 'action') === 'delete'
    ) {
      if (_.get(submissionStatus, 'status') === 'success') {
        closeModal();
      }
      if (_.get(submissionStatus, 'status') === 'fail') {
        setFormErrorMessage(_.get(submissionStatus, 'message'));
      }
    }
  }, [submissionStatus]);

  const handleOnClose = () => {
    setFormErrorMessage(null);
  };

  const ConnectedAddModal = connectModal(() => (
    <DeleteModal
      closeModal={closeModal}
      pending={deletingComment}
      onSubmit={() => {
        deleteComment(parseFloat(comment.id));
      }}
      formErrorMessage={formErrorMessage}
      onClose={handleOnClose}
    />
  ));

  return (
    <>
      <ConnectedAddModal isOpen={isOpen} closeModal={closeModal} />
      {allowChanges ? (
        <Button
          type="button"
          variant="unstyled"
          className="cursor-pointer display-flex flex-align-center"
          onClick={openModal}
          leftIcon={{name: "cancel", className: "usa-icon--size-3 text-base" }}
          label="Delete Comment"
        />
      ) : null}
    </>
  );
}

DeleteCommentButton.defaultProps = {
  allowChanges: true,
};

DeleteCommentButton.propTypes = {
  comment: PropTypes.shape(CommentPropType).isRequired,
  allowChanges: PropTypes.bool,
  currentUser: PropTypes.string.isRequired,
  deleteComment: PropTypes.func.isRequired,
  deletingComment: PropTypes.bool.isRequired,
  submissionStatus: PropTypes.shape({
    action: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
};
