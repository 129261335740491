import React, { useRef, useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useDebounce from '../../lib/hooks/useDebounce';
import { Label } from '../../atoms/Label/Label';
import { ErrorMessage, Help, Hint } from '../../lib/util/index';
import { Icon } from '../..';
import TypeaheadItem from './TypeaheadItem';
import Spinner from '../../atoms/Spinner/Spinner';
import './Typeahead.scss';

const Typeahead = ({
  id,
  name,
  accessor,
  filterValue,
  readOnly,
  disabled,
  onFilterKeyDown,
  onFilterChange,
  typeaheadValues,
  fetchTypeaheadValues,
  inputCharNum,
  label,
  required,
  forceUpdate,
  onOptionEnter,
  className,
  debounceDelay,
  placeholder,
  errorMessage,
  help,
  hint,
  model,
  prefixIcon,
  ariaLabel,
  reset,
  showPrompt,
  promptText,
  showNoResults,
  noResultsText,
  showLoadingSpinner,
  ignoreFieldFeedback,
  inputClass,
  labelClass,
  onClear,
  generateCustomOption,
}) => {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const [errorState, setErrorState] = useState();
  const [value, setValue] = useState(filterValue);
  const [valueIsSet, setValueIsSet] = useState(false);
  const [isShowMenu, setShowmenu] = useState(false);
  const [clearButton, toggleClearButton] = useState(false);
  const [prefix, togglePrefixIcon] = useState(true);
  const [inFocus, setInFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isEscKeyDown, setIsEscKeyDown] = useState(false);
  const resultsRef = useRef();

  const prevValue = usePrevious(value);
  const arrayReducer = (state, opts) => {
    return [...opts];
  };
  const [matchingOpts, setMatchingOpts] = useReducer(arrayReducer, []);

  const debounceFetchTypeheadValues = useDebounce((accessor, value) => {
    fetchTypeaheadValues(accessor, value, model);
  }, debounceDelay);

  const labelClasses = classnames(
    { [`usa-label--error`]: !!errorMessage },
    labelClass,
  );

  useEffect(() => {
    if (
      /* ((prevValue || '').length < inputCharNum || !prevValue) && */
      value.length >= inputCharNum
      /* && !typeaheadValues.find((tp) => tp.includes(value)) */
    ) {
      // emit event to request data
      debounceFetchTypeheadValues(accessor, value);
      return;
    }

    if (
      (prevValue || '').length > (value || '').length &&
      (value || '').length < inputCharNum
    ) {
      setErrorState(false);
    }

    if (prevValue && !value) {
      if (errorState) {
        setErrorState(false);
      }
      setMatchingOpts([]);
    }
    if (
      errorState &&
      prevValue &&
      value &&
      prevValue.length > value.length &&
      value.length < inputCharNum
    ) {
      setErrorState(false);
    }
  }, [value]);

  useEffect(() => {
    setValue(filterValue);
    if (filterValue.toString() === '') {
      setValue('');
      setValueIsSet(false);
      togglePrefixIcon(true);
      toggleClearButton(false);
    } else {
      togglePrefixIcon(false);
      toggleClearButton(true);
    }
  }, [filterValue]);

  useEffect(() => {
    if (isDirty && inFocus) {
      setIsLoading(false);
      if (typeaheadValues[0] === 'Error: 404') {
        setErrorState(true);
        return;
      }
    }

    const newSet = [];
    typeaheadValues.forEach((opt) => {
      if (opt.toLowerCase().includes(value.toLowerCase())) {
        newSet.push(opt);
      }
    });
    if (newSet.length) {
      setErrorState(false);
      setIsLoading(false);
    }
    setMatchingOpts(newSet);
  }, [typeaheadValues]);

  const inputRef = useRef();

  const onInputChange = (ev) => {
    if (!ev.target.value || ev.target.value.length < inputCharNum)
      toggleClearButton(false);

    setValue(ev.target.value);
    if (onFilterChange) onFilterChange(ev);
    setShowmenu(!!ev.target.value);
  };

  const processOptionSelection = (opt) => {
    setValueIsSet(true);
    setInFocus(false);
    toggleClearButton(true);
    inputRef.current.value = opt;
    setValue(opt);
    onOptionEnter(opt);
    setMatchingOpts([]);
  };

  const optEnterPress = (ev, opt) => {
    if (ev.key === 'Enter' && value) {
      processOptionSelection(opt);
    }
  };

  const clearFilter = () => {
    inputRef.current.value = '';
    setValue('');
    inputRef.current.focus();
    toggleClearButton(false);
    setValueIsSet(false);
    setInFocus(true);
    setIsLoading(false);
    setMatchingOpts([]);
    setShowmenu(true);

    if (onClear && typeof onClear === 'function') {
      onClear();
    }
  };

  useEffect(() => {
    if (forceUpdate) {
      setValue(forceUpdate.value);
      inputRef.current.value = forceUpdate.value;
    }
  }, [forceUpdate]);

  useEffect(() => {
    if (reset) {
      setValue('');
      inputRef.current.value = '';
      togglePrefixIcon(true);
      toggleClearButton(false);
      setErrorState(false);
      if (onClear && typeof onClear === 'function') {
        onClear();
      }
    }
  }, [reset]);

  const handleTabOut = (e) => {
    if (!e.target.value) {
      togglePrefixIcon(true);
      setShowmenu(false);
      setErrorState(false);
      setInFocus(false);
    } else {
      if (errorState) {
        togglePrefixIcon(true);
        setShowmenu(false);
        setErrorState(false);
        setInFocus(false);
        setValue('');
        if (onClear && typeof onClear === 'function') {
          onClear();
        }
      } else if (matchingOpts.length === 1) {
        setShowmenu(false);
        setErrorState(false);
        setInFocus(false);
        toggleClearButton(true);
        findMatchingOption(inputRef.current.value, isDirty);
      }
    }
  };

  const findMatchingOption = (inputValue, inputIsDirty) => {
    if (!inputIsDirty || !inputValue) return;
    const matchingOpt = matchingOpts.find(
      (opt) => opt.toLowerCase() === inputValue.toLowerCase(),
    );
    if (matchingOpt) {
      processOptionSelection(matchingOpt);
      togglePrefixIcon(false);
    } else {
      setValue('');
      toggleClearButton(false);
      if (onClear && typeof onClear === 'function') {
        onClear();
      }
    }
  };

  const handleClickOut = (e) => {
    if (
      inputRef.current &&
      !e.target.classList.contains('typeahead-option') &&
      e.target !== inputRef.current
    ) {
      const inputRefValue = inputRef.current.value;
      setShowmenu(false);
      setInFocus(false);
      setErrorState(false);

      if (!valueIsSet || !inputRefValue) {
        if (!inputRefValue) togglePrefixIcon(true);
        else {
          togglePrefixIcon(false);
          toggleClearButton(true);
          findMatchingOption(inputRefValue, isDirty);
        }

        document.removeEventListener('click', handleClickOut);
      } else {
        document.removeEventListener('click', handleClickOut);
      }
    } else if (e.target.classList.contains('typeahead-option')) {
      document.removeEventListener('click', handleClickOut);
    }
  };

  function onArrowKeyDown(ev) {
    const inputIsFocused = document.activeElement === inputRef.current;
    if (resultsRef && resultsRef.current) {
      const resultsItems = Array.from(resultsRef.current.children);
      const activeResultIndex = resultsItems.findIndex((child) => {
        return child === document.activeElement;
      });
      if (ev.key === 'ArrowUp') {
        ev.preventDefault();
        if (inputIsFocused) {
          resultsItems[resultsItems.length - 1].focus();
        } else if (resultsItems[activeResultIndex - 1]) {
          resultsItems[activeResultIndex - 1].focus();
        } else {
          inputRef.current.focus();
        }
      }
      if (ev.key === 'ArrowDown') {
        ev.preventDefault();
        if (inputIsFocused) {
          resultsItems[0].focus();
        } else if (matchingOpts[activeResultIndex + 1]) {
          resultsItems[activeResultIndex + 1].focus();
        } else {
          inputRef.current.focus();
        }
      }
      if (ev.key === 'Escape') {
        setIsEscKeyDown(true);
        setMatchingOpts([]);
        setIsDirty(false);
      }
    }
  }

  useEffect(() => {
    if (matchingOpts) {
      document.body.addEventListener('keydown', onArrowKeyDown);
    } else {
      document.body.removeEventListener('keydown', onArrowKeyDown);
    }
    return () => {
      document.body.removeEventListener('keydown', onArrowKeyDown);
    };
  }, [matchingOpts]);

  useEffect(() => {
    if (!isDirty || isLoading || !inFocus) return;
    if (
      value.length > inputCharNum &&
      matchingOpts.length === 0 &&
      !valueIsSet
    ) {
      setErrorState(true);
    }

    if (value.length > 0) {
      togglePrefixIcon(false);
    }
  }, [matchingOpts, value, valueIsSet, isDirty, isLoading, inFocus]);

  return (
    <div
      className={`typeahead-container usa-form-group ${className} ${
        !ignoreFieldFeedback && errorMessage ? ' usa-form-group--error' : ''
      }`}
    >
      {label && (
        <Label required={required} className={labelClasses} htmlFor={id}>
          {label}
        </Label>
      )}
      <ErrorMessage id={id} text={errorMessage} />
      <Help text={help} />
      <div className="typeahead-prefix-container">
        <input
          placeholder={placeholder}
          className={`usa-input main_input ${inputClass} ${
            !ignoreFieldFeedback && errorState ? 'usa-input--error' : ''
          }`}
          aria-describedby={id}
          aria-label={ariaLabel || name || 'Typeahead'}
          aria-expanded={isShowMenu}
          aria-autocomplete="list"
          aria-haspopup="listbox"
          aria-controls={`${id}-listbox`}
          role="combobox"
          autoComplete="off"
          required={required}
          id={id}
          value={value}
          name={name}
          disabled={disabled}
          readOnly={readOnly}
          ref={inputRef}
          onChange={onInputChange}
          onKeyDown={(ev) => {
            if (ev.key === 'Tab') {
              handleTabOut(ev);
            }
          }}
          onKeyUp={(ev) => {
            if (
              ev.key !== 'Tab' &&
              ev.key !== 'Shift' &&
              ev.key !== 'Control' &&
              ev.key !== 'Meta' &&
              ev.key !== 'Enter'
            ) {
              setIsDirty(true);
              setValueIsSet(false);
              if (ev.target.value.length < inputCharNum) {
                setMatchingOpts([]);
                setIsLoading(false);
                setShowmenu(true);
              } else {
                if (showLoadingSpinner && value !== prevValue) {
                  setIsLoading(true);
                }
                if (onFilterKeyDown) {
                  onFilterKeyDown(ev);
                }
              }
            }
          }}
          onFocus={() => {
            document.addEventListener('click', handleClickOut);
            togglePrefixIcon(false);
            setShowmenu(true);
            setInFocus(true);
          }}
        />

        {prefix && !disabled ? (
          <Icon
            iconName={prefixIcon}
            className="prefix-icon height-2 margin-left-1"
          />
        ) : null}
        {clearButton && (readOnly || !disabled) ? (
          <button
            type="button"
            onClick={clearFilter}
            className="display-flex usa-button usa-button--unstyled"
            aria-label="clear"
          >
            <Icon iconName="close" className="usa-icon--size-3 text-base" />
          </button>
        ) : null}
      </div>

      <div
        className={`afp-typeahead-options-wrapper${!isShowMenu ? '-hide' : ''}`}
        id={`${id}-options`}
        tabIndex={-1}
      >
        <Hint text={hint} />

        {showPrompt && value.length < inputCharNum && !isLoading && inFocus && (
          <div className="opts-container" role="status" aria-live="polite">
            <div role="alert" className="typeahead-option unselectable">
              {promptText}
            </div>
          </div>
        )}

        {showLoadingSpinner &&
          isLoading &&
          matchingOpts.length === 0 &&
          value &&
          inFocus &&
          value.length >= inputCharNum && (
            <div className="opts-container" role="status" aria-live="polite">
              <div className="typeahead-option unselectable loading">
                <Spinner size="small" />
                Loading...
              </div>
            </div>
          )}

        {showNoResults &&
          value.length >= inputCharNum &&
          (matchingOpts.length === 0 || errorState) &&
          inFocus &&
          !isLoading &&
          !valueIsSet &&
          isDirty &&
          !isEscKeyDown && (
            <div className="opts-container" role="status" aria-live="polite">
              <div className="typeahead-option unselectable">
                {noResultsText}
              </div>
            </div>
          )}

        {value.length >= inputCharNum &&
        matchingOpts.length &&
        !valueIsSet &&
        !errorState ? (
          <div
            ref={resultsRef}
            tabIndex={-1}
            className="opts-container"
            aria-describedby={id}
            role="listbox"
            id={`${id}-listbox`}
          >
            {matchingOpts.map((opt) => (
              <TypeaheadItem
                key={opt}
                opt={opt}
                onKeyDown={(ev) => optEnterPress(ev, opt)}
                onClick={() => {
                  processOptionSelection(opt);
                }}
              >
                {generateCustomOption ? generateCustomOption(opt) : opt}
              </TypeaheadItem>
            ))}
            {matchingOpts && matchingOpts.length > 1 && (
              <span
                id={id}
                role="alert"
                className="usa-sr-only"
                aria-live="polite"
              >
                {matchingOpts.length} results found
              </span>
            )}
            {matchingOpts && matchingOpts.length === 1 && (
              <span
                id={id}
                role="alert"
                className="usa-sr-only"
                aria-live="polite"
              >
                {matchingOpts.length} result found
              </span>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

Typeahead.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onFilterKeyDown: PropTypes.func,
  onFilterChange: PropTypes.func,
  accessor: PropTypes.string,
  filterValue: PropTypes.string,
  typeaheadValues: PropTypes.array,
  fetchTypeaheadValues: PropTypes.func,
  inputCharNum: PropTypes.number,
  onOptionEnter: PropTypes.func,
  debounceDelay: PropTypes.number,
  placeholder: PropTypes.string,
  forceUpdate: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  help: PropTypes.string,
  hint: PropTypes.string,
  prefixIcon: PropTypes.string,
  ariaLabel: PropTypes.string,
  reset: PropTypes.bool,
  showPrompt: PropTypes.bool,
  promptText: PropTypes.string,
  showNoResults: PropTypes.bool,
  noResultsText: PropTypes.string,
  showLoadingSpinner: PropTypes.bool,
  ignoreFieldFeedback: PropTypes.bool,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  onClear: PropTypes.func,
  generateCustomOption: PropTypes.func,
};

Typeahead.defaultProps = {
  id: undefined,
  name: undefined,
  readOnly: false,
  disabled: false,
  className: '',
  inputCharNum: 3,
  label: undefined,
  required: false,
  errorMessage: undefined,
  help: undefined,
  hint: undefined,
  debounceDelay: 500,
  placeholder: '',
  onFilterKeyDown: undefined,
  onOptionEnter: undefined,
  onFilterChange: undefined,
  prefixIcon: 'search',
  ariaLabel: '',
  reset: false,
  showPrompt: true,
  promptText: 'Please start typing to select an option...',
  showNoResults: true,
  noResultsText: 'No results found.',
  showLoadingSpinner: true,
  ignoreFieldFeedback: false,
  inputClass: '',
  labelClass: '',
  generateCustomOption: undefined,
};

export default Typeahead;
