import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

export const Label = ({
  id,
  children,
  htmlFor,
  className,
  error,
  hint,
  srOnly,
  required,
}) => {
  const classes = classnames(
    {
      'usa-label': !srOnly,
      'usa-sr-only': srOnly,
      'usa-label--error': error,
    },
    className,
  );
  const content = () => {
    if (required)
      return (
        <>
          {children}
          <span className="afp-required-field">&nbsp;*</span>
        </>
      );
    return children;
  };

  return (
    <label
      id={id}
      data-testid={htmlFor || `label_${uniqueId()}`}
      className={classes}
      htmlFor={htmlFor}
    >
      {content()}
      {hint && <span className="usa-hint">{hint}</span>}
    </label>
  );
};

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  className: PropTypes.string,
};
