import React from 'react';
import classnames from 'classnames';

export const FormGroup = ({ children, className, error }) => {
  const classes = classnames(
    'usa-form-group',
    { 'usa-form-group--error': error },
    className,
  );

  return (
    <div data-testid="formGroup" className={classes}>
      {children}
    </div>
  );
};
