import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from '../..';
import DeleteCommentButton from './delete-comment-button';
import { CommentPropType } from './types';

const Comment = ({
  comment,
  allowChanges,
  currentUser,
  deleteComment,
  deletingComment,
  submissionStatus,
}) => (
  <li key={comment.id} className="margin-bottom-4">
    <div className="display-flex flex-align-center">
      <Icon
        iconName="account_circle"
        className="usa-icon--size-3 margin-right-1"
      />
      <span className="text-bold">
        {(comment.author || {}).fullName || comment.createdByUser}
      </span>
      <span className="margin-x-4">
        {moment(comment.createdAt).format('l')} &bull;{' '}
        {moment(comment.createdAt).format('h:mm a')}
      </span>
      <DeleteCommentButton
        comment={comment}
        allowChanges={allowChanges}
        currentUser={currentUser}
        deleteComment={deleteComment}
        deletingComment={deletingComment}
        submissionStatus={submissionStatus}
      />
    </div>
    <p>{comment.comment}</p>
  </li>
);

Comment.defaultProps = {
  allowChanges: true,
};

Comment.propTypes = {
  comment: PropTypes.shape(CommentPropType).isRequired,
  allowChanges: PropTypes.bool,
  currentUser: PropTypes.string.isRequired,
  deleteComment: PropTypes.func.isRequired,
  deletingComment: PropTypes.bool.isRequired,
  submissionStatus: PropTypes.shape({
    action: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
};

export default Comment;
