import React from 'react';
import PropTypes from 'prop-types';
import IconFinder from '../../lib/util/iconFinder';

const sortStrings = {
  DESC: 'descending',
  ASC: 'ascending',
};

function HeaderSortButton({ label, isActive, order, onClick }) {
  return (
    <button
      type="button"
      className="table-header afp-table-header__sort-button"
      aria-label={`sort by ${label} in ${
        order === 'ASC' ? sortStrings.DESC : sortStrings.ASC
      } order`}
      onClick={onClick}
    >
      <div className="table-header__name">{label}</div>
      <div className="table__sort-icon">
        {isActive ? (
          <IconFinder
            iconName={order === 'ASC' ? 'arrowUp' : 'arrowDown'}
            fill="#005EA2"
          />
        ) : (
          ''
        )}
      </div>
    </button>
  );
}

HeaderSortButton.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  order: PropTypes.string,
};

export default ({ header, sortingState, setSortingState }) => {
  return (
    <thead>
      <tr role="row">
        {header.map((item) => {
          const activeSortItem = item.accessor === sortingState.sortBy;

          const ariaSort =
            item.sortable && activeSortItem
              ? sortStrings[sortingState.sortOrder]
              : undefined;

          return (
            <th
              role="columnheader"
              scope="col"
              aria-sort={ariaSort}
              key={item.accessor}
              className={item.className}
              aria-label={item.name}
            >
              {item.sortable ? (
                <HeaderSortButton
                  label={item.name}
                  isActive={activeSortItem}
                  order={activeSortItem ? sortingState.sortOrder : undefined}
                  onClick={
                    activeSortItem
                      ? // column already sorted
                        () =>
                          setSortingState({
                            type: 'sortOrder',
                            payload:
                              sortingState.sortOrder === 'DESC'
                                ? 'ASC'
                                : 'DESC',
                          })
                      : // column is not sorted
                        () =>
                          setSortingState({
                            type: 'sortBy',
                            payload: item.accessor,
                          })
                  }
                />
              ) : (
                <div className="table-header">
                  <div className="table-header__name">{item.name}</div>
                </div>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
