import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '../Button/Button';
import './ButtonDropdown.scss';

const ButtonDropdown = ({
  className,
  label,
  variant,
  size,
  iconName,
  iconType,
  iconSize,
  children,
  disabled,
  side = 'left',
}) => {
  const [isOpen, setIsOpen] = useState();
  const buttonDropdownRef = useRef();
  const dropdownItems = useRef();
  const handleOpen = () => setIsOpen(!isOpen);

  const classes = classnames('afp-buttondropdown  button-main', className);

  const clickAwayHandler = (e) => {
    if (buttonDropdownRef && !buttonDropdownRef.current.contains(e.target)) {
      handleOpen(!isOpen);
    }
  };

  const focusFirstChild = () => {
    if (dropdownItems) {
      const { childNodes: rowItems } = dropdownItems.current;
      rowItems[0].focus();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', clickAwayHandler);
      focusFirstChild();
    }
    return () => {
      document.removeEventListener('click', clickAwayHandler);
    };
  }, [isOpen]);

  const iconDisplayName = isOpen ? 'expand_less' : 'expand_more';

  const leftIconProp = {name: iconName, type: iconType, className: `usa-icon--size-${iconSize}`};

  return (
    <div
      data-testid="button-dropdown-testid"
      className="display-inline-block button-dropdown position-relative"
    >
      <Button
        className={classes}
        inputRef={buttonDropdownRef}
        variant={variant}
        size={size}
        disabled={disabled}
        onClick={handleOpen}
        aria-expanded={isOpen}
        leftIcon={iconName && leftIconProp}
        label={label}
        rightIcon={{name: iconDisplayName, className: `usa-icon--size-${iconSize}`}}
      />
      {isOpen && (
        <div
          ref={dropdownItems}
          className={`grid-row flex-column bg-white z-100 shadow-2 border position-absolute min-width-100p ${side}-0`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

ButtonDropdown.defaultProps = {
  variant: '',
  size: '',
  iconType: 'uswds',
  iconName: '',
  iconSize: '',
  disabled: false,
  className: '',
};

ButtonDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  iconSize: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ButtonDropdown;
