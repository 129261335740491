/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { DateRangePicker as TrussworksDateRangePicker } from '@trussworks/react-uswds';
import { ErrorMessage } from '../../lib/util';
import moment from 'moment/moment';

export const DateRangePicker = ({
  id,
  name,
  defaultValue,
  disabled,
  required,
  minDate,
  maxDate,
  onChange,
  onBlur,
  startDateLabel,
  startDateHint,
  startDatePickerProps,
  endDateLabel,
  endDateHint,
  endDatePickerProps,
  className,
  ...props
}) => {
  if (!id) id = '';
  const [isValidDate, setIsValidDate] = useState(true);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (
      startDatePickerProps.defaultValue &&
      !isDateInValidRange(
        startDatePickerProps.defaultValue,
        startDatePickerProps.maxDate,
        startDatePickerProps.minDate,
      )
    ) {
      setIsValidDate(false);
      setErrorText('Please enter a valid start date.');
    }
    if (
      endDatePickerProps.defaultValue &&
      !isDateInValidRange(
        endDatePickerProps.defaultValue,
        endDatePickerProps.maxDate,
        endDatePickerProps.minDate,
      )
    ) {
      setIsValidDate(false);
      setErrorText('Please enter a valid end date.');
    }
  }, [startDatePickerProps.defaultValue, endDatePickerProps.defaultValue]);

  const isValidYear = (value) => {
    const year = Number(value.split('/')[2]);
    return year > 1900;
  };

  const handleChange = (e, type, isValue = false) => {
    if (!e.target?.value && !isValue) return;

    const value = (isValue ? e : e.target.value).trim();

    if (value === '') {
      setIsValidDate(true);
      return;
    }

    if (!isValidYear(value)) {
      setIsValidDate(false);
      setErrorText('Please enter a valid year.');
      return;
    }

    const dateFormat = 'MM/DD/YYYY';
    const sanitizeInput = moment(value, dateFormat, true);
    if (!sanitizeInput.isValid()) {
      setIsValidDate(false);
      setErrorText('Please enter correct date format.');
      return false;
    }
    const instance = moment(sanitizeInput, dateFormat, true);
    const selectedDate = instance.locale(instance);
    if (!selectedDate) {
      setIsValidDate(false);
      setErrorText('Please enter correct date format.');
      return false;
    }

    if (
      type === 'startDate' &&
      (startDatePickerProps.minDate || startDatePickerProps.maxDate)
    ) {
      if (
        !isDateInValidRange(
          value,
          startDatePickerProps.maxDate,
          startDatePickerProps.minDate,
        )
      ) {
        setIsValidDate(false);
        setErrorText('Please enter a valid start date.');
        return false;
      }
    } else if (type === 'startDate') {
      startDatePickerProps.defaultValue = value;
      if (endDatePickerProps.defaultValue) {
        const selectedStartDate = moment(value).startOf('day');
        const endDate = moment(endDatePickerProps.defaultValue).startOf('day');
        if (endDate < selectedStartDate) {
          setIsValidDate(false);
          setErrorText('Please enter a valid start date.');
          return false;
        }
      }
    }

    if (
      type === 'endDate' &&
      (endDatePickerProps.minDate || endDatePickerProps.maxDate)
    ) {
      if (
        !isDateInValidRange(
          value,
          endDatePickerProps.maxDate,
          endDatePickerProps.minDate,
        )
      ) {
        setIsValidDate(false);
        setErrorText('Please enter a valid end date.');
        return false;
      }
    } else if (type === 'endDate') {
      endDatePickerProps.defaultValue = value;

      if (startDatePickerProps.defaultValue) {
        const startDate = moment(startDatePickerProps.defaultValue).startOf(
          'day',
        );
        const selectedEndDate = moment(value).startOf('day');
        if (startDate > selectedEndDate) {
          setIsValidDate(false);
          setErrorText('Please enter a valid end date.');
          return false;
        }
      }
    }
    setIsValidDate(true);
    return true;
  };

  const isDateInValidRange = (inputDate, maxGivenDate, minGivenDate) => {
    const selectedDate = moment(inputDate).startOf('day');
    const maxDateInput = maxGivenDate && moment(maxGivenDate).startOf('day');
    const minDateInput = minGivenDate && moment(minGivenDate).startOf('day');
    return !(selectedDate > maxDateInput || selectedDate < minDateInput);
  };

  const handleChangeEvent = (e, type) => {
    if (handleChange(e, type, true) !== false) {
      if (type === 'startDate') {
        if (
          startDatePickerProps.onChange &&
          typeof startDatePickerProps.onChange === 'function'
        ) {
          startDatePickerProps.onChange(e);
        }
      } else if (type === 'endDate') {
        if (
          endDatePickerProps.onChange &&
          typeof endDatePickerProps.onChange === 'function'
        ) {
          endDatePickerProps.onChange(e);
        }
      }
    }
  };

  return (
    <>
      <div
        className={[
          'usa-form-group',
          !isValidDate ? 'usa-form-group--error' : '',
        ].join(' ')}
      >
        {!isValidDate && <ErrorMessage text={errorText} />}
        <TrussworksDateRangePicker
          id={id}
          name={name}
          defaultValue={defaultValue}
          disabled={disabled}
          required={required}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          onBlur={onBlur}
          startDateLabel={startDateLabel}
          startDateHint={startDateHint}
          startDatePickerProps={{
            ...startDatePickerProps,
            onChange: (e) => handleChangeEvent(e, 'startDate'),
          }}
          endDateLabel={endDateLabel}
          endDateHint={endDateHint}
          endDatePickerProps={{
            ...endDatePickerProps,
            onChange: (e) => handleChangeEvent(e, 'endDate'),
          }}
          className={className}
          {...props}
        />
      </div>
    </>
  );
};
