import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from '../..';

const FilterItemClear = ({ value, onClear }) => {
  if (!value) return null;
  return (
    <Button
      data-testid={`${value.toLowerCase()}-clear`}
      variant="unstyled"
      className="width-auto margin-top-2 line-height-sans-4"
      onClick={() => {
        onClear();
      }}
      label={value}
      rightIcon={{name: "close", className: "text-middle text-gray-50"}}
    />
  );
};

FilterItemClear.defaultProps = {
  value: null,
  onClear: () => null,
};
FilterItemClear.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(Object),
    PropTypes.array,
  ]),
  onClear: PropTypes.func,
};

export default FilterItemClear;
