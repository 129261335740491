import React from 'react';

export default ({ iconName, width, height, fill }) => {
  const icons = {
    arrowDown: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.50586 15.134C7.77539 15.4035 8.22461 15.4035 8.49414 15.134L14.334 9.3241C14.6035 9.02462 14.6035 8.5754 14.334 8.30587L13.6452 7.61707C13.3757 7.34754 12.9264 7.34754 12.627 7.61707L9.35046 10.8936V1.44107C9.35046 0.990976 9.02896 0.669479 8.57887 0.669479L7.55008 0.669479C7.13213 0.669479 6.77848 0.990976 6.77848 1.44107V11.0005L3.37305 7.61707C3.07357 7.34754 2.62435 7.34754 2.35482 7.61707L1.66602 8.30587C1.39648 8.5754 1.39648 9.02462 1.66602 9.3241L7.50586 15.134Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    arrowLeft: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.868795 7.50865C0.599264 7.77818 0.599264 8.2274 0.868795 8.49693L6.67869 14.3368C6.97817 14.6063 7.42739 14.6063 7.69692 14.3368L8.38572 13.648C8.65525 13.3784 8.65525 12.9292 8.38572 12.6297L5.10923 9.35325H14.5617C15.0118 9.35325 15.3333 9.03175 15.3333 8.58166V7.55287C15.3333 7.13492 15.0118 6.78128 14.5617 6.78128L5.00226 6.78128L8.38572 3.37584C8.65525 3.07636 8.65525 2.62714 8.38572 2.35761L7.69692 1.66881C7.42739 1.39928 6.97817 1.39928 6.67869 1.66881L0.868795 7.50865Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    arrowRight: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1312 7.50865C15.4007 7.77818 15.4007 8.2274 15.1312 8.49693L9.32131 14.3368C9.02183 14.6063 8.57261 14.6063 8.30308 14.3368L7.61428 13.648C7.34475 13.3784 7.34475 12.9292 7.61428 12.6297L10.8908 9.35325H1.43828C0.988184 9.35325 0.666687 9.03175 0.666687 8.58166V7.55287C0.666687 7.13492 0.988184 6.78128 1.43828 6.78128H10.9977L7.61428 3.37584C7.34475 3.07636 7.34475 2.62714 7.61428 2.35761L8.30308 1.66881C8.57261 1.39928 9.02183 1.39928 9.32131 1.66881L15.1312 7.50865Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    arrowUp: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.50586 0.871582C7.77539 0.602051 8.22461 0.602051 8.49414 0.871582L14.334 6.68148C14.6035 6.98096 14.6035 7.43018 14.334 7.69971L13.6452 8.38851C13.3757 8.65804 12.9264 8.65804 12.627 8.38851L9.35046 5.11201L9.35046 14.5645C9.35046 15.0146 9.02896 15.3361 8.57887 15.3361H7.55008C7.13213 15.3361 6.77848 15.0146 6.77848 14.5645V5.00504L3.37305 8.38851C3.07357 8.65804 2.62435 8.65804 2.35482 8.38851L1.66602 7.69971C1.39648 7.43018 1.39648 6.98096 1.66602 6.68148L7.50586 0.871582Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    avatar: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 8.00476C9.97917 8.00476 11.619 6.39315 11.619 4.38571C11.619 2.40654 9.97917 0.766663 8 0.766663C5.99256 0.766663 4.38095 2.40654 4.38095 4.38571C4.38095 6.39315 5.99256 8.00476 8 8.00476ZM10.5164 8.90952H10.0357C9.41369 9.22053 8.73512 9.3619 8 9.3619C7.26488 9.3619 6.55804 9.22053 5.93601 8.90952H5.45536C3.3631 8.90952 1 10.6342 1 12.7265V13.8857C1 14.6491 1.59375 15.2429 2.35714 15.2429H13.6429C14.378 15.2429 15 14.6491 15 13.8857V12.7265C15 10.6342 12.6086 8.90952 10.5164 8.90952Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    back: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.902839 5.91976C0.587888 6.21924 0.587888 6.72835 0.902839 7.02783L6.31333 11.3107C6.7428 11.7 7.4586 11.3706 7.4586 10.7417V8.24C11.8965 8.2999 13.525 9.26495 12.1265 13.9458C11.9834 14.425 12.556 14.8143 12.9282 14.5148C14.188 13.5565 15.3333 11.7297 15.3333 9.90283C15.3333 5.91976 12.0397 4.52995 7.4586 4.5V2.24168C7.4586 1.61277 6.7428 1.28334 6.31333 1.67267L0.902839 5.91976Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    calendar: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.19995 13.7503C1.19995 14.4899 1.85599 15.09 2.66459 15.09H13.4053C14.2139 15.09 14.87 14.4899 14.87 13.7503V6.15875H1.19995V13.7503ZM10.9642 8.27992C10.9642 8.09572 11.129 7.945 11.3304 7.945H12.5509C12.7523 7.945 12.9171 8.09572 12.9171 8.27992V9.39633C12.9171 9.58054 12.7523 9.73125 12.5509 9.73125H11.3304C11.129 9.73125 10.9642 9.58054 10.9642 9.39633V8.27992ZM10.9642 11.8524C10.9642 11.6682 11.129 11.5175 11.3304 11.5175H12.5509C12.7523 11.5175 12.9171 11.6682 12.9171 11.8524V12.9688C12.9171 13.153 12.7523 13.3038 12.5509 13.3038H11.3304C11.129 13.3038 10.9642 13.153 10.9642 12.9688V11.8524ZM7.05852 8.27992C7.05852 8.09572 7.22329 7.945 7.42468 7.945H8.64522C8.84661 7.945 9.01138 8.09572 9.01138 8.27992V9.39633C9.01138 9.58054 8.84661 9.73125 8.64522 9.73125H7.42468C7.22329 9.73125 7.05852 9.58054 7.05852 9.39633V8.27992ZM7.05852 11.8524C7.05852 11.6682 7.22329 11.5175 7.42468 11.5175H8.64522C8.84661 11.5175 9.01138 11.6682 9.01138 11.8524V12.9688C9.01138 13.153 8.84661 13.3038 8.64522 13.3038H7.42468C7.22329 13.3038 7.05852 13.153 7.05852 12.9688V11.8524ZM3.15281 8.27992C3.15281 8.09572 3.31758 7.945 3.51897 7.945H4.7395C4.94089 7.945 5.10567 8.09572 5.10567 8.27992V9.39633C5.10567 9.58054 4.94089 9.73125 4.7395 9.73125H3.51897C3.31758 9.73125 3.15281 9.58054 3.15281 9.39633V8.27992ZM3.15281 11.8524C3.15281 11.6682 3.31758 11.5175 3.51897 11.5175H4.7395C4.94089 11.5175 5.10567 11.6682 5.10567 11.8524V12.9688C5.10567 13.153 4.94089 13.3038 4.7395 13.3038H3.51897C3.31758 13.3038 3.15281 13.153 3.15281 12.9688V11.8524ZM13.4053 2.58625H11.9407V1.24657C11.9407 1.00096 11.721 0.800003 11.4525 0.800003H10.476C10.2075 0.800003 9.98781 1.00096 9.98781 1.24657V2.58625H6.08209V1.24657C6.08209 1.00096 5.8624 0.800003 5.59388 0.800003H4.61745C4.34893 0.800003 4.12924 1.00096 4.12924 1.24657V2.58625H2.66459C1.85599 2.58625 1.19995 3.18632 1.19995 3.92594V5.26563H14.87V3.92594C14.87 3.18632 14.2139 2.58625 13.4053 2.58625Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    check: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2 4.80926C15.2 4.57838 15.1071 4.3475 14.9399 4.18126L13.6764 2.92527C13.5091 2.75904 13.2769 2.66669 13.0446 2.66669C12.8124 2.66669 12.5801 2.75904 12.4129 2.92527L6.31844 8.99281L3.58708 6.26842C3.41986 6.10219 3.1876 6.00983 2.95534 6.00983C2.72308 6.00983 2.49083 6.10219 2.3236 6.26842L1.06012 7.52441C0.892891 7.69064 0.799988 7.92152 0.799988 8.1524C0.799988 8.38328 0.892891 8.61416 1.06012 8.7804L5.6867 13.3795C5.85392 13.5458 6.08618 13.6381 6.31844 13.6381C6.5507 13.6381 6.78296 13.5458 6.95018 13.3795L14.9399 5.43725C15.1071 5.27102 15.2 5.04014 15.2 4.80926Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    chevronRight2x: (
      <svg
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.999878 2.70203C0.999878 2.43974 1.0929 2.17731 1.29766 1.95934L2.06388 1.23307C2.29728 1.03114 2.59084 0.929993 2.87854 0.929993C3.14948 0.929993 3.41381 1.01932 3.62198 1.1918L9.9608 7.14174C10.1833 7.37075 10.2914 7.65963 10.2914 7.9412C10.2914 8.20772 10.1953 8.46902 10.0037 8.67433L3.67553 14.6508C3.4598 14.8545 3.17311 14.96 2.87854 14.96C2.61495 14.96 2.34644 14.8751 2.1238 14.7056L1.34262 13.9761C1.12076 13.7477 1.01211 13.4598 1.01211 13.173C1.01211 12.9081 1.10487 12.6423 1.29379 12.4232L6.18485 7.93028L1.34491 3.52247C1.11805 3.28985 0.999878 2.99384 0.999878 2.70203Z"
          fill={fill || 'black'}
        />
        <path
          d="M9.09998 2.70203C9.09998 2.43974 9.193 2.17731 9.39776 1.95934L10.164 1.23307C10.3974 1.03114 10.6909 0.929993 10.9786 0.929993C11.2496 0.929993 11.5139 1.01932 11.7221 1.1918L18.0609 7.14174C18.2834 7.37075 18.3915 7.65963 18.3915 7.9412C18.3915 8.20772 18.2954 8.46902 18.1038 8.67433L11.7756 14.6508C11.5599 14.8545 11.2732 14.96 10.9786 14.96C10.715 14.96 10.4465 14.8751 10.2239 14.7056L9.44272 13.9761C9.22086 13.7477 9.11221 13.4598 9.11221 13.173C9.11221 12.9081 9.20497 12.6423 9.39389 12.4232L14.285 7.93028L9.445 3.52247C9.21815 3.28985 9.09998 2.99384 9.09998 2.70203Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    chevronDownThin: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8897 4.71716L8.33835 11.3871C8.24474 11.4827 8.13689 11.5305 8.015 11.5305C7.89333 11.5305 7.78526 11.4827 7.69165 11.3871L1.14052 4.71716C1.04669 4.62158 1 4.51197 1 4.38768C1 4.26338 1.04691 4.15377 1.14074 4.05841L1.84333 3.3431C1.93716 3.24752 2.04502 3.19995 2.16668 3.19995C2.28857 3.19995 2.39642 3.24752 2.49003 3.3431L8.015 8.96847L13.5402 3.34288C13.6338 3.24752 13.7419 3.20017 13.8633 3.20017C13.9854 3.20017 14.0933 3.24774 14.1869 3.34266L14.8897 4.05863C14.9835 4.15399 15.03 4.26382 15.03 4.3879C15.03 4.51197 14.9835 4.6218 14.8897 4.71716Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    chevronDown: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.258 3.49988C13.5202 3.49988 13.7827 3.5929 14.0007 3.79766L14.7269 4.56388C14.9289 4.79728 15.03 5.09084 15.03 5.37854C15.03 5.64948 14.9407 5.91381 14.7682 6.12198L8.81826 12.4608C8.58924 12.6833 8.30036 12.7914 8.01879 12.7914C7.75227 12.7914 7.49097 12.6953 7.28566 12.5037L1.30916 6.17553C1.10553 5.9598 1 5.67311 1 5.37854C1 5.11495 1.08488 4.84644 1.25439 4.6238L1.98384 3.84262C2.2123 3.62076 2.5002 3.51211 2.78698 3.51211C3.0519 3.51211 3.31768 3.60487 3.53677 3.7938L8.02971 8.68485L12.4375 3.84491C12.6701 3.61805 12.9661 3.49988 13.258 3.49988Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    chevronLeft2x: (
      <svg
        width={width || '19'}
        height={height || '16'}
        viewBox="0 0 19 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.1913 2.70203C18.1913 2.43974 18.0983 2.17731 17.8936 1.95934L17.1273 1.23307C16.8939 1.03114 16.6004 0.929993 16.3127 0.929993C16.0417 0.929993 15.7774 1.01932 15.5692 1.1918L9.23042 7.14174C9.00794 7.37075 8.89978 7.65963 8.89978 7.9412C8.89978 8.20772 8.99594 8.46902 9.18754 8.67433L15.5157 14.6508C15.7314 14.8545 16.0181 14.96 16.3127 14.96C16.5763 14.96 16.8448 14.8751 17.0674 14.7056L17.8486 13.9761C18.0705 13.7477 18.1791 13.4598 18.1791 13.173C18.1791 12.9081 18.0863 12.6423 17.8974 12.4232L13.0064 7.93028L17.8463 3.52247C18.0732 3.28985 18.1913 2.99384 18.1913 2.70203Z"
          fill={fill || 'black'}
        />
        <path
          d="M10.0912 2.70203C10.0912 2.43974 9.99823 2.17731 9.79347 1.95934L9.02725 1.23307C8.79385 1.03114 8.50029 0.929993 8.21259 0.929993C7.94165 0.929993 7.67732 1.01932 7.46914 1.1918L1.13032 7.14174C0.907844 7.37075 0.799683 7.65963 0.799683 7.9412C0.799683 8.20772 0.895846 8.46902 1.08745 8.67433L7.41559 14.6508C7.63132 14.8545 7.91801 14.96 8.21259 14.96C8.47617 14.96 8.74468 14.8751 8.96733 14.7056L9.7485 13.9761C9.97036 13.7477 10.079 13.4598 10.079 13.173C10.079 12.9081 9.98625 12.6423 9.79733 12.4232L4.90627 7.93028L9.74622 3.52247C9.97307 3.28985 10.0912 2.99384 10.0912 2.70203Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    chevronLeft: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6611 2.70277C12.6611 2.44048 12.5681 2.17804 12.3633 1.96007L11.5971 1.2338C11.3637 1.03187 11.0701 0.930725 10.7824 0.930725C10.5115 0.930725 10.2471 1.02006 10.039 1.19253L3.70015 7.14247C3.47767 7.37148 3.36951 7.66036 3.36951 7.94193C3.36951 8.20845 3.46567 8.46975 3.65727 8.67506L9.98542 14.6516C10.2011 14.8552 10.4878 14.9607 10.7824 14.9607C11.046 14.9607 11.3145 14.8758 11.5372 14.7063L12.3183 13.9769C12.5402 13.7484 12.6488 13.4605 12.6488 13.1737C12.6488 12.9088 12.5561 12.643 12.3672 12.424L7.4761 7.93102L12.316 3.5232C12.5429 3.29058 12.6611 2.99458 12.6611 2.70277Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    chevronRight: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.76959 2.70277C3.76959 2.44047 3.86261 2.17804 4.06737 1.96007L4.83359 1.2338C5.06699 1.03187 5.36055 0.930725 5.64825 0.930725C5.91919 0.930725 6.18352 1.02005 6.3917 1.19253L12.7305 7.14247C12.953 7.37148 13.0612 7.66036 13.0612 7.94193C13.0612 8.20845 12.965 8.46975 12.7734 8.67506L6.44524 14.6516C6.22952 14.8552 5.94283 14.9607 5.64825 14.9607C5.38466 14.9607 5.11615 14.8758 4.89351 14.7063L4.11234 13.9769C3.89048 13.7484 3.78182 13.4605 3.78182 13.1737C3.78182 12.9088 3.87459 12.643 4.06351 12.424L8.95457 7.93102L4.11462 3.5232C3.88776 3.29058 3.76959 2.99458 3.76959 2.70277Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    chevronUpThin: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8897 9.71332L8.33835 3.04337C8.24474 2.94779 8.13689 2.9 8.015 2.9C7.89333 2.9 7.78526 2.94779 7.69165 3.04337L1.14052 9.71332C1.04669 9.8089 1 9.91851 1 10.0428C1 10.1671 1.04691 10.2767 1.14074 10.3721L1.84333 11.0874C1.93716 11.183 2.04502 11.2305 2.16668 11.2305C2.28857 11.2305 2.39642 11.183 2.49003 11.0874L8.015 5.46201L13.5402 11.0876C13.6338 11.183 13.7419 11.2303 13.8633 11.2303C13.9854 11.2303 14.0933 11.1827 14.1869 11.0878L14.8897 10.3719C14.9835 10.2765 15.03 10.1667 15.03 10.0426C15.03 9.91851 14.9835 9.80868 14.8897 9.71332Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    chevronUpThin: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8897 9.71332L8.33835 3.04337C8.24474 2.94779 8.13689 2.9 8.015 2.9C7.89333 2.9 7.78526 2.94779 7.69165 3.04337L1.14052 9.71332C1.04669 9.8089 1 9.91851 1 10.0428C1 10.1671 1.04691 10.2767 1.14074 10.3721L1.84333 11.0874C1.93716 11.183 2.04502 11.2305 2.16668 11.2305C2.28857 11.2305 2.39642 11.183 2.49003 11.0874L8.015 5.46201L13.5402 11.0876C13.6338 11.183 13.7419 11.2303 13.8633 11.2303C13.9854 11.2303 14.0933 11.1827 14.1869 11.0878L14.8897 10.3719C14.9835 10.2765 15.03 10.1667 15.03 10.0426C15.03 9.91851 14.9835 9.80868 14.8897 9.71332Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    chevronUp: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.77204 12.3915C2.50975 12.3915 2.24732 12.2985 2.02935 12.0938L1.30308 11.3275C1.10115 11.0941 1 10.8006 1 10.5129C1 10.2419 1.08933 9.97761 1.26181 9.76944L7.21174 3.43062C7.44076 3.20814 7.72964 3.09998 8.01121 3.09998C8.27773 3.09998 8.53903 3.19614 8.74434 3.38774L14.7208 9.71589C14.9245 9.93162 15.03 10.2183 15.03 10.5129C15.03 10.7765 14.9451 11.045 14.7756 11.2676L14.0462 12.0488C13.8177 12.2707 13.5298 12.3793 13.243 12.3793C12.9781 12.3793 12.7123 12.2865 12.4932 12.0976L8.00029 7.20657L3.59247 12.0465C3.35985 12.2734 3.06385 12.3915 2.77204 12.3915Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    close: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1469 8.00071L13.8544 4.29321C14.2708 3.90882 14.2708 3.26816 13.8544 2.88377L13.1497 2.17905C12.7653 1.76262 12.1246 1.76262 11.7402 2.17905L8.03272 5.88655L4.29318 2.17905C3.90879 1.76262 3.26813 1.76262 2.88374 2.17905L2.17902 2.88377C1.76259 3.26816 1.76259 3.90882 2.17902 4.29321L5.88652 8.00071L2.17902 11.7402C1.76259 12.1246 1.76259 12.7653 2.17902 13.1497L2.88374 13.8544C3.26813 14.2708 3.90879 14.2708 4.29318 13.8544L8.03272 10.1469L11.7402 13.8544C12.1246 14.2708 12.7653 14.2708 13.1497 13.8544L13.8544 13.1497C14.2708 12.7653 14.2708 12.1246 13.8544 11.7402L10.1469 8.00071Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    commentAdd: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.677916 7.57143C0.677916 4.51786 3.94104 2 8.00563 2C12.0416 2 15.3334 4.51786 15.3334 7.57143C15.3334 10.6518 12.0416 13.1429 8.00563 13.1429C6.8893 13.1429 5.85884 12.9554 4.91425 12.6339C4.22728 13.1696 2.79608 14 0.906907 14C0.792412 14 0.735164 13.9732 0.677916 13.8929C0.649292 13.8125 0.677916 13.7054 0.735164 13.6518C0.735164 13.6479 0.759973 13.6203 0.803566 13.5718C1.06343 13.2825 1.99082 12.2502 2.30948 11.0804C1.27902 10.1161 0.677916 8.91071 0.677916 7.57143ZM11.0556 8.55128C11.1945 8.55128 11.3334 8.44712 11.3334 8.2735V6.77778C11.3334 6.63889 11.1945 6.5 11.0556 6.5H9.03367V4.48611C9.03367 4.34722 8.89478 4.20833 8.7559 4.20833H7.26017C7.08656 4.20833 6.98239 4.34722 6.98239 4.48611V6.5H4.94446C4.77085 6.5 4.66669 6.63889 4.66669 6.77778V8.2735C4.66669 8.44712 4.77085 8.55128 4.94446 8.55128H6.98239V10.5972C6.98239 10.7708 7.08656 10.875 7.26017 10.875H8.7559C8.89478 10.875 9.03367 10.7708 9.03367 10.5972V8.55128H11.0556Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    comment: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00563 2C3.94104 2 0.677916 4.51786 0.677916 7.57143C0.677916 8.91071 1.27902 10.1161 2.30948 11.0804C1.93737 12.4464 0.735164 13.625 0.735164 13.6518C0.677916 13.7054 0.649292 13.8125 0.677916 13.8929C0.735164 13.9732 0.792412 14 0.906907 14C2.79608 14 4.22728 13.1696 4.91425 12.6339C5.85884 12.9554 6.8893 13.1429 8.00563 13.1429C12.0416 13.1429 15.3334 10.6518 15.3334 7.57143C15.3334 4.51786 12.0416 2 8.00563 2Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    delete: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 1C4.13306 1 1 4.13306 1 8C1 11.8669 4.13306 15 8 15C11.8669 15 15 11.8669 15 8C15 4.13306 11.8669 1 8 1ZM11.4153 9.8629C11.5565 9.97581 11.5565 10.2016 11.4153 10.3427L10.3145 11.4435C10.1734 11.5847 9.94758 11.5847 9.83468 11.4435L8 9.58064L6.1371 11.4435C6.02419 11.5847 5.79839 11.5847 5.65726 11.4435L4.55645 10.3145C4.41532 10.2016 4.41532 9.97581 4.55645 9.83468L6.41935 8L4.55645 6.16532C4.41532 6.05242 4.41532 5.82661 4.55645 5.68548L5.68548 4.58468C5.79839 4.44355 6.02419 4.44355 6.16532 4.58468L8 6.41935L9.83468 4.58468C9.94758 4.44355 10.1734 4.44355 10.3145 4.58468L11.4153 5.68548C11.5565 5.82661 11.5565 6.05242 11.4153 6.16532L9.58064 8L11.4153 9.8629Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    download: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.50859 12.3981C7.77663 12.6673 8.22337 12.6673 8.49141 12.3981L14.299 6.59544C14.567 6.29633 14.567 5.84767 14.299 5.57847L13.614 4.89053C13.3459 4.62133 12.8992 4.62133 12.6014 4.89053L9.21477 8.26979V1.37065C9.21477 0.921111 8.86307 0.600014 8.44744 0.600014H7.42434C6.97673 0.600014 6.65701 0.921111 6.65701 1.37065V8.16295L3.39863 4.89053C3.1008 4.62133 2.65407 4.62133 2.38603 4.89053L1.70103 5.57847C1.43299 5.84767 1.43299 6.29633 1.70103 6.59544L7.50859 12.3981Z"
          fill={fill || 'black'}
        />
        <path
          d="M2.32857 13.4001L13.6714 13.4001C14.1647 13.4001 14.5 13.699 14.5 14.037V14.7631C14.5 15.121 14.1647 15.4001 13.6714 15.4001L2.32857 15.4001C1.80625 15.4001 1.5 15.121 1.5 14.7631V14.037C1.5 13.699 1.80625 13.4001 2.32857 13.4001Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    edit: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.34469 3.39248L1.37265 11.3645L0.791312 14.3964C0.733959 14.8266 1.10675 15.1994 1.5369 15.142L4.56877 14.5606L12.5408 6.5886L9.34469 3.39248ZM14.8054 2.37236L13.5609 1.12786C13.0448 0.583003 12.155 0.582193 11.6101 1.12705L10.0043 2.73292L13.2004 5.92905L14.8063 4.32317C15.3511 3.77832 15.3503 2.88854 14.8054 2.37236Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    error: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0614 4.4863C13.4355 3.41388 12.5863 2.56477 11.5139 1.93884C10.4413 1.31291 9.27032 1 8.00022 1C6.73024 1 5.55904 1.31291 4.48652 1.93884C3.41399 2.56465 2.56488 3.41377 1.93895 4.4863C1.31291 5.55882 1 6.73013 1 8C1 9.26987 1.31302 10.4411 1.93884 11.5137C2.56477 12.5859 3.41388 13.4352 4.48641 14.0612C5.55893 14.6871 6.73013 15 8.00011 15C9.27009 15 10.4414 14.6871 11.5138 14.0612C12.5862 13.4353 13.4353 12.5861 14.0613 11.5137C14.6871 10.4411 15 9.26976 15 8C15 6.73013 14.6871 5.55871 14.0614 4.4863ZM9.16695 12.3658C9.16695 12.451 9.13943 12.5222 9.08483 12.58C9.03024 12.6377 8.96334 12.6664 8.88424 12.6664H7.13422C7.05523 12.6664 6.98531 12.6361 6.92457 12.5754C6.86382 12.5146 6.8335 12.4447 6.8335 12.3658V10.6342C6.8335 10.5552 6.86382 10.4853 6.92457 10.4245C6.98531 10.3638 7.05523 10.3334 7.13422 10.3334H8.88424C8.96334 10.3334 9.03035 10.3623 9.08483 10.4199C9.13943 10.4779 9.16695 10.549 9.16695 10.6342V12.3658ZM9.1486 9.23049C9.14245 9.29124 9.11067 9.34449 9.05306 9.39002C8.99522 9.43555 8.92396 9.45826 8.83893 9.45826H7.15267C7.06754 9.45826 6.9946 9.43555 6.93385 9.39002C6.8731 9.34449 6.84267 9.29124 6.84267 9.23049L6.68773 3.57028C6.68773 3.49723 6.71805 3.44263 6.7788 3.40616C6.83965 3.35761 6.9126 3.33322 6.99762 3.33322H9.00305C9.08819 3.33322 9.16102 3.3575 9.22176 3.40616C9.28251 3.44263 9.31272 3.49734 9.31272 3.57028L9.1486 9.23049Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    external: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1494 0.820651C15.0224 0.693684 14.8476 0.615069 14.6573 0.615069L10.2979 0.600067C10.1005 0.608894 9.92417 0.691922 9.79731 0.818774C9.66789 0.948186 9.58983 1.12282 9.58983 1.31265V2.00167C9.58983 2.19151 9.66789 2.36614 9.79731 2.49555C9.92394 2.62217 10.0999 2.70513 10.2968 2.71421L11.7386 2.72212L5.93948 8.52117C5.78767 8.67298 5.71707 8.86238 5.71707 9.04917C5.71707 9.24268 5.79354 9.43457 5.93947 9.5805L6.45403 10.0951C6.60852 10.2496 6.79694 10.3235 6.9837 10.3235C7.17046 10.3235 7.35888 10.2496 7.51336 10.0951L13.2558 4.35262V5.67257C13.2647 5.86977 13.3477 6.04597 13.4745 6.17274C13.6039 6.30215 13.7786 6.3802 13.9684 6.3802H14.6573C14.8472 6.3802 15.0218 6.30215 15.1512 6.17274C15.2781 6.04588 15.3611 5.86953 15.37 5.67217L15.355 1.31192C15.355 1.12241 15.2764 0.947621 15.1494 0.820651ZM8.38184 2.82835C8.25463 2.74079 8.09882 2.68729 7.9224 2.68729H2.14859C1.74487 2.68729 1.38564 2.85033 1.12583 3.11014C0.861201 3.37477 0.699951 3.74002 0.699951 4.13592V13.8652C0.699951 14.2727 0.859371 14.6342 1.11947 14.8943C1.37958 15.1544 1.74116 15.3138 2.14859 15.3138H11.9292C12.3251 15.3138 12.6903 15.1526 12.9549 14.888C13.2147 14.6282 13.3778 14.269 13.3778 13.8652V8.06649C13.3778 7.8918 13.3253 7.7373 13.2392 7.61034C13.1467 7.47394 13.0146 7.36935 12.8647 7.30721C12.7152 7.24519 12.5483 7.2256 12.3866 7.25679C12.2368 7.28569 12.0909 7.35773 11.967 7.48167L11.5519 7.8968C11.4142 8.03438 11.3108 8.30952 11.3108 8.4816V13.2468H2.76699V4.75433H7.50728C7.68452 4.75433 7.95814 4.64344 8.07387 4.50545L8.47908 4.10023C8.60793 3.97851 8.6845 3.83556 8.71835 3.68896C8.75577 3.52691 8.74061 3.35955 8.6819 3.2091C8.62254 3.05701 8.51876 2.92259 8.38184 2.82835Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    filter: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3277 1.16666H1.67225C1.07091 1.16666 0.770241 1.87711 1.20758 2.28698L6.26431 7.34207V13.1733C6.26431 13.3919 6.34631 13.6105 6.53765 13.7198L8.72434 15.25C9.16168 15.5506 9.76302 15.25 9.76302 14.7035V7.34207L14.7924 2.28698C15.2298 1.87711 14.9291 1.16666 14.3277 1.16666Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    info: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0614 4.4863C13.4355 3.41388 12.5863 2.56477 11.5139 1.93884C10.4413 1.31291 9.27032 1 8.00022 1C6.73024 1 5.55893 1.31291 4.48652 1.93884C3.41399 2.56465 2.56488 3.41377 1.93895 4.4863C1.31291 5.55882 1 6.73013 1 8C1 9.26998 1.31302 10.4411 1.93884 11.5137C2.56477 12.586 3.41388 13.4352 4.48641 14.0612C5.55893 14.6871 6.73013 15 8.00011 15C9.27009 15 10.4414 14.6871 11.5138 14.0612C12.5862 13.4353 13.4353 12.5861 14.0613 11.5137C14.6871 10.4412 15 9.26987 15 8C15 6.73002 14.6871 5.55871 14.0614 4.4863ZM6.8335 2.74992C6.8335 2.66478 6.8608 2.59497 6.9155 2.54027C6.97021 2.48567 7.04013 2.45826 7.12515 2.45826H8.87529C8.96032 2.45826 9.03024 2.48567 9.08472 2.54027C9.13931 2.59497 9.16683 2.66478 9.16683 2.74992V4.20829C9.16683 4.29331 9.13931 4.36324 9.08472 4.41783C9.03024 4.47254 8.96032 4.49983 8.87529 4.49983H7.12515C7.04013 4.49983 6.97021 4.47254 6.9155 4.41783C6.8608 4.36324 6.8335 4.29331 6.8335 4.20829V2.74992ZM10.3334 12.375C10.3334 12.46 10.3059 12.53 10.2514 12.5844C10.197 12.6391 10.127 12.6664 10.0418 12.6664H5.95843C5.8733 12.6664 5.80349 12.6391 5.74878 12.5844C5.69408 12.5298 5.66678 12.4599 5.66678 12.3749V10.9165C5.66678 10.8313 5.69408 10.7616 5.74878 10.7069C5.80349 10.6523 5.87341 10.6249 5.95843 10.6249H6.8335V7.70824H5.95843C5.8733 7.70824 5.80349 7.68094 5.74878 7.62623C5.69408 7.57164 5.66678 7.50172 5.66678 7.41658V5.95832C5.66678 5.87318 5.69408 5.80338 5.74878 5.74867C5.80349 5.69396 5.87341 5.66667 5.95843 5.66667H8.87529C8.96032 5.66667 9.03024 5.69396 9.08472 5.74867C9.13931 5.80338 9.16683 5.87318 9.16683 5.95832V10.625H10.0417C10.1268 10.625 10.1967 10.6524 10.2513 10.707C10.3058 10.7616 10.3333 10.8315 10.3333 10.9166V12.375H10.3334Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    lock: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2381 7.125H12.5238V5.15625C12.5238 2.88672 10.4702 1 7.99998 1C5.49998 1 3.47617 2.88672 3.47617 5.15625V7.125H2.76188C1.95831 7.125 1.33331 7.72656 1.33331 8.4375V13.6875C1.33331 14.4258 1.95831 15 2.76188 15H13.2381C14.0119 15 14.6666 14.4258 14.6666 13.6875V8.4375C14.6666 7.72656 14.0119 7.125 13.2381 7.125ZM10.1428 7.125H5.85712V5.15625C5.85712 4.08984 6.8095 3.1875 7.99998 3.1875C9.16069 3.1875 10.1428 4.08984 10.1428 5.15625V7.125Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    minus: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7143 6.51997L2.28576 6.51999C1.75004 6.51999 1.33337 6.96735 1.33337 7.47331V8.55999C1.33337 9.09571 1.75004 9.51331 2.28576 9.51331L13.7143 9.51333C14.2203 9.51333 14.6667 9.09571 14.6667 8.55999V7.47331C14.6667 6.96735 14.2203 6.51997 13.7143 6.51997Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    plus: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7143 6.57142H9.42861V2.28571C9.42861 1.77976 8.98218 1.33333 8.47623 1.33333H7.52385C6.98814 1.33333 6.57147 1.77976 6.57147 2.28571V6.57142H2.28575C1.75004 6.57142 1.33337 7.01785 1.33337 7.5238V8.47619C1.33337 9.0119 1.75004 9.42857 2.28575 9.42857H6.57147V13.7143C6.57147 14.25 6.98814 14.6667 7.52385 14.6667H8.47623C8.98218 14.6667 9.42861 14.25 9.42861 13.7143V9.42857H13.7143C14.2203 9.42857 14.6667 9.0119 14.6667 8.47619V7.5238C14.6667 7.01785 14.2203 6.57142 13.7143 6.57142Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    print: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7143 6.57142H9.42861V2.28571C9.42861 1.77976 8.98218 1.33333 8.47623 1.33333H7.52385C6.98814 1.33333 6.57147 1.77976 6.57147 2.28571V6.57142H2.28575C1.75004 6.57142 1.33337 7.01785 1.33337 7.5238V8.47619C1.33337 9.0119 1.75004 9.42857 2.28575 9.42857H6.57147V13.7143C6.57147 14.25 6.98814 14.6667 7.52385 14.6667H8.47623C8.98218 14.6667 9.42861 14.25 9.42861 13.7143V9.42857H13.7143C14.2203 9.42857 14.6667 9.0119 14.6667 8.47619V7.5238C14.6667 7.01785 14.2203 6.57142 13.7143 6.57142Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    question: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15C11.8657 15 15 11.8669 15 8C15 4.13541 11.8657 1 8 1C4.13428 1 1 4.13541 1 8C1 11.8669 4.13428 15 8 15ZM8.11681 3.64775C6.73379 3.64775 5.85171 4.23034 5.15904 5.26579C5.06931 5.39993 5.09933 5.58085 5.22795 5.67838L6.10853 6.34607C6.24062 6.44624 6.42882 6.42241 6.53145 6.29222C6.9848 5.71721 7.29566 5.38377 7.98568 5.38377C8.50412 5.38377 9.14539 5.71743 9.14539 6.22017C9.14539 6.60023 8.83165 6.79541 8.31975 7.08241L8.27668 7.10652C7.6855 7.43731 6.9328 7.85846 6.9328 8.87558V8.97709C6.9328 9.14527 7.06916 9.28162 7.23734 9.28162H8.65849C8.82667 9.28162 8.96303 9.14527 8.96303 8.97709V8.94326C8.96303 8.67018 9.26478 8.49604 9.64012 8.27944C10.2576 7.92313 11.0741 7.45191 11.0741 6.23628C11.0741 4.76432 9.54725 3.64775 8.11681 3.64775ZM7.9479 9.94153C7.30419 9.94153 6.78052 10.4652 6.78052 11.1089C6.78052 11.7526 7.30419 12.2763 7.9479 12.2763C8.59161 12.2763 9.11528 11.7526 9.11528 11.1089C9.11528 10.4652 8.59161 9.94153 7.9479 9.94153Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    save: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2438 4.17482L11.7589 1.6899C11.6586 1.58963 11.5226 1.53329 11.3806 1.53329H2.13456C1.83925 1.53329 1.60004 1.77269 1.60004 2.06781V13.7988C1.60004 14.0941 1.83941 14.3333 2.13456 14.3333H13.8655C14.1608 14.3333 14.4 14.0939 14.4 13.7988V4.55273C14.4 4.41095 14.3437 4.27493 14.2434 4.17479L14.2438 4.17482ZM11.0536 6.67649H3.7108V2.75559H11.0536V6.67649ZM9.68497 6.19188H8.33042C8.23197 6.19188 8.1522 6.11208 8.1522 6.01364V3.40682C8.1522 3.3084 8.23197 3.2286 8.33042 3.2286H9.68497C9.78342 3.2286 9.86319 3.3084 9.86319 3.40682V6.01364C9.86319 6.11208 9.78342 6.19188 9.68497 6.19188Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    search: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.69669 9.69688C10.4692 8.92395 10.8565 7.99493 10.8565 6.90638C10.8565 5.81989 10.4701 4.88972 9.69669 4.11611C8.92376 3.34365 7.99474 2.95718 6.90619 2.95718C5.8197 2.95718 4.88953 3.34365 4.11592 4.11611C3.34346 4.8888 2.95699 5.81989 2.95699 6.90638C2.95699 7.99402 3.34346 8.92395 4.11592 9.69665C4.88861 10.4691 5.8197 10.8565 6.90619 10.8565C7.99383 10.8565 8.92376 10.4703 9.69646 9.69665L9.69669 9.69688ZM15.0432 13.4483C15.2612 13.6653 15.3698 13.9303 15.3698 14.2413C15.3698 14.5476 15.2584 14.8116 15.0345 15.0344C14.8279 15.2506 14.5412 15.3717 14.2423 15.3691C13.9235 15.3691 13.6601 15.2575 13.4481 15.0344L10.424 12.0195C9.39397 12.7416 8.16407 13.1239 6.90619 13.1129C6.06611 13.1129 5.26315 12.9506 4.4955 12.624C3.75498 12.3159 3.08137 11.8669 2.51208 11.3019C1.94671 10.7323 1.4973 10.0584 1.18881 9.31752C0.863694 8.55538 0.697334 7.73496 0.699857 6.90638C0.699857 6.06629 0.863319 5.26334 1.18881 4.49569C1.4973 3.7551 1.94671 3.08149 2.51208 2.51227C3.08079 1.94671 3.75414 1.49726 4.49459 1.189C5.2568 0.863594 6.07742 0.697223 6.90619 0.70004C7.74742 0.70004 8.55128 0.862362 9.31802 1.189C10.0587 1.4974 10.7323 1.94683 11.3014 2.51227C11.8661 3.08184 12.3151 3.75538 12.6236 4.49569C12.9488 5.25764 13.1153 6.07792 13.1127 6.90638C13.1127 8.19917 12.7478 9.37093 12.0194 10.4242L15.0432 13.4483Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    signOut: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7284 6.21984L9.74976 11.219C9.30524 11.6654 8.53475 11.3529 8.53475 10.7131V7.85647H4.50445C4.11031 7.85647 3.79322 7.53807 3.79322 7.1423V4.28564C3.79322 3.88987 4.11031 3.57147 4.50445 3.57147H8.53475V0.7148C8.53475 0.078001 9.30228 -0.237423 9.74976 0.208932L14.7284 5.2081C15.004 5.48782 15.004 5.94012 14.7284 6.21984ZM5.68983 11.0702V9.87994C5.68983 9.68355 5.5298 9.52286 5.33422 9.52286H2.84492C2.32038 9.52286 1.89661 9.09734 1.89661 8.57064V2.8573C1.89661 2.3306 2.32038 1.90508 2.84492 1.90508H5.33422C5.5298 1.90508 5.68983 1.74439 5.68983 1.54799V0.357716C5.68983 0.161321 5.5298 0.000632888 5.33422 0.000632888H2.84492C1.27429 0.000632888 0 1.28018 0 2.8573V8.57064C0 10.1478 1.27429 11.4273 2.84492 11.4273H5.33422C5.5298 11.4273 5.68983 11.2666 5.68983 11.0702Z"
          fill={fill || 'none'}
        />
      </svg>
    ),
    success: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0614 4.4863C13.4355 3.41388 12.5863 2.56477 11.5139 1.93884C10.4413 1.31291 9.27032 1 8.00022 1C6.73024 1 5.55893 1.31291 4.48652 1.93884C3.41399 2.56465 2.56488 3.41377 1.93895 4.4863C1.31291 5.55882 1 6.73013 1 8C1 9.26998 1.31302 10.4411 1.93884 11.5137C2.56477 12.586 3.41388 13.4352 4.48641 14.0612C5.55893 14.6871 6.73013 15 8.00011 15C9.27009 15 10.4414 14.6871 11.5138 14.0612C12.5862 13.4353 13.4353 12.5861 14.0613 11.5137C14.6871 10.4412 15 9.26987 15 8C15 6.73002 14.6871 5.55871 14.0614 4.4863ZM12.539 6.70988L7.58987 11.659C7.47442 11.7745 7.33469 11.8323 7.17057 11.8323C7.01261 11.8323 6.8759 11.7745 6.76045 11.659L3.46087 8.35967C3.35157 8.25015 3.29686 8.11366 3.29686 7.94954C3.29686 7.77939 3.35146 7.63955 3.46087 7.53013L4.29029 6.70988C4.40586 6.59443 4.54246 6.5367 4.70053 6.5367C4.85861 6.5367 4.99532 6.59443 5.11077 6.70988L7.17069 8.7698L10.8895 5.06009C11.0049 4.94464 11.1416 4.88691 11.2996 4.88691C11.4574 4.88691 11.5944 4.94464 11.7097 5.06009L12.5391 5.88046C12.6487 5.98976 12.7032 6.12948 12.7032 6.29964C12.7032 6.46376 12.6487 6.60047 12.539 6.70988Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    toggle: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7477 6.57311C12.625 6.69984 12.4794 6.76321 12.3113 6.76321H3.62083C3.45274 6.76321 3.30726 6.69984 3.18429 6.57311C3.06143 6.44637 3 6.2963 3 6.12289C3 5.94949 3.06143 5.7993 3.18429 5.67256L7.52952 1.19011C7.65238 1.06349 7.79786 1 7.96607 1C8.13429 1 8.27976 1.06349 8.4025 1.19011L12.7477 5.67256C12.8705 5.79942 12.9321 5.94949 12.9321 6.12289C12.9321 6.2963 12.8705 6.44637 12.7477 6.57311ZM3.18429 9.51481C3.30714 9.3882 3.45262 9.32483 3.62083 9.32483H12.3113C12.4794 9.32483 12.625 9.38807 12.7477 9.51481C12.8705 9.64155 12.9321 9.79162 12.9321 9.96502C12.9321 10.1384 12.8705 10.2886 12.7477 10.4152L8.4025 14.8977C8.27976 15.0244 8.13417 15.0879 7.96607 15.0879C7.79798 15.0879 7.6525 15.0244 7.52952 14.8977L3.18429 10.4152C3.06143 10.2886 3 10.1384 3 9.96502C3 9.79162 3.06143 9.64155 3.18429 9.51481Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    trash: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.74759 13.8085C2.74759 14.5616 3.32572 15.1473 4.06902 15.1473H11.9976C12.7134 15.1473 13.319 14.5616 13.319 13.8085V4.4368H2.74759V13.8085ZM10.2357 6.66816C10.2357 6.44502 10.4284 6.22189 10.6762 6.22189C10.8964 6.22189 11.1166 6.44502 11.1166 6.66816V12.916C11.1166 13.167 10.8964 13.3622 10.6762 13.3622C10.4284 13.3622 10.2357 13.167 10.2357 12.916V6.66816ZM7.59283 6.66816C7.59283 6.44502 7.78554 6.22189 8.0333 6.22189C8.25354 6.22189 8.47378 6.44502 8.47378 6.66816V12.916C8.47378 13.167 8.25354 13.3622 8.0333 13.3622C7.78554 13.3622 7.59283 13.167 7.59283 12.916V6.66816ZM4.94997 6.66816C4.94997 6.44502 5.14268 6.22189 5.39045 6.22189C5.61069 6.22189 5.83092 6.44502 5.83092 6.66816V12.916C5.83092 13.167 5.61069 13.3622 5.39045 13.3622C5.14268 13.3622 4.94997 13.167 4.94997 12.916V6.66816ZM13.7595 1.75917H10.4559L10.1806 1.25711C10.0705 1.03397 9.82274 0.866623 9.6025 0.866623H6.43658C6.21634 0.866623 5.94104 1.03397 5.85845 1.25711L5.61069 1.75917H2.30711C2.05935 1.75917 1.86664 1.9823 1.86664 2.20544V3.09798C1.86664 3.34901 2.05935 3.54425 2.30711 3.54425H13.7595C13.9797 3.54425 14.2 3.34901 14.2 3.09798V2.20544C14.2 1.9823 13.9797 1.75917 13.7595 1.75917Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    triangleDown: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.81985 4.3997C1.9135 4.3997 1.43874 5.47869 2.1293 6.16925L7.26529 11.3052C7.65373 11.6937 8.30112 11.6937 8.68956 11.3052L13.8256 6.16925C14.5161 5.47869 14.0414 4.3997 13.0918 4.3997H2.81985Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    triangleUp: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.81985 4.3997C1.9135 4.3997 1.43874 5.47869 2.1293 6.16925L7.26529 11.3052C7.65373 11.6937 8.30112 11.6937 8.68956 11.3052L13.8256 6.16925C14.5161 5.47869 14.0414 4.3997 13.0918 4.3997H2.81985Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    warning: (
      <svg
        width={width || '16'}
        height={height || '16'}
        viewBox="0 0 16 16"
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2308 13.0613L8.95102 1.54789C8.85828 1.37888 8.73022 1.24529 8.56642 1.14724C8.40302 1.04908 8.22578 1 8.03499 1C7.84419 1 7.66695 1.04918 7.50345 1.14724C7.33996 1.24529 7.21179 1.37888 7.11915 1.54789L0.839205 13.0613C0.648411 13.4048 0.65383 13.7483 0.855564 14.0917C0.948302 14.2497 1.07506 14.3752 1.23585 14.4677C1.39673 14.5604 1.56976 14.6069 1.75514 14.6069H14.3149C14.5005 14.6069 14.6734 14.5604 14.8343 14.4677C14.9952 14.3752 15.1218 14.2497 15.2146 14.0917C15.4163 13.7482 15.4218 13.4048 15.2308 13.0613ZM9.08189 12.2435C9.08189 12.3198 9.0559 12.3838 9.00431 12.4356C8.95252 12.4874 8.8912 12.5132 8.82024 12.5132H7.25023C7.17937 12.5132 7.11805 12.4874 7.06626 12.4356C7.01447 12.3837 6.98858 12.3198 6.98858 12.2435V10.6899C6.98858 10.6136 7.01447 10.5496 7.06626 10.4978C7.11805 10.446 7.17927 10.4202 7.25023 10.4202H8.82014C8.8911 10.4202 8.95242 10.446 9.00421 10.4978C9.0559 10.5496 9.08179 10.6136 9.08179 10.6899V12.2435H9.08189ZM9.06564 9.18538C9.06001 9.23988 9.03141 9.28484 8.97972 9.32027C8.92783 9.3558 8.8639 9.37337 8.78752 9.37337H7.27472C7.19844 9.37337 7.133 9.3557 7.07851 9.32027C7.02391 9.28474 6.99671 9.23988 6.99671 9.18518L6.8577 5.44849C6.8577 5.37211 6.885 5.3149 6.9395 5.27676C7.01036 5.21684 7.0758 5.18683 7.13581 5.18683H8.93476C8.99478 5.18683 9.06022 5.21674 9.13107 5.27676C9.18557 5.3149 9.21267 5.36669 9.21267 5.43213L9.06564 9.18538Z"
          fill={fill || 'black'}
        />
      </svg>
    ),
    gsaFleet: (
      <svg
        width="157"
        height="72"
        viewBox="0 0 157 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 72H71.2641V0H0V72Z"
          fill="#005087"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.4812 48.7797L55.8791 44.8769L54.1692 48.7797H50.2944L55.7473 36.6635L61.231 48.7797H57.4812Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.7314 51.4292L50.7317 56.0344L55.6061 53.5101L58.2938 55.6556L48.7941 60.3238H45.0443L50.0441 48.9689L52.7314 51.4292Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.1688 60.3238H62.1061L58.4152 51.5059L61.0604 48.8999L66.1688 60.3238Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.1316 37.3969C42.1316 37.3969 41.5766 40.8131 41.6296 40.9717C39.5235 39.9093 36.523 39.2709 34.5233 40.3868C33.5265 40.9429 33.1869 42.5002 33.6279 43.6814C36.8915 47.2941 42.6826 46.4442 42.9976 53.4046C43.0986 55.6395 41.8971 57.3317 40.8396 58.3996C37.7946 60.8059 33.3644 60.6309 29.8373 59.3555L30.2063 55.5824C32.2593 56.6983 36.0494 57.7091 38.2605 55.6363C39.0179 54.9262 39.2075 53.7226 39.1021 52.5012C37.8385 48.7815 27.8372 48.941 29.6793 41.2372C30.1216 39.3888 31.6699 37.7593 33.5224 37.0926C36.1024 35.8894 39.6182 36.5945 42.1316 37.3969Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7733 37.8712L23.6474 41.6658C20.8687 39.8622 15.9409 39.1996 12.7452 41.0965C9.89768 42.7881 8.98682 45.2712 8.92389 48.3063C8.85689 51.5411 10.1612 54.5758 13.3215 56.2446C15.3936 57.3381 20.2263 56.8085 20.2263 56.8085L20.2023 51.0421L16.0328 51.0508L16.069 47.4838H24.1164L24.1323 59.6128C19.6966 60.9471 13.8725 61.0353 9.98867 58.3617C5.82418 55.4952 5.1012 51.9903 5.1012 48.3063C5.1012 43.3739 8.0239 38.107 13.8105 36.9857C17.6092 36.2491 21.2037 36.8591 23.7733 37.8712Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.4892 38.2976H99.5482V39.3988H86.7047V48.6184H98.2394V49.7197H86.7047V60.3234H85.4892V38.2976Z"
          fill="#772430"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.04 60.3234H104.256V36.5671H103.04V60.3234Z"
          fill="#772430"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.776 50.9466H119.687C119.78 47.7374 118.409 45.0628 114.792 45.0628C111.582 45.0628 109.212 47.2969 108.776 50.9466ZM114.762 44.0246C119.032 44.0246 120.871 46.9821 120.871 50.884C120.871 51.1669 120.84 51.6389 120.809 51.8907H108.714V52.1425C108.714 56.7993 110.709 59.6631 114.824 59.6631C116.632 59.6631 118.471 58.9073 119.655 58.0582L120.279 58.9703C118.938 59.9455 116.881 60.7013 114.792 60.7013C109.961 60.7013 107.498 57.4601 107.498 52.1425C107.498 47.1708 110.583 44.0246 114.762 44.0246Z"
          fill="#772430"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.456 50.9466H135.367C135.461 47.7374 134.089 45.0628 130.472 45.0628C127.262 45.0628 124.893 47.2969 124.456 50.9466ZM130.442 44.0246C134.713 44.0246 136.551 46.9821 136.551 50.884C136.551 51.1669 136.52 51.6389 136.489 51.8907H124.394V52.1425C124.394 56.7993 126.389 59.6631 130.504 59.6631C132.312 59.6631 134.151 58.9073 135.336 58.0582L135.959 58.9703C134.619 59.9455 132.561 60.7013 130.472 60.7013C125.641 60.7013 123.178 57.4601 123.178 52.1425C123.178 47.1708 126.264 44.0246 130.442 44.0246Z"
          fill="#772430"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140.823 45.4403H137.83V44.4336H140.823V39.2101H142.039V44.4336H146.715V45.4403H142.039V56.3903C142.039 58.4672 142.693 59.6631 144.72 59.6631C145.53 59.6631 146.341 59.3797 146.995 58.9703L147.494 59.8198C146.746 60.3234 145.718 60.7013 144.657 60.7013C141.883 60.7013 140.823 59.1595 140.823 56.3903V45.4403Z"
          fill="#772430"
        />
        <path
          d="M148.177 38.8748V38.173C148.615 38.3572 148.988 38.4493 149.294 38.4493C149.528 38.4493 149.703 38.4051 149.82 38.3167C149.936 38.2246 149.995 38.0865 149.995 37.9023C149.995 37.6776 149.807 37.4584 149.431 37.2447L149.157 37.0955C148.497 36.7161 148.166 36.298 148.166 35.8412C148.166 35.5023 148.289 35.2315 148.533 35.0289C148.781 34.8226 149.108 34.7195 149.513 34.7195C149.831 34.7195 150.194 34.7674 150.602 34.8632V35.5152C150.186 35.3605 149.858 35.2831 149.617 35.2831C149.409 35.2831 149.254 35.3218 149.152 35.3991C149.05 35.4728 148.998 35.5852 148.998 35.7362C148.998 35.9278 149.186 36.1304 149.562 36.344L149.852 36.5098C150.539 36.9003 150.882 37.3294 150.882 37.7973C150.882 38.2025 150.75 38.5064 150.487 38.709C150.225 38.9116 149.831 39.0129 149.305 39.0129C148.98 39.0129 148.604 38.9669 148.177 38.8748ZM151.79 38.9135V34.8245H152.907L153.876 37.6978H153.887L154.889 34.8245H155.863V38.9135H155.091V35.8246H155.075L154.101 38.6372H153.422L152.464 35.7859H152.453V38.9135H151.79Z"
          fill="#772430"
        />
      </svg>
    ),
    gsaLogo: (
      <svg
        width="71"
        height="71"
        viewBox="0 0 71 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M71 0H0V71H71V0Z" fill="#005087" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.2056 47.3838L55.7318 35.21L50.2554 47.3838H54.0688L55.7318 43.3382L57.3917 47.3838H61.2056ZM24.338 58.2226C22.9004 58.7138 20.6994 59.2933 17.1875 59.2933C9.98076 59.2933 5.15698 54.6437 5.15698 47.4062C5.15698 40.3835 10.2773 35.7099 17.2197 35.7099C20.5164 35.7099 21.9861 36.1983 24.0692 36.9158L23.7557 40.8496C21.8387 39.599 20.2067 39.0991 17.3797 39.0991C12.415 39.0991 9.10088 42.6851 9.12437 47.501C9.14785 52.6833 12.7553 55.9052 17.2197 55.9052C18.5507 55.9052 19.6818 55.8081 20.4945 55.595V49.6909H16.2011V46.3638H24.338V58.2226ZM42.9351 52.3764C42.9351 57.358 39.0163 59.4378 34.4962 59.2933C32.8915 59.2415 31.3289 58.8365 29.6265 58.2821L30.0127 54.696C31.4535 55.2881 33.3208 55.9297 34.8845 55.9057C36.9272 55.8741 38.9682 54.9277 38.9682 52.5672C38.9682 50.7491 37.51 49.8545 36.0998 49.142C35.7158 48.9476 35.3248 48.7605 34.9342 48.5736L34.9342 48.5736L34.9342 48.5736C32.1529 47.2429 29.3955 45.9237 29.3955 42.056C29.3955 37.8856 32.7184 35.7104 36.8371 35.7104C38.3341 35.7104 40.1218 35.9705 41.9159 36.605L41.3779 40.1365C39.4243 39.3717 38.5706 39.1002 36.966 39.1002C35.1893 39.1002 33.3629 39.7233 33.3629 41.7693C33.3629 43.3682 35.0069 44.1985 36.2292 44.8074C36.4419 44.9133 36.6549 45.018 36.8673 45.1225C39.9635 46.6443 42.9351 48.1049 42.9351 52.3764ZM61.2684 47.5261L66.3637 58.8926H62.3597L58.5512 50.0665L61.2684 47.5261ZM52.9093 50.0676L50.1932 47.5283L45.0974 58.8937H49.0063L58.4999 54.2566L55.7242 52.1229L50.9026 54.6464L52.9093 50.0676Z"
          fill="white"
        />
      </svg>
    ),
  };

  return icons[iconName];
};
