import React from 'react';
import PropTypes from 'prop-types';
import { ResetButton } from '../..';
import { useFilterPanel } from './FilterProvider';

export default function ClearAllButton({
  showClearIcon,
  clearButtonLabel,
  handleClearAll,
}) {
  const { clearAllFilters, mergedFilters } = useFilterPanel();

  const renderClearButton = () => {
    return mergedFilters.find((filter) => {
      const { value, permanent } = filter;

      return (
        ((typeof value === 'string' && value) ||
          (typeof value === 'object' && value.length)) &&
        !permanent
      );
    });
  };

  return renderClearButton() ? (
    <ResetButton
      className="margin-bottom-2"
      aria-label="Reset All Filters"
      data-testid="Reset All Filters"
      onClick={(e) => {
        clearAllFilters(e);
        if (handleClearAll) {
          handleClearAll();
        }
      }}
      iconBefore
      hideIcon={!showClearIcon}
      underline
    >
      {clearButtonLabel}
    </ResetButton>
  ) : null;
}

ClearAllButton.defaultProps = {
  handleClearAll: undefined,
};

ClearAllButton.propTypes = {
  showClearIcon: PropTypes.bool.isRequired,
  clearButtonLabel: PropTypes.string.isRequired,
  handleClearAll: PropTypes.func,
};
