import { useRef, useState, useEffect } from 'react';
import { upperCase, isEmpty } from 'lodash';

const MenuFocus = Object.freeze({
  First: 'First',
  Last: 'Last',
  Previous: 'Previous',
  Next: 'Next',
});

const menuKeys = Object.freeze({
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  UP: 38,
  DOWN: 40,
  END: 35,
  HOME: 36,
});

const findMenuItemIndex = ({ menuItems, keyCode }) => {
  if (isEmpty(menuItems)) {
    return -1;
  }
  return menuItems.findIndex(
    (item) => upperCase(item.label)[0] === String.fromCharCode(keyCode),
  );
};

const useMenu = () => {
  const [menuItemsCount, setMenuItemsCount] = useState(0);

  let currentItemIndex = -1;

  const menuItemsRef = useRef([]);

  const focusByItemIndex = (itemIndex) => {
    currentItemIndex = itemIndex;
    menuItemsRef.current[itemIndex].focus();
  };

  const [isMenuOpen, setsMenuOpen] = useState(false);
  useEffect(() => {
    if (isMenuOpen) {
      // eslint-disable-next-line
      menuItemsRef.current[0] && focusByItemIndex(0);
    }
  }, [isMenuOpen]);

  // DO not need to reset the currentItemIndex because menu close will reset the state
  // when a reset function is needed please add * [reset]:    currentItemIndex === -1 *
  const focusMenuItem = {
    [MenuFocus.First]: () => {
      focusByItemIndex(0);
    },
    [MenuFocus.Last]: () => {
      focusByItemIndex(menuItemsCount - 1);
    },
    [MenuFocus.Next]: () => {
      // eslint-disable-next-line
      currentItemIndex === menuItemsCount - 1
        ? focusByItemIndex(0)
        : focusByItemIndex(currentItemIndex + 1);
    },
    [MenuFocus.Previous]: () => {
      // eslint-disable-next-line
      currentItemIndex <= 0
        ? focusByItemIndex(menuItemsCount - 1)
        : focusByItemIndex(currentItemIndex - 1);
    },
  };
  const focusKeyDownMap = {
    [menuKeys.DOWN]: focusMenuItem[`${MenuFocus.Next}`],
    [menuKeys.UP]: focusMenuItem[`${MenuFocus.Previous}`],
    [menuKeys.HOME]: focusMenuItem[`${MenuFocus.First}`],
    [menuKeys.END]: focusMenuItem[`${MenuFocus.Last}`],
  };

  return {
    menuItemsRef,
    setMenuItemsCount,
    focusByItemIndex,
    focusKeyDownMap,
    isMenuOpen,
    setsMenuOpen,
  };
};

export { MenuFocus, menuKeys, useMenu, findMenuItemIndex };
