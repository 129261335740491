import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import './summaryBox.scss';

export const SummaryContext = createContext();

const SummaryBox = ({
    children,
    className
}) => {

    const [ summaryId, setSummaryId ] = useState(`summary-box-${uniqueId()}`);
    const classes = classnames(
        'usa-summary-box',
        className,
    );

    return (
        <section
            className={classes}
            aria-labelledby={summaryId}
        >
            <div className="usa-summary-box__body">
                <SummaryContext.Provider value={{ summaryId, setSummaryId }}>
                    {children}
                </SummaryContext.Provider>
            </div>
        </section>
    );
};

SummaryBox.defaultProps = {
    className: ''
};


SummaryBox.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    className: PropTypes.string
};

export default SummaryBox;