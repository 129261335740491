import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../../atoms/Icon/Icon';
import MenuDialog, {
  MenuItemsType,
} from '../../molecules/MenuDialog/MenuDialog';
import './Menu.scss';

const getDirection = (e) => {
  if (e) {
    const { innerWidth, innerHeight } = window;
    const { clientX, clientY } = e;
    const rX = clientX / innerWidth;
    const rY = clientY / innerHeight;

    if (rX < 0.5 && rY < 0.5) return '';
    if (rX > 0.5 && rY < 0.5) return 'translate-left';
    if (rX > 0.5 && rY > 0.5) return 'translate-top-left';
    if (rX < 0.5 && rY > 0.5) return 'translate-top';
  }
  return '';
};

export const Menu = ({
  actionIcon,
  actionIconSize,
  onActionClick,
  menuItems,
  className,
  iconColor,
  menuDialogState,
}) => {
  const [showMenu, toggle] = useState(false);
  const [direction, setDirection] = useState('');
  const menuRef = useRef();

  const classNames = classnames([
    'menu-container position-relative',
    className,
  ]);

  const menuDialogClasses = classnames([
    'position-absolute',
    'padding-0',
    direction,
  ]);
  const buttonClasses = classnames([
    'display-flex usa-button usa-button--unstyled menu-box',
    showMenu ? 'bg-primary text-white radius-top-md' : '',
  ]);

  const toggleMenu = (state, e) => {
    setDirection(getDirection(e));
    toggle(!state);
  };

  const clickAwayHandler = (e) => {
    if (!menuRef.current.contains(e.target)) {
      toggleMenu(showMenu);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', clickAwayHandler);
    }
    return () => {
      document.removeEventListener('click', clickAwayHandler);
    };
  }, [showMenu]);

  return (
    <div ref={menuRef} className={classNames}>
      <button
        onClick={(e) => {
          toggleMenu(showMenu, e);
        }}
        type="button"
        aria-label="Menu"
        aria-expanded={showMenu}
        className={buttonClasses}
      >
        <Icon iconName={actionIcon} className={`${actionIconSize} action-icon`} />
      </button>
      <MenuDialog
        className={menuDialogClasses}
        show={showMenu}
        items={menuItems}
        state={menuDialogState}
        onClick={onActionClick}
        iconColor={iconColor}
        onClose={(e) => {
          toggleMenu(showMenu, e);
        }}
      />
    </div>
  );
};

Menu.propTypes = {
  actionIcon: PropTypes.string,
  actionIconSize: PropTypes.string,
  onActionClick: PropTypes.func,
  menuItems: MenuItemsType,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  menuDialogState: PropTypes.string,
};

Menu.defaultProps = {
  actionIcon: 'more_horiz',
  actionIconSize: 'usa-icon--size-4',
  onActionClick: () => {},
  menuItems: [],
  className: '',
  iconColor: 'text-gray-50',
  menuDialogState: '',
};

export default Menu;
