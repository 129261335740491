import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Dialog = ({ className, children, ...props }) => {
  const classes = classNames(
    'afp-menu bg-white padding-105 z-100 radius-sm shadow-2 border-base-lighter',
    className || '',
  );
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Dialog.propTypes = {
  children: PropTypes.node,
};

export default Dialog;
