import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Button } from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import './table.scss';
import {
  useMenu,
  menuKeys,
  findMenuItemIndex,
} from '../UserMenu/hooks/useMenu';

const AFPTableRowAction = ({
  actions,
  actionTitle,
  onSelectAction,
  row: { original },
}) => {
  const {
    menuItemsRef,
    setMenuItemsCount,
    focusByItemIndex,
    focusKeyDownMap,
    isMenuOpen,
    setsMenuOpen,
  } = useMenu();
  const menuTriggerRef = useRef();

  const [allowedActions, setAllowedActions] = useState([]);

  const handleClickOutside = () => {
    setsMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const allowed = actions.filter((a) => {
      const permission = get(a, 'canShowIndicator');
      // No permission control specified for action item
      // Therefore show action item.
      if (!permission) return true;

      // Check if permission is set to false as part of row object
      // e.g. :
      //    if row.canEdit = false => don't show
      //    if row.canEdit = true =>  show
      //    if row.canEdit = undefined  => show
      return get(original, permission, true);
    });

    setAllowedActions(allowed);
    setMenuItemsCount(allowed.length);
  }, [actions, original]);

  const keyDownActionMap = {
    ...focusKeyDownMap,
    [menuKeys.ESCAPE]: () => {
      setsMenuOpen(false);
      menuTriggerRef.current.focus();
    },
    [menuKeys.TAB]: () => {
      setsMenuOpen(false);
    },
  };

  const keyDownHandler = (evt) => {
    const { keyCode } = evt;
    if (allowedActions.length === 0) {
      return;
    }
    if (keyDownActionMap[`${keyCode}`]) {
      keyDownActionMap[`${keyCode}`]();
      if (keyCode !== menuKeys.TAB) {
        evt.preventDefault();
      }
      return;
    }
    // search item by typed character a-z A-Z
    if (keyCode >= 65 && keyCode <= 90) {
      const itemIndex = findMenuItemIndex({
        menuItems: allowedActions,
        keyCode,
      });
      if (itemIndex >= 0) {
        focusByItemIndex(itemIndex);
      }
      evt.preventDefault();
    }
  };

  return (
    <div className="dropdown">
      <Button
        variant="unstyled"
        className="dropdown-button"
        title={actionTitle}
        label={actionTitle} 
        labelClass="usa-sr-only"
        rightIcon={{name:"icon-action-dots", type: "custom", className: 'icon-font'}}
        onClick={() => setsMenuOpen(!isMenuOpen)}
        aria-haspopup={allowedActions.length > 0}
        aria-expanded={isMenuOpen}
        aria-controls={`action-menu-${original.id}`}
        // eslint-disable-next-line
        inputRef={(el) => (menuTriggerRef.current = el)}
        onKeyDown={keyDownHandler}
        id={`menu-trigger-${original.id}`}
      />

      <div
        id={`action-menu-${original.id}`}
        className={`dropdown-content ${isMenuOpen ? 'show' : ''}`}
        role="menu"
      >
        {[...allowedActions].map((action, i) => {
          return (
            <div
              className="dropdown-menu-item"
              key={`${original.id}-${action.label}`}
            >
              <Button
                role="menuitem"
                data-testid={`${action.label.toLowerCase()}-row-action`}
                variant="unstyled"
                className="text-no-underline text-ink margin-y-1 padding-1 margin-right-2"
                onClick={() => {
                  onSelectAction(action.label);
                  setsMenuOpen(false);
                }}
                onKeyDown={keyDownHandler}
                title={`${actionTitle}: ${action.label}`}
                label={action.label}
                leftIcon={{name: action.icon, className: "text-bottom text-base"}}
                inputRef={(el) => (menuItemsRef.current[i] = el)}
              />
              {/* Divider */}
              {i < actions.length - 1 ? (
                <div className="border-bottom  border-base-lighter" />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

AFPTableRowAction.defaultProps = {
  actionTitle: 'Row action',
};

AFPTableRowAction.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      canShowIndicator: PropTypes.string,
    }),
  ).isRequired,
  onSelectAction: PropTypes.func.isRequired,
  actionTitle: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  }).isRequired,
};

export default AFPTableRowAction;
