import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from '../../atoms/RadioButton/RadioButton';
import { addressShape } from './helpers';

const FormattedAddress = ({ address }) => {
  return (
    <div className="afp-formatted-address">
      {address.address}
      {address.address && <br />}
      {address.address2}
      {address.address2 && <br />}
      {address.city}
      {address.city && address.state && `, `} 
      {address.state} {address.zip}
    </div>
  );
};
FormattedAddress.propTypes = {
  address: PropTypes.shape(addressShape).isRequired,
};

const AddressCard = ({ children, selected, ...restProps }) => (
  <div
    className={`usa-card__container padding-x-1 margin-y-2 cursor-pointer ${
      selected ? 'bg-primary-lighter' : ''
    }`}
    {...restProps}
  >
    {children}
  </div>
);
AddressCard.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
};

const AddressOption = ({
  value,
  selectAddress,
  address,
  label,
  selected,
  onChange,
}) => (
  <AddressCard selected={selected} onClick={() => selectAddress(address)}>
    <RadioButton
      name="address"
      value={value}
      checked={selected}
      onChange={onChange}
      label={<b>{label}</b>}
      className={`${selected ? 'bg-primary-lighter' : ''}`}
    />
    <div className="padding-left-4 padding-y-1">
      <FormattedAddress address={address} />
    </div>
  </AddressCard>
);
AddressOption.defaultProps = {
  onChange: () => undefined,
};
AddressOption.propTypes = {
  address: PropTypes.shape(addressShape).isRequired,
  label: PropTypes.string.isRequired,
  selectAddress: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default AddressOption;
