/* eslint-disable import/no-cycle */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Accordion } from '../..';
import FilterItem from './FilterItem';
import FilterProvider, { useFilter } from './FilterProvider';
import SortItem from './SortItem';
import useViewport from '../../lib/hooks/useViewport';
import MobileFilters from './MobileFilters';
import './ResponsiveFilterPanel.scss';

const Filter = ({
  items,
  breakPoint,
  toggleSort,
  order,
  setOrder,
  options,
}) => {
  const [toggleFilters, setToggleFilters] = useState(false);
  const { filterState, reset } = useFilter();
  const { width } = useViewport();

  const filterList = useMemo(
    () =>
      Object.values(items).map((item) => ({
        ...item,
        content: <FilterItem {...item} state={filterState} />,
      })),
    [filterState],
  );

  return (
    <>
      {width > breakPoint ? (
        <div data-testid="afp-filter" className="grid-row afp-filter">
          <div className="grid-col">
            <div className="grid-row flex-row">
              <div className="grid-col display-flex flex-column flex-align-start flex-align-self-center text-uppercase text-bold ">
                filters
              </div>
              <div className="grid-col display-flex flex-column flex-align-end flex-align-self-center">
                {/* Show Reset All only if there are selected filters */}
                {Object.values(filterState).some((f) => f) && (
                  <Button
                    data-testid="reset-filter"
                    type="button"
                    variant="unstyled"
                    onClick={reset}
                    className="width-auto"
                    label="Reset All"
                    leftIcon={{
                      name: 'close',
                      className: 'text-middle text-gray-50',
                    }}
                  />
                )}
              </div>
            </div>
            <div className="grid-row padding-y-2">
              <Accordion
                items={filterList}
                multiselectable
                className="overflow-visible"
              />
            </div>
            {toggleSort && (
              <div className="grid-row padding-x-2">
                <SortItem order={order} setOrder={setOrder} options={options} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <MobileFilters
          toggleFilters={toggleFilters}
          setToggleFilters={setToggleFilters}
          filterList={filterList}
          filterState={filterState}
          reset={reset}
          toggleSort={toggleSort}
          order={order}
          setOrder={setOrder}
          options={options}
        />
      )}
    </>
  );
};

// eslint-disable-next-line react/prop-types
export default (props) => (
  <FilterProvider {...props}>
    <Filter {...props} />
  </FilterProvider>
);

Filter.defaultProps = {
  items: {},
  defaultProps: {},
  filterResult: {},
  onChange: () => null,
  onFilterItemSearch: () => null,
  breakPoint: 640,
  toggleSort: false,
  order: '',
  setOrder: () => null,
  options: [],
};
Filter.propTypes = {
  items: PropTypes.shape(Object),
  defaultProps: PropTypes.shape(Object),
  filterResult: PropTypes.shape(Object),
  onChange: PropTypes.func,
  onFilterItemSearch: PropTypes.func,
  breakPoint: PropTypes.number,
  toggleSort: PropTypes.bool,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
};
