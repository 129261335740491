import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '../Button/Button';

const ButtonDropdownItem = ({
  className,
  label,
  iconName,
  iconType,
  variant,
  size,
  onClick,
}) => {
  const classes = classnames('afp-buttondropdown-item', className);

  return (
    <>
      <Button
        variant={variant}
        size={size}
        className={`${classes} width-full height-6 text-no-underline text-ink hover:text-no-underline hover:text-ink text-no-wrap padding-right-1`}
        onClick={onClick}
        label={label}
        leftIcon={{
          className: 'margin-left-2 text-bottom text-base',
          name: iconName,
          type: iconType 
        }}
      />
      <div className="width-full border-bottom border-base-lighter" />
    </>
  );
};

ButtonDropdownItem.defaultProps = {
  className: '',
  iconName: '',
  iconType: 'uswds',
  variant: 'unstyled',
  size: '',
};

ButtonDropdownItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default ButtonDropdownItem;
