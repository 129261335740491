import React, { useReducer, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import './Table.scss';

export const Table = ({
  bordered,
  caption,
  children,
  fullWidth,
  style,
  fixed,
  header,
  sortingState,
  setSortingState,
  filters,
  showFilterIcon,
  rows,
}) => {
  /**
   * @header - each object represents a column
   * [{
      name: String,
      accessor: String,
      sortable: Boolean,
      className: String,
      }]
   */

  /**
   * @filters
    [{
      accessor: String,
      type: String, // "text", "select", "combo"
      options: Array, // {value: <Any>, label: String}
      label: String
      placeholder: String
      value: String or Number      
    }]
   */

  /**
   * @rows - each key-value pair represents a column
   * {
      [key]: value<Any>
   * }
   */
  const classes = classnames('usa-table', {
    'usa-table--borderless': !bordered,
    'usa-table--full-width': fullWidth,
    'usa-table--fixed': fixed,
  });

  /**
   * FILTERING STATE AND REDUCER
   */
  // TODO: needs to be moved out of the Table component and passed in as a prop (AFP-77)
  const filterReducer = (state, action) => {
    const { accessor, value } = action;
    const newFilterSetting = { [accessor]: value };
    return { ...state, ...newFilterSetting };
  };

  const [filterState, setFilterState] = useReducer(filterReducer, {});

  const onEnterPress = (ev) => {
    if (ev.key == 'Enter') {
      console.log('Filter triggered:', filterState);
    }
  };

  const dataReducer = (state, action) => {
    return [...action.payload];
  };

  const [data, setData] = useReducer(dataReducer, []);

  useEffect(() => {
    if (rows instanceof Promise) {
      rows.then((d) => {
        setData({ payload: d });
      });
    } else if (rows instanceof Array) {
      setData({ payload: rows });
    }
  }, [rows]);

  return (
    <table className={classes} data-testid="table" style={style}>
      {caption ? <caption>{caption}</caption> : null}
      {header ? (
        <TableHeader
          header={header}
          sortingState={sortingState}
          setSortingState={setSortingState}
        />
      ) : null}
      {rows ? (
        <TableBody
          header={header}
          rows={data}
          showFilterIcon={showFilterIcon}
          filters={filters}
          filterState={filterState}
          setFilterState={setFilterState}
          onFilterKeyDown={onEnterPress}
        />
      ) : null}
      {children}
    </table>
  );
};

Table.propTypes = {
  bordered: PropTypes.bool,
  caption: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  fixed: PropTypes.bool,
};
