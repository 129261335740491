import PropTypes from 'prop-types';

export const addressShape = {
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  countryCode: PropTypes.string,
};

export default {
  addressShape,
};
