import React from 'react';
import { Filter } from '../../molecules/Filter/Filter';
import IconFinder from '../../lib/util/iconFinder';

export default ({
  filters,
  filterState,
  setFilterState,
  header,
  onFilterKeyDown,
  showFilterIcon,
}) => {
  return (
    <tr className="table-filters">
      {header
        ? header.map((headerItem, index) => {
            const filterColumn = filters.find(
              (el) => el.accessor === headerItem.accessor,
            );
            if (filterColumn) {
              if (filterColumn.type === 'text') {
                const { accessor, type, placeholder } = filterColumn;
                const hasValue = Object.keys(filterState).find(
                  (key) => key === accessor,
                );
                return (
                  <td key={index}>
                    {index === 0 && showFilterIcon ? (
                      <div className="table__filter-icon">
                        <IconFinder
                          iconName="filter"
                          fill="#005EA2"
                          width="20"
                          height="20"
                        />
                      </div>
                    ) : null}
                    <Filter
                      name={accessor}
                      type={type}
                      size="medium"
                      placeholder={placeholder}
                      value={hasValue ? filterState[accessor] : ''}
                      onFilterChange={(value) =>
                        setFilterState({ accessor, value })
                      }
                      onFilterKeyDown={onFilterKeyDown}
                    />
                  </td>
                );
              }
            }
            return (
              <td key={index}>
                {index === 0 && showFilterIcon ? (
                  <div className="table__filter-icon">
                    <IconFinder
                      iconName="filter"
                      fill="#005EA2"
                      width="20"
                      height="20"
                    />
                  </div>
                ) : null}
              </td>
            );
          })
        : null}
    </tr>
  );
};
