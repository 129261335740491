import classnames from 'classnames';
import debounce from 'lodash/debounce';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../Button/Button';
import Icon from '../Icon/Icon';
import { Tag } from '../Tag/Tag';

moment.updateLocale('en', {
  meridiem: (hour) => {
    return hour < 12 ? 'a.m.' : 'p.m.';
  },
});
export const dateFormat = (date) => moment(date).format('MM/DD/YYYY • h:mm a');

const ActivityCardUserInfo = ({ email, userName }) => {
  return userName ? (
    <div className="activity-card-header-user-info">
      <Icon
        iconName="account_circle"
        className="usa-icon--size-1 activity-card-header-user-info-icon"
      />
      <div className="activity-card-header-user-info-name">
        {email ? <a href={`mailto:${email}`}>{userName}</a> : userName}
      </div>
    </div>
  ) : null;
};

export const ActivityCardColor = {
  DEFAULT: 'default',
  BLUE: 'blue',
};

const ActivityCard = ({
  id,
  type,
  date,
  email,
  userName,
  comment,
  highlight,
  tagDetails,
  includeUserNameInHeading,
  activityFooter,
  activityBody,
  variant,
}) => {
  const header = () => {
    if (!userName || !includeUserNameInHeading) {
      return type;
    }
    if (includeUserNameInHeading) {
      return `${type} by ${userName}`;
    }
    return (
      <>
        {type} by{' '}
        {email ? <a href={`mailto:${email}`}>{userName}</a> : userName}
      </>
    );
  };

  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(false);

  const textRef = useRef(null);
  const hasClamping = (el) => {
    const { clientHeight, scrollHeight } = el;
    return clientHeight !== scrollHeight;
  };

  const handleClick = () => {
    setClamped(!clamped);
  };

  useEffect(() => {
    const checkButtonAvailability = () => {
      if (textRef.current) {
        const hadClampClass = textRef.current.classList.contains('clamp');
        if (!hadClampClass) textRef.current.classList.add('clamp');
        // Check for clamping to show or hide button
        setShowButton(hasClamping(textRef.current));
        if (!hadClampClass) textRef.current.classList.remove('clamp');
      }
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [textRef]);

  return (
    <div className="activity-card-wrapper" data-testid={id}>
      {!highlight && <div className="dot" />}
      <article className={classnames('activity-card', variant || 'default')}>
        <header className="activity-card-header">
          <div className="activity-card-header-title">
            <h1>{header()}</h1>
            {tagDetails && (
              <span
                className="activity-card-header-title-tag"
                data-testid="activity-panel-tag"
              >
                <Tag variant={tagDetails.variant}>{tagDetails.text}</Tag>
              </span>
            )}
            {highlight && (userName || email) && (
              <>
                <strong>by</strong>{' '}
                <ActivityCardUserInfo email={email} userName={userName} />
              </>
            )}
            <div className="flex-spacer"></div>
            <div data-testid={`${id}-timestamp`}>{dateFormat(date)}</div>
          </div>

          {!highlight && (userName || email) && (
            <ActivityCardUserInfo email={email} userName={userName} />
          )}
        </header>

        {activityBody && (
          <section className="activity-body" data-testid="activity-body">
            {typeof activityBody === 'function' ? activityBody() : activityBody}
          </section>
        )}

        {!highlight && (
          <>
            {comment && (
              <section
                className={classnames(
                  'activity-card-comments',
                  !activityBody && 'no-body',
                )}
              >
                {!highlight && <h2>User comments</h2>}
                <p
                  ref={textRef}
                  className={classnames(
                    'activity-card-comments-content',
                    clamped && 'clamp',
                  )}
                >
                  {comment}
                </p>
                {showButton && (
                  <Button
                    className="overflow-button"
                    data-testid="overflow-button"
                    variant="unstyled"
                    label={clamped ? 'Read more' : 'Read less'}
                    onClick={handleClick}
                  />
                )}
              </section>
            )}

            {activityFooter && (
              <footer
                className="activity-card-footer"
                data-testid="activity-footer"
              >
                {typeof activityFooter === 'function'
                  ? activityFooter()
                  : activityFooter}
              </footer>
            )}
          </>
        )}
      </article>
    </div>
  );
};

ActivityCard.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  date: PropTypes.string,
  email: PropTypes.string,
  userName: PropTypes.string,
  comment: PropTypes.string,
  highlight: PropTypes.bool,
  includeUserNameInHeading: PropTypes.bool,
  activityBody: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  activityFooter: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  tagDetails: PropTypes.shape({
    text: PropTypes.string.isRequired,
    variant: PropTypes.oneOf([
      'Inactive-Dark',
      'Inactive-Medium',
      'Inactive-Light',
      'Ready-Dark',
      'Ready-Medium',
      'Ready-Light',
      'Info-Dark',
      'Info-Light',
      'Important-Dark',
      'Important-Medium',
      'Important-Light',
      'Urgent-Dark',
      'Urgent-Medium',
      'Urgent-Light',
    ]).isRequired,
  }),
  variant: PropTypes.oneOf(['default', 'blue']),
};

ActivityCard.defaultProps = {
  type: '',
  date: '',
  email: '',
  userName: '',
  comment: '',
  highlight: false,
  includeUserNameInHeading: false,
  activityBody: null,
  activityFooter: null,
};

export default ActivityCard;
