import React from 'react';

export const MegaMenuSubItem = ({
  label,
  href,  
  primaryIdx,
  secondaryIdx,
}) => {
  return (
    <li
      className="usa-nav__submenu-item"
      role="menuitem"
      data-primary-idx={primaryIdx}
      data-secondary-idx={secondaryIdx}
    >
      <a
        href={href || '#'}
        data-primary-idx={primaryIdx}
        data-secondary-idx={secondaryIdx}
      >
        {label || 'Navigation link'}
      </a>
    </li>
  );
};

export default MegaMenuSubItem;
