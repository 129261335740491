import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { Label } from '../Label/Label';

export const SelectBox = ({
  id,
  name,
  className,
  inputRef,
  disabled,
  size,
  label,
  defaultValue,
  placeHolder,
  variant,
  children,
  wrapperClassName,
  options: dropDownOptions,
  optionKey,
  optionLabel,
  optionValue,
  onChangeFunc,
  ...restProps
}) => {
  id = id || `select_${uniqueId()}`;
  optionKey = optionKey || 'value';
  optionLabel = optionLabel || 'label';
  optionValue = optionValue || 'value';

  const classes = classnames(
    'usa-select',
    { [`usa-input--${variant}`]: !!variant },
    { [`usa-input--${size}`]: !!size },
    className,
  );
  const wrapperClassNames = classnames('usa-combo-box', wrapperClassName);

  const isError = variant === 'error';

  return (
    <>
      {label && (
        <Label htmlFor={id} error={isError}>
          {label}
        </Label>
      )}
      <div
        className={wrapperClassNames}
        data-default-value={defaultValue}
        data-placeholder={placeHolder}
      >
        <select
          data-testid={id}
          className={classes}
          id={id}
          name={name}
          disabled={disabled}
          {...restProps}
          onChange={onChangeFunc}
        >
          {dropDownOptions.map((optionsInfo, index) => (
            <option value={optionsInfo[optionValue].toString()} key={index}>
              {optionsInfo[optionLabel]}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

SelectBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['', 'success', 'error']),
  size: PropTypes.oneOf(['small', 'medium']),
  onChangeFunc: PropTypes.func,
};
