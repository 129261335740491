import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '../..';
import FilterItem from './FilterItem';
import FiltersApplied from './FiltersApplied';
import FilterProvider from './FilterProvider';
import ClearAllButton from './ClearAllButton';
import FiltersAppliedTitle from './FiltersAppliedTitle';
import FilterTypeaheadProvider from './FilterTypeaheadProvider';
import './FilterPanel.scss';
import Spinner from '../../atoms/Spinner/Spinner';

const FilterPanel = ({
  filterStructure, // used to render filters
  clearButtonLabel,
  showClearIcon,
  model,
  order,
  setQueryFiltersState,
  fetchTypeaheads,
  typeaheadData,
  sectionTitle,
  handleToggleFilter,
  handleClearAll,
  updateAppliedFilters,
}) => {
  const [accordionFilters, setAccordionFilters] = useState([]);

  useEffect(() => {
    localStorage.removeItem('filters');
    if (filterStructure.length) {
      setAccordionFilters(filterStructure);
    }
  }, [filterStructure]);

  const updateFilters = (filters) => {
    if (updateAppliedFilters) {
      updateAppliedFilters(filters);
    }
  };

  // filters can be singular, or nested array
  const flattenedFilterStructure = [];
  filterStructure.forEach((f) => {
    if (f.filters) {
      f.filters.forEach((filter) => {
        // eslint-disable-next-line no-param-reassign
        filter.category = f.category || f.title;
        flattenedFilterStructure.push(filter);
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      if (!f.category) f.category = f.title;
      flattenedFilterStructure.push(f);
    }

    return undefined;
  });

  const initFilters = {
    updateToggle: false,
    filters: flattenedFilterStructure,
  };

  return (
    <FilterProvider {...{ initFilters, setQueryFiltersState }}>
      <FilterTypeaheadProvider
        {...{
          model,
          order,
          fetchTypeaheads,
          typeaheadData,
        }}
      >
        <div className="filter-header-main filters__title border-bottom-2px">
          <div className="">
            <h2 className="font-family-sans font-heading-md">
              {sectionTitle || 'FILTERS'}
            </h2>
          </div>
          <div className="filter-reset-button">
            <ClearAllButton
              clearButtonLabel={clearButtonLabel}
              showClearIcon={showClearIcon}
              handleClearAll={handleClearAll}
            />
          </div>
        </div>
        <div key="1" className="filters__accordion-container">
          <Accordion
            bordered={false}
            className="vms-filter-accordion__1 applied-filter-accordion"
            items={[
              {
                title: <FiltersAppliedTitle />,
                content: (
                  <FiltersApplied updateAppliedFilters={updateFilters} />
                ),
                key: '1',
                expanded: true,
                className:
                  'border-solid border-right-width-0 border-bottom-width-0 border-top-width-0 border-left-width-1px border-base-lightest overflow-visible',
              },
            ]}
          />
        </div>
        {accordionFilters.length
          ? accordionFilters.map((filter) => {
              const {
                key,
                title,
                filters,
                model: filterModel,
                loading,
              } = filter;
              const content = (filters || [filter]).map((field) => {
                return loading ? (
                  <>
                    <Spinner key={field.key} />
                  </>
                ) : (
                  <>
                    <FilterItem
                      key={field.key}
                      filter={field}
                      category={title}
                    />
                  </>
                );
              });
              return (
                <div key={key} className="filters__accordion-container">
                  <Accordion
                    handleToggleFilter={handleToggleFilter}
                    bordered={false}
                    className={`vms-filter-accordion__${key} accordian-filter-border-top base-lighter`}
                    items={[
                      {
                        title,
                        filterModel,
                        content,
                        id: key,
                        expanded: filter.expanded,
                        requiredAsterisk: filter.requiredAsterisk,
                        className:
                          'border-solid border-right-width-0 border-bottom-width-0 border-top-width-0 border-left-width-1px border-base-lightest overflow-visible',
                      },
                    ]}
                  />
                </div>
              );
            })
          : null}
      </FilterTypeaheadProvider>
    </FilterProvider>
  );
};

FilterPanel.defaultProps = {
  fetchTypeaheads: undefined,
  typeaheadData: undefined,
  handleClearAll: undefined,
  subTitle: '',
  titleSeparator: false,
  showSearchIcon: false,
  sectionTitle: '',
  handleToggleFilter: undefined,
  updateAppliedFilters: undefined,
};

FilterPanel.propTypes = {
  filterStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
  clearButtonLabel: PropTypes.string.isRequired,
  showClearIcon: PropTypes.bool.isRequired,
  setQueryFiltersState: PropTypes.func.isRequired,
  model: PropTypes.string.isRequired,
  order: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  fetchTypeaheads: PropTypes.func,
  typeaheadData: PropTypes.shape({
    field: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
  subTitle: PropTypes.string,
  titleSeparator: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  sectionTitle: PropTypes.string,
  handleClearAll: PropTypes.func,
  handleToggleFilter: PropTypes.func,
  updateAppliedFilters: PropTypes.func,
};

export default FilterPanel;
