import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardHeader = ({
    children,
    className
}) => {

    const headerClass = classnames(
        'usa-card__header',
        className
    );

    return (
        <div className={headerClass}>
            {typeof children === 'string' ?
                <h2 className="title">{children}</h2>
                : children
            }
        </div>
    );
};

CardHeader.defaultProps = {
    className: ''
};


CardHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.PropTypes.element
    ]).isRequired,
    className: PropTypes.string,
};

export default CardHeader;