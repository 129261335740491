import React from 'react';
import PropTypes from 'prop-types';

const LinkCardText = ({ children }) => {
  return (
    <>
      <div data-testid="link-card-body-text-id" className="link-card__text">
        <p>{children}</p>
      </div>
    </>
  );
};

LinkCardText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LinkCardText;
