import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardFooter = ({
    children,
    variant,
    className
}) => {

    const classes = classnames(
        'usa-card__footer',
        {
            'usa-card__footer--exdent': variant === 'exdent'
        },
        className,
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

CardFooter.defaultProps = {
    className: ''
};


CardFooter.propTypes = {
    children: PropTypes.element,
    variant: PropTypes.oneOf(['exdent']),
    className: PropTypes.string
};

export default CardFooter;