/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../..';
import FilterPanelOverlay from './FilterPanelOverlay';

const MobileFilters = ({
  toggleFilters,
  setToggleFilters,
  filterList,
  filterState,
  order,
  setOrder,
  reset,
  toggleSort,
  options,
}) => {
  return (
    <>
      <div className="display-flex flex-justify">
        <Button
          className="usa-button usa-button--outline afp-filter__toggleBtn"
          onClick={() => setToggleFilters(true)}
          label="Show filters"
          leftIcon={{name: "hide-filters-icon", type: "close-icon", className:"margin-left-1 margin-right-1 text-middle" }}
        />
      </div>
      {toggleFilters && (
        <FilterPanelOverlay
          setToggleFilters={setToggleFilters}
          filterList={filterList}
          filterState={filterState}
          reset={reset}
          toggleSort={toggleSort}
          order={order}
          setOrder={setOrder}
          options={options}
        />
      )}
    </>
  );
};

export default MobileFilters;

MobileFilters.propTypes = {
  toggleFilters: PropTypes.bool.isRequired,
  setToggleFilters: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  filterList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterState: PropTypes.shape(Object).isRequired,
  toggleSort: PropTypes.bool.isRequired,
  order: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
};
