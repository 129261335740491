import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import './StepIndicator.scss';

const getStatusClass = (status) => {
  if (status === 'completed') {
    return 'usa-step-indicator__segment--complete';
  }
  if (status === 'current') {
    return 'usa-step-indicator__segment--current';
  }
  return '';
};

const getStep = ({ id, status, label }, onClick) => (
  <li
    tabIndex={status === 'completed' || status == 'current' ? 0 : -1}
    key={id || uniqueId()}
    className={`usa-step-indicator__segment ${getStatusClass(status)}`}
    onClick={() =>
      onClick && status === 'completed' && onClick({ status, label })
    }
  >
    <span className="usa-step-indicator__segment-label">
      {label}{' '}
      {status !== 'current' && <span className="usa-sr-only">{status}</span>}
    </span>
  </li>
);

export const StepIndicator = ({
  steps,
  title,
  className,
  noLabel,
  centered,
  heading,
  counterSize,
  onClick,
}) => {
  const classes = classnames(
    'usa-step-indicator',
    {
      'usa-step-indicator--no-labels': !!noLabel,
    },
    {
      'usa-step-indicator--center': !!centered,
      'usa-step-indicator--counters':
        !centered && counterSize && counterSize !== 'small',
      'usa-step-indicator--counters-sm':
        !centered && counterSize && counterSize === 'small',
    },
    className,
  );
  return (
    <div className={classes} aria-label={title} data-testid="StepIndicator">
      <ol className="usa-step-indicator__segments">
        {steps &&
          steps.map((step) => {
            return getStep(step, onClick);
          })}
      </ol>
      {heading && (
        <div className="usa-step-indicator__header" tabIndex={0}>
          <h2 className="usa-step-indicator__heading">
            <span className="usa-step-indicator__heading-counter">
              <span className="usa-sr-only">Step</span>
              <span className="usa-step-indicator__current-step">
                {heading.current}
              </span>
              <span className="usa-step-indicator__total-steps">
                &nbsp;of {heading.total || steps.length}
              </span>
            </span>
            <span className="usa-step-indicator__heading-text">
              {heading.text}
            </span>
          </h2>
        </div>
      )}
    </div>
  );
};

StepIndicator.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.oneOf(['completed', 'current', 'not completed']),
      label: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  centered: PropTypes.bool,
  className: PropTypes.string,
  heading: PropTypes.shape({
    current: PropTypes.number.isRequired,
    total: PropTypes.number,
    text: PropTypes.string.isRequired,
  }),
  counterSize: PropTypes.oneOf(['big', 'small']),
  onClick: PropTypes.func,
};
