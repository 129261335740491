import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../Link/Link';
import { Button } from '../Button/Button';

export const ActivityActionLinks = ({
  links
}) => {
  return (
    <div className='activity-card-action-links'>
      {links.map((link, index) => {
        return (
          <>
            {link.href ?
              <Link
                key={index}
                {...link}
              >
                {link.label}
              </Link>
              :
              <Button type="button" variant="unstyled" {...link} />
            }
            {index < links.length - 1 && <span className='activity-card-action-links-separator'>|</span>}
          </>
        );
      })}
    </div>
  )
};

Link.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};
