import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../atoms/Dialog/Dialog';
import Icon from '../../atoms/Icon/Icon';
import './MenuDialog.scss';

const MenuDialog = ({
  show,
  items,
  onClick,
  onClose,
  className,
  iconColor,
  state,
}) => {

  const TAB_KEY_CODE = 9;
  const ESCAPE_KEY_CODE = 27;

  useEffect(() => {
    const listItems = document.querySelectorAll('.dropdown__list-item');
    
    listItems.forEach((item) => {
      item.addEventListener('keydown', (e) => {
        switch (e.keyCode) {
          case TAB_KEY_CODE:
            const actionLabel = e.target.innerText;
            if (actionLabel === items[items.length - 1].label) {
              onClose();
            } 
            return;

          case ESCAPE_KEY_CODE:
            onClose();
            return;

          default:
            return;
        }
      });
    });
  });
  
  if (show) {
    return (
      <Dialog className={className}>
        <ul
          aria-labelledby="Menu"
          className="menu-dialog usa-list usa-list--unstyled text-no-wrap border border-base"
        >
          {items &&
            items.map((item, index) => (
              <li
                value={`item-${index}`}
                className={`padding-3 border-bottom border-base-lightest items-list-${state} dropdown__list-item`}
                key={item.action}
                onClick={() => {
                  onClick(item.action);
                  onClose();
                }}
              >
                <button
                  type="button"
                  aria-label={item.label}
                  data-toggle="dropdown"
                  role="link"
                  className="menu-dialog-action display-flex usa-button usa-button--unstyled"
                >
                  {item.icon && (
                    <span className={`menu-list-icon ${iconColor}`}>
                      <Icon iconName={item.icon} type={item.iconType} />
                    </span>
                  )}
                  <span className="button-label-menu-dialog">{item.label}</span>
                </button>
              </li>
            ))}
        </ul>
      </Dialog>
    );
  }
  return null;
};

export const MenuItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconType: PropTypes.string,
  }),
);

const MenuDialogType = {
  show: PropTypes.bool,
  items: MenuItemsType,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  state: PropTypes.oneOf(['hover', 'default'])
};

const defaultProps = {
  show: false,
  items: [],
  onClick: () => {},
  onClose: () => {},
  className: '',
  iconColor: 'text-gray-50',
  state: 'hover',
};

MenuDialog.propTypes = MenuDialogType;
MenuDialog.defaultProps = defaultProps;

export default MenuDialog;
