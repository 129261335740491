import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Button } from '../..';
import './Notification.scss';

export const Notification = ({
  description,
  hasAction,
  id,
  isNewAlert,
  notificationAction,
  notificationTimeOffset,
}) => {
  const processeOffset = (offset) => {
    return offset
      .split(' ')
      .map((str) => {
        return str[0].toUpperCase() + str.substring(1);
      })
      .join(' ');
  };
  return (
    <div className="usa-alert usa-alert--info usa-alert--slim afp-notification">
      <div className="usa-alert__body afp-notification__body">
        <div className="flex-10">
          <p
            className="usa-alert__text font-sans-xs afp-notification__description padding-right-2"
            data-testid={`${id}-notification-description`}
          >
            {description}
          </p>
        </div>
        <div className="afp-notification__status_tag">
          {isNewAlert && (
            <Tag
              data-testid={`${id}-notification-tag`}
              className="afp-notification__new_tag"
            >
              New
            </Tag>
          )}
        </div>
        <div className="afp-notification__offset_container">
          <span
            className="afp-notification__body__timeoffset font-sans-3xs"
            data-testid={`${id}-notification-time`}
          >
            {processeOffset(notificationTimeOffset)}
          </span>
        </div>
        <div className="afp-notification__action_button">
          {hasAction && (
            <Button
              variant="default"
              size="sm"
              data-testid={`${id}-notification-button`}
              className="afp-notification__body__button"
              onClick={notificationAction}
              label="View Details"
            />
          )}
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isNewAlert: PropTypes.bool.isRequired,
  hasAction: PropTypes.bool.isRequired,
  notificationAction: PropTypes.func,
  notificationTimeOffset: PropTypes.string.isRequired,
};

export default Notification;
