import React from 'react';
import PropTypes from 'prop-types';

const MenuList = React.forwardRef(
  ({ children, className, ...restProps }, ref) => {
    return (
      <div ref={ref} {...restProps} className={className}>
        {children}
      </div>
    );
  },
);

MenuList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

MenuList.defaultProps = {
  className: null,
};

export default MenuList;
