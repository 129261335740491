// Shortened country list
const COUNTRIES_LIST = [
  {
    countryName: 'United States of America',
    isoCountryCode2: 'US',
  },
  {
    countryName: 'Andorra',
    isoCountryCode2: 'AD',
  },
  {
    countryName: 'United Arab Emirates',
    isoCountryCode2: 'AE',
  },
  {
    countryName: 'Afghanistan',
    isoCountryCode2: 'AF',
  },
  {
    countryName: 'American Samoa',
    isoCountryCode2: 'AS',
  },
  {
    countryName: 'Austria',
    isoCountryCode2: 'AT',
  },
  {
    countryName: 'Australia',
    isoCountryCode2: 'AU',
  },
  {
    countryName: 'Aruba',
    isoCountryCode2: 'AW',
  },
  {
    countryName: 'Bosnia and Herzegovina',
    isoCountryCode2: 'BA',
  },
  {
    countryName: 'Burundi',
    isoCountryCode2: 'BI',
  },
  {
    countryName: 'Benin',
    isoCountryCode2: 'BJ',
  },
  {
    countryName: 'Saint-Barthélemy',
    isoCountryCode2: 'BL',
  },
  {
    countryName: 'Bermuda',
    isoCountryCode2: 'BM',
  },
  {
    countryName: 'Belarus',
    isoCountryCode2: 'BY',
  },
  {
    countryName: 'Belize',
    isoCountryCode2: 'BZ',
  },
  {
    countryName: 'Canada',
    isoCountryCode2: 'CA',
  },
  {
    countryName: 'Cocos (Keeling) Islands',
    isoCountryCode2: 'CC',
  },
  {
    countryName: 'Congo, (Kinshasa)',
    isoCountryCode2: 'CD',
  },
  {
    countryName: 'Central African Republic',
    isoCountryCode2: 'CF',
  },
  {
    countryName: 'Guam',
    isoCountryCode2: 'GU',
  },
  {
    countryName: 'Nepal',
    isoCountryCode2: 'NP',
  },
  {
    countryName: 'Nauru',
    isoCountryCode2: 'NR',
  },
  {
    countryName: 'Northern Mariana Islands',
    isoCountryCode2: 'MP',
  },
  {
    countryName: 'Niue',
    isoCountryCode2: 'NU',
  },
  {
    countryName: 'Philippines',
    isoCountryCode2: 'PH',
  },
  {
    countryName: 'Pakistan',
    isoCountryCode2: 'PK',
  },
  {
    countryName: 'Poland',
    isoCountryCode2: 'PL',
  },
  {
    countryName: 'Saint Pierre and Miquelon',
    isoCountryCode2: 'PM',
  },
  {
    countryName: 'Pitcairn',
    isoCountryCode2: 'PN',
  },
  {
    countryName: 'Puerto Rico',
    isoCountryCode2: 'PR',
  },
  {
    countryName: 'Palau',
    isoCountryCode2: 'PW',
  },
  {
    countryName: 'Paraguay',
    isoCountryCode2: 'PY',
  },
  {
    countryName: 'Qatar',
    isoCountryCode2: 'QA',
  },
  {
    countryName: 'Réunion',
    isoCountryCode2: 'RE',
  },
  {
    countryName: 'Romania',
    isoCountryCode2: 'RO',
  },
  {
    countryName: 'Solomon Islands',
    isoCountryCode2: 'SB',
  },
  {
    countryName: 'Slovakia',
    isoCountryCode2: 'SK',
  },
  {
    countryName: 'Suriname',
    isoCountryCode2: 'SR',
  },
  {
    countryName: 'South Sudan',
    isoCountryCode2: 'SS',
  },
  {
    countryName: 'Sao Tome and Principe',
    isoCountryCode2: 'ST',
  },
  {
    countryName: 'El Salvador',
    isoCountryCode2: 'SV',
  },
  {
    countryName: 'Tajikistan',
    isoCountryCode2: 'TJ',
  },
  {
    countryName: 'Tokelau',
    isoCountryCode2: 'TK',
  },
  {
    countryName: 'Timor-Leste',
    isoCountryCode2: 'TL',
  },

  {
    countryName: 'Turkey',
    isoCountryCode2: 'TR',
  },
  {
    countryName: 'Trinidad and Tobago',
    isoCountryCode2: 'TT',
  },
  {
    countryName: 'US Minor Outlying Islands',
    isoCountryCode2: 'UM',
  },
  {
    countryName: 'Uruguay',
    isoCountryCode2: 'UY',
  },
  {
    countryName: 'Uzbekistan',
    isoCountryCode2: 'UZ',
  },
  {
    countryName: 'Holy See (Vatican City State)',
    isoCountryCode2: 'VA',
  },
  {
    countryName: 'Saint Vincent and Grenadines',
    isoCountryCode2: 'VC',
  },
  {
    countryName: 'Venezuela (Bolivarian Republic)',
    isoCountryCode2: 'VE',
  },
  {
    countryName: 'British Virgin Islands',
    isoCountryCode2: 'VG',
  },
  {
    countryName: 'Virgin Islands, US',
    isoCountryCode2: 'VI',
  },
  {
    countryName: 'Viet Nam',
    isoCountryCode2: 'VN',
  },
  {
    countryName: 'Zimbabwe',
    isoCountryCode2: 'ZW',
  },
];

const STATES_LIST = [
  {
    stateName: 'Armed Forces America',
    stateCode: 'AA',
  },
  {
    stateName: 'Armed Forces Canada, Europe, Middle East, Africa"',
    stateCode: 'AE',
  },
  {
    stateName: 'Alaska',
    stateCode: 'AK',
  },
  {
    stateName: 'Alabama',
    stateCode: 'AL',
  },
  {
    stateName: 'Armed Forces Pacific',
    stateCode: 'AP',
  },
  {
    stateName: 'Arkansas',
    stateCode: 'AR',
  },
  {
    stateName: 'America Samoa',
    stateCode: 'AS',
  },
  {
    stateName: 'Arizona',
    stateCode: 'AZ',
  },
  {
    stateName: 'California',
    stateCode: 'CA',
  },
  {
    stateName: 'Colorado',
    stateCode: 'CO',
  },
  {
    stateName: 'Connecticut',
    stateCode: 'CT',
  },
  {
    stateName: 'District of Columbia',
    stateCode: 'DC',
  },
  {
    stateName: 'Delaware',
    stateCode: 'DE',
  },
  {
    stateName: 'Florida',
    stateCode: 'FL',
  },
  {
    stateName: 'Federated States of Micronesia',
    stateCode: 'FM',
  },
  {
    stateName: 'Georgia',
    stateCode: 'GA',
  },
  {
    stateName: 'Germany',
    stateCode: 'GE',
  },
  {
    stateName: 'Guam',
    stateCode: 'GU',
  },
  {
    stateName: 'Hawaii',
    stateCode: 'HI',
  },
  {
    stateName: 'Iowa',
    stateCode: 'IA',
  },
  {
    stateName: 'Idaho',
    stateCode: 'ID',
  },
  {
    stateName: 'Illinois',
    stateCode: 'IL',
  },
  {
    stateName: 'Indiana',
    stateCode: 'IN',
  },
  {
    stateName: 'Italy',
    stateCode: 'IT',
  },
  {
    stateName: 'Kansas',
    stateCode: 'KS',
  },
  {
    stateName: 'Kentucky',
    stateCode: 'KY',
  },
  {
    stateName: 'Louisiana',
    stateCode: 'LA',
  },
  {
    stateName: 'Massachusetts',
    stateCode: 'MA',
  },
  {
    stateName: 'Maryland',
    stateCode: 'MD',
  },
  {
    stateName: 'Maine',
    stateCode: 'ME',
  },
  {
    stateName: 'Marshall Island',
    stateCode: 'MH',
  },
  {
    stateName: 'Michigan',
    stateCode: 'MI',
  },
  {
    stateName: 'Minnesota',
    stateCode: 'MN',
  },
  {
    stateName: 'Missouri',
    stateCode: 'MO',
  },
  {
    stateName: 'Northern Mariana Islands',
    stateCode: 'MP',
  },
  {
    stateName: 'Mississippi',
    stateCode: 'MS',
  },
  {
    stateName: 'Montana',
    stateCode: 'MT',
  },
  {
    stateName: 'North Carolina',
    stateCode: 'NC',
  },
  {
    stateName: 'North Dakota',
    stateCode: 'ND',
  },
  {
    stateName: 'Nebraska',
    stateCode: 'NE',
  },
  {
    stateName: 'New Hampshire',
    stateCode: 'NH',
  },
  {
    stateName: 'New Jersey',
    stateCode: 'NJ',
  },
  {
    stateName: 'New Mexico',
    stateCode: 'NM',
  },
  {
    stateName: 'NU',
    stateCode: 'NU',
  },
  {
    stateName: 'Nevada',
    stateCode: 'NV',
  },
  {
    stateName: 'New York',
    stateCode: 'NY',
  },
  {
    stateName: 'Ohio',
    stateCode: 'OH',
  },
  {
    stateName: 'Oklahoma',
    stateCode: 'OK',
  },
  {
    stateName: 'Oregon',
    stateCode: 'OR',
  },
  {
    stateName: 'Pennsylvania',
    stateCode: 'PA',
  },
  {
    stateName: 'Puerto Rico',
    stateCode: 'PR',
  },
  {
    stateName: 'Palau',
    stateCode: 'PW',
  },
  {
    stateName: 'Rhode Island',
    stateCode: 'RI',
  },
  {
    stateName: 'South Carolina',
    stateCode: 'SC',
  },
  {
    stateName: 'South Dakota',
    stateCode: 'SD',
  },
  {
    stateName: 'Tennessee',
    stateCode: 'TN',
  },
  {
    stateName: 'Texas',
    stateCode: 'TX',
  },
  {
    stateName: 'Utah',
    stateCode: 'UT',
  },
  {
    stateName: 'Virginia',
    stateCode: 'VA',
  },
  {
    stateName: 'Virgin Islands',
    stateCode: 'VI',
  },
  {
    stateName: 'Vermont',
    stateCode: 'VT',
  },
  {
    stateName: 'Washington',
    stateCode: 'WA',
  },
  {
    stateName: 'Wisconsin',
    stateCode: 'WI',
  },
  {
    stateName: 'West Virginia',
    stateCode: 'WV',
  },
  {
    stateName: 'Wyoming',
    stateCode: 'WY',
  },
];

export const countryOptions = COUNTRIES_LIST.map(
  ({ countryName, isoCountryCode2 }) => ({
    label: `${countryName}`,
    value: isoCountryCode2,
  }),
);
export const US_COUNTRIES = ['AS', 'GU', 'MP', 'PR', 'US', 'VI'];

export const stateOptions = STATES_LIST.map(({ stateName, stateCode }) => ({
  label: `${stateCode} - ${stateName}`,
  value: stateCode,
}));

// Default props for the form fields
export const FORM_DEFAULT = {
  country: {
    name: 'address-country',
    label: 'Country',
    required: true,
    options: countryOptions,
    value: '',
  },
  company: {
    name: 'address-company',
    label: 'Company',
    required: false,
    value: '',
  },
  address1: {
    name: 'address-line-one',
    label: 'Address line 1',
    required: true,
    value: '',
  },
  address2: {
    name: 'address-line-two',
    label: 'Address line 2',
    required: false,
    value: '',
  },
  city: {
    name: 'address-city-town',
    label: 'City',
    labelAlt: 'City/Town',
    required: true,
    value: '',
  },
  state: {
    name: 'address-state',
    label: 'State',
    labelAlt: 'State/Province/Region',
    required: true,
    options: stateOptions,
    value: '',
  },
  zipCode: {
    name: 'address-zip-code',
    label: 'Zip code',
    labelAlt: 'Postal code',
    required: true,
    value: '',
  },
};
