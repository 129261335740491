import React from 'react';
import PropTypes from 'prop-types';
import SortOptions from './SortOptions';

const SortItem = ({ order, setOrder, options }) => {
  return (
    <div className="margin-bottom-6">
      <div className="text-uppercase text-bold ">sort by</div>
      <div className="">
        <SortOptions value={order} options={options} onSort={setOrder} />
      </div>
    </div>
  );
};

SortItem.propTypes = {
  order: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
};

export default SortItem;
