import React, { useState, useEffect } from 'react';
import {
  Editor,
  EditorState,
  ContentState,
  convertFromHTML,
  RichUtils,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import PropTypes from 'prop-types';
import './RichTextEditor.scss';
import Icon from '../../atoms/Icon/Icon';

const BLOCK_TYPES1 = [
  {
    label: 'H1',
    ariaLabel: 'H1 header',
    style: 'header-one',
    icon: (
      <Icon
        type="custom"
        data-testid="header-one"
        iconName="h1"
        className="text-icon"
      />
    ),
  },
  {
    label: 'H2',
    ariaLabel: 'H2 header',
    style: 'header-two',
    icon: (
      <Icon
        type="custom"
        data-testid="header-two"
        iconName="h2"
        className="text-icon"
      />
    ),
  },
  {
    label: 'H3',
    ariaLabel: 'H3 header',
    style: 'header-three',
    icon: (
      <Icon
        type="custom"
        data-testid="header-three"
        iconName="h3"
        className="text-icon"
      />
    ),
  },
  {
    label: 'H4',
    ariaLabel: 'H4 header',
    style: 'header-four',
    icon: (
      <Icon
        type="custom"
        data-testid="header-four"
        iconName="h4"
        className="text-icon"
      />
    ),
  },
  {
    label: 'H5',
    ariaLabel: 'H5 header',
    style: 'header-five',
    icon: (
      <Icon
        type="custom"
        data-testid="header-five"
        iconName="h5"
        className="text-icon"
      />
    ),
  },
  {
    label: 'H6',
    ariaLabel: 'H6 header',
    style: 'header-six',
    icon: (
      <Icon
        type="custom"
        data-testid="header-six"
        iconName="h6"
        className="text-icon"
      />
    ),
  },
];

const BLOCK_TYPES2 = [
  {
    label: 'Blockquote',
    ariaLabel: 'Blockquote',
    style: 'blockquote',
    icon: <Icon type="custom" data-testid="blockquote" iconName="blockquote" />,
  },
  {
    label: 'UL',
    ariaLabel: 'Unordered List',
    style: 'unordered-list-item',
    icon: <Icon type="custom" data-testid="ul" iconName="unordered-list" />,
  },
  {
    label: 'OL',
    ariaLabel: 'Ordered List',
    style: 'ordered-list-item',
    icon: <Icon type="custom" data-testid="ol" iconName="ordered-list" />,
  },
];

const INLINE_STYLES = [
  {
    label: 'B',
    ariaLabel: 'Bold',
    style: 'BOLD',
    icon: <Icon data-testid="bold" type="custom" iconName="bold" />,
  },
  {
    label: 'I',
    ariaLabel: 'Italic',
    style: 'ITALIC',
    icon: <Icon data-testid="italic" type="custom" iconName="italic" />,
  },
  {
    label: 'U',
    ariaLabel: 'Underline',
    style: 'UNDERLINE',
    icon: <Icon data-testid="underline" type="custom" iconName="underline" />,
  },
];

const StyleButton = ({ onToggle, active, style, label, ariaLabel, icon }) => {
  const [className, setClassName] = useState('');

  const onStyleToggle = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  useEffect(() => {
    let c = 'RichEditor-styleButton';
    if (active) {
      c += ' RichEditor-activeButton';
    }
    setClassName(c);
  }, [active]);

  return (
    <span
      className={className}
      aria-label={ariaLabel}
      onMouseDown={onStyleToggle}
      role="button"
      tabIndex={0}
    >
      {icon || label}
    </span>
  );
};

StyleButton.defaultProps = {
  icon: null,
};

StyleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  style: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

// eslint-disable-next-line react/prop-types
const BlockStyleControls = ({ editorState, onToggle, blocks = [] }) => {
  const [blockType, setBlockType] = useState(null);

  useEffect(() => {
    if (editorState) {
      const selection = editorState.getSelection();
      setBlockType(
        editorState
          .getCurrentContent()
          .getBlockForKey(selection.getStartKey())
          .getType(),
      );
    }
  }, [editorState]);

  return (
    <div className="">
      {blocks.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          ariaLabel={type.ariaLabel}
          icon={type.icon}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const InlineStyleControls = ({ editorState, onToggle, blocks = [] }) => {
  // eslint-disable-next-line react/prop-types
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className="">
      {blocks.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          ariaLabel={type.ariaLabel}
          icon={type.icon}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

BlockStyleControls.propTypes = {
  onToggle: PropTypes.func.isRequired,
  editorState: PropTypes.objectOf(EditorState).isRequired,
};

const RichTextEditor = ({
  content,
  onChange,
  disableHeaders,
  disableInline,
  disableList,
  customStylesToInclude,
}) => {
  const [editorState, setEditorState] = useState(null);

  useEffect(() => {
    if (content) {
      const blocksFromHTML = convertFromHTML(content);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  const handleKeyCommand = (c, e) => {
    const newState = RichUtils.handleKeyCommand(e, c);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  };

  const handleChange = (e) => {
    setEditorState(e);
    const c = editorState.getCurrentContent();
    onChange(stateToHTML(c));
  };

  const toggleBlockType = (blockType) => {
    handleChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineType = (inlineStyle) => {
    handleChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case 'blockquote':
        return 'RichEditor-blockquote text-grey-5';
      default:
        return null;
    }
  };

  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  const customizeStyles = (blockStyles, arrayOfCustomStyles) => {
    if (!arrayOfCustomStyles) return blockStyles;

    const customStyles = blockStyles.filter((blockStyle) =>
      arrayOfCustomStyles.includes(blockStyle.label),
    );

    return customStyles;
  };

  return (
    <div className="grid-col-12">
      <div className="RichEditor-root">
        <div className="grid-row">
          <div className="grid-col flex-1 editor-header RichEditor-controls">
            {editorState && (
              <>
                {!disableHeaders && (
                  <BlockStyleControls
                    blocks={customizeStyles(
                      BLOCK_TYPES1,
                      customStylesToInclude,
                    )}
                    editorState={editorState}
                    onToggle={toggleBlockType}
                  />
                )}
                {!disableInline && (
                  <InlineStyleControls
                    blocks={customizeStyles(
                      INLINE_STYLES,
                      customStylesToInclude,
                    )}
                    editorState={editorState}
                    onToggle={toggleInlineType}
                  />
                )}
                {!disableList && (
                  <BlockStyleControls
                    blocks={customizeStyles(
                      BLOCK_TYPES2,
                      customStylesToInclude,
                    )}
                    editorState={editorState}
                    onToggle={toggleBlockType}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col flex-1 editor-body">
            {editorState && (
              <Editor
                editorState={editorState}
                onChange={(e) => {
                  handleChange(e);
                }}
                ariaLabel="content"
                blockStyleFn={getBlockStyle}
                customStyleMap={styleMap}
                handleKeyCommand={handleKeyCommand}
                spellCheck
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

RichTextEditor.defaultProps = {
  disableHeaders: false,
  disableInline: false,
  disableList: false,
  customStylesToInclude: null,
};

RichTextEditor.propTypes = {
  content: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disableHeaders: PropTypes.bool,
  disableInline: PropTypes.bool,
  disableList: PropTypes.bool,
  customStylesToInclude: PropTypes.arrayOf(PropTypes.string),
};

export default RichTextEditor;
