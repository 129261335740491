/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const AccordionItem = ({
  title,
  id,
  content,
  expanded,
  className,
  handleToggle,
  requiredAsterisk,
}) => {
  const headingClasses = classnames('usa-accordion__heading', className);
  const contentClasses = classnames(
    'usa-accordion__content',
    'usa-prose',
    'margin-top-1em',
    'margin-bottom-1em',
    'padding-bottom-1',
    'padding-top-1',
    className,
  );

  return (
    <>
      <h2 className={headingClasses}>
        <button
          type="button"
          className="usa-accordion__button bg-gray-3"
          aria-expanded={expanded}
          aria-controls={id}
          data-testid={`accordionButton_${id}`}
          onClick={handleToggle}
        >
          {title}
          {requiredAsterisk && (
            <span
              data-testid="required-asterisk"
              className="text-secondary-dark"
            >
              {' '}
              *
            </span>
          )}
        </button>
      </h2>
      <div
        id={id}
        data-testid={`accordionItem_${id}`}
        className={contentClasses}
        hidden={!expanded}
      >
        {content}
      </div>
    </>
  );
};

AccordionItem.defaultProps = {
  title: null,
  id: '',
  content: null,
  className: null,
  requiredAsterisk: false,
};

AccordionItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  requiredAsterisk: PropTypes.bool,
};

export default AccordionItem;
