export const keyCodes = {
  TAB: 'Tab',
  ENTER: 'Enter',
  ESC: 'Escape',
  IEESC: 'Esc',
  SPACE: ' ',
  PAGEUP: 'PageUp',
  PAGEDOWN: 'PageDown',
  END: 'End',
  HOME: 'Home',
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown',
};

export const keys = {
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

export function matches(event, keysToMatch) {
  for (let i = 0; i < keysToMatch.length; i += 1) {
    if (keysToMatch[i] === event.which || keysToMatch[i] === event.key) {
      return true;
    }
  }
  return false;
}

export function match(eventOrCode, key) {
  return eventOrCode.which === key || eventOrCode === key;
}

export function getCharacterFor(eventOrCode) {
  if (typeof eventOrCode === 'number') {
    return String.fromCharCode(eventOrCode);
  }

  return (
    eventOrCode.key ||
    String.fromCharCode(eventOrCode.which || eventOrCode.keyCode)
  );
}
