import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Announcement.scss';

export const Announcement = ({
  id,
  announcementMessage,
  className,
  announcementTitle,
}) => {
  const classes = classnames('usa-banner afp-announcement', className);
  return (
    <section data-testid={`${id}_wrapper`} className={classes}>
      <h3 className="afp-announcements__title font-sans-xl">
        {announcementTitle}
      </h3>
      <div className="grid-row">
        <div className="desktop:grid-col-6">
          <p>{announcementMessage}</p>
        </div>
      </div>
    </section>
  );
};

Announcement.defaultProps = {
  announcementTitle: 'Announcement',
};

Announcement.propTypes = {
  id: PropTypes.string.isRequired,
  announcementMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
};
