import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './CounterTag.scss';

export const CounterTag = ({ children, count, ...props }) => {
  const tagClasses = classnames('afp-counter-tag text-no-wrap');

  const counterClasses = classnames(
    'text-white padding-x-05 afp-counter-tag__count',
    { 'bg-primary': count > 0 },
    { 'bg-base-lighter text-ink': count <= 0 },
    { 'margin-left-1': children }
  );

  return (
    <span className={tagClasses} {...props}>
      {children}
      <span className={counterClasses}>{count}</span>
    </span>
  );
};

CounterTag.defaultProps = {
  children: undefined,
  count: 0,
};

CounterTag.propTypes = {
  children: PropTypes.node,
  count: PropTypes.number,
};

export default CounterTag;
