import { useReducer } from 'react';
import {cloneDeep} from 'lodash';

export const typeaheadReducer = (state, action) => {
  const { key, value, typeaheadValues } = action.payload;
  const newState = [...state];
  const tpFieldIndex = state.findIndex((el) => el.key === key);
  switch (action.type) {
    case 'setEmpty':
      if (tpFieldIndex === -1) {
        newState.push({ key, value, typeaheadValues: [] });
      } else {
        newState[tpFieldIndex] = { key, value, typeaheadValues: [] };
      }
      return newState;
    case 'setValues':
      newState[tpFieldIndex] = { ...newState[tpFieldIndex], typeaheadValues };
      return newState;
    case 'clearValues':
      newState[tpFieldIndex] = {
        ...newState[tpFieldIndex],
        typeaheadValues: [],
        value: '',
      };
      return newState;
    default:
      return [...state];
  }
};

export const useTypeaheadReducer = (initialState) =>
  useReducer(typeaheadReducer, initialState);

export const filterReducer = (state, action) => {
  switch (action.type) {
    case 'setOne': {
      const { key } = action.filter;
      const { fetchNewData } = action;
      const filterIndex = state.filters.findIndex((el) => el.key === key);

      if (filterIndex !== -1) {
        const newFilterState = [...state.filters];
        newFilterState[filterIndex] = action.filter;
        let newState = { ...state, filters: newFilterState };
        if (fetchNewData) {
          newState = { ...newState, updateToggle: !newState.updateToggle };
        }
        return newState;
      }
      return { ...state };
    }
    case 'clearAll': {
      const { initialState } = action;

      return {
        ...initialState,
        filters: initialState.filters,
        updateToggle: !state.updateToggle,
      };
    }
    default:
      return { ...state };
  }
};

export const useFilterReducer = (initialState) =>
  useReducer(filterReducer, cloneDeep(initialState));
