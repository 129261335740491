import React from 'react';
import RFlexView from 'react-flexview';
import classnames from 'classnames';
import styles from './FlexView.module.scss';

export const FlexView = ({ fullWidth, contentSpace, className, ...props }) => {
  const classes = classnames(
    {
      [styles.fullWidth]: fullWidth,
    },
    {
      [styles[contentSpace]]: !!contentSpace,
    },
    className,
  );
  return <RFlexView {...props} className={classes} />;
};
