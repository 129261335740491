import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Button } from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';

const Alert = ({
  type,
  heading,
  actions,
  children,
  slim,
  noIcon,
  className,
  validation,
  focused,
  onClose,
  showClose,
  ...props
}) => {
  const innerRef = useRef(null);
  useEffect(() => {
    if (focused && innerRef.current) {
      innerRef.current.focus();
    }
  }, [focused]);

  const classes = classnames(
    'usa-alert',
    { [`usa-alert--${type}`]: !!type },
    {
      'usa-alert--slim': slim,
      'usa-alert--no-icon': noIcon,
      'usa-alert--validation': validation,
    },
    className,
  );

  function closeAlert() {
    return (
      <>
        {type !== 'error' && type !== 'warning' ? (
          <div
            data-testid="alert-message-close"
            className="position-absolute right-0 top-1"
          >
            <Button
              data-testid={`${type}-close`}
              variant="unstyled"
              className="margin-x-1"
              aria-label="close"
              style={{ color: 'black' }}
              onClick={
                onClose ||
                (() => {
                  return null;
                })
              }
              rightIcon={{name: "close", className: "usa-icon--size-4"}}
            />
          </div>
        ) : null}
      </>
    );
  }

  function showCloseAlert() {
    if (showClose || onClose) {
      return closeAlert();
    }
    return null;
  }

  return (
    <div
      className={classes}
      data-testid="alert"
      {...props}
      tabIndex="-1"
      ref={innerRef}
      role="alert"
    >
      <div className="usa-alert__body">
        {heading && <h3 className="usa-alert__heading">{heading}</h3>}
        {children &&
          (validation ? (
            children
          ) : (
            <div className="usa-alert__text">{children}</div>
          ))}
      </div>
      {/* TODO: need to remove actions once everyone swithces to new alert */}
      {actions ? <div>{actions}</div> : showCloseAlert()}
    </div>
  );
};

Alert.defaultProps = {
  heading: '',
  children: '',
  // TODO: remove actions once everyone swithces to new alert
  actions: '',
  className: '',
  slim: false,
  noIcon: false,
  validation: false,
  focused: false,
  onClose: null,
  showClose: false,
};

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  heading: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
  slim: PropTypes.bool,
  noIcon: PropTypes.bool,
  className: PropTypes.string,
  validation: PropTypes.bool,
  focused: PropTypes.bool,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
};

export default Alert;
