import React from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Textbox from '../../atoms/Textbox/Textbox';
import { ErrorMessage, Help, Hint, HintWithAria } from '../../lib/util/index';
import './TextInput.scss';

const Label = ({ text, className, htmlFor, required }) => {
  let labelContent = text;
  if (required) {
    labelContent = (
      <>
        {labelContent} <span className="afp-required-field">*</span>
      </>
    );
  }
  if (text) {
    return (
      <label className={`usa-label ${className}`} htmlFor={htmlFor}>
        {labelContent}
      </label>
    );
  }
  return null;
};

Label.defaultProps = { text: undefined, className: '', required: false };
Label.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

const getRemainCharLength = (characterLimit, value) => {
  if (characterLimit && typeof parseInt(characterLimit, 10) === 'number') {
    const valueLength = !value ? 0 : value.toString().length;
    return parseInt(characterLimit, 10) - valueLength;
  }
  return null;
};

const TextInput = ({
  id,
  label,
  errorMessage,
  hint,
  help,
  required,
  containerClassName,
  showLabelError,
  errorMessagePlacementEnd,
  characterLimit,
  inputClass,
  onChange,
  prefix,
  suffix,
  ...restProps
}) => {
  // when user type new character the component will refresh. without useMemo, a new Id will generated after component refresh
  const uniqueIdString = React.useMemo(() => uniqueId(), [id]);

  const [remainCharacterState, setRemainCharacterState] = React.useState(
    getRemainCharLength(characterLimit, restProps.value),
  );

  const textBoxId = id || `textbox_${uniqueIdString}`;
  // if user have characterLimit always use uniqueId to avoid conflict ids (e.g. AFP-13286) for aria-describedby which is used by HintWithAria when we have multiple textInputBox.
  const hintId = characterLimit ? `textbox_hint_${uniqueIdString}` : '';

  const textBoxProps = {
    variant: errorMessage && 'error',
    ...restProps,
    id: textBoxId,
    className: inputClass,
  };

  const inputGroupClasses = classnames('usa-input-group', {
    [`usa-input--${textBoxProps.variant}`]: !!textBoxProps.variant,
  });

  return (
    <div
      className={`usa-form-group${
        errorMessage ? ' usa-form-group--error' : ''
      } ${containerClassName}`}
    >
      <Label
        id={textBoxId}
        text={label}
        className={errorMessage && showLabelError ? 'usa-label--error' : ''}
        htmlFor={textBoxId}
        required={required}
      />
      {!errorMessagePlacementEnd && <ErrorMessage text={errorMessage} />}
      <Help text={help} />
      {(prefix || suffix) && restProps.type !== 'textarea' ? (
        <div className={inputGroupClasses}>
          {prefix && <div className="usa-input-prefix">{prefix}</div>}
          <Textbox
            required={required}
            maxLength={characterLimit}
            onChange={(evt) => {
              setRemainCharacterState(
                getRemainCharLength(characterLimit, evt.target.value),
              );
              onChange(evt);
            }}
            aria-describedby={`${label ? `${textBoxId}` : ''}${
              hintId ? ' ' + hintId : ''
            }`}
            {...textBoxProps}
            variant="" // to disable to styles as we're adding them via inputGroupClasses
          />
          {suffix && <div className="usa-input-suffix">{suffix}</div>}
        </div>
      ) : (
        <Textbox
          required={required}
          maxLength={characterLimit}
          onChange={(evt) => {
            setRemainCharacterState(
              getRemainCharLength(characterLimit, evt.target.value),
            );
            onChange(evt);
          }}
          aria-describedby={`${label ? `${textBoxId}` : ''}${
            hintId ? ' ' + hintId : ''
          }`}
          {...textBoxProps}
        />
      )}

      {characterLimit && (
        <div>
          <HintWithAria
            text={`${remainCharacterState} characters ${
              characterLimit.toString() === remainCharacterState.toString()
                ? 'allowed'
                : 'left'
            }`}
            hintId={hintId}
          />
        </div>
      )}
      <Hint text={hint} />
      {errorMessagePlacementEnd && (
        <ErrorMessage id={textBoxId} text={errorMessage} />
      )}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  help: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  containerClassName: PropTypes.string,
  showLabelError: PropTypes.bool,
  errorMessagePlacementEnd: PropTypes.bool,
  type: PropTypes.string,
  characterLimit: PropTypes.number,
  inputClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

TextInput.defaultProps = {
  id: undefined,
  label: undefined,
  hint: undefined,
  help: undefined,
  errorMessage: undefined,
  required: false,
  containerClassName: '',
  showLabelError: true,
  errorMessagePlacementEnd: false,
  type: undefined,
  characterLimit: undefined,
  inputClass: '',
  value: undefined,
  onChange: () => null,
  prefix: undefined,
  suffix: undefined,
};

export default TextInput;
