import { find } from 'lodash';

const getFilterLabelByValue = (filter, value, key) => {
  if (typeof value === 'string' || Array.isArray(value)) {
    const filterLabel = find(filter.options, { value });
    return filterLabel ? filterLabel.label : value;
  }
  if (typeof value === 'object') {
    if (value.beginning) return `${value.beginning} - ${value.end}`;
    return `${key}: ${value[key]}`;
  }
  return [];
};

// eslint-disable-next-line import/prefer-default-export
export const generateFilterValues = (filterGroup) => {
  const generatedFilterValues = filterGroup.reduce((acc, filter) => {
    if (filter.value && !filter.hideChip) {
      if (filter.displayValue) {
        const label = filter.displayValue(filter.value);
        if (label) {
          acc.push({
            value: filter.value,
            label: filter.displayValue(filter.value),
            filter,
          });
        }
      } else if (typeof filter.value === 'string')
        acc.push({
          value: filter.value,
          label: getFilterLabelByValue(filter, filter.value),
          filter,
        });
      else if (Array.isArray(filter.value)) {
        filter.value.forEach((val) => {
          acc.push({
            value: val,
            label: getFilterLabelByValue(filter, val),
            filter,
          });
        });
      } else if (typeof filter.value === 'object') {
        if (filter.value.beginning)
          acc.push({
            value: filter.value,
            label: getFilterLabelByValue(filter, filter.value),
            filter,
          });
        else
          Object.keys(filter.value).forEach((key) => {
            // eslint-disable-next-line no-unused-expressions
            filter.value[key] &&
              acc.push({
                value: filter.value[key],
                label: getFilterLabelByValue(filter, filter.value, key),
                filter,
              });
          });
      }
    }
    return acc;
  }, []);
  return generatedFilterValues;
};
