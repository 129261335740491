import React from 'react';
import PropTypes from 'prop-types';
import SelectDropdown from '../../atoms/SelectDropdown/SelectDropdown';

const SortOptions = ({ options, value, onSort }) => {
  let orderValue = value;

  if (typeof orderValue === 'string')
    // Removes backtick. e.g. `title` ASC => title ASC
    orderValue = value.replace(/`/g, '');

  return (
    <SelectDropdown
      data-testid="sort-dropdown"
      name="" //required in base component
      aria-label="sort by"
      value={orderValue}
      options={options}
      onChange={(e) => {
        onSort(e.target.value);
      }}
    />
  );
};

SortOptions.defaultProps = {
  value: '',
  onSort: () => null,
};
SortOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSort: PropTypes.func,
};

export default SortOptions;
