import React from 'react';
import PropTypes from 'prop-types';
import imgLight from './icon-empty-light.svg';
import imgDark from './icon-empty-dark.svg';

const EmptyState = ({
  alt,
  hasBackground,
  containerStyles,
  topText,
  topTextStyles,
  bottomText,
  bottomTextStyles,
  ...imageProps
}) => {
  return (
    <div
      className={`${
        hasBackground && 'bg-gray-3'
      } flex-justify-center text-center ${containerStyles}`}
    >
      {topText && (
        <div className={`padding-205 ${topTextStyles}`}>{topText}</div>
      )}

      <img
        src={hasBackground ? imgDark : imgLight}
        {...{
          'aria-hidden': true,
          alt,
          ...imageProps,
        }}
      />
      {bottomText && (
        <div className={`padding-205 ${bottomTextStyles}`}>{bottomText}</div>
      )}
    </div>
  );
};

EmptyState.propTypes = {
  alt: PropTypes.string,
  hasBackground: PropTypes.bool,
  containerStyles: PropTypes.string,
  topText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  topTextStyles: PropTypes.string,
  bottomText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bottomTextStyles: PropTypes.string,
};

EmptyState.defaultProps = {
  alt: 'empty state',
  hasBackground: false,
  containerStyles: null,
  topText: null,
  topTextStyles: null,
  bottomText: null,
  bottomTextStyles: null,
};

export default EmptyState;
