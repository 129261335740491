import React from 'react';
import PropTypes from 'prop-types';
import GsaLogo from '../../../styles/images/logo-gsa.svg';
import Icon from '../../atoms/Icon/Icon';
import './AFPFooter.scss';

// `primaryLinks` were not properly implemented/styled. complete as needed.

const AfpFooter = ({ importantLinks, privacyLink }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <footer className="usa-footer afp-footer">
      <div className="grid-container usa-footer__return-to-top">
        <a href="#main-content" id="return-to-top" onClick={scrollTop}>
          <Icon
            iconName="arrow_upward"
            className="usa-icon--size-2 primary-light text-base-dark afp-icon-adjust"
          />
          Return to top
        </a>
      </div>
      <div className="usa-footer__primary-section">
        <div className="grid-container">
          <div className="grid-row afp-footer__links padding-y-2">
            <div className="grid-col-12 tablet:grid-col grid-col-fill">
              GSA Fleet Technical Support:{" "}
              <a
                className="usa-footer__primary-link padding-0 display-inline-block text-normal"
                href="tel:18664726711"
              >
                866-472-6711 
              </a>
              {" "}from 8:00 a.m. – 7:00 p.m. ET, Monday&ndash;Friday
            </div>
            <div className="grid-col-auto flex-justify-end">
              <a
                href="mailto:fleet.helpdesk@gsa.gov"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-normal afp-footer-email">
                  fleet.helpdesk@gsa.gov
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="usa-identifier">
        <section className="usa-identifier__section usa-identifier__section--masthead">
          <div className="usa-identifier__container">
            <div className="usa-identifier__logos">
              <a className="usa-identifier__logo">
                <img
                  className="usa-identifier__logo-img"
                  alt="General Services Administration"
                  src={GsaLogo}
                />
              </a>
            </div>
            <div className="usa-identifier__identity">
              <p className="usa-identifier__identity-domain">GSAFleet.gov</p>
              <p className="usa-identifier__identity-disclaimer">
                {' '}
                An official website of the{' '}
                <a
                  target="_blank"
                  href="https://www.gsa.gov/"
                  className="Footer_link_color"
                >
                  General Services Administration
                </a>
              </p>
            </div>
          </div>
        </section>
        <nav
          className="usa-identifier__section usa-identifier__section--required-links"
          aria-label="Important Links"
        >
          <div className="usa-identifier__container">
            {importantLinks && (
              <ul className="usa-identifier__required-links-list">
                {importantLinks.map((link, i) => (
                  <li key={i} className="usa-identifier__required-links-item">
                    <a
                      target="_blank"
                      href={link.href}
                      className="usa-identifier__required-link"
                    >
                      {link.description}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </nav>

        <section
          className="usa-identifier__section usa-identifier__section--warning"
          aria-label="U.S. government information and services warning"
        >
          <div className="usa-identifier__container">
            <p className="usa-identifier__identity-domain">
              This is a U.S. General Services Administration computer system
              that is &quot;FOR OFFICIAL USE ONLY&quot;.
            </p>
            <br />
            <p className="usa-identifier__identity-domain">
              This system is subject to monitoring. Therefore, no expectation of
              privacy is to be assumed. Individuals found performing
              unauthorized activities are subject to disciplinary action
              including criminal prosecution.{' '}
              {privacyLink && (
                <a
                  target="_blank"
                  href={privacyLink}
                  className="usa-identifier__identity-domain"
                >
                  <span>Privacy &amp; Regulations</span>
                </a>
              )}{' '}
            </p>
          </div>
        </section>

        <section
          className="usa-identifier__section usa-identifier__section--usagov"
          aria-label="U.S. government information and services"
        >
          <div className="usa-identifier__container ">
            <a
              href="https://www.usa.gov/"
              target="_blank"
              className="usa-link Footer_link_color"
            >
              <span className="text-normal">
                {' '}
                Looking for U.S. government information and services?
              </span>{' '}
              <span>Visit USA.gov</span>
            </a>
          </div>
        </section>
      </div>
    </footer>
  );
};

AfpFooter.propTypes = {
  primaryLinks: PropTypes.array,
  importantLinks: PropTypes.array,
};

export default AfpFooter;
