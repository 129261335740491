/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-multi-spaces */
import classnames from 'classnames';
import { get, kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import {
  useColumnOrder,
  useExpanded,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { Button } from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import useDidMountEffect from '../../lib/hooks/useDidMountEffect';
import useViewport from '../../lib/hooks/useViewport';
import './table.scss';

// Select checkbox
const IndeterminateCheckbox = forwardRef(
  (
    {
      id,
      indeterminate,
      selectionHeaderClicked,
      selectAllHeader,
      className,
      complianceRowName,
      ...rest
    },
    ref,
  ) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    const rowHeader = complianceRowName
      ? complianceRowName
      : id === 'select-all-header'
      ? 'selectAll'
      : selectAllHeader;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const classes = classnames('usa-checkbox__label', 'text-bold', className);

    return (
      // <>
      //   <input
      //     id={id}
      //     type="checkbox"
      //     ref={resolvedRef}
      //     onClick={() => {
      //       if (selectionHeaderClicked) selectionHeaderClicked(id);
      //     }}
      //     {...rest}
      //   />
      // </>
      <div className="usa-checkbox text-middle text-center">
        <input
          id={`select-row-${id}`}
          type="checkbox"
          className="usa-checkbox__input"
          aria-label="Check"
          aria-describedby="descriptionCheck"
          ref={resolvedRef}
          onClick={() => {
            if (selectionHeaderClicked) selectionHeaderClicked(id);
          }}
          {...rest}
        />
        <label
          data-testid={`select-row-${id}`}
          className={classes}
          style={{ lineHeight: 0.8 }}
          htmlFor={`select-row-${id}`}
        >
          <span className="afp-responsive-input"> {rowHeader}</span>
        </label>
      </div>
    );
  },
);

IndeterminateCheckbox.defaultProps = {
  selectionHeaderClicked: undefined,
  selectAllHeader: '',
  className: '',
};
IndeterminateCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  selectionHeaderClicked: PropTypes.func,
  selectAllHeader: PropTypes.string,
  className: PropTypes.string,
};

// Table component.
/**
 * @type React.ForwardRefRenderFunction<React.FunctionComponent>
 */
const AFPTable = forwardRef(
  (
    {
      testId,
      columns: dataColumns,
      data,
      renderRowSubComponent,
      onSort,
      onRowSelect,
      bordered,
      fullWidth,
      stacked,
      defaultSort,
      expandable,
      selectable,
      selectAllHeader,
      selectAllHeaderClicked,
      selectedRows,
      selectDisabledRows,
      hiddenColumns,
      boldLastRow,
      inputRef,
      tableHeaderRef,
      updateCellData,
      skipPageReset,
    },
    ref,
  ) => {
    // Custom hook to detect screen size.
    const { width, device } = useViewport();

    const allColumns = useMemo(() => {
      if (expandable) {
        return [
          {
            // Make an expander cell
            Header: '', // No header
            id: 'expander', // It needs an ID
            sortable: false,
            headerClassName: 'cell-expander',
            Cell: ({
              row: { id, isExpanded = false, getToggleRowExpandedProps },
            }) => {
              const iconName = isExpanded ? 'expand_more' : 'navigate_next';
              const buttonText = isExpanded ? 'View less' : 'View more';

              return (
                // Use Cell to render an expander for each row.
                // We can use the getToggleRowExpandedProps prop-getter
                // to build the expander.
                <>
                  <Button
                    variant="unstyled"
                    className="table__expand_button"
                    aria-expanded={isExpanded}
                    data-testid={`row-expander-${id}`}
                    {...getToggleRowExpandedProps()}
                    rightIcon={{
                      name: iconName,
                      className: 'usa-icon--size-4 text-middle',
                    }}
                  />

                  <Button
                    variant="outline"
                    className="table__expand_button--mobile"
                    data-testid={`row-expander-mobile-${id}`}
                    {...getToggleRowExpandedProps()}
                    label={buttonText}
                    rightIcon={{
                      name: iconName,
                      className: 'usa-icon--size-4 text-middle',
                    }}
                  />
                </>
              );
            },
          },
          ...dataColumns,
        ];
      }

      return dataColumns;
    }, []);

    const prepareSortOrder = useCallback((sortValue) => {
      let id = null;
      let desc = false;

      // Default sort is empty
      if (!sortValue) return '';

      // default sort passed as an array e.g.[['standardItemNumber', 'DESC']]
      if (Array.isArray(sortValue) && sortValue.length) {
        id = sortValue[0][0] || null;

        if (id && sortValue[0][1] && sortValue[0][1].length) {
          desc = sortValue[0][1].toLowerCase() === 'desc';
        }
      } // Default sort passed as string literal e.g. "standardItemNumber DESC"
      else if (typeof sortValue === 'string' && sortValue.length) {
        const [field, direction = ''] = sortValue.split(' ');
        id = field;
        desc = direction.toLowerCase() === 'desc';
      }

      return { id, desc };
    }, []);

    const prepareSelections = useCallback((selection) => {
      Object.keys(selection).forEach((rowIndex) => {
        const rowKey = Number(rowIndex);
        // Prevents selection of disabled rows.
        if (selectDisabledRows.includes(rowKey)) delete selection[rowKey];
      });
      return selection;
    }, []);

    const tableInstance = useTable(
      {
        columns: allColumns,
        data,
        disableSortRemove: true,
        // Only set manualSortBy true if onSort function is passed as a prop.
        // If manualSortBy is set to false. Sorting happens locally.
        manualSortBy: typeof onSort === 'function',
        initialState: {
          selectedRowIds: prepareSelections(selectedRows),
          sortBy: [prepareSortOrder(defaultSort)],
          hiddenColumns,
        },
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateCellData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer. Example in VMS PM-Express table (editable fields)
        updateCellData,
      },
      useColumnOrder,
      useSortBy,
      useExpanded, // We can useExpanded to track the expanded state
      // for sub components too!
      useRowSelect,

      (hooks) => {
        if (selectable) {
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: 'selection',
              sortable: false,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox

              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  id="select-all-header"
                  className="margin-top-0"
                  selectAllHeader={selectAllHeader}
                  selectionHeaderClicked={selectAllHeaderClicked}
                  {...getToggleAllRowsSelectedProps()}
                />
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox

              Cell: ({ row }) => (
                <IndeterminateCheckbox
                  id={row.id}
                  complianceRowName={row?.original?.rowName}
                  {...row.getToggleRowSelectedProps()}
                  disabled={row.selectDisabled || false}
                />
              ),
              headerClassName: 'cell-middle',
            },
            ...columns,
          ]);

          // Select all hook to ignore disabled rows.
          hooks.getToggleAllRowsSelectedProps = [
            (props, { instance }) => [
              props,
              {
                onChange: () => {
                  instance.rows.forEach((row) => {
                    // Prevents selectAll header for checking disabled rows.
                    if (row.toggleRowSelected && !row.selectDisabled) {
                      row.toggleRowSelected(
                        !instance.rows.every((r) => r.isSelected),
                      );
                    }
                  });
                },
                style: { cursor: 'pointer' },
                checked:
                  instance.rows.length &&
                  instance.rows.every((row) => row.isSelected),
                title: 'Toggle All Rows Selected',
                indeterminate: Boolean(
                  !instance.isAllRowsSelected &&
                    Object.keys(instance.state.selectedRowIds).length,
                ),
              },
            ],
          ];
        }
      },
    );

    const {
      getTableProps,
      getTableBodyProps,
      prepareRow,
      toggleSortBy,
      toggleAllRowsSelected,
      headerGroups,
      rows,
      visibleColumns,
      setColumnOrder,
      state: { sortBy, selectedRowIds },
      selectedFlatRows,
    } = tableInstance;

    // Expose instance functions to parent.
    useImperativeHandle(ref, () => ({
      /**
       * Used to toggle select all rows using header checkbox
       * @param {Boolean} set set select all row select or not.
       */
      toggleAllRowsSelected(set = true) {
        // https://react-table.tanstack.com/docs/api/useRowSelect#instance-properties
        toggleAllRowsSelected(set);
      },

      /**
       * Toggle sort direction.
       * @param {string} columnId The column id
       * @param {Boolean} descending descending value true/false
       * @param {Boolean} isMulti multi column sort
       */
      toggleSortBy(columnId, descending, isMulti = false) {
        // https://react-table.tanstack.com/docs/api/useSortBy#instance-properties
        toggleSortBy(columnId, descending, isMulti);
      },
    }));

    /* Feb 2, 2022 - removed updateExpanderPosition and useEffect below to fix the
       expander issue with horizontal scrolling as stacked prop is disabled
    */
    // Re-order expander column based on screen size.
    // On mobile, the expander will be the last child of tr
    // January 12 2022 - disable stacked prop on temporary basis

    // Fire sort
    useDidMountEffect(() => {
      let order = [];
      sortBy.forEach((sort) => {
        const field = sort.id || '';
        const direction = sort.desc ? 'DESC' : 'ASC';

        if (field.length && !field.includes('`')) {
          // returns "id DESC" or for associated fields "`content.id` ASC"
          order = `\`${field}\` ${direction}`;
        }
      });

      // if onSort handler is present and sort field is available, bubble up sortOrder.
      if (onSort && order !== defaultSort) onSort(order);
    }, [sortBy]);

    // Row select
    useDidMountEffect(() => {
      onRowSelect({ selectedFlatRows, selectedRowIds });
    }, [selectedFlatRows, selectedRowIds]);

    const tableClasses = classnames('afp-responsive-table', 'usa-table', {
      // 'usa-table--stacked': stacked,
      'usa-table--borderless': !bordered,
      'usa-table--full-width': fullWidth,
      'afp-responsive-table--total-row': boldLastRow,
    });

    const getRowClasses = ({ isExpanded = false, isSelected = false }) => {
      if (isExpanded)
        return classnames({
          'table__expanded-row': isExpanded,
        });

      if (isSelected)
        return classnames({
          'table__selected-row': isSelected,
        });
      return null;
    };

    // Functions sticks sort icon with the last character of the header
    // Sort icon always wraps with last word of header title.
    const getSortIcon = (column, isSortable) => {
      const header = column.render('Header');
      const formatedHeader = header === '' ? 'collapsableheader' : header;

      // Table header sort ions.
      const tableSortIcon = () => {
        let iconName = 'icon-sort-down';

        if (!column.isSorted) iconName = 'icon-sort-toggle';
        if (column.isSortedDesc) iconName = 'icon-sort-up';
        let checkVisibility = !isSortable ? 'icon-visibility' : '';

        return {
          name: iconName,
          className: `usa-icon--size-3 table__sort-icon ${checkVisibility}`,
          type: 'custom',
        };
      };

      const sortIcon = tableSortIcon();
      const text = formatedHeader;
      const className = header === '' ? 'afp-responsive-input' : '';

      return { sortIcon, text, className };
    };

    return (
      <div
        ref={inputRef}
        data-testid="responsive-table-wrapper"
        className="afp-table-container--scrollable"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0} // tab-index added as per https://designsystem.digital.gov/components/table
      >
        <table
          {...getTableProps()}
          className={tableClasses}
          data-testid={testId}
        >
          <thead ref={tableHeaderRef}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  // Everything is sortable unless sortable is set to false.
                  const isSortable =
                    get(column, 'sortable', true) &&
                    get(column, 'accessor', false);
                  const { sortIcon, text, className } = getSortIcon(
                    column,
                    isSortable,
                  );

                  return (
                    <th
                      key={column.id}
                      scope="col"
                      className={`${get(
                        column,
                        'headerClassName',
                        '',
                      )} table-header__${
                        column.accessor
                          ? kebabCase(column.render('Header'))
                          : `no-accessor`
                      }`}
                      {...(isSortable
                        ? {
                            ...column.getHeaderProps(
                              column.getSortByToggleProps(),
                            ),
                          }
                        : {})}
                      title={
                        isSortable
                          ? `Toggle Sort by ${column.render('Header')}`
                          : column.id === 'selection'
                          ? 'Select All'
                          : column.render('Header')
                      }
                    >
                      <div className="display-inline-block">
                        <Button
                          className="header__sort-button table__icon-wrapper"
                          variant="unstyled"
                          data-testid={`sort-by-${kebabCase(column.Header)}-${
                            !column.isSorted || column.isSortedDesc
                              ? 'ascending'
                              : 'descending'
                          }`}
                          aria-label={`sort by ${column.Header} in ${
                            !column.isSorted || column.isSortedDesc
                              ? 'ascending'
                              : 'descending'
                          } order`}
                          leftIcon={sortIcon}
                          label={text}
                          labelClass={className}
                        />
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              // Disable selection if row index is available in selectDisabledRows.
              row.selectDisabled = selectDisabledRows.includes(Number(row.id));
              prepareRow(row);

              return (
                // Use a React.Fragment here so the table markup is still valid
                <React.Fragment key={row.id}>
                  <tr
                    data-row-type={Number(row.id) % 2 === 0 ? 'even' : 'odd'}
                    className={getRowClasses(row)}
                    data-testid={`table-row-${row.id}`}
                  >
                    <th
                      scope="row"
                      data-label={
                        typeof row.cells[0].render('Header') === 'string' &&
                        get(row.cells[0], 'column.accessor')
                          ? row.cells[0].render('Header')
                          : ''
                      }
                      className={`cell-${row.cells[0].column.id} first-cell order-last`}
                      {...row.cells[0].getCellProps()}
                    >
                      {row.cells[0].render('Cell')}
                    </th>
                    {row.cells.slice(1).map((cell) => {
                      return (
                        <td
                          data-label={cell.column.Header || ''}
                          {...cell.getCellProps()}
                          className={`${get(cell, 'column.cellClassName', '')}`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>

                  {/*
                      If the row is in an expanded state, render a row with a
                      column that fills the entire length of the table.
                    */}

                  {row.isExpanded ? (
                    <tr
                      className="table__sub-component_row"
                      data-testid={`sub-component-${row.id}`}
                    >
                      <th
                        colSpan={visibleColumns.length}
                        className="table__sub-component_cell"
                      >
                        {/*
                            Inside it, call our renderRowSubComponent function. In reality,
                            you could pass whatever you want as props to
                            a component like this, including the entire
                            table instance. But for this example, we'll just
                            pass the row
                          */}
                        {typeof renderRowSubComponent === 'function' &&
                          renderRowSubComponent({ row })}
                      </th>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  },
);

AFPTable.defaultProps = {
  selectAllHeader: '',
  selectedRows: {},
  testId: 'responsive-table',
  bordered: false,
  fullWidth: true,
  stacked: false,
  expandable: false,
  selectable: false,
  defaultSort: '',
  onSort: null,
  renderRowSubComponent: null,
  selectDisabledRows: [],
  hiddenColumns: [],
  rowActions: [],
  onRowSelect: () => null,
  selectAllHeaderClicked: () => null,
  boldLastRow: false,
};

AFPTable.propTypes = {
  selectAllHeader: PropTypes.string,
  selectedRows: PropTypes.shape(Object),
  testId: PropTypes.string,
  expandable: PropTypes.bool,
  selectable: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderRowSubComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  bordered: PropTypes.bool,
  fullWidth: PropTypes.bool,
  stacked: PropTypes.bool,
  defaultSort: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    PropTypes.string,
  ]),
  onSort: PropTypes.PropTypes.func, // Could be func or null
  onRowSelect: PropTypes.func,
  selectAllHeaderClicked: PropTypes.func,
  selectDisabledRows: PropTypes.arrayOf(PropTypes.number),
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  rowActions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired, // icon name e.g. edit
      label: PropTypes.string.isRequired, // action menu text e.g. Edit
      canShowIndicator: PropTypes, // row accessor to check to show/hide action.
    }),
  ),
  boldLastRow: PropTypes.bool,
};

export default AFPTable;
