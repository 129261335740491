import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './Chip.scss';

export const Chip = ({
  label,
  onClose,
  readonly,
  nowrap,
  tabIndex = "0",
  ...props
}) => {
  const [isClosed, setIsClosed] = React.useState(false);

  const onClickClose = () => {
    setIsClosed(true);
    onClose();
  };

  if (isClosed) return null;
  return (
    <div
      tabIndex={tabIndex}
      className="afp-chip"
      aria-label={`Filtering ${label}`}
      {...props}
    >
      <div className="afp-chip__container">
        <label
          className={`afp-chip__label-${
            nowrap ? 'nowrap' : 'wrap'
          } text-primary`}
        >
          {label}
        </label>
        {readonly ? null : (
          <button
            type="button"
            tabIndex={tabIndex}
            aria-label={`Remove ${label} filter`}
            data-testid="afp-chip-close"
            onClick={onClickClose}
          >
            <div className="afp-chip__close bg-primary">
              <Icon iconName="close" className="afp-chip__close-icon" />
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

Chip.defaultProps = {
  onClose: () => null,
  readonly: false,
  nowrap: false,
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  readonly: PropTypes.bool,
  nowrap: PropTypes.bool,
};

export default Chip;
