import { isNull } from 'lodash';
import React from 'react';
import { MegaMenuHamburger } from './mega-menu-hamburger';
import { MegaMenuImageClose } from './mega-menu-image-close';
import { MegaMenuSection } from './mega-menu-section';
import { MegaMenuSubItem } from './mega-menu-sub-item';
import { MegaMenuSubsectionList } from './mega-menu-subsystem-list';
import { MegaMenuSubsystemListGroup } from './mega-menu-subsystem-list-group';
import './megaMenu.scss';

const compareOrder = (a, b) => {
  return (a.order || 0) - (b.order || 0);
};

const getPrimaryMenuItems = () => {
  return document.querySelectorAll('.usa-nav__link');
};
const getSecondaryMenuItems = (primaryIdx) => {
  if (primaryIdx === null) {
    return document.querySelectorAll(`.usa-nav__submenu-item`);
  }
  return document.querySelectorAll(
    `.usa-nav__submenu-item[data-primary-idx="${primaryIdx}"]`,
  );
};

export const MegaMenuPrimaryNavigation = React.memo(
  ({ logo, menu, userMenu }) => {
    const data = [...menu];
    const [selectedGroup, setSelectedGroup] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [showMegaMenuHamburger, setShowMegaMenuHamburger] =
      React.useState(false);
    const renderMegaMenuHamburgerCutoffWidth = 1024;

    const onMenuPrimaryKeydown = (ev) => {
      const kbev = ev;

      const items = getPrimaryMenuItems();
      const el = ev.target;
      const primaryIdx = parseInt(el.dataset.primaryIdx, 10);

      if (kbev.key === 'Escape') {
        setSelectedGroup(null);
      }
      if (kbev.key === 'ArrowLeft' || kbev.key === 'ArrowUp') {
        const next = primaryIdx - 1;
        if (next < 0) {
          setSelectedGroup(null);
        } else {
          items[next].focus();
          setSelectedGroup(data[next].title);
        }
      }
      if (kbev.key === 'ArrowRight') {
        let next = primaryIdx + 1;
        if (next > items.length - 1) {
          next = items.length - 1;
        }
        items[next].focus();
        setSelectedGroup(data[next].title);
      }
      if (kbev.key === 'ArrowDown') {
        if (selectedGroup !== data[primaryIdx].title) {
          setSelectedGroup(data[primaryIdx].title);
        }

        const secondaryItems = getSecondaryMenuItems(primaryIdx);
        secondaryItems[0].children[0].focus();
      }
    };
    const onMenuSecondaryKeydown = (ev) => {
      const kbev = ev;

      const isCollapsed = window.matchMedia('(max-width: 1023px)').matches;

      const items = getPrimaryMenuItems();

      const el = ev.target;

      const primaryIdx = parseInt(el.dataset.primaryIdx, 10);
      const secondaryIdx = parseInt(el.dataset.secondaryIdx, 10);

      const itemsSecondary = getSecondaryMenuItems(primaryIdx);

      if (kbev.key === 'Escape') {
        setSelectedGroup(null);
        items[primaryIdx].focus();
      }
      if (kbev.key === 'ArrowLeft' || kbev.key === 'ArrowUp') {
        const next = secondaryIdx - 1;
        if (next < 0) {
          items[primaryIdx].focus();
          if (kbev.key === 'ArrowUp' && !isCollapsed) {
            setSelectedGroup(null);
          }
          if (kbev.key === 'ArrowLeft' || isCollapsed) {
            const nextPrimary = primaryIdx - 1;
            if (nextPrimary >= 0) {
              items[nextPrimary].focus();
              setSelectedGroup(data[nextPrimary].title);
              const itemsSecondary2 = getSecondaryMenuItems(nextPrimary);
              itemsSecondary2[itemsSecondary2.length - 1].children[0].focus();
            } else {
              setSelectedGroup(null);
            }
          }
        } else {
          itemsSecondary[next].children[0].focus();
        }
      }
      if (kbev.key === 'ArrowRight' || kbev.key === 'ArrowDown') {
        let next = secondaryIdx + 1;
        if (next > itemsSecondary.length - 1) {
          next = primaryIdx + 1;
          if (kbev.key === 'ArrowRight' || isCollapsed) {
            if (next <= items.length - 1) {
              items[next].focus();
              setSelectedGroup(data[next].title);
              const itemsSecondary2 = getSecondaryMenuItems(next);
              itemsSecondary2[0].children[0].focus();
            }
          }
        } else {
          itemsSecondary[next].children[0].focus();
        }
      }
    };

    const onClickOutside = (ev) => {
      if (ev.target) {
        const t = ev.target;
        // to support span inside the menu button
        // FIX ME on the css class name
        if (!t.closest('.usa-nav__link')) {
          setSelectedGroup(null);
        }
      }
    };
    React.useEffect(() => {
      document.addEventListener('click', onClickOutside);
      return () => {
        document.removeEventListener('click', onClickOutside);
      };
    }, []);

    React.useEffect(() => {
      const itemsPrimary = getPrimaryMenuItems();
      const itemsSecondary = getSecondaryMenuItems(null);
      itemsPrimary.forEach((c) =>
        c.addEventListener('keydown', onMenuPrimaryKeydown),
      );
      itemsSecondary.forEach((c) =>
        c.addEventListener('keydown', onMenuSecondaryKeydown),
      );
      return () => {
        itemsPrimary.forEach((c) =>
          c.removeEventListener('keydown', onMenuPrimaryKeydown),
        );
        itemsSecondary.forEach((c) =>
          c.removeEventListener('keydown', onMenuSecondaryKeydown),
        );
      };
    }, [menu]);

    // useEffect blocks that handles the window resize event
    React.useEffect(() => {
      const onWindowResize = () => {
        // the MegaMenuHamburger will only be rendered in the DOM tree
        // when the viewport size is below the cutoff size
        setShowMegaMenuHamburger(
          window.innerWidth < renderMegaMenuHamburgerCutoffWidth,
        );
      };

      window.addEventListener('resize', onWindowResize);
      onWindowResize();
      return () => {
        window.removeEventListener('resize', onWindowResize);
      };
    }, []);

    return (
      <>
        <div
          className="usa-navbar"
          style={{ position: 'relative', overflow: 'visible' }}
        >
          {logo()}
          {userMenu()}
          {showMegaMenuHamburger && menu && menu.length > 0 && (
            <MegaMenuHamburger onClick={() => setIsOpen(true)} />
          )}
        </div>
        <div
          role="none"
          className={`usa-overlay ${isOpen ? 'is-visible' : ''}`}
          onClick={() => {
            setIsOpen(false);
          }}
        />
        <nav
          aria-label="Primary navigation"
          className={`usa-nav ${isOpen ? 'is-visible' : ''}`}
        >
          <button
            type="button"
            className="usa-nav__close"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <MegaMenuImageClose />
          </button>
          <div className="usa-nav__inner">
            <ul className="usa-nav__primary usa-accordion" role="menubar">
              {data.map((d, dIdx) => {
                return (
                  <li
                    role="menuitem"
                    key={d.title}
                    className="usa-nav__primary-item"
                    aria-haspopup="true"
                  >
                    {d?.childNavigationItems &&
                    d.childNavigationItems?.length > 0 ? (
                      <>
                        <MegaMenuSection
                          label={d.title}
                          isCurrent={d.title === selectedGroup}
                          sectionId={`extended-mega-nav-section-${dIdx}`}
                          primaryIdx={dIdx}
                          onClick={() => {
                            if (selectedGroup === d.title) {
                              setSelectedGroup(null);
                            } else {
                              setSelectedGroup(d.title);
                            }
                          }}
                        />
                        <div id={`extended-mega-nav-section-${dIdx}`}>
                          <MegaMenuSubsystemListGroup>
                            <MegaMenuSubsectionList
                              isShown={selectedGroup === d.title}
                            >
                              {d.childNavigationItems
                                .filter((c) => !c.isHidden)
                                .sort(compareOrder)
                                .map((e, eIdx) => {
                                  return (
                                    <MegaMenuSubItem
                                      key={e.title}
                                      label={e.title}
                                      href={e.linkUrl}
                                      isShown={selectedGroup === d.title}
                                      primaryIdx={dIdx}
                                      secondaryIdx={eIdx}
                                    />
                                  );
                                })}
                            </MegaMenuSubsectionList>
                          </MegaMenuSubsystemListGroup>
                        </div>
                      </>
                    ) : (
                      <a
                        href={d.linkUrl}
                        data-primary-idx={dIdx}
                      >
                        {d.title}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </>
    );
  },
);

export default MegaMenuPrimaryNavigation;
