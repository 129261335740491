import React from 'react';
import PropTypes from 'prop-types';

// https://designsystem.digital.gov/components/character-count/
// aria-live="polite"  is required
// Use the aria-live attribute on character count message.
// Use aria-live="polite" so that updates to character count message
// are also announced when using a screen reader.

const HintWithAria = ({ text, hintId }) => {
  return (
    <span
      id={hintId}
      className="usa-hint  usa-character-count__message"
      aria-live="polite"
    >
      {text}
    </span>
  );
};

HintWithAria.propTypes = {
  hintId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default HintWithAria;
