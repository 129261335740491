import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './Spinner.scss';

const Spinner = ({ className, size, ...spinnerProps }) => {
  const classes = classnames('afp-spinner', className);

  return (
    <div className={classes} data-element="spinner">
      <div
        className="afp-spinner"
        size={size}
        data-element="spinner-wrap"
        {...spinnerProps}
      >
        <div className={`afp-spinner-border afp-spinner-border__${size}`}>
          <div className={`spinner-loading spinner-loading__${size}`} />
        </div>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Spinner.defaultProps = {
  className: undefined,
  size: 'medium',
};

export default Spinner;
