import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChipList } from '../../atoms/ChipList/ChipList';
import Chip from '../../atoms/Chip/Chip';
import { useFilterPanel } from './FilterProvider';
import _ from 'lodash';
import { generateFilterValues } from './helpers/utils';

const FilterChipGroup = ({ category, filterGroup }) => {
  const [appliedChips, setAppliedChips] = useState([]);
  const { clearOneFilter, multiSelectChange } = useFilterPanel();

  const handleClearFilter = (filter, value) => {
    if (filter.isSingleValueFilter) {
      clearOneFilter(filter);
    } else if (Array.isArray(filter.value)) {
      multiSelectChange(filter, false, value);
    } else clearOneFilter(filter);
  };

  useEffect(() => {
    if (filterGroup?.length) {
      const filterValues = generateFilterValues(filterGroup);
      setAppliedChips(filterValues);
    }
  }, [filterGroup]);

  return appliedChips?.length ? (
    <>
      <div className="filter-chip-group-header">{category}</div>
      <ChipList direction="horizontal">
        {appliedChips.map((chip) => {
          return (
            <Chip
              id={`${category}-${chip.value}`}
              key={chip.value}
              label={chip.label}
              onClose={() => handleClearFilter(chip.filter, chip.value)}
              role="option"
              aria-selected="true"
            />
          );
        })}
      </ChipList>
    </>
  ) : null;
};

FilterChipGroup.propTypes = {
  category: PropTypes.string.isRequired,
  filterGroup: PropTypes.array.isRequired,
};

export default FilterChipGroup;
