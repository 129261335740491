import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Carousel.scss';
import Icon from '../../atoms/Icon/Icon';

let firstElement = 0,
  lastElement = 4;

const Chevron = ({ className, id, iconName, onClick, type }) => {
  return (
    <div className="carousel-button">
      <div className={className} id={id} onClick={(e) => onClick(e, type)}>
        <Icon className="chevron" iconName={iconName} />
      </div>
    </div>
  );
};

const CarouselCard = ({ index, customClass, li, onClick }) => {
  return (
    <div className="carousel-card-child">
      <div
        data-testid={`oncard-click-${index}`}
        id={index}
        className={`image-card ${customClass}`}
        onClick={() => onClick(li)}
      ></div>
      <span className="image-title">{li.type}</span>
    </div>
  );
};

export const Carousel = ({ list, onClick }) => {
  const [dataList, setDataList] = useState([]),
    [customClass, setCustomClass] = useState('');

  useEffect(() => {
    if (list && list.length) {
      const slicedList = list.slice(firstElement, lastElement);
      setDataList(slicedList);
    }
  }, []);

  const carouselEffect = () => {
    setCustomClass('hideCarousel');
    setTimeout(() => {
      setCustomClass('');
    }, 50);
  };

  const onCarouselClick = (e, type) => {
    if (type === 'right' && lastElement < list.length) {
      firstElement = lastElement;
      lastElement = lastElement + 4;

      const slicedList = list.slice(firstElement, lastElement);
      setDataList(slicedList);
      carouselEffect();
    } else if (type === 'left' && firstElement > 0) {
      firstElement = firstElement - 4;
      lastElement = lastElement - 4;

      const slicedList = list.slice(firstElement, lastElement);
      setDataList(slicedList);
      carouselEffect();
    }
  };

  return (
    <div className="main">
      {!!dataList.length && (
        <Chevron
          type="left"
          id="btnLeft"
          className="button-left"
          onClick={onCarouselClick}
          iconName="navigate_before"
        />
      )}
      <div className="carousel-card-parent">
        {dataList.map((li, index) => (
          <CarouselCard
            key={index}
            index={index}
            customClass={customClass}
            li={li}
            onClick={onClick}
          />
        ))}
      </div>
      {!!dataList.length && (
        <Chevron
          type="right"
          id="btnRight"
          className="button-right"
          onClick={onCarouselClick}
          iconName="navigate_next"
        />
      )}
    </div>
  );
};

Carousel.propTypes = {
  list: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Carousel;
