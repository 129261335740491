/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Accordion } from '../..';
import SortItem from './SortItem';

const FilterPanelOverlay = ({
  setToggleFilters,
  filterState,
  reset,
  filterList,
  toggleSort,
  order,
  setOrder,
  options,
}) => {
  return (
    <div
      data-testid="afp-filter"
      className="grid-row afp-filter mobile_overlay"
    >
      <div className="grid-col">
        <div className="display-flex flex-justify">
          <Button
            className="usa-button usa-button--outline afp-filter__toggleBtn"
            onClick={() => setToggleFilters(false)}
            label="Hide filters"
            leftIcon={{name: "hide-filters-icon", type: "custom"}}
          />
          <Button
            data-testid="close-filter"
            type="button"
            variant="unstyled"
            onClick={() => setToggleFilters(false)}
            className="width-auto afp-filter__closeBtn"
            label="Close"
            rightIcon={{name: "hide-filters-icon", type: "custom", className: "margin-left-1 margin-right-1 text-middle"}}
          />
        </div>
        <div className="afp-filter__resetBtn">
          {/* Show Reset All only if there are selected filters */}
          {Object.values(filterState).some((f) => f) && (
            <Button
              data-testid="reset-filter"
              type="button"
              variant="unstyled"
              onClick={reset}
              className="width-auto"
              label="Reset All"
            />
          )}
        </div>

        <div className="grid-row padding-y-2 ">
          <Accordion
            items={filterList}
            multiselectable
            className="overflow-visible"
          />
        </div>
        {toggleSort && (
          <div className="grid-row padding-x-2">
            <SortItem order={order} setOrder={setOrder} options={options} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterPanelOverlay;

FilterPanelOverlay.propTypes = {
  setToggleFilters: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  filterList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterState: PropTypes.shape(Object).isRequired,
  toggleSort: PropTypes.bool.isRequired,
  order: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
};
