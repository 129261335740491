import React, { useEffect, useMemo, useRef, useState } from 'react';
import Chip from '../../atoms/Chip/Chip';
import PropTypes from 'prop-types';
import { ChipList } from '../../atoms/ChipList/ChipList';
import { Checkbox } from '../../atoms/Checkbox/Checkbox';
import './MultiSelectDropdown.scss';
import useDebounce from '../../lib/hooks/useDebounce';
import useAriaListBox from '../../lib/hooks/useAriaListBox';
import Spinner from '../../atoms/Spinner/Spinner';
import { uniqueId, isEqual } from 'lodash';

export const MultiSelectDropdown = ({
    id = useMemo(() =>`multiselect-id-${uniqueId()}`, []),
    options: _options = [],
    getLabel = (item) => item?.label,
    getValue = (item) => item?.value,
    onChange: onSelect = () => {}, 
    selectedValues: value,
    placeholder = '',
    isOpen = false,
    readonly = false,
    formLabelId = '',
    formHintId = '',
    formErrorId = '',
    queryThreshold = 3,
    invalidQueryText = ({ queryThreshold, query, limit, skip }) => 
        `Please type ${queryThreshold} characters or more`,
    loadingText = 'Loading',
    noResults: noDataFoundText = 'No data found',
    disabled = false,
    ...props
}) => {
    const inputListId = `input-listbox-${id}`;
    const displayListId = `display-listbox-${id}`;
    const getOptions = async ({ query = '' }) => {
        if (typeof _options === 'function') {
            return await _options({ query })
        }
        if (query) {
            return Promise.resolve(_options.filter(item => 
                _getLabel(item)?.trim().toLocaleLowerCase()
                .includes(query.trim().toLocaleLowerCase())));
        }
        return Promise.resolve(_options);
    };
    const getSelected = () => {
        if (typeof _options !== 'function' && Array.isArray(value)) {
           return _options.filter(item => value.some(v => v === _getValue(item)));
        }
        return [];
    }
    const defaultFilter = { query: '', skip: 0, limit: 0};
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(getSelected());
    const [display, setDisplay] = useState(selected);
    const [filter, setFilter] = useState(defaultFilter);
    const [open, _setOpen] = useState(isOpen)
    const [focused, setFocused] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isReadonly,] = useState(readonly);

    // Keyboard navigation for options dropdown
    const {
        listBoxKeydown,
        listBoxClickOption,
        toggledElement: toggledListElement
    } = useAriaListBox({ listId: inputListId });

    // Keyboard navigation for selected chips
    // TODO: move this to chip list
    const {
        listBoxKeydown: chipsKeyDown,
        listBoxClickOption: chipsClickOption,
        toggledElement: toggleSelectedElement
    } = useAriaListBox({ listId: displayListId });

    
    // Functional wrappers
    const _getLabel = (item) => {
        if (typeof getLabel === 'string') {
            return item[getLabel];
        }
        return getLabel(item);
    };
    function _getValue (item) {
        if (typeof getValue === 'string') {
            return item[getValue];
        }
        return getValue(item);
    };
    const _invalidQueryText = () => {
        if (typeof invalidQueryText === 'string') {
            return invalidQueryText;
        }
        return invalidQueryText({queryThreshold, ...filter});
    };
    const _loadingText = (item) => {
        if (typeof loadingText === 'string') {
            return loadingText;
        }
        return loadingText({queryThreshold, ...filter});
    };
    const _noDataFoundText = (item) => {
        if (typeof noDataFoundText === 'string') {
            return noDataFoundText;
        }
        return noDataFoundText({queryThreshold, ...filter});
    };
    const setOpen = (value) => {
        if (isReadonly) {
            return;
        }
        _setOpen(value);
    };

    const displayRef = useRef(null);
    const inputRef = useRef(null);
    const selfRef = useRef(null);

    // Actions
    const toggleSelected = (item) => {
        const value = _getValue(item);
        setSelected(prevSelected => {
            const _selected = prevSelected.slice();
            let index = -1;
            for (let i = 0; i < _selected.length; i++) {
                if (_selected[i].value === value) {
                    index = i;
                    break;
                }   
            }

            if (index < 0) {
                return [..._selected, item];
            }
            _selected.splice(index, 1);
            return _selected;
        });
    };
    const clearSelected = () => {
        if (isReadonly) {
            return;
        }
        setSelected(() => []);
        inputRef?.current?.focus();
    }
    const toggleList = async () => {
        if (isReadonly) {
            return;
        }
        if (open) {
            setOpen(false);
        } else {
            inputRef?.current.focus();
        }
    }

    // Event handlers
    const filterOptions = async (newFilter) => {
        setLoading(true);
        setOpen(true);
        setOptions([]);
        const options = await getOptions(newFilter);
        setLoading(false);
        setOptions(options || []);
    };
    const filterOptionsDebounced = useDebounce(filterOptions, 500);
    
    const onInput = async (text = '') => {
        const newFilter = { query: text, limit: 0, skip: 0 };
        setFilter(newFilter);

        if (isValidQuery(text)) {
            filterOptionsDebounced(newFilter);
        }
    };
    const handleBlur = () => {
        setOpen(false);
        setFilter(defaultFilter);
        setDisplay(getDisplayItems());
    }
    const handleFocus = () => {
        setDisplay(getDisplayItems());
    }
    const replaceSpaces = (value, withCharacter) => {
        return value?.replace(/\s/g , withCharacter);
    }

    // Fragments
    const filterFeedback = ({ query }) => {
        const feedbackContainer = (children) => (
            <div
                role='option status'
                aria-live='polite'
                className="usa-combo-box__list-status"
            >
                {children}
            </div>
        );
        if (!isValidQuery(query)) {
            return feedbackContainer(
                    <span>{_invalidQueryText()}</span>);
        }
        if (loading) {
            return feedbackContainer(
                    <span className="usa-combo-box__list-status--loading">
                        <Spinner size="small" />
                        {_loadingText()}
                    </span>);
        }
        if (!options.length) {
            return feedbackContainer(
                    <span>{_noDataFoundText()}</span>);
        }

        return null;
    };
    const listItem = (item) => {
        const { label, value } = getLabelValuePair(item);
        const checked = selected.some(elem => _getValue(elem) === value);
        const formattedValue = replaceSpaces(value, '-');
        const children = () => {
            return (
                <Checkbox
                    label={label}
                    tabIndex="-1"
                    aria-hidden="true"
                    key={`input-ComboBox--list--option-checkbox-${formattedValue}`}
                    name={label}
                    value={value}
                    checked={checked}
                    onChange={() => {}}
                />
            );
        };

        return <li
            value={value}
            className="usa-combo-box__list-option"
            role="option"
            aria-setsize="64"
            key={`input-ComboBox--list--option-${formattedValue}`}
            id={`input-ComboBox--list--option-${formattedValue}`}
            data-testid={`combo-box-option-${formattedValue}`}
            data-value={value}
            onClick={(evt) => {
                listBoxClickOption(evt);
            }}
            >
                {children()}
        </li>;
    };
    const list = () => {
        return (
            <ul
                data-testid="combo-box-option-list"
                id={inputListId}
                tabIndex="-1"
                className="usa-combo-box__list usa-combo-box__list-mt-1"
                role="listbox"
                aria-multiselectable="true"
                aria-expanded={open}
                hidden={!open}
            >
                {filterFeedback(filter)
                || options.map((item) => listItem(item)
                )}
            </ul>
        );
    };

    // Utilities
    const getFocusableElements = (element) => {
        if (!element) {
            return [];
        }
        return [...element.querySelectorAll(
          'a[href], button, input, [tabindex="0"], [tabindex="-1"]'
        )];
    }
    const getDisplayItems = () => {
        const containerWidth = displayRef.current?.clientWidth || 0;
        let items = [];
        let itemsWidth = 0;
        const itemsGap = 5;
        const restItemWidth = 15;

        for (let i = 0; i < selected.length; i++) {
            const item = selected[i];
            itemsWidth += displayRef.current?.childNodes[i]?.clientWidth || 0;

            if (!focused && containerWidth < (restItemWidth + itemsWidth + i * itemsGap)) {
                items.push({ plusNumber: selected.length - i });
                break;
            } else {
                items.push(item);
            }
        }
        return items;
    };
    const getLabelValuePair = (item) => {
        return {
            label: _getLabel(item),
            value: _getValue(item)
        };
    };
    const getAriaDescribedBy = (extras) => {
        return `${formLabelId} ${formHintId} ${formErrorId} ${extras}`;
    };
    const getAriaInvalid = () => {
        return !!formErrorId;
    };
    const isValidQuery = (query = '') => {
        return query.length === 0 || query.length >= queryThreshold;
    };

    // programmatically set selectedValues
    useEffect(() => {
        const modifiedValue = getSelected(value);
        if(value && !isEqual(modifiedValue, selected)) {
            setSelected(modifiedValue)    
        }
    }, [value]);

    useEffect(() => {
        if (toggledListElement) {
            const { element } = toggledListElement ;
            const value = element?.dataset?.value || '';
            getOptions(filter)
            .then(items => items.filter(opt => _getValue(opt) === value))
            .then(filteredItems => {
                if (filteredItems.length) {
                    toggleSelected(filteredItems[0]);
                }
            })
        }
    }, [toggledListElement]);

    useEffect(() => {
        if (toggleSelectedElement) {
            const { element } = toggleSelectedElement ;
            const value = element?.dataset?.value || '';
            const filteredItems = selected.filter(opt => _getValue(opt) === value);
            if (filteredItems.length) {
                toggleSelected(filteredItems[0]);
            }
        }
    }, [toggleSelectedElement]);

    function inputKeyDownListener(evt) {
       listBoxKeydown(evt);
    }
    function displayItemKeyDownListener(evt) {
        chipsKeyDown(evt);
     }
    useEffect(() => {
        if (open) {
            inputRef.current?.addEventListener('keydown', inputKeyDownListener);
        }
        return function() {
            inputRef.current?.removeEventListener('keydown', inputKeyDownListener);
        }
    }, [open]);

    useEffect(() => {
        onSelect(selected.map(s => _getValue(s)));
        if (selected.length) {
            displayRef?.current?.addEventListener('keydown', displayItemKeyDownListener);
        } 
        return () => {
            displayRef?.current?.removeEventListener('keydown', displayItemKeyDownListener);
        }
    }, [selected]);
    
    useEffect(() => {
        const focusableElements = getFocusableElements(selfRef.current);
        focusableElements.push(selfRef.current);

        for (let i = 0; i < focusableElements.length; i++) {
            focusableElements[i].addEventListener('focus', () => {
                setTimeout(() => {
                    if (typeof _options !== 'function' 
                    && inputRef?.current === document.activeElement) {
                        setLoading(true);
                        setOpen(true);
                        const newFilter = {...defaultFilter, query: inputRef?.current.value};
                        setFilter(newFilter);
                        getOptions(newFilter).then(opts => {
                            setOptions(opts || []);
                            setLoading(false);
                        });
                    }
                    if (selfRef?.current === document.activeElement) {
                        inputRef?.current.focus();
                    }
                });
                setFocused(true);
            });
            focusableElements[i].addEventListener('blur', () => {
                setTimeout(() => {
                    const hasFocus = focusableElements
                                    .some(node => node.contains(document.activeElement));
                    setFocused(hasFocus);
                });
            });
        }

        if (!focused) {
            handleBlur();
        } else {
            handleFocus();
        }
    }, [focused, selected]);

    return (
        <div
            data-testid="multiselect-dropdown-testId"
            data-enhanced="true"
            className={`usa-combo-box usa-combo-box--pristine usa-combo-box--bordered ${disabled ? 'disabled-control' : ''}`}
            ref={selfRef}
            tabIndex="-1"
        >
            <ChipList
                style={{
                    width: 'calc(100% - 5rem)'
                }}
                id={displayListId}
                role="listbox"
                cRef={displayRef}
            >
                {display.map((item, index) => {
                    const { label, value } = getLabelValuePair(item);
                    const formattedValue = replaceSpaces(value, '-');
                    const { plusNumber } = item;
                    if (plusNumber) {
                        return <Chip
                            label={`+${plusNumber}`}
                            readonly={true}
                            key='plusNumber-key'
                            data-testid='display-item-rest'
                            aria-hidden="true"
                        />;
                    }
                    return <Chip
                        label={label}
                        tabIndex={ !index ? '0' : '-1'}
                        readonly={isReadonly}
                        onClose={() => toggleSelected(item)}
                        key={value}
                        role="option"
                        aria-selected="true"
                        id={`display-item-${formattedValue}`}
                        data-value={value}
                        data-testid={`display-item-${formattedValue}`}
                    />;
                })}
            </ChipList>
            <input
                type="text"
                role="combobox"
                placeholder={placeholder}
                ref={inputRef}
                aria-owns={inputListId}
                aria-describedby={getAriaDescribedBy("input-ComboBox--assistiveHint")}
                aria-invalid={getAriaInvalid()}
                id="input-ComboBox"
                className='usa-input-combo-box--unstyled'
                hidden={isReadonly || (!focused && selected.length)}
                readOnly={isReadonly}
                data-testid="combo-box-input"
                autoCapitalize="off"
                autoComplete="off"
                onChange={(evt) => {
                    evt.preventDefault();
                    onInput(evt.currentTarget.value);
                }}
                value={filter.query}
                disabled={disabled}
            />
            {!isReadonly && selected.length > 0 &&
                <button
                    type="button"
                    className="usa-combo-box__clear-input usa-combo-box__clear-input--sticky-top"
                    aria-label="Clear the select contents"
                    data-testid="combo-box-clear-button"
                    onClick={clearSelected}
                >
                    &nbsp;
                </button>
            }
            <span className="usa-combo-box__input-button-separator">&nbsp;</span>
            <button
                data-testid="combo-box-toggle"
                type="button"
                className="usa-combo-box__toggle-list usa-combo-box__toggle-list--sticky-top"
                aria-label="Toggle the dropdown list"
                onClick={toggleList}
            >
                &nbsp;
            </button>
            {list()}

            <div className="usa-combo-box__status usa-sr-only" role="status"></div>
            <span
                id="input-ComboBox--assistiveHint"
                className="usa-sr-only"
                data-testid="combo-box-assistive-hint"
            >
                When autocomplete results are available use up and down arrows to review
                and enter to select. Touch device users, explore by touch or with swipe
                gestures.
            </span>
        </div>);
}

const numberGreaterThan = (value = 0) => {
    return (props, propName, componentName) => {
        const propValue = props[propName];
        if (propValue < value) {
            return new Error(
                `Invalid prop ${propName} supplied to ${componentName}.
                 ${propName} must be greater than ${value}.`
            );
        }
    };
};

MultiSelectDropdown.propTypes = {
    id: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape),
        PropTypes.func
    ]),
    getLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    getValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    selectedValues: PropTypes.arrayOf(PropTypes.shape),
    onChange: PropTypes.func,
    formLabelId: PropTypes.string,
    formHintId: PropTypes.string,
    formErrorId: PropTypes.string,
    readonly: PropTypes.bool,
    queryThreshold: numberGreaterThan(2),
    invalidQueryText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    loadingText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    noResults: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    disabled: PropTypes.bool,
  };





