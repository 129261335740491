/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AccordionItem from './AccordionItem';

const Accordion = ({ bordered, items, className, multiselectable, handleToggleFilter }) => {
  const [openItems, setOpenState] = useState(
    items.filter((i) => !!i.expanded).map((i) => i.id),
  );
  const classes = classnames(
    'usa-accordion',
    bordered && 'usa-accordion--bordered',
    className,
  );

  const toggleItem = (itemId) => {
    const newOpenItems = [...openItems];
    const itemIndex = openItems.indexOf(itemId);
    const isMultiselectable = multiselectable;
    if (itemIndex > -1) {
      newOpenItems.splice(itemIndex, 1);
    } else if (isMultiselectable) {
      newOpenItems.push(itemId);
    } else {
      newOpenItems.splice(0, newOpenItems.length);
      newOpenItems.push(itemId);
    }
    setOpenState(newOpenItems);
  };

  return (
    <div
      className={classes}
      data-testid="accordion"
      aria-multiselectable={multiselectable}
    >
      {items.map((item, i) => (
        <AccordionItem
          key={`accordionItem_${i}`}
          {...item}
          expanded={openItems.indexOf(item.id) > -1}
          handleToggle={() => {
            toggleItem(item.id);
            // Used only if the parent component needs to control behavior when filters are toggled
            if(handleToggleFilter) {
              handleToggleFilter(item.id);
            }
          }}
        />
      ))}
    </div>
  );
};

Accordion.defaultProps = {
  bordered: false,
  className: null,
  multiselectable: true,
};

Accordion.propTypes = {
  bordered: PropTypes.bool,
  className: PropTypes.string,
  multiselectable: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Accordion;
