import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

const Label = ({ text, className, htmlFor, required }) => {
  let labelContent = text;
  if (required) {
    labelContent = (
      <>
        {labelContent} <span className="afp-required-field">*</span>
      </>
    );
  }
  if (text) {
    return (
      <label className={`usa-label ${className}`} htmlFor={htmlFor}>
        {labelContent}
      </label>
    );
  }
  return null;
};

Label.defaultProps = { text: undefined, className: '' };
Label.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
};
const ErrorMessage = ({ text }) =>
  text ? <span className="usa-error-message">{text}</span> : '';

ErrorMessage.propTypes = {
  text: PropTypes.string,
};

const SelectDropdown = ({
  id,
  effect,
  name,
  className,
  disabled,
  required,
  errorMessage,
  containerClassName,
  label,
  value,
  defaultValue,
  placeHolder,
  children,
  wrapperClassName,
  options: dropDownOptions,
  optionKey,
  optionLabel,
  optionValue,
  onChange,
  inputRef,
  ...restProps
}) => {
  id = id || `select_${uniqueId()}`;
  optionKey = optionKey || 'value';
  optionLabel = optionLabel || 'label';
  optionValue = optionValue || 'value';

  const classes = classnames('usa-select', className);
  const defaultOption = dropDownOptions.find((opt) => opt.defaultValue);
  const [valueState, setValueState] = useState(
    defaultValue || defaultOption?.value || value || '',
  );

  useEffect(() => {
    setValueState(value);
  }, [value]);

  const setValueAndUpdate = (ev) => {
    setValueState(ev.target.value);
    onChange(ev);
  };

  return (
    <>
      <div
        data-default-value={defaultValue}
        data-placeholder={placeHolder}
        className={`usa-form-group${
          errorMessage ? ' usa-form-group--error' : ''
        } ${containerClassName}`}
      >
        <Label
          text={label}
          className={errorMessage && 'usa-label--error'}
          htmlFor={id}
          required={required}
        />
        <ErrorMessage id={id} text={errorMessage} />
        <select
          data-testid={id}
          className={`${classes} ${
            errorMessage ? 'outline-05 outline-secondary-dark' : ''
          }`}
          aria-describedby={id}
          required={required}
          id={id}
          name={name}
          value={valueState}
          onChange={setValueAndUpdate}
          disabled={disabled}
          aria-label={name}
          ref={inputRef}
          {...restProps}
        >
          {dropDownOptions.map((optionsInfo) => (
            <option
              value={optionsInfo[optionValue]}
              key={optionsInfo[optionValue]}
              disabled={optionsInfo.disabled}
              aria-label={`${optionsInfo[optionLabel]}`}
            >
              {optionsInfo[optionLabel]}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

SelectDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SelectDropdown.defaultProps = {
  containerClassName: '',
};

export default SelectDropdown;
