import React from 'react';
import PropTypes from 'prop-types';
import FilterMultiSelectDropdown from './FilterItemMultiselectDropdown';
import FilterItemCheckbox from './FilterItemCheckbox';
import FilterItemCombobox from './FilterItemCombobox';
import FilterItemDropdown from './FilterItemDropdown';
import FilterItemTextInput from './FilterItemTextInput';
import FilterItemTypeAhead from './FilterItemTypeahead';

const FilterItem = ({ type, id, ...props }) => {
  if (id?.length && type === 'type-ahead')
    return <FilterItemTypeAhead id={id} {...props} />;

  if (id?.length && type === 'input')
    return (
      <FilterItemTextInput test-dataid="text-input-filter" id={id} {...props} />
    );

  if (id?.length && type === 'checkbox-list')
    return <FilterItemCheckbox id={id} {...props} />;

  if (id?.length && type === 'dropdown')
    return (
      <FilterItemDropdown test-dataid="dropdown-filter" id={id} {...props} />
    );

  if (id?.length && type === 'combobox')
    return (
      <FilterItemCombobox test-dataid="combobox-filter" id={id} {...props} />
    );

  if (id?.length && type === 'multi-select-dropdown')
    return (
      <FilterMultiSelectDropdown
        test-dataid="multi-select-filter"
        id={id}
        {...props}
      />
    );

  return null;
};

FilterItem.defaultProps = {
  options: [],
};

FilterItem.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(Object), PropTypes.string]),
  ),
};

export default FilterItem;
