import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFilterPanel } from './FilterProvider';
import { useTypeaheadReducer } from './helpers/reducers';
import refinedFilters from './helpers/refinedFilters';

export const FilterTypeaheadContext = createContext();

export default function FilterTypeaheadProvider({
  children,
  fetchTypeaheads,
  typeaheadData,
  model,
  order,
}) {
  const { mergedFilters } = useFilterPanel();
  // Example of typeahead state: [{key: "metaData.tagNumber", value: "J11", typeaheadValues: ["J11-345", "J11-645"]}]
  const [typeaheadState, setTypeaheadState] = useTypeaheadReducer([]);
  const [fetchArguments, setFetchArguments] = useState([]);

  // use hook here instead of passing function to avoid stale cb issues
  useEffect(() => {
    const [accessor, value, inlinemodel] = fetchArguments;
    if (!accessor) return;

    const tpInState = typeaheadState.find(
      (el) => el.key === accessor && el.value && el.value.includes(value),
    );
    if (!tpInState) {
      setTypeaheadState({
        type: 'setEmpty',
        payload: {
          key: accessor,
          value,
        },
      });
    }
    const typeaheadCondition = {
      operator: '$startsWith',
      key: accessor,
      value,
    };

    const queryVariables = {
      model: inlinemodel ? inlinemodel : model,
      field: accessor,
      order,
      filters: [
        {
          operator: '$and',
          conditions: [...refinedFilters(mergedFilters), typeaheadCondition],
        },
      ],
    };

    fetchTypeaheads({ variables: queryVariables });
  }, [fetchArguments]);

  // returns an array of suggested values based on the filter key
  const passTypeaheadValues = (key) => {
    return (
      (typeaheadState.find((tpField) => tpField.key === key) || {})
        .typeaheadValues || []
    );
  };

  useEffect(() => {
    if (typeaheadData) {
      const { field: key, values } = typeaheadData;
      setTypeaheadState({
        type: 'setValues',
        payload: { key, typeaheadValues: values },
      });
    }
  }, [typeaheadData]);

  return (
    <FilterTypeaheadContext.Provider
      value={{
        fetchTypeaheadValues: (...args) => setFetchArguments(args),
        passTypeaheadValues,
      }}
    >
      {children}
    </FilterTypeaheadContext.Provider>
  );
}

FilterTypeaheadProvider.defaultProps = {
  typeaheadData: undefined,
};

FilterTypeaheadProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fetchTypeaheads: PropTypes.func.isRequired,
  typeaheadData: PropTypes.shape({
    field: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
  model: PropTypes.string.isRequired,
  order: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

// Hook to exposes context value.
export const useFilterTypeaheads = () => useContext(FilterTypeaheadContext);
