const between = (value, min, max) => value > min && value < max;

const getPopoverPosition = (rX, rY) => {
  if (rX < 0.33 && rY < 0.33) return 1;
  if (between(rX, 0.33, 0.66) && rY < 0.33) return 2;
  if (rX > 0.66 && rY < 0.33) return 3;
  if (rX < 0.33 && between(rY, 0.33, 0.66)) return 4;
  if (between(rX, 0.33, 0.66) && between(rY, 0.33, 0.66)) return 5;
  if (rX > 0.66 && between(rY, 0.33, 0.66)) return 6;
  if (rX < 0.33 && rY > 0.66) return 7;
  if (between(rX, 0.33, 0.66) && rY > 0.66) return 8;
  if (rX > 0.66 && rY > 0.66) return 9;
  return null;
};
export default getPopoverPosition;
