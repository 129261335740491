import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFilterPanel } from './FilterProvider';
import refinedFilters from './helpers/refinedFilters';
import FilterChipGroup from './FilterChipGroup';
import { generateFilterValues } from './helpers/utils';
import _ from 'lodash';
import useAriaListBox from '../../lib/hooks/useAriaListBox';
import useId from '../../lib/hooks/useId';

const FiltersApplied = ({ updateAppliedFilters }) => {
  const { mergedFilters } = useFilterPanel();
  const [filtersApplied, setFiltersApplied] = useState({});
  const listRef = useRef(null);
  const listId = useId('filter-chip-groups');

  const { listBoxKeydown } = useAriaListBox({ listId });

  const updateAllFilters = (filtersByCategory) => {
    const allFilters = Object.keys(filtersByCategory)
      .sort()
      .map((category) => generateFilterValues(filtersByCategory[category]))
      .filter((val) => val.length);
    updateAppliedFilters(_.flatten(allFilters));
  };

  useEffect(() => {
    const filtersByCategory = {};

    mergedFilters.forEach((filter) => {
      if (!filtersByCategory[filter.category])
        filtersByCategory[filter.category] = [filter];
      else filtersByCategory[filter.category].push(filter);
    });

    setFiltersApplied(filtersByCategory);
    updateAllFilters(filtersByCategory);
  }, [mergedFilters]);

  useEffect(() => {
    listRef?.current?.addEventListener('keydown', (e) => listBoxKeydown(e));

    return () => {
      listRef?.current?.removeEventListener('keydown', listBoxKeydown);
    };
  }, [listRef.current]);

  if (!refinedFilters(mergedFilters, false).length) return 'No filters applied';

  return (
    <div id={listId} ref={listRef} role="listbox">
      {Object.keys(filtersApplied)
        .sort()
        .map((key) => {
          return filtersApplied[key]?.length ? (
            <FilterChipGroup
              key={key}
              category={key}
              filterGroup={filtersApplied[key]}
            />
          ) : null;
        })}
    </div>
  );
};

FiltersApplied.defaultProps = {
  updateAppliedFilters: undefined,
};

FiltersApplied.propTypes = {
  updateAppliedFilters: PropTypes.func,
};

export default FiltersApplied;
