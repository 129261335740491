import React from 'react';
import PropTypes from 'prop-types';
import './Typeahead.scss';

const TypeaheadItem = ({ opt, onClick, onKeyDown, children }) => {
  return (
    <div
      // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
      role="option"
      key={opt}
      tabIndex={0}
      className="typeahead-option"
      onClick={onClick}
      onKeyDown={onKeyDown}
      data-testid={`typeahead-item-${opt}`}
    >
      {children}
    </div>
  );
};

export default TypeaheadItem;

TypeaheadItem.propTypes = {
  opt: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};
