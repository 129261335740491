import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { indexOf } from 'lodash';
import Icon from '../../atoms/Icon/Icon';
import { ErrorMessage } from '../../atoms/ErrorMessage/ErrorMessage';
import Fieldset from '../Fieldset/Fieldset';
import { FormGroup } from '../FormGroup/FormGroup';
import { Button } from '../../atoms/Button/Button';
import { Label } from '../../atoms/Label/Label';
import './FileUpload.scss';

const FileUpload = ({
  acceptableFiles,
  acceptableFilesLabel,
  fileSizeLimit,
  label,
  onChange,
  defaultValue,
  required,
  errorMessage,
}) => {
  const fileInputRef = useRef();
  const changeFileRef = useRef();

  const [file, setFile] = useState(defaultValue);
  const [errMsg, setErrMsg] = useState(null);
  const [refocus, setRefocus] = useState(false);

  useEffect(() => {
    if (refocus) {
      if (changeFileRef?.current && file) changeFileRef.current.focus();
      else if (fileInputRef?.current && !file) fileInputRef.current.focus();
      setRefocus(false);
    }
  }, [refocus, changeFileRef, fileInputRef, file]);

  useEffect(() => {
    setFile(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setErrMsg(errorMessage);
  }, [errorMessage]);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const onFileDrop = (e) => {
    e.preventDefault();

    const { files } = e.dataTransfer;

    if (files.length > 1) {
      setErrMsg('Single file upload only');
    } else {
      handleFiles(files);
    }
  };

  const getFileSizeLimitInBytes = () => fileSizeLimit * Math.pow(1024, 2);

  const validateFileType = (validateFile) => {
    return indexOf(acceptableFiles, validateFile.type) > -1;
  };

  const changeFile = (fileObj) => {
    setFile(fileObj);
    onChange(fileObj);
    setRefocus(true);
  };

  const handleFiles = (files) => {
    const fileToUpload = files[0];
    const isFileValid = validateFileType(fileToUpload);
    const isFileLimitValid = fileToUpload.size <= getFileSizeLimitInBytes();

    if (!isFileValid) {
      fileInputRef.current.blur();
      setErrMsg('File type is not permitted');
    } else if (!isFileLimitValid) {
      fileInputRef.current.blur();
      setErrMsg('File size exceeds limit');
    } else {
      setErrMsg(null);
      changeFile(fileToUpload);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  return (
    <Fieldset
      className={classnames('usa-fieldset', errMsg && 'margin-left-205')}
    >
      <FormGroup className="usa-file-input" error={errMsg}>
        <Label
          className="usa-label text-bold"
          data-testid="file-label"
          required={required}
        >
          {label}
        </Label>
        {errMsg && <ErrorMessage>{errMsg}</ErrorMessage>}
        <span
          className="usa-hint"
          id="file-input-hint"
          data-testid="acceptable-files"
        >
          {acceptableFilesLabel}
        </span>
        <div
          className="usa-file-input__target"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={onFileDrop}
          data-testid="file-container"
          aria-dropeffect="copy"
        >
          {file ? (
            <div className="display-flex flex-column width-full">
              <div className="display-flex padding-1 bg-primary-lighter justify-space-between">
                <p className="text-bold font-body-xs margin-0">Selected file</p>
                <input
                  ref={fileInputRef}
                  id="file-input"
                  className="usa-file-input__input"
                  type="file"
                  name="file-input"
                  onChange={filesSelected}
                  data-testid="file-input"
                  aria-hidden
                  hidden
                />
                <Button
                  variant="unstyled"
                  className="action-btn"
                  label="Change file"
                  labelClass="font-body-xs"
                  onClick={() => fileInputRef.current.click()}
                  data-testid="change-file-btn"
                  inputRef={changeFileRef}
                  aria-label="change file"
                />
              </div>
              <div className="display-flex margin-top-2px padding-x-1 padding-y-2 bg-primary-lighter justify-space-between">
                <div className="flex-1 text-left padding-top-05">
                  <Icon
                    type="custom"
                    className="usa-icon--size-2 margin-right-1 file-icon ink text-ink"
                    iconName="document_file"
                  />
                  <span
                    className="font-body-2xs break-all"
                    data-testid="file-name"
                  >
                    {file?.name}
                  </span>
                </div>
                <div className="flex-auto margin-left-5 text-right">
                  <Button
                    variant="unstyled"
                    className="action-btn"
                    label="Delete"
                    labelClass="font-body-xs"
                    onClick={() => changeFile(null)}
                    data-testid="cancel-btn"
                    aria-label="remove file"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className="usa-file-input__instructions width-full"
                data-testid="file-input-instructions"
              >
                <span className="usa-file-input__drag-text font-body-xs">
                  Drag file here or{' '}
                </span>
                <span className="usa-file-input__choose font-body-xs">
                  choose from folder
                </span>
              </div>
              <div className="usa-file-input__box" />
              <input
                ref={fileInputRef}
                id="file-input"
                className="usa-file-input__input"
                type="file"
                name="file-input"
                onChange={filesSelected}
                data-testid="file-input"
                aria-hidden
              />
            </>
          )}
        </div>
      </FormGroup>
    </Fieldset>
  );
};

FileUpload.defaultProps = {
  acceptableFiles: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  acceptableFilesLabel: 'Accepts .pdf, .doc, or .docx',
  fileSizeLimit: 100,
  label: 'Supporting file',
  onChange: () => null,
  defaultValue: null,
  required: false,
  errorMessage: '',
};

FileUpload.propTypes = {
  acceptableFiles: PropTypes.arrayOf(PropTypes.string),
  acceptableFilesLabel: PropTypes.string,
  fileSizeLimit: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default FileUpload;
