import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardMedia = ({
    children,
    variant,
    type,
    className
}) => {

    const classes = classnames(
        'usa-card__media',
        {
            'usa-card__media--inset': variant === 'inset',
            'usa-card__media--exdent': variant === 'exdent'
        },
        className
    );
    const typeClass = classnames({
        'usa-card__img': type === 'image'
    });

    return (
        <div className={classes}>
            <div className={typeClass}>
                {children}
            </div>
        </div>
    );
};

CardMedia.defaultProps = {
    type: 'image',
    className: ''
};


CardMedia.propTypes = {
    children: PropTypes.element,
    type: PropTypes.oneOf(['image']),
    variant: PropTypes.oneOf(['inset', 'exdent']),
    className: PropTypes.string,
};

export default CardMedia;