// via https://www.npmjs.com/package/@trussworks/react-uswds
import React from 'react';
import { uniqueId } from 'lodash';
import { ComboBox as TrussworksComboBox } from '@trussworks/react-uswds';
import classnames from 'classnames';

export const ComboBox = ({
  id = `select_${uniqueId()}`,
  name,
  className,
  options,
  defaultValue,
  disabled,
  onChange,
  assistiveHint,
  noResults,
  selectProps,
  inputProps,
  ulProps,
  ...props
}) => {

  const comboBoxClasses = classnames(
    { [`disabled`]: !!disabled },
    className,
  );

  return (
    <TrussworksComboBox
      id={id}
      name={name}
      className={comboBoxClasses}
      options={options}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      assistiveHint={assistiveHint}
      noResults={noResults}
      selectProps={selectProps}
      inputProps={inputProps}
      ulProps={ulProps}
      {...props}
    />
  )
};
