import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardList = ({
    children,
    direction,
    shrinked,
    className
}) => {

    const classes = classnames(
        'usa-card-group',
        {
            'flex-column': direction === 'column',
            'flex-align-start': shrinked
        },
        className,
    );

    return (
        <ul className={classes}>
            {children.map(c => c)}
        </ul>
    );
};

CardList.defaultProps = {
    shrinked: false,
    direction: 'row',
    className: ''
    
};


CardList.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    direction: PropTypes.oneOf(['row', 'column']),
    shrinked: PropTypes.bool,
    className: PropTypes.string
};

export default CardList;