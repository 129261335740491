/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ComboBox } from '@trussworks/react-uswds';
import { TextInput, Label } from '../..';
import { ErrorMessage } from '../../lib/util';
import './PhoneFaxInput.scss';

export const PhoneFaxInput = ({
  type,
  countryCodeProps,
  phoneFaxProps,
  extensionProps,
}) => {
  const [extError, setExtError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [countryCodeError, setCountryCodeError] = useState('');
  const [comboBoxValue, setComboBoxValue] = useState('');
  const [countryCodeOptions, setCountryCodeOptions] = useState(
    countryCodeProps.options || [],
  );
  const textBoxRef = useRef();

  const validatePhone = (phone) => {
    if (comboBoxValue === '+1') {
      if (phone.length) {
        if (phone.length < 12 || phone.length > 12) {
          setPhoneError('Invalid phone number');
          return;
        }
      }
    }

    setPhoneError('');
  };
  const maskPhoneNumber = (e) => {
    const { value } = e.target;
    const stripped = value.replace(/\D/g, '');
    if (stripped && stripped.length > 0) {
      e.target.value = `${stripped.substring(0, 3)}-${stripped.substring(
        3,
        6,
      )}-${stripped.substring(6)}`;
    } else {
      e.target.value = '';
    }
    const strippedValue = e.target.value;
    validatePhone(strippedValue);
  };

  useEffect(() => {
    const textBoxValue = textBoxRef.current.value;
    validatePhone(textBoxValue);
  }, [comboBoxValue]);

  const handlePhoneChange = (e) => {
    const phoneregex = RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g);

    if (e.target.value.length) {
      phoneregex.test(e.target.value)
        ? setPhoneError('')
        : setPhoneError(
            type.toUpperCase() === 'FAX'
              ? 'Invalid fax number'
              : 'Invalid phone number',
          );
    } else {
      setPhoneError('');
    }
  };
  const handleExtChange = (e) => {
    const extregex = RegExp(/^[0-9]{1,5}$/g);
    if (e.target.value.length) {
      extregex.test(e.target.value)
        ? setExtError('')
        : setExtError('Invalid extension number');
    } else {
      setExtError('');
    }
  };

  const handleCallingCodeChange = (e) => {
    if (e !== undefined && !e && countryCodeProps.required) {
      setComboBoxValue('');
      setCountryCodeError('Invalid country code');
    } else {
      setCountryCodeError('');
      setComboBoxValue(e);
    }
  };

  useEffect(() => {
    if (countryCodeProps.options) {
      setCountryCodeOptions(countryCodeProps.options);
    }
  }, [countryCodeProps?.options]);

  useEffect(() => {
    if (countryCodeProps.errorMessage !== undefined) {
      setCountryCodeError(countryCodeProps.errorMessage);
    }
  }, [countryCodeProps?.errorMessage]);

  return (
    <>
      <div className="grid-container">
        <div className="grid-row grid-gap-lg">
          <div className="tablet:grid-col">
            <div
              className={`usa-form-group${
                countryCodeError ? ' usa-form-group--error' : ''
              }`}
            >
              <Label
                className={`margin-bottom-0${
                  countryCodeError && ' usa-label--error'
                }`}
                htmlFor="country_code"
                required={countryCodeProps?.required}
              >
                {countryCodeProps?.label || 'Country code'}
              </Label>
              <ErrorMessage text={countryCodeError} />
              <ComboBox
                name="country_code"
                data-testid="country_code_combo_test"
                className={countryCodeError ? 'usa-input--error' : ''}
                inputProps={{
                  id: 'country_code',
                }}
                onChange={(e) => handleCallingCodeChange(e)}
                {...countryCodeProps}
                options={countryCodeOptions}
              />
            </div>
          </div>
          <div className="tablet:grid-col">
            <TextInput
              name="phone_fax"
              inputRef={textBoxRef}
              data-testid="phone_fax_test"
              onBlur={(e) => maskPhoneNumber(e)}
              label={type.toUpperCase() === 'FAX' ? 'Fax' : 'Phone'}
              errorMessage={phoneError}
              maxLength="15"
              onChange={(e) => {
                handlePhoneChange(e);
              }}
              {...phoneFaxProps}
            />
          </div>
          <div className="tablet:grid-col">
            <TextInput
              name="extension"
              data-testid="ext_test"
              maxLength="5"
              type="text"
              label="Extension"
              errorMessage={extError}
              onChange={(e) => {
                handleExtChange(e);
              }}
              {...extensionProps}
            />
          </div>
        </div>
      </div>
    </>
  );
};

PhoneFaxInput.propTypes = {
  type: PropTypes.string,
};
PhoneFaxInput.defaultProps = {
  type: 'phone',
};

export default PhoneFaxInput;
