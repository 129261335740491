import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

export const Link = ({
  id,
  children,
  className,
  visited,
  external,
  ...linkProps
}) => {
  const classes = classnames(
    'usa-link',
    {
      'usa-link--external': !!external,
    },
    {
      'usa-color-text-visited': !!visited,
    },
    className,
  );

  return (
    <a id={id || `link_${uniqueId()}`} className={classes} {...linkProps}>
      {children}
    </a>
  );
};

Link.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  external: PropTypes.bool,
  visited: PropTypes.bool,
};
