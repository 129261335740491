import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ComboBox } from '../../atoms/ComboBox/ComboBox';
import { Label } from '../../atoms/Label/Label';
import { ErrorMessage } from '../../atoms/ErrorMessage/ErrorMessage';
import './AddressFormGroup.scss';

export const ComboBoxWrapper = ({
  inputsDisabled,
  showLabelError,
  name,
  label,
  required,
  defaultValue,
  value,
  errorMessage,
  error,
  options,
  restProps,
  onChange,
}) => {
  if (!options?.length) return null;

  const [isShown, setIsShown] = useState(true);
  const [curVal, setCurVal] = useState(undefined);

  const { className: classProp, ...otherProps } = restProps || {};
  const hasError = !!errorMessage;

  useEffect(() => {
    // Force re-render the defaultValue of the ComboBox
    if ((!curVal && defaultValue) || (curVal && curVal !== defaultValue)) {
      setIsShown(false);
      setTimeout(() => setIsShown(true), 100);
    }
  }, [defaultValue]);

  if (isShown)
    return (
      <div
        className={`address-form-group ${
          errorMessage || error ? 'address-form-error-border' : ''
        }`}
      >
        <Label
          htmlFor={name}
          required={required}
          error={showLabelError && hasError}
        >
          {label}
        </Label>
        {(errorMessage || error) && (
          <ErrorMessage> {errorMessage || error} </ErrorMessage>
        )}
        <ComboBox
          id={name}
          data-testid={name}
          {...otherProps}
          name={name}
          className={classnames(name, classProp, {
            'combo-box--error': hasError,
          })}
          options={options}
          defaultValue={defaultValue}
          value={value}
          onChange={(val) => {
            onChange(val);
            setCurVal(val);
          }}
          disabled={inputsDisabled}
        />
      </div>
    );

  return (
    <div className="address-form-group">
      <Label htmlFor={name} required={required}>
        {label}
      </Label>
      <ComboBox options={options} disabled={inputsDisabled} />
    </div>
  );
};

ComboBoxWrapper.defaultProps = {
  inputsDisabled: false,
  showLabelError: false,
  required: false,
  defaultValue: undefined,
  value: undefined,
  errorMessage: '',
  options: [],
  restProps: {},
  onChange: () => undefined,
};

ComboBoxWrapper.propTypes = {
  inputsDisabled: PropTypes.bool,
  showLabelError: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.objectOf({})),
  restProps: PropTypes.objectOf({}),
  onChange: PropTypes.func,
};
