import React from 'react';
import PropTypes from 'prop-types';
import './Link.scss';

const LinkCardMedia = ({ children }) => {
  return (
    <>
      <div data-testid="link-card-media-test-id" className="link-card__media">
        <div className="link-card__img">{children}</div>
      </div>
    </>
  );
};

LinkCardMedia.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LinkCardMedia;
