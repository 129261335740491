import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { coerceBoolean } from '../../lib/util/booleanUtils';

export const Checkbox = ({
  id,
  name,
  value,
  className,
  label,
  disabled,
  inputRef,
  ...restProps
}) => {
  id = id || `checkbox_${uniqueId()}`;

  const classes = classnames('usa-checkbox', className);

  return (
    <div data-testid={`${id}_wrapper`} className={classes}>
      <input
        className="usa-checkbox__input"
        id={id}
        type="checkbox"
        name={name}
        checked={coerceBoolean(value)}
        disabled={disabled}
        ref={inputRef}
        aria-label={label}
        {...restProps}
      />
      <label className="usa-checkbox__label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
