import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../../atoms/Checkbox/Checkbox';
import './MultiSelect.scss';

export const MultiSelect = ({ id, options, onChange, selectedValues }) => {
  const checkStateChange = (opt, ev) => {
    onChange(opt.value, ev.target.checked);
  };

  return (
    <div id={id}>
      {options.map((opt) => {
        return (
          <Checkbox
            name={opt.value}
            key={opt.value}
            label={opt.label}
            onChange={(ev) => checkStateChange(opt, ev)}
            checked={Boolean(selectedValues.find((el) => el === opt.value))}
          />
        );
      })}
    </div>
  );
};

MultiSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.array.isRequired,
};
