import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Fieldset = ({ children, legend, required, className, legendSrOnly }) => {
  const classes = classnames('usa-fieldset', className);

  const legendClasses = classnames('usa-legend text-bold', {
    'usa-sr-only': legendSrOnly,
  });

  return (
    <fieldset data-testid="fieldset" className={classes}>
      {legend && (
        <legend className={legendClasses}>
          {legend}
          {required && (
            <abbr
              data-testid="fs-abbr-required"
              title="required"
              className="usa-label--required"
            >
              {' '}
              *
            </abbr>
          )}
        </legend>
      )}
      {children}
    </fieldset>
  );
};

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  legend: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  legendSrOnly: PropTypes.bool,
};

Fieldset.defaultProps = {
  legend: '',
  required: false,
  className: '',
  legendSrOnly: false,
};

export default Fieldset;
