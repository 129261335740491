import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

const Field = ({ children }) => (
  <div className="usa-form-group">{children}</div>
);

export const Form = ({
  id,
  onSubmit,
  children,
  className,
  size,
  ...formProps
}) => {
  const classes = classnames(
    'usa-form',
    { [`usa-form--${size}`]: !!size },
    className,
  );

  return (
    <form
      data-testid={id || `form_${uniqueId()}`}
      className={classes}
      onSubmit={onSubmit}
      {...formProps}
    >
      {children}
    </form>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large', 'medium']),
};

Form.Field = Field;
