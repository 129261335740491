import React from 'react';
import PropTypes from 'prop-types';
import gsaFleetLogo from './logo-gsa-fleet.svg';
import './Logo.scss';

const Logo = ({ url, className, ...restProps }) => {
  const logo = <img src={gsaFleetLogo} alt="GSA Fleet" />;
  return (
    <div className={`usa-logo afp-logo ${className}`} {...restProps}>
      {url ? <a href={url}>{logo}</a> : logo}
    </div>
  );
};

Logo.defaultProps = {
  url: null,
  className: null,
};

Logo.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
};

export default Logo;
