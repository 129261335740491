import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Day from './Day';
import { EN_US } from './i18n';
import MonthPicker from './MonthPicker';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfWeek,
  isDateWithinMinAndMax,
  isSameDay,
  isSameMonth,
  keepDateBetweenMinAndMax,
  listToTable,
  max,
  min,
  setMonth,
  setYear,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
  subYears,
  today,
  parseDateString,
} from './Utils';
import YearPicker from './YearPicker';
import './Calendar.scss';

const CalendarModes = {
  DATE_PICKER: 'DATE_PICKER',
  MONTH_PICKER: 'MONTH_PICKER',
  YEAR_PICKER: 'YEAR_PICKER',
};

const DEFAULT_MIN_DATE = '0000-01-01';

const Calendar = ({
  date,
  selectedDate,
  handleSelectDate,
  minDate = parseDateString(DEFAULT_MIN_DATE),
  maxDate,
  rangeDate,
  focusMode = 'None',
  disableWeekends,
  i18n = EN_US,
}) => {
  const prevYearEl = useRef(null);
  const prevMonthEl = useRef(null);
  const nextMonthEl = useRef(null);
  const nextYearEl = useRef(null);
  const selectMonthEl = useRef(null);
  const selectYearEl = useRef(null);
  const focusedDayEl = useRef(null);
  const datePickerEl = useRef(null);

  const [dateToDisplay, setDateToDisplay] = useState(date || today());
  const [mode, setMode] = useState(CalendarModes.DATE_PICKER);
  const [nextToFocus, setNextToFocus] = useState([null, null]);

  const handleSelectMonth = (monthIndex) => {
    let newDate = setMonth(dateToDisplay, monthIndex);
    newDate = keepDateBetweenMinAndMax(newDate, minDate, maxDate);
    setDateToDisplay(newDate);
    setMode(CalendarModes.DATE_PICKER);
  };

  const handleSelectYear = (year) => {
    let newDate = setYear(dateToDisplay, year);
    newDate = keepDateBetweenMinAndMax(newDate, minDate, maxDate);
    setDateToDisplay(newDate);
    setMode(CalendarModes.DATE_PICKER);
  };

  const focusedDate = addDays(dateToDisplay, 0);
  const focusedMonth = dateToDisplay.getMonth();
  const focusedYear = dateToDisplay.getFullYear();

  const monthLabel = i18n.months[parseInt(`${focusedMonth}`, 10)];
  const dayOfWeekShortLabels = i18n.daysOfWeekShort;
  const dayOfWeekLabels = i18n.daysOfWeek;
  const { backOneYear, backOneMonth, forwardOneMonth, forwardOneYear } = i18n;
  const clickToSelectMonth = `${monthLabel}. ${i18n.clickToSelectMonth}`;
  const clickToSelectYear = `${focusedYear}. ${i18n.clickToSelectYear}`;

  useEffect(() => {
    // Update displayed date when input changes (only if viewing date picker - otherwise an effect loop will occur)
    if (date && mode === CalendarModes.DATE_PICKER) {
      setDateToDisplay(date);
    }
  }, [date]);

  useEffect(() => {
    if (focusMode !== 'Input') {
      const [focusEl, fallbackFocusEl] = nextToFocus;

      if (focusEl && fallbackFocusEl) {
        if (focusEl.disabled) {
          fallbackFocusEl.focus();
        } else {
          focusEl.focus();
        }
        setNextToFocus([null, null]);
      } else {
        // Focus on new date when it changes
        const focusedDateEl =
          datePickerEl.current &&
          datePickerEl.current.querySelector(
            '.usa-date-picker__calendar__date--focused',
          );

        if (focusedDateEl) {
          focusedDateEl.focus();
        }
      }
    }
  }, [dateToDisplay]);

  if (mode === CalendarModes.MONTH_PICKER) {
    return (
      <MonthPicker
        date={dateToDisplay}
        minDate={minDate}
        maxDate={maxDate}
        handleSelectMonth={handleSelectMonth}
        i18n={i18n}
      />
    );
  }
  if (mode === CalendarModes.YEAR_PICKER) {
    return (
      <YearPicker
        date={dateToDisplay}
        minDate={minDate}
        maxDate={maxDate}
        handleSelectYear={handleSelectYear}
      />
    );
  }

  const prevMonth = subMonths(dateToDisplay, 1);
  const nextMonth = addMonths(dateToDisplay, 1);

  const firstOfMonth = startOfMonth(dateToDisplay);
  const prevButtonsDisabled = isSameMonth(dateToDisplay, minDate);
  const nextButtonsDisabled = maxDate && isSameMonth(dateToDisplay, maxDate);

  const rangeConclusionDate = selectedDate || dateToDisplay;
  const rangeStartDate = rangeDate && min(rangeConclusionDate, rangeDate);
  const rangeEndDate = rangeDate && max(rangeConclusionDate, rangeDate);

  const withinRangeStartDate = rangeStartDate && addDays(rangeStartDate, 1);
  const withinRangeEndDate = rangeEndDate && subDays(rangeEndDate, 1);

  const handleKeyDownFromDay = (event) => {
    let newDisplayDate;
    switch (event.key) {
      case 'ArrowUp':
      case 'Up':
        newDisplayDate = subWeeks(dateToDisplay, 1);
        break;
      case 'ArrowDown':
      case 'Down':
        newDisplayDate = addWeeks(dateToDisplay, 1);
        break;
      case 'ArrowLeft':
      case 'Left':
        newDisplayDate = subDays(dateToDisplay, 1);
        break;
      case 'ArrowRight':
      case 'Right':
        newDisplayDate = addDays(dateToDisplay, 1);
        break;
      case 'Home':
        newDisplayDate = startOfWeek(dateToDisplay);
        break;
      case 'End':
        newDisplayDate = endOfWeek(dateToDisplay);
        break;
      case 'PageDown':
        if (event.shiftKey) {
          newDisplayDate = addYears(dateToDisplay, 1);
        } else {
          newDisplayDate = addMonths(dateToDisplay, 1);
        }
        break;
      case 'PageUp':
        if (event.shiftKey) {
          newDisplayDate = subYears(dateToDisplay, 1);
        } else {
          newDisplayDate = subMonths(dateToDisplay, 1);
        }
        break;
      default:
        return;
    }

    if (newDisplayDate !== undefined) {
      const cappedDate = keepDateBetweenMinAndMax(
        newDisplayDate,
        minDate,
        maxDate,
      );
      if (!isSameDay(dateToDisplay, cappedDate)) {
        setDateToDisplay(newDisplayDate);
      }
    }

    event.preventDefault();
  };

  const handleMouseMoveFromDay = (hoverDate) => {
    if (hoverDate === dateToDisplay) return;
    setDateToDisplay(hoverDate);
  };

  const handlePreviousYearClick = () => {
    let newDate = subYears(dateToDisplay, 1);
    newDate = keepDateBetweenMinAndMax(newDate, minDate, maxDate);
    setDateToDisplay(newDate);
    setNextToFocus([prevYearEl.current, datePickerEl.current]);
  };

  const handlePreviousMonthClick = () => {
    let newDate = subMonths(dateToDisplay, 1);
    newDate = keepDateBetweenMinAndMax(newDate, minDate, maxDate);
    setDateToDisplay(newDate);
    setNextToFocus([prevMonthEl.current, datePickerEl.current]);
  };

  const handleNextMonthClick = () => {
    let newDate = addMonths(dateToDisplay, 1);
    newDate = keepDateBetweenMinAndMax(newDate, minDate, maxDate);
    setDateToDisplay(newDate);
    setNextToFocus([nextMonthEl.current, datePickerEl.current]);
  };

  const handleNextYearClick = () => {
    let newDate = addYears(dateToDisplay, 1);
    newDate = keepDateBetweenMinAndMax(newDate, minDate, maxDate);
    setDateToDisplay(newDate);
    setNextToFocus([nextYearEl.current, datePickerEl.current]);
  };

  const handleToggleMonthSelection = () => {
    setMode(CalendarModes.MONTH_PICKER);
  };

  const handleToggleYearSelection = () => {
    setMode(CalendarModes.YEAR_PICKER);
  };

  const isTileDisabled = (dateIterator2) => {
    // disabling selection for saturday and sunday for every week.
    if (disableWeekends) {
      return dateIterator2.getDay() === 6 || dateIterator2.getDay() === 0;
    }
    return false;
  };

  const days = [];

  let dateIterator = startOfWeek(firstOfMonth);
  while (
    days.length < 28 ||
    dateIterator.getMonth() === focusedMonth ||
    days.length % 7 !== 0
  ) {
    const isFocused = isSameDay(dateIterator, focusedDate);

    days.push(
      <Day
        date={dateIterator}
        onClick={handleSelectDate}
        onKeyDown={handleKeyDownFromDay}
        onMouseMove={handleMouseMoveFromDay}
        ref={isFocused ? focusedDayEl : null}
        isDisabled={
          isTileDisabled(dateIterator) ||
          !isDateWithinMinAndMax(dateIterator, minDate, maxDate)
        }
        isSelected={selectedDate && isSameDay(dateIterator, selectedDate)}
        isFocused={isFocused}
        isPrevMonth={isSameMonth(dateIterator, prevMonth)}
        isFocusedMonth={isSameMonth(dateIterator, focusedDate)}
        isNextMonth={isSameMonth(dateIterator, nextMonth)}
        isToday={isSameDay(dateIterator, today())}
        isRangeDate={rangeDate && isSameDay(dateIterator, rangeDate)}
        isRangeStart={rangeStartDate && isSameDay(dateIterator, rangeStartDate)}
        isRangeEnd={rangeEndDate && isSameDay(dateIterator, rangeEndDate)}
        isWithinRange={
          withinRangeStartDate &&
          withinRangeEndDate &&
          isDateWithinMinAndMax(
            dateIterator,
            withinRangeStartDate,
            withinRangeEndDate,
          )
        }
        i18n={i18n}
      />,
    );
    dateIterator = addDays(dateIterator, 1);
  }

  return (
    // Ignoring error: "Static HTML elements with event handlers require a role."
    // Ignoring because this element does not have a role in the USWDS implementation (https://github.com/uswds/uswds/blob/develop/src/js/components/date-picker.js#L1042)
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      tabIndex={-1}
      className="usa-date-picker__calendar__date-picker"
      data-testid="calendar-date-picker"
      ref={datePickerEl}
    >
      <div className="usa-date-picker__calendar__row">
        <div className="usa-date-picker__calendar__cell usa-date-picker__calendar__cell--center-items">
          <button
            type="button"
            data-testid="previous-year"
            onClick={handlePreviousYearClick}
            ref={prevYearEl}
            className="usa-date-picker__calendar__previous-year"
            aria-label={backOneYear}
            disabled={prevButtonsDisabled}
          >
            &nbsp;
          </button>
        </div>
        <div className="usa-date-picker__calendar__cell usa-date-picker__calendar__cell--center-items">
          <button
            type="button"
            data-testid="previous-month"
            onClick={handlePreviousMonthClick}
            ref={prevMonthEl}
            className="usa-date-picker__calendar__previous-month"
            aria-label={backOneMonth}
            disabled={prevButtonsDisabled}
          >
            &nbsp;
          </button>
        </div>
        <div className="usa-date-picker__calendar__cell usa-date-picker__calendar__month-label">
          <button
            type="button"
            data-testid="select-month"
            onClick={handleToggleMonthSelection}
            ref={selectMonthEl}
            className="usa-date-picker__calendar__month-selection"
            aria-label={clickToSelectMonth}
          >
            {monthLabel}
          </button>
          <button
            type="button"
            data-testid="select-year"
            onClick={handleToggleYearSelection}
            ref={selectYearEl}
            className="usa-date-picker__calendar__year-selection"
            aria-label={clickToSelectYear}
          >
            {focusedYear}
          </button>
        </div>
        <div className="usa-date-picker__calendar__cell usa-date-picker__calendar__cell--center-items">
          <button
            type="button"
            data-testid="next-month"
            onClick={handleNextMonthClick}
            ref={nextMonthEl}
            className="usa-date-picker__calendar__next-month"
            aria-label={forwardOneMonth}
            disabled={nextButtonsDisabled}
          >
            &nbsp;
          </button>
        </div>
        <div className="usa-date-picker__calendar__cell usa-date-picker__calendar__cell--center-items">
          <button
            type="button"
            data-testid="next-year"
            onClick={handleNextYearClick}
            ref={nextYearEl}
            className="usa-date-picker__calendar__next-year"
            aria-label={forwardOneYear}
            disabled={nextButtonsDisabled}
          >
            &nbsp;
          </button>
        </div>
      </div>
      <table className="usa-date-picker__calendar__table" role="presentation">
        <thead>
          <tr>
            {dayOfWeekShortLabels.map((d, i) => (
              <th
                className="usa-date-picker__calendar__day-of-week bg-base-lightest"
                scope="col"
                aria-label={dayOfWeekLabels[parseInt(`${i}`, 10)]}
                // eslint-disable-next-line react/no-array-index-key
                key={`day-of-week-${d}-${i}`}
              >
                {d}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{listToTable(days, 7)}</tbody>
      </table>
    </div>
  );
};

Calendar.defaultProps = {
  date: undefined,
  selectedDate: undefined,
  handleSelectDate: undefined,
  minDate: undefined,
  maxDate: undefined,
  rangeDate: undefined,
  focusMode: undefined,
  disableWeekends: true,
  i18n: undefined,
};

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date),
  selectedDate: PropTypes.instanceOf(Date),
  handleSelectDate: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  rangeDate: PropTypes.instanceOf(Date),
  focusMode: PropTypes.string,
  disableWeekends: PropTypes.bool,
  i18n: PropTypes.shape({
    months: PropTypes.arrayOf(PropTypes.string),
    daysOfWeek: PropTypes.arrayOf(PropTypes.string),
    daysOfWeekShort: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string),
    selectedDate: PropTypes.string,
    selectAMonth: PropTypes.string,
    toggleCalendar: PropTypes.string,
    backOneYear: PropTypes.string,
    backOneMonth: PropTypes.string,
    forwardOneYear: PropTypes.string,
    forwardOneMonth: PropTypes.string,
    clickToSelectMonth: PropTypes.string,
    clickToSelectYear: PropTypes.string,
  }),
};

export default Calendar;
