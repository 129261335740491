import React from 'react';

export const MegaMenuSubsectionList = ({ children, isShown }) => {
  return (
    <ul className="usa-nav__submenu" role="menu" hidden={!isShown}>
      {children}
    </ul>
  );
};

export default MegaMenuSubsectionList;
