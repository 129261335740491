import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AccordionItem from './AccordionItem';

const AccordionGroup = ({
  accordionChildrenContent,
  customClass,
  isOpen = false,
  id,
  bordered = false,
  handleInnerAccordionClick,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(isOpen);
  const [innerAccordionOpenState, setInnerAccordionOpenState] = useState([]);
  const {
    accordionGroupTitle,
    accordionGroupContent,
    accordionGroupCustomClasses,
  } = accordionChildrenContent;

  const classes = classnames(
    'usa-accordion',
    {
      'usa-accordion--bordered': bordered,
    },
    customClass,
  );
  const innerAccordionClasses = classnames(
    'usa-accordion',
    'usa-accordion--bordered',
    accordionGroupCustomClasses,
  );

  useEffect(() => {
    const initialInnerAccodionState =
      accordionChildrenContent.accordionGroupContent.map((accordion) => {
        if (accordion.expanded) {
          return accordion.id;
        }
        return;
      });
    setInnerAccordionOpenState(initialInnerAccodionState);
  }, [accordionChildrenContent]);

  const handleAccordionToggle = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleInnerAccordionToggle = (accordionId) => {
    const newOpenItems = [...innerAccordionOpenState];
    const itemIndex = innerAccordionOpenState.indexOf(accordionId);

    if (itemIndex > -1) {
      newOpenItems.splice(itemIndex, 1);
    } else {
      newOpenItems.push(accordionId);
    }

    setInnerAccordionOpenState(newOpenItems);
    if (handleInnerAccordionClick) {
      handleInnerAccordionClick(newOpenItems);
    }
  };

  const isAccordionOpen = (accordionId) => {
    return innerAccordionOpenState.indexOf(accordionId) > -1;
  };

  return (
    <div className={classes} data-testid={id}>
      <AccordionItem
        handleToggle={handleAccordionToggle}
        title={accordionGroupTitle}
        expanded={accordionOpen}
        id={id}
        content={accordionGroupContent.map((content) => (
          <div className={innerAccordionClasses}>
            <AccordionItem
              title={content.title}
              key={`${id}--${content.id}`}
              data-testid={`${id}--${content.id}`}
              id={`${id}--${content.id}`}
              expanded={isAccordionOpen(content.id)}
              content={content.content}
              handleToggle={() => handleInnerAccordionToggle(content.id)}
            />
          </div>
        ))}
      />
    </div>
  );
};

AccordionGroup.defaultProps = {
  id: '',
  customClass: null,
  accordionChildrenContent: {
    accordionGroupTitle: '',
    accordionGroupContent: null,
    accordionGroupCustomClasses: null,
  },
};

AccordionGroup.propTypes = {
  accordionChildrenContent: PropTypes.shape({
    accordionGroupTitle: PropTypes.string,
    accordionGroupContent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    accordionGroupCustomClasses: PropTypes.string,
  }),
  id: PropTypes.string,
  customClass: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  bordered: PropTypes.bool.isRequired,
  handleInnerAccordionClick: PropTypes.func.isRequired,
};
export default AccordionGroup;
