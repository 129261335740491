import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import FilterPanelFilterShape from './helpers/FilterPanelFilterShape';
import { useFilterReducer } from './helpers/reducers';
import refinedFilters from './helpers/refinedFilters';

export const FilterContext = createContext();

export default function FilterProvider({
  children,
  initFilters,
  setQueryFiltersState,
}) {
  // Plain text and typeahead filters
  const [currentFilters, setFilters] = useFilterReducer(initFilters);
  const [clonedInitFilters, setClonedInitFilters] = useState(
    cloneDeep(initFilters),
  );

  const formatMergedFilters = () => {
    const formattedMergedState = [];
    const unformattedMergedState = [...currentFilters.filters];

    initFilters.filters.forEach((childEl) => {
      const filterEquivalentInState = unformattedMergedState.find(
        (filter) => filter.key === childEl.key,
      );
      formattedMergedState.push(filterEquivalentInState);
    });

    return [...formattedMergedState];
  };

  const mergedFiltersReducer = () => {
    return formatMergedFilters();
  };

  const [mergedFilters, setMergedFilters] = useReducer(
    mergedFiltersReducer,
    formatMergedFilters(),
  );

  const setFiltersAndRefetch = (ev) => {
    if (ev.key === 'Enter') {
      setMergedFilters();
    }
  };

  useEffect(() => {
    setMergedFilters();
  }, [currentFilters.updateToggle]);

  // pull/push a value from/to array of multiselect values
  const multiSelectChange = (filter, checked, checkboxValue) => {
    let { value } = filter;
    if (checked) {
      value.push(checkboxValue);
    } else {
      value = value.filter((el) => el !== checkboxValue);
    }

    setFilters({
      type: 'setOne',
      fetchNewData: true,
      filter: { ...filter, value },
    });
  };

  // clears value from filter state, fetches data based on updated filters
  const clearOneFilter = (filter) => {
    // FIXME: const { key } = filter;
    // FIXME: setTypeaheadState({ type: 'clearValues', payload: { key } });

    const payload = {
      type: 'setOne',
      fetchNewData: true,
      filter: {
        ...filter,
        value: '',
      },
    };
    setFilters(payload);
  };

  const clearAllFilters = () => {
    const payload = { type: 'clearAll', initialState: clonedInitFilters };
    setFilters({ ...payload });
    setClonedInitFilters(cloneDeep(clonedInitFilters));
  };

  useEffect(() => {
    const queryConditions = refinedFilters(mergedFilters);
    setQueryFiltersState(queryConditions);
  }, [mergedFilters]);

  const getFilterFromState = (key) => mergedFilters.find((f) => f.key === key);

  return (
    <FilterContext.Provider
      value={{
        currentFilters,
        setFilters,
        setFiltersAndRefetch,
        multiSelectChange,
        mergedFilters,
        getFilterFromState,
        clearOneFilter,
        clearAllFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

FilterProvider.defaultProps = {};

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
  setQueryFiltersState: PropTypes.func.isRequired,
  initFilters: PropTypes.shape({
    updateToggle: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.shape(FilterPanelFilterShape)),
  }).isRequired,
};

// Hook to exposes context value.
export const useFilterPanel = () => useContext(FilterContext);
