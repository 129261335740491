import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../atoms/Icon/Icon';
import './InteractiveCheck.scss';

const InteractiveCheck = ({ checks, isDirty }) => {
  const colorChange = (condition) => {
    if (!isDirty) {
      return 'is-neutral';
    }
    return condition ? 'is-valid' : 'is-invalid';
  };

  const iconChange = (condition) => {
    return condition ? 'check' : 'close';
  };

  return (
    <ul className="interactive-checks">
      {checks.map((item, index) => (
        <li
          key={item.id}
          className={colorChange(item.condition)}
          data-testid={`interactive-check-testid-${index}`}
        >
          {isDirty ? (
            <Icon iconName={iconChange(item.condition)} />
          ) : (
            <Icon type="custom" iconName="filled_circle" />
          )}
          <span>{item.message}</span>
        </li>
      ))}
    </ul>
  );
};

InteractiveCheck.propTypes = {
  checks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      condition: PropTypes.bool.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isDirty: PropTypes.bool.isRequired,
};

export default InteractiveCheck;
