import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/Icon';

const ResetButton = ({
  children,
  iconBefore,
  hideIcon,
  className,
  iconClassName,
  underline,
  ...buttonProps
}) => {
  const classes = classnames(
    'usa-button usa-button--unstyled display-flex',
    { 'text-no-underline': !underline },
    className,
  );

  const icon = hideIcon ? (
    ''
  ) : (
    <Icon
      iconName="close"
      style={{ fill: 'inherit' }}
      className={classnames(
        {
          'margin-right-05': iconBefore,
          'margin-left-05': !iconBefore,
        },
        iconClassName,
      )}
    />
  );

  return (
    <button
      type="button"
      className={classes}
      aria-label="reset"
      {...buttonProps}
    >
      {iconBefore && icon}
      <span>{children}</span>
      {!iconBefore && icon}
    </button>
  );
};

ResetButton.defaultProps = {
  iconBefore: false,
  hideIcon: false,
  underline: false,
  className: undefined,
  iconClassName: undefined,
};

ResetButton.propTypes = {
  children: PropTypes.node.isRequired,
  iconBefore: PropTypes.bool,
  hideIcon: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  underline: PropTypes.bool,
};

export default ResetButton;
