import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TabList, Tabs, Tab, TabPanel } from 'react-tabs';
import { CounterTag } from '../CounterTag/CounterTag';
import './Tab.scss';

const TabSet = ({ tabs, onSelect }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const openTabIndex = _.findIndex(tabs, { tabSelectedWhenOpen: true });
    if (openTabIndex > -1) {
      setTabIndex(openTabIndex);
    }
  }, [tabs]);

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={(index) => {
        setTabIndex(index);
        onSelect(index);
      }}
    >
      <TabList>
        {tabs.map((element) => (
          <Tab key={`${element.heading}-tab`}>
            {element.heading}
            {element.count && (
              <CounterTag className="padding-left-1" count={element.count} />
            )}
          </Tab>
        ))}
      </TabList>
      {tabs.map((element) => (
        <TabPanel key={`${element.heading}-panel`}>{element.content}</TabPanel>
      ))}
    </Tabs>
  );
};

TabSet.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.node,
      content: PropTypes.node,
      count: PropTypes.number,
      tabSelectedWhenOpen: PropTypes.bool,
    }),
  ).isRequired,
  onSelect: PropTypes.func,
};
TabSet.defaultProps = {
  onSelect: () => {},
};

export default TabSet;
