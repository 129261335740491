import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Tag.scss';

const variantColorMap = [
  { name: 'Inactive-Dark', background: 'ink', text: 'white' },
  { name: 'Inactive-Medium', background: 'base', text: 'white' },
  { name: 'Inactive-Light', background: 'base-lighter', text: 'black' },
  { name: 'Ready-Dark', background: 'green-cool-50v', text: 'white' },
  { name: 'Ready-Medium', background: 'green-20v', text: 'black' },
  { name: 'Ready-Light', background: 'green-5v', text: 'black' },
  { name: 'Info-Dark', background: 'primary-light', text: 'black' },
  { name: 'Info-Light', background: 'primary-lighter', text: 'black' },
  { name: 'Important-Dark', background: 'warning', text: 'black' },
  { name: 'Important-Medium', background: 'warning-light', text: 'black' },
  { name: 'Important-Light', background: 'warning-lighter', text: 'black' },
  { name: 'Urgent-Dark', background: 'red-60v', text: 'white' },
  { name: 'Urgent-Medium', background: 'accent-warm-dark', text: 'white' },
  { name: 'Urgent-Light', background: 'error-lighter', text: 'black' },
];

export const Tag = ({ children, variant, ...props }) => {
  let bgColor = 'base';
  let textColor = 'white';
  const existingVariant = variantColorMap.find((x) => x.name === variant);

  if (existingVariant) {
    bgColor = existingVariant.background;
    textColor = existingVariant.text;
  }

  const tagClasses = classnames(
    'usa-tag afp-tag',
    {
      [`bg-${bgColor}`]: !!variant,
    },
    {
      [`text-${textColor}`]: !!variant,
    },
  );

  return (
    <span className={tagClasses} {...props}>
      {children}
    </span>
  );
};

Tag.defaultProps = {
  children: undefined,
  variant: 'base',
};

Tag.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
};

export default Tag;
