import React from 'react';
import classnames from 'classnames';
import { CounterTag } from '../../atoms/CounterTag/CounterTag';
import PropTypes from 'prop-types';
import './SideNav.scss';

const SideNavItem = ({ item }) => {
  const itemClasses = classnames(
    'sidenav-item',
    'display-flex',
    'flex-align-center',
    'flex-justify',
    'border-bottom',
    'border-base-lighter',
    'text-base',
    'font-sans-md',
    'padding-y-1',
  );

  const itemBodyClasses = classnames(
    'sidenav-item-body',
    'padding-y-1',
    'width-full',
    'display-block',
    {
      'usa-current': item.active,
    },
  );

  const itemCounterClasses = classnames(
    'sidenav-item-counter',
    'position-absolute',
  );

  return (
    <li className={itemClasses}>
      <span className={itemBodyClasses}>{item.body}</span>
      {item.count && (
        <span className={itemCounterClasses}>
          <CounterTag count={item.count} />
        </span>
      )}
    </li>
  );
};

export default SideNavItem;

SideNavItem.propTypes = {
  item: PropTypes.shape({ 
    id: PropTypes.string,
    body: PropTypes.node,
    count: PropTypes.number,
    active: PropTypes.bool,
  })
};