const operatorFromFilter = (filter) => {
  const { operator } = filter;
  return typeof operator === 'function' ? operator(filter) : operator;
};

const valueFromFilter = (filter) => {
  const { getValue, value } = filter;
  return typeof getValue === 'function' ? getValue(filter) : value;
};

const keyFromFilter = (filter) => {
  const field = filter.field || filter.key;
  return typeof field === 'function' ? field(filter) : field;
};

// loops through the current filter state returning necessary attributes for the query
const refinedFilters = (allFilters, countHiddenChips = true) => {
  const filtersWithValues = [];
  allFilters.forEach((filter) => {
    const value = valueFromFilter(filter);
    const key = keyFromFilter(filter);
    const operator = operatorFromFilter(filter);

    // don't add filter if operator not present
    if (!operator) return;

    // don't add filter if chip is hidden and countHiddenChips is false
    if (!countHiddenChips && filter.hideChip) return;

    if (typeof value === 'string' && value && key !== 'updatedAt') {
      filtersWithValues.push({
        operator,
        key,
        value: value ? value.trim() : value,
      });
    } else if (typeof value === 'string' && value && key === 'updatedAt') {
      const date = new Date(
        new Date().getTime() -
          +filter.value.split(' ')[0] * 24 * 60 * 60 * 1000,
      ).toISOString();
      const now = new Date().toISOString();
      filtersWithValues.push({ operator, key, value: [date, now] });
    } else if (typeof value === 'object' && value.length) {
      filtersWithValues.push({ operator, key, value });
    } else if (
      typeof value === 'object' &&
      value.constructor.name !== 'Array' &&
      Object.values(value).every((v) => v)
    ) {
      filtersWithValues.push({ operator, key, value });
    }
  });
  return filtersWithValues;
};

export default refinedFilters;
