import React from 'react';
import PropTypes from 'prop-types';

// Supports only anchor for now, can be extended to render button or any other type
const MenuItem = React.forwardRef(
  ({ href, children, className, hasSeparator, ...restProps }, ref) => (
    <a
      href={href}
      ref={ref}
      className={`${className} ${hasSeparator ? 'has-separator' : ''}`}
      role="menuitem"
      tabIndex="-1"
      {...restProps}
    >
      {children}
    </a>
  ),
);

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  hasSeparator: PropTypes.bool,
  className: PropTypes.string,
};

MenuItem.defaultProps = {
  className: null,
  hasSeparator: false,
};

export default MenuItem;
