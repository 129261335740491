import { useEffect } from 'react';
import throttle from 'lodash.throttle';

const useOutsideClick = (ref, callback) => {
  const handleClick = throttle((e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  }, 100);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
