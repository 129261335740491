import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from '../../molecules/Popover/Popover';
import Portal from '../../molecules/Portal/Portal';
import getPopoverPosition from './DynamicPopoverUtils';
import {
  positionToArrowPosition,
  positionToPopoverTransform,
} from './DynamicPopoverConfig';
import './DynamicPopover.scss';

export default function useDynamicPopover(portalEl) {
  const [showPopover, setShowPopover] = useState(false);
  const [coords, setCoords] = useState({ left: 0, top: 0 });
  const [position, setPosition] = useState(1);

  const togglePopover = (el) => {
    const { innerWidth, innerHeight } = window;
    const { x, y, width, height } = el.current.getBoundingClientRect();
    const elWidthMid = x + width / 2;
    const elHeightMid = y + height / 2;
    const rX = elWidthMid / innerWidth;
    const rY = elHeightMid / innerHeight;

    setShowPopover((currentState) => !currentState);
    setCoords({ left: elWidthMid, top: elHeightMid });
    setPosition(getPopoverPosition(rX, rY));
  };

  const closePopover = () => {
    setShowPopover(false);
  };

  const PopTrigger = ({ children, className }) => {
    const wrapper = useRef(null);
    const handleKeyDown = (e) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        togglePopover(wrapper);
      }
    };
    return (
      <div
        ref={wrapper}
        className={className}
        role="button"
        tabIndex="0"
        onClick={() => {
          togglePopover(wrapper);
        }}
        onKeyDown={handleKeyDown}
      >
        {children}
      </div>
    );
  };

  PopTrigger.defaultProps = {
    className: '',
  };

  PopTrigger.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
  };

  const Pop = ({ children, ...props }) => {
    if (showPopover) {
      const { left, top } = coords;
      const transform = positionToPopoverTransform[position];
      return (
        <Portal backdrop portalEl={portalEl}>
          <Popover
            arrowPosition={positionToArrowPosition[position] || null}
            style={{ left: left || 0, top: top || 0, transform }}
            {...props}
          >
            {children}
          </Popover>
        </Portal>
      );
    }
    return null;
  };

  Pop.propTypes = {
    children: PropTypes.element.isRequired,
  };

  return [Pop, PopTrigger, closePopover];
}
