// import React, { useState, useEffect } from 'react';
// import throttle from 'lodash.throttle';

// const viewportContext = React.createContext({});

// const ViewportProvider = ({ children }) => {
//   const [width, setWidth] = useState(window.innerWidth);
//   const [height, setHeight] = useState(window.innerHeight);
//   const [brkPnt, setBrkPnt] = useState(() =>
//     getDeviceConfig(window.innerWidth),
//   );

//   useEffect(() => {
//     const handleWindowResize = throttle(() => {
//       setWidth(window.innerWidth);
//       setHeight(window.innerHeight);
//       setBrkPnt(getDeviceConfig(window.innerWidth));
//     }, 200);

//     window.addEventListener('resize', handleWindowResize);
//     return () => window.removeEventListener('resize', handleWindowResize);
//   }, []);

//   return (
//     <viewportContext.Provider value={{ viewPort: { width, height, brkPnt } }}>
//       {children}
//     </viewportContext.Provider>
//   );
// };

// const useViewport = () => {
//   const { viewPort } = React.useContext(viewportContext);
//   return { viewPort };
// };

// export { ViewportProvider, useViewport };

import React from 'react';

// https://designsystem.digital.gov/utilities/layout-grid/
// USWDS-THEME-UTILITIES.SCSS
// $theme-utility-breakpoints: (
//   'card':              false,   // 160px
//   'card-lg':           false,   // 240px
//   'mobile':            false,   // 320px
//   'mobile-lg':         true,    // 480px
//   'tablet':            true,    // 640px
//   'tablet-lg':         false,   // 800px
//   'desktop':           true,    // 1040px
//   'desktop-lg':        false,   // 1200px
//   'widescreen':        false,   // 1400px
// );

const useViewport = () => {
  const [width, setWidth] = React.useState(window.outerWidth);
  // Add a second state variable "height" and default it to the current window height
  const [height, setHeight] = React.useState(window.outerHeight);

  const getDeviceConfig = () => {
    if (width < 240) {
      return 'card';
    }
    if (width >= 240 && width < 320) {
      return 'card-lg';
    }
    if (width >= 320 && width < 480) {
      return 'mobile';
    }
    if (width >= 480 && width < 640) {
      return 'mobile-lg';
    }
    if (width >= 640 && width < 880) {
      return 'tablet';
    }
    if (width >= 880 && width < 1024) {
      return 'tablet-lg';
    }
    if (width >= 1024 && width < 1200) {
      return 'desktop';
    }
    if (width >= 1200 && width < 1400) {
      return 'desktop-lg';
    }
    // >= 1400
    return 'widescreen';
  };

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.outerWidth);
      // Set the height in state as well as the width
      setHeight(window.outerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // Return both the height and width
  return { width, height, device: getDeviceConfig() };
};
export default useViewport;
