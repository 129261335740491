import React from 'react';
import PropTypes from 'prop-types';
import './Link.scss';

const LinkCardBody = ({ children }) => {
  return (
    <div data-testid="link-card-body-test-id" className="link-card__body">
      {children}
    </div>
  );
};

LinkCardBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LinkCardBody;
