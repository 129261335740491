import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from '../Chip/Chip';
import './ChipList.scss';

export const ChipsDirection = {
    row: 'row',
    column: 'column'
};

export const ChipList = ({ children, cRef, ...props}) => {
    const {
        className = '',
        direction = 'row'
    } = props;

    const classes = classNames(
        'afp-chip-list--wrapper',
        'display-flex',
        'flex-align-baseline',
        'flex-wrap',
        { 'flex-column': direction === ChipsDirection.column },
        className
    );
    return (
        <div
            className={classes}
            ref={cRef}
            {...props}
        >
            {children}
        </div>
    );
}

ChipList.defaultProps = {
    direction: ChipsDirection.row
};

const chipType = PropTypes.shape({
    type: PropTypes.oneOf([Chip])
});

ChipList.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(chipType),
        chipType
    ]),
    cRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]),
    direction: PropTypes.oneOf(Object.values(ChipsDirection))
}