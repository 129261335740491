import React from 'react';
import PropTypes from 'prop-types';
import spriteSheet from '@uswds/src/img/sprite.svg';
import customIcons from '../../../styles/images/custom-icons.svg';

const Icon = ({ iconName, className, type, ...rest }) => (
  <svg
    className={`usa-icon ${className}`}
    aria-hidden="true"
    focusable="false"
    role="img"
    {...rest}
  >
    {type === 'custom' ? (
      <use xlinkHref={`${customIcons}#${iconName}`} />
    ) : (
      <use xlinkHref={`${spriteSheet}#${iconName}`} />
    )}
  </svg>
);

export const IndividualIcon = ({ iconName, className, type, ...rest }) => {
  const iconSheet = type === 'custom' ? customIcons : spriteSheet;
  const icons = atob(iconSheet.replace('data:image/svg+xml;base64,', ''));
  return (
    <svg
      className={`usa-icon ${className}`}
      focusable="false"
      aria-hidden={true}
      {...rest}
      ref={(svgRef) => {
        if (svgRef) {
          const el = document.createElement('div');
          el.style.display = 'none';
          el.innerHTML = icons;
          const innerIcon = el.querySelector('#' + iconName);
          innerIcon.childNodes.forEach(part => {
            if (
              part.nodeName !== '#text' &&
              !(part.nodeName === 'path' && part.attributes?.fill && part.attributes?.fill?.value === 'none')
            ) {
              svgRef.append(part);
            }
          });
        }
      }}
    >
    </svg>
  );
};

Icon.defaultProps = {
  className: '',
  type: 'uswds',
};

Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default Icon;
