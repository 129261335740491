import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../../atoms/Button/Button';
import usePagination from '../../lib/hooks/usePagination';
import './Pagination.scss';

export const calculatePageRange = (itemsCount, currentPage, limit) => {
  const numberOfPages = Math.ceil(itemsCount / limit);
  const start = (currentPage - 1) * limit + 1;
  const end = Math.min(currentPage * limit, itemsCount);
  return { start, end, numberOfPages };
};

const paginationIcon = (type, disabled, className) => {
  const classes = classnames(
    { [`page-control-icon--${type}`]: !!type },
    { [`page-control-icon-disabled`]: !!disabled },
    className,
  );
  const checkType = ['first', 'last', 'previous', 'next'].includes(type);
  if (checkType) {
    return {
      name: `${type}-icon`,
      className: classes,
      type: 'custom'
    }
  }
  return null;
};

// type => first, previous, next, last
const PageControlButton = ({ type, className, ...buttonProps }) => {
  if(buttonProps.disabled) {
    return null;
  }
  const classes = classnames(
    'usa-button',
    'usa-button--outline',
    'pagination-control-button',
    'margin-x-1',
    {
      disabled: buttonProps.disabled,
    },
    { [`pagination-control-button--${type}`]: !!type },
    className,
  );
  const label = `Go to ${type} page`;
  const returnedIconProp = paginationIcon(type, buttonProps.disabled);
  return (
    <Button
      id={`${type}-button`}
      variant="unstyled"
      className={classes}
      aria-label={label}
      {...buttonProps}
      label={label}
      labelClass="usa-sr-only"
      leftIcon={returnedIconProp}
    />
  );
};
PageControlButton.defaultProps = {
  className: '',
};
PageControlButton.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const PageButton = ({ page, selected, variant, className, ...items }) => {
  const classes = classnames(
    'pagination-page-button',
    { 'usa-button--unstyled text-no-underline text-ink': !selected },
    'margin-x-1',
    { active: selected },
    { [`usa-button--${variant}`]: selected },
    className,
  );

  return (
    <Button
      id={`page-${page}-button`}
      data-testid={`page-${page}-button`}
      className={classes}
      aria-label={selected ? `page ${page}` : `Go to page ${page}`}
      {...items}
      label={page}
    />
  );
};
PageButton.defaultProps = {
  selected: false,
  className: '',
};
PageButton.propTypes = {
  page: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const PageSelectorDropdown = ({ items }) => {
  const selected = items.find((i) => i.selected);

  const value =
    selected && hasOwnProperty.call(selected, 'page')
      ? selected.page
      : undefined;

  const [selectedItem, setSelectedItem] = useState(value);

  useEffect(() => {
    if (selectedItem === value) return;
    const currentPage = items.find((i) => {
      if (i && hasOwnProperty.call(i, 'page')) return i.page === selectedItem;
      return false;
    });
    // Trigger click
    if (currentPage && currentPage.onClick) {
      currentPage.onClick();
    }
  }, [selectedItem]);

  return (
    <li
      key="dropdown-selector"
      className="page-selector-dropdown padding-y-4 margin-x-2"
    >
      <select
        className="usa-select margin-top-0"
        value={selectedItem}
        data-testid="dropdown-page-selector"
        onChange={(event) => {
          const selectedValue = Number(event.target.value);
          if (selectedValue) {
            setSelectedItem(selectedValue);
          }
        }}
      >
        {items.map(({ page, type }, index) => {
          const optionKey = index;
          return (
            <option key={optionKey} value={page || ''}>
              {type === 'page' && page ? `Page ${page}` : '...'}
            </option>
          );
        })}
      </select>
    </li>
  );
};
PageSelectorDropdown.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
};

const PageSelectorButtons = ({ items, buttonVariant }) =>
  items.map(({ page, type, selected, ...item }, index) => {
    let selectors = null;
    const key = index;

    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
      selectors = <span className="group-split">…</span>;
    } else if (type === 'page') {
      selectors = (
        <PageButton
          page={page}
          variant={buttonVariant}
          selected={selected}
          {...item}
        />
      );
    }

    return (
      <li className="page-selector-button" key={key}>
        {selectors}
      </li>
    );
  });

PageSelectorButtons.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
  buttonVariant: PropTypes.string.isRequired,
};

export const Pagination = ({
  id,
  variant,
  currentPage,
  showFirstButton,
  showPreviousButton,
  showNextButton,
  showLastButton,
  buttonVariant,
  disabled,
  className,
  boundaryCount,
  siblingCount,
  onPageChange,
  itemsCount,
  itemsPerPage,
  itemsPerPageOptions,
  isReset,
}) => {
  const [activePage, setActivePage] = useState(currentPage);
  const [limit, setLimit] = useState(itemsPerPage);

  useEffect(() => {
    if (isReset) {
      setActivePage(1);
    } else {
      setActivePage(currentPage);
    }
  }, [itemsCount, isReset]);

  useEffect(() => {
    setLimit(itemsPerPage);
  }, [itemsPerPage]);

  const { start, end, numberOfPages } = useMemo(
    () => calculatePageRange(itemsCount, activePage, limit),
    [itemsCount, activePage, limit],
  );

  const handlePageChange = (value) => {
    if (value) setActivePage(value);
    if (onPageChange) onPageChange(value, limit);
  };

  const { items } = usePagination({
    page: activePage,
    showFirstButton,
    showLastButton,
    hidePrevButton: !showPreviousButton,
    hideNextButton: !showNextButton,
    count: numberOfPages,
    boundaryCount,
    siblingCount,
    onChange: (page) => handlePageChange(page),
    disabled,
    isReset,
  });

  const itemsPerPageClasses = classnames(
    'pagination-items-per-page-selector',
    {
      'grid-col-auto':
        variant === 'advanced',
    },
    { hide: variant === 'basic' || variant === 'primary' },
  );

  const ItemsPerPageSelect = useMemo(
    () => () => (
      <div className={itemsPerPageClasses}>
        <div className="grid-row flex-align-center">
          <div className="grid-col-auto padding-x-1">
            <select
              id="select-items-per-page"
              data-testid="select-items-per-page"
              name="items per page"
              aria-label="items per page"
              className="usa-select margin-top-0"
              value={limit}
              onChange={(event) => {
                const value = Number(event.target.value);
                setLimit(value);
                if (onPageChange) onPageChange(1, value);
                setActivePage(1);
              }}
            >
              {itemsPerPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="grid-col-auto">
            items per page
          </div>
        </div>
      </div>
    ),
    [limit, itemsPerPageOptions],
  );

  const pageNavigatorClasses = classnames(
    'pagination-page-navigator',
    'grid-col-fill flex-align-center',
  );

  const pageNavigatorNavClasses = classnames(
    'page-navigator display-flex ',
    { 'flex-justify-center': variant === 'primary' || variant === 'advanced' },
  );

  const PageNavigator = useMemo(
    () => () => {
      const [first, previous, next, last] = items.filter(
        ({ type }) =>
          type === 'next' ||
          type === 'last' ||
          type === 'previous' ||
          type === 'first',
      );

      const pageSelectors = items.filter(
        ({ type }) =>
          type === 'start-ellipsis' ||
          type === 'end-ellipsis' ||
          type === 'page',
      );
      
      // Hide PageNavigator if limit is greater or equal than itemsCount. Only advanced variant
      if (limit >= itemsCount) return null;
        
      return (
        <div className={pageNavigatorClasses}>
          <nav
            id="page-navigator"
            className={pageNavigatorNavClasses}
            aria-label="pagination selector"
          >
            <ul className="page-navigator-items add-list-reset display-flex flex-wrap flex-align-center">
              {/* FIRST AND PREVIOUS BUTTONS */}
              <li key="first">
                <PageControlButton type="first" {...first} />
              </li>
              <li key="previous">
                <PageControlButton type="previous" {...previous} />
              </li>

              {/* PAGE BUTTONS AND ELLIPSIS */}
              <PageSelectorDropdown items={pageSelectors} />
              <PageSelectorButtons
                items={pageSelectors}
                buttonVariant={buttonVariant}
              />

              {/* LAST AND NEXT BUTTONS */}
              <li key="next">
                <PageControlButton type="next" {...next} />
              </li>
              <li key="last">
                <PageControlButton type="last" {...last} />
              </li>
            </ul>
          </nav>
        </div>
      );
    },
    [items],
  );

  const pageCounterClasses = classnames(
    'pagination-page-counter',
    'desktop:grid-col-3 tablet-lg:grid-col-fill display-flex flex-column flex-justify-end',
    { hide: variant === 'primary' },
    { 'no-navigator': limit >= itemsCount && variant === 'advanced' }
  );

  const PageCounter = useMemo(
    () => () => (
      <div className={pageCounterClasses}>
        <div className="grid-row ">
          <div className="grid-col-auto display-flex flex-column flex-justify-end">
            <span
              data-testid="page-counter-range"
              className="page-counter-range"
            >
              <span>Displaying&nbsp;</span>
              {`${start} - ${end} of`}
            </span>
          </div>

          <div className="grid-col-auto display-flex flex-column flex-justify-center">
            <span
              data-testid="page-counter-total"
              className="page-counter-total text-bold"
            >
              &nbsp;{`${itemsCount} items`}
            </span>
          </div>
        </div>
      </div>
    ),
    [start, end, itemsCount],
  );

  const paginationId = id || variant;

  const classes = classnames('afp-pagination', className);

  // Hide pagination if itemsCount is undefined or itemsCount <= itemsPerPage.
  if (!itemsCount) return null;

  return (
    <div className={classes} data-testid={`pagination-${paginationId}`}>
      <div className='grid-row flex-align-center'>
        <ItemsPerPageSelect />
        <PageNavigator />
        <PageCounter />
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  id: '',
  className: '',
  variant: 'basic',
  buttonVariant: 'primary',
  itemsCount: 0,
  currentPage: 1,
  boundaryCount: 1,
  siblingCount: 1,
  itemsPerPage: 10,
  itemsPerPageOptions: [10, 15, 20, 25, 50],
  showFirstButton: true,
  showPreviousButton: true,
  showNextButton: true,
  showLastButton: true,
  disabled: false,
  isReset: false,
};

Pagination.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'basic', 'advanced']),
  buttonVariant: PropTypes.oneOf([
    'primary',
    'secondary',
    'base',
    'accent-cool',
    'accent-warm',
    'outline',
    'inverse',
  ]),
  itemsCount: PropTypes.number,
  currentPage: PropTypes.number,
  boundaryCount: PropTypes.number,
  siblingCount: PropTypes.number,
  itemsPerPage: PropTypes.number,
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  showFirstButton: PropTypes.bool,
  showPreviousButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  showLastButton: PropTypes.bool,
  disabled: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
  isReset: PropTypes.bool,
};
