import PropTypes from 'prop-types';

export const CommentPropType = {
  id: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

export const PaginationPropType = {
  rows: PropTypes.arrayOf(PropTypes.object),
  count: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export const GQLPropType = {
  kind: PropTypes.string.isRequired,
  definitions: PropTypes.array.isRequired,
  loc: PropTypes.object.isRequired,
};
