import React from 'react';
import ErrorMessage from './ErrorMessage';

const Hint = ({ text }) =>
  text ? (
    <span className="usa-hint usa-character-count__message">{text}</span>
  ) : (
    ''
  );

Hint.propTypes = ErrorMessage.propTypes;

export default Hint;
