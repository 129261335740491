import React from 'react';
import PropTypes from 'prop-types';
import { useFilterPanel } from './FilterProvider';
import FilterPanelField from './FilterPanelField';
import FilterPanelFilterShape from './helpers/FilterPanelFilterShape';

export default function FilterItem({ filter: initialFilter, showSearchIcon }) {
  const { getFilterFromState } = useFilterPanel();
  const { value } = getFilterFromState(initialFilter.key);

  const filter = {
    ...initialFilter,
    value,
  };

  return (
    <>
      {filter.label && <b>{filter.label}</b>}
      <FilterPanelField
        filter={filter}
        component={initialFilter.component}
        showSearchIcon={showSearchIcon}
      />
    </>
  );
}

FilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};
