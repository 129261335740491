import React from 'react';
import PropTypes from 'prop-types';
import LinkCardMedia from './LinkCardMedia';
import LinkCardHeader from './LinkCardHeader';
import LinkCardBody from './LinkCardBody';
import LinkCardText from './LinkCardText';
import './Link.scss';

const LinkCard = ({ children, noMedia, cardGridWidth }) => {
  let className = 'link-card__container';
  if (!noMedia) {
    className += ' link-card__container__has-media';
  }
  return (
    <li data-testid="link-card-test-id" className={`tablet:grid-col-${cardGridWidth} link-card`}>
      <div className={className}>{children}</div>
    </li>
  );
};

LinkCard.Media = LinkCardMedia;
LinkCard.Header = LinkCardHeader;
LinkCard.Body = LinkCardBody;
LinkCard.Text = LinkCardText;

LinkCard.propTypes = {
  children: PropTypes.node.isRequired,
  noMedia: PropTypes.bool,
  cardGridWidth: PropTypes.number,
};

LinkCard.defaultProps = {
  noMedia: false,
  cardGridWidth: 6,
};

export default LinkCard;
