import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { Icon, Button } from '../..';
import { useFilterPanel } from './FilterProvider';
import { ErrorMessage } from '../../lib/util';
import './TextFilterItem.scss';

export const TextFilterItem = ({
  id,
  name,
  type,
  disabled,
  variant,
  size,
  className,
  onFilterChange,
  onFilterKeyDown,
  showSearchIcon,
  value,
  filter,
  inputType,
  ...restProps
}) => {
  const inputRef = useRef();
  const { clearOneFilter } = useFilterPanel();

  const [clearButton, toggleClearButton] = useState(false);
  const [searchButton, toggleSearchButton] = useState(true);
  const [searchError, setSearchError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const notAllowedRegExp = /^[^%$]*$/;
  const alphaNumericRegExp = /^$|^[a-z0-9 ]+$/i;
  const numericRegExp = /^\d+$/;

  const resetError = () => {
    setSearchError(false);
    setErrorMessage('');
  };

  const inputValidation = (inputValue) => {
    let hasError = false;
    if (
      inputType === 'text' &&
      !RegExp(notAllowedRegExp).test(inputValue)
    ) {
      setErrorMessage('Special characters are not allowed.');
      hasError = true;
    } else if (
      inputType === 'number' &&
      !RegExp(numericRegExp).test(inputValue)
    ) {
      setErrorMessage('Only numbers are allowed.');
      hasError = true;
    } else if (
      inputType === 'alphaNumeric' &&
      !RegExp(alphaNumericRegExp).test(inputValue)
    ) {
      setErrorMessage('Only alpha numeric characters are allowed.');
      hasError = true;
    }
    return hasError;
  };

  const clearFilter = () => {
    inputRef.current.value = '';
    inputRef.current.focus();
    if (!filter.showSearchButton) {
      onFilterChange('');
    }
    toggleClearButton(false);
    resetError();
    clearOneFilter(filter);
  };

  const classes = classnames(
    'usa-input',
    { [`usa-input--${variant}`]: !!variant },
    { [`usa-input--${size}`]: !!size },
    { 'usa-input--error': searchError },
    className,
  );

  const validateSearch = (value) => {
    if (filter.minSearchLength) {
      const hasValidationErrors = inputValidation(value);
      if (hasValidationErrors) {
        setSearchError(true);
        return;
      }
      if (value.length >= filter.minSearchLength) {
        setSearchError(false);
        setErrorMessage('');
        onFilterChange(value);
      } else {
        setSearchError(true);
      }
    } else {
      onFilterChange(value);
    }
  };

  const onInputChange = (ev) => {
    if (ev.target.value) {
      toggleClearButton(true);
    } else {
      toggleClearButton(false);
    }
    if (filter.position !== 'top') {
      validateSearch(ev.target.value);
    } else {
      inputRef.current.value = ev.target.value;
    }
  };

  const onFilterKeyUp = (ev) => {
    if (ev.key === 'Enter' && filter.position === 'top') {
      validateSearch(ev.target.value);
    }
  };

  const handleClickOut = (e) => {
    if (!e.target.value) toggleSearchButton(true);
  };

  useEffect(() => {
    if (!value) {
      inputRef.current.value = '';
      onFilterChange('');
      toggleClearButton(false);
      resetError();
    } else {
      inputRef.current.value = value;
    }
  }, [value]);

  useEffect(() => {
    if (!inputRef.current.value) {
      if (!filter.showSearchButton) {
        onFilterChange('');
      }
      toggleClearButton(false);
    }
    if (inputRef.current.value) {
      toggleClearButton(true);
      toggleSearchButton(false);
    } else {
      toggleClearButton(false);
      toggleSearchButton(true);
      resetError();
    }
  }, [inputRef?.current?.value]);

  return (
    <>
      {searchError && errorMessage && <ErrorMessage text={errorMessage} />}
      <div className="text-filter-container width-full">
        <input
          className={classes}
          id={id}
          name={name}
          type={type}
          disabled={disabled}
          ref={inputRef}
          data-testid={id || `textbox_${uniqueId()}`}
          {...restProps}
          onChange={onInputChange}
          onKeyDown={onFilterKeyDown}
          onKeyUp={onFilterKeyUp}
          onFocus={() => toggleSearchButton(false)}
          onBlur={(e) => handleClickOut(e)}
          aria-label={filter?.label || 'search-input'}
          autoComplete={'off'}
        />
        {searchButton ? (
          <Icon
            iconName="search"
            className="searchIcon height-2 margin-left-1"
          />
        ) : null}
        {clearButton && !disabled ? (
          <button
            type="button"
            onClick={clearFilter}
            className="display-flex usa-button usa-button--unstyled filter-clear-button"
            aria-label="clear-search"
          >
            <Icon iconName="close" className="height-2" />
          </button>
        ) : null}
        {filter.minSearchLength && (
          <span className="usa-hint margin-top-1">
            Minimum of {filter.minSearchLength} {inputType === 'number' ? 'digits' : 'characters'} required
          </span>
        )}
        {filter.showSearchButton && (
          <Button
            onClick={() => validateSearch(inputRef.current.value)}
            className="usa-button margin-top-1"
            aria-label="search-trigger"
            label="Search"
          />
        )}
      </div>
    </>
  );
};

TextFilterItem.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'password', 'email', 'url'])
    .isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['', 'success', 'error']),
  size: PropTypes.oneOf(['small', 'medium']),
  onFilterChange: PropTypes.func,
  onFilterKeyDown: PropTypes.func,
  inputType: PropTypes.oneOf(['text', 'number', 'alphaNumeric']).isRequired,
};

TextFilterItem.defaultProps = {
  disabled: false,
  type: 'text',
  size: '',
  className: '',
  variant: '',
  inputType: 'text',
};
