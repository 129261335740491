import React from 'react';
import PropTypes from 'prop-types';
import './Link.scss';

const LinkCardGroup = ({ children }) => {
  return (
    <ul data-testid="link-card-group-test-id" className="link-card-group">
      {children}
    </ul>
  );
};

LinkCardGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LinkCardGroup;
