export const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
  
export const setCookie = (name, value, opts = {}) => {
    let options = {};
    if (window.AFP_CONFIG &&
        window.AFP_CONFIG.cognito_config &&
        window.AFP_CONFIG.cognito_config.cookieStorage) {
        options = window.AFP_CONFIG.cognito_config.cookieStorage;
    }  
    options = {...options, ...opts };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }
    // on Safari localhost domain is not allowing secure param to create session cookie.
    if(process.env.NODE_ENV==='development'){
        delete options.secure;
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}
  
export const deleteCookie = (name) => {
    document.cookie = `${name}=; max-age=-1`;
}

export const deleteCookies = (regex) => {
    const names = document.cookie.split(/=[^;]*(?:;\s*|$)/);
    for (let i = 0; i < names.length; i++) {
        const name = names[i];
        if (regex.test(name)) {
            deleteCookie(name);
        }
    }
}

let oldValue = null;
let intervalId;
export const startPollingCookie = (name, onChange, interval = 30000) => {
    let pollInterval = interval;
    if (window.AFP_CONFIG && window.AFP_CONFIG.cookiePollInterval) {
        pollInterval = window.AFP_CONFIG.cookiePollInterval;
    } 

    const poll = () => {
        const newValue = getCookie(name);
        if (newValue !== oldValue) {
            onChange(newValue);
        }
        oldValue = newValue;
    };
    
    poll();
    intervalId = setInterval(() => {
        poll();
    }, pollInterval);
};

export const stopPollingCookie = () => {
    if (intervalId) {
        clearInterval(intervalId);
        intervalId = undefined;
    }
};