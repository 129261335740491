import React, { useEffect, useState } from 'react';
import { useFilterPanel } from './FilterProvider';

const FiltersAppliedTitle = () => {
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const { mergedFilters } = useFilterPanel();

  useEffect(() => {
    let count = 0;

    mergedFilters.forEach((filter) => {
      if (typeof filter.value === 'string' && filter.value && !filter.hideChip)
        // eslint-disable-next-line no-plusplus
        count++;
      else if (Array.isArray(filter.value) && !filter.hideChip)
        count += filter.value.length;
      else if (filter.type === 'daterange' && filter.value.beginning && filter.value.end && !filter.hideChip)
        count ++;
    });

    setAppliedFilterCount(count);
  }, mergedFilters);

  return (
    <>
      {appliedFilterCount} filter{`${appliedFilterCount === 1 ? '' : 's'}`}{' '}
      applied
    </>
  );
};

export default FiltersAppliedTitle;
