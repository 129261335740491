import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Dialog from '../../atoms/Dialog/Dialog';
import './Popover.scss';

export default function Popover({
  children,
  className,
  arrowPosition,
  arrowSize,
  arrowOffset,
  focusOnRender,
  ...props
}) {
  const classes = classNames(
    'afp-popover position-relative',
    arrowPosition,
    className,
  );
  const popoverEl = useRef(null);
  const [arrowVars, setArrowVars] = useState({});

  useEffect(() => {
    setArrowVars((currArrowVars) => ({
      ...currArrowVars,
      '--size': arrowSize,
      '--offset': arrowOffset,
    }));
  }, [arrowSize, arrowOffset, setArrowVars]);

  useEffect(() => {
    if (focusOnRender && popoverEl.current) {
      popoverEl.current.focus();
    }
  }, []);

  return (
    <div ref={popoverEl} tabIndex="0" className={classes} {...props}>
      <Dialog>
        <div className="pop-over-content">{children}</div>
        <div style={arrowVars} className="popover-arrow">
          <span className="popover-arrow-content" />
        </div>
      </Dialog>
    </div>
  );
}

Popover.propTypes = {
  arrowPosition: PropTypes.oneOf([
    'arrow-top-left',
    'arrow-top-center',
    'arrow-top-right',
    'arrow-right-top',
    'arrow-right-center',
    'arrow-right-bottom',
    'arrow-bottom-right',
    'arrow-bottom-center',
    'arrow-bottom-left',
    'arrow-left-bottom',
    'arrow-left-center',
    'arrow-left-top',
  ]),
  arrowSize: PropTypes.string,
  arrowOffset: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  focusOnRender: PropTypes.bool,
};

Popover.defaultProps = {
  arrowPosition: 'arrow-left-top',
  arrowSize: '10px',
  arrowOffset: '10px',
  className: '',
  focusOnRender: false,
  children: null,
};
