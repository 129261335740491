import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AfpFooter from '../Footer/AFPFooter';
import AfpHeader from '../Header/AFPHeader';

const DefaultLayout = ({ currentUser, menu, children }) => {
  const HOME_APP_URL =
    window.AFP_CONFIG &&
    window.AFP_CONFIG.appURLs &&
    window.AFP_CONFIG.appURLs.home;

  if (!HOME_APP_URL) {
    throw new Error('home app URL should be provided in the app config');
  }

  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(currentUser);    
  }, [currentUser]);

  const userType =
    currentUser && currentUser.userType
      ? currentUser.userType.name
      : 'unauthenticated';

  const privacyLink = `${HOME_APP_URL}/privacy-regulations`;

  const importantLinks = [
    {
      description: 'GSA Privacy Policy',
      href: 'https://www.gsa.gov/reference/gsa-privacy-program',
    },
    {
      description: 'Accessibility Support',
      href: 'https://www.gsa.gov/website-information/accessibility-aids',
    },
    {
      description: 'Budget and Performance',
      href: 'https://www.gsa.gov/reference/reports/budget-performance',
    },
    {
      description: 'Office of the Inspector General',
      href: 'https://www.gsaig.gov/',
    },
    {
      description: 'Freedom of Information Act Requests',
      href: 'https://www.gsa.gov/reference/freedom-of-information-act-foia',
    },
    {
      description: 'No FEAR Act',
      href: 'https://www.gsa.gov/reference/civil-rights-programs/notification-and-federal-employee-antidiscrimination-and-retaliation-act-of-2002',
    },
    {
      description: 'Reports',
      href: 'https://www.gsa.gov/reference/gsa-plans-and-reports',
    },
    {
      description: 'GSA Website Policy Page',
      href: 'https://www.gsa.gov/website-information/website-policies#required',
    },
  ];

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const rootClasses = classnames('grid-container');

  return (
    <>
      <a className="usa-skipnav" href="#main-content">
        Skip to main content
      </a>      
      <AfpHeader
        user={user}
        menu={menu}
        userType={userType}
        id="afpHeader"
        homeAppUrl={HOME_APP_URL}
      />
      <div className={rootClasses}>
        <main id="main-content">{children}</main>
      </div>
      <AfpFooter importantLinks={importantLinks} privacyLink={privacyLink} />
    </>
  );
};

DefaultLayout.propTypes = {
  currentUser: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,  
};

export default DefaultLayout;
