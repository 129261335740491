import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ titleRef, title, ...props }) => {
  return (
    <h1 tabIndex='-1' ref={titleRef} {...props}>{title}</h1>
  )
}

export default PageTitle;

PageTitle.propTypes = {
  titleRef: PropTypes.func,
  title: PropTypes.string
}

PageTitle.defaultProps = {
  titleRef: null,
  title: null
}