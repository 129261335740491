import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../Button/Button';
import CancelIconLogo from '../../../styles/images/logo-cancel.svg';
import './Modal.scss';
import useLockBodyScroll from '../../lib/hooks/useLockBodyScroll';

const FOCUSABLE_ELEMENTS =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

/** Modal UI component */
export const Modal = ({
  title,
  children,
  actions,
  className,
  hideClose,
  onClose,
  showAlert,
  alert,
  variant,
}) => {
  useLockBodyScroll();

  const modalClasses = classnames(
    'afp-modal margin-x-auto',
    {
      large: variant === 'large',
    },
    {
      'extra-large': variant === 'extra-large',
    },
    className,
  );

  const closeButtonClasses = classnames(
    'modal-close-button',
    'width-auto',
    'modalClose',
    'text-base',
  );

  const modalEl = useRef(null);

  function setFocus() {
    const modal = modalEl.current || document.querySelector('.modal');
    if (!modal) {
      return;
    }
    // get the first focusable element, in most cases will it will be the close button
    const firstFocusableElement = modal.querySelectorAll(FOCUSABLE_ELEMENTS)[0];
    firstFocusableElement.focus();
  }

  function tabPress(e) {
    //const modal = modalEl.current; // select the modal by ref
    const modal = modalEl.current || document.querySelector('.modal');

    if (!modal) {
      return;
    }

    const firstFocusableElement = modal.querySelectorAll(FOCUSABLE_ELEMENTS)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(FOCUSABLE_ELEMENTS);
    const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

    let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus(); // add focus for the last focusable element
        e.preventDefault();
      }
    } else {
      // if tab key is pressed
      if (document.activeElement === lastFocusableElement) {
        // if focused has reached to last focusable element then focus first focusable element after pressing tab
        firstFocusableElement.focus(); // add focus for the first focusable element
        e.preventDefault();
      }
    }
  }

  /* Component Did Mount */
  useEffect(() => {
    setFocus();
    document.addEventListener('keydown', tabPress);
  }, []);

  return (
    <div
      data-testid="modal"
      ref={modalEl}
      className={modalClasses}
      aria-labelledby="modal-heading"
      aria-describedby="modal-description"
    >
      {!hideClose && (
        <div className="close-button">
          <Button
            variant="unstyled"
            className={closeButtonClasses}
            onClick={onClose}
            label="Close"
            labelClass= "usa-sr-only"
            rightIcon={{name: "close-icon", type: "custom",}}
          />
        </div>
      )}
      <div className="modal-inner">
        <div className="title" id="modal-heading">{title}</div>
        {showAlert && <div className="margin-y-3">{alert}</div>}
        <div className="content" id="modal-description">
          {children}
        </div>
      </div>
      {!!actions && <div className="actions bg-base-lightest">{actions}</div>}
    </div>
  );
};

Modal.defaultProps = {
  title: null,
  actions: null,
  className: null,
  hideClose: false,
  onClose: () => {},
  showAlert: false,
  alert: null,
  variant: null,
};

Modal.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  className: PropTypes.string,
  hideClose: PropTypes.bool,
  onClose: PropTypes.func,
  showAlert: PropTypes.bool,
  alert: PropTypes.element,
  variant: PropTypes.string,
};

/** Modal positioning component */
export const ModalContainer = ({ children, isOpen }) => {
  const containerEl = useRef(null);
  useEffect(() => {
    if (isOpen && containerEl.current) {
      containerEl.current.focus();
    }
  }, [isOpen]); 
  return (
    <div
      ref={containerEl}
      className="afp-modal-wrapper"
      role="dialog"
      aria-labelledby="modal-heading"
    >
      <div className="afp-modal-overlay">{children}</div>
    </div>
  );
};

ModalContainer.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool,
};

export const connectModal = (Component) => {
  // eslint-disable-next-line react/prop-types
  const ConnectedModal = ({ isOpen, ...props }) => {
    if (!isOpen) return null;
    return (
      <ModalContainer isOpen={isOpen}>
        <Component {...props} />
      </ModalContainer>
    );
  };

  return ConnectedModal;
};

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return { isOpen, openModal, closeModal };
};
