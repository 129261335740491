import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Alert, Button, Spinner, TextInput } from '../..';

const MAX_COUNT = 1000;

const CommentForm = ({
  submitComment,
  submittingComment,
  submissionStatus,
}) => {
  const [body, setBody] = useState('');
  const [fieldErrorMessage, setFieldErrorMessage] = useState(null);
  const [formErrorMessage, setFormErrorMessage] = useState(null);

  const postCommentButton = useRef(null);

  useEffect(() => {
    if (
      !_.isEmpty(submissionStatus) &&
      _.get(submissionStatus, 'action') === 'add'
    ) {
      if (_.get(submissionStatus, 'status') === 'success') {
        setBody('');
        setFormErrorMessage(null);
        postCommentButton?.current?.focus();
      }
      if (_.get(submissionStatus, 'status') === 'fail') {
        setFormErrorMessage(_.get(submissionStatus, 'message'));
      }
    }
  }, [submissionStatus]);

  const submit = () => {
    if (!body.length) {
      setFieldErrorMessage('Please fill out the comment field');
    } else if (body.length > 1000) {
      setFieldErrorMessage('Entry must be fewer than 1,000 characters');
    } else {
      setFieldErrorMessage(null);

      submitComment(body);
    }
  };

  const clear = () => {
    setBody('');
    setFieldErrorMessage(null);
  };

  return (
    <div className="afp-comment-form margin-bottom-8">
      {formErrorMessage && <Alert type="error">{formErrorMessage}</Alert>}
      <TextInput
        id="comment-area"
        inputClass="maxw-full"
        type="textarea"
        label="Comment"
        value={body}
        onChange={(e) => setBody(e.target.value)}
        characterLimit={MAX_COUNT}
        errorMessage={fieldErrorMessage}
      />

      <div className="display-flex flex-justify-end">
        {submittingComment ? (
          <Spinner size="small" />
        ) : (
          <>
            <Button
              type="button"
              variant="outline"
              onClick={() => clear()}
              label="Clear"
            />
            <Button
              type="button"
              onClick={submit}
              className="margin-right-0"
              inputRef={postCommentButton}
              label="Post Comment"
            />
          </>
        )}
      </div>
    </div>
  );
};

CommentForm.propTypes = {
  submitComment: PropTypes.func.isRequired,
  submittingComment: PropTypes.bool.isRequired,
  submissionStatus: PropTypes.shape({
    action: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
};

export default CommentForm;
