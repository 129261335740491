import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ComboBox } from '../../atoms/ComboBox/ComboBox';
import FilterItemClear from './FilterItemClear';
import { useFilter } from './FilterProvider';

// When noChip prop is set to true, no chip will be displayed to clear the selected item
const FilterItemCombobox = ({ id, noChip }) => {
  const { filterState, onFilterItemChange, filterOptions } = useFilter();

  const value = useMemo(() => {
    return filterState[id] ?? '';
  }, [filterState[id]]);

  const options = useMemo(() => {
    return filterOptions[id] ?? [];
  }, [filterOptions[id]]);

  const clearText = useMemo(() => {
    let text = '';
    if (options?.length) {
      text = options?.find((o) => o.value === value)?.label ?? '';
    }

    return text;
  }, [value, options]);

  return (
    <div key={options}>
      <div key={value}>
        <ComboBox
          defaultValue={value}
          value={value}
          options={options}
          onChange={(val) => {
            onFilterItemChange({ id, value: val });
          }}
        />

        {noChip ? null : (
          <FilterItemClear
            value={clearText}
            onClear={() => {
              onFilterItemChange({ id, value: null });
            }}
          />
        )}
      </div>
    </div>
  );
};

FilterItemCombobox.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FilterItemCombobox;
