import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SummaryBody = ({
    children,
    className
}) => {

    const classes = classnames(
        'usa-summary-box__text',
        className,
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

SummaryBody.defaultProps = {
    className: ''
};


SummaryBody.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    className: PropTypes.string
};

export default SummaryBody;