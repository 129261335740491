import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Breadcrumbs.scss';

// breadcrumb links must have this class
const ensureClassName = (item) => {
  const className = classnames(item.props.className, 'custom-breadcrumb-link');
  return React.cloneElement(item, { className });
};

const Breadcrumbs = ({ trail, current }) => {
  return (
    <nav className="usa-breadcrumb" aria-label="Breadcrumbs">
      <ol className="usa-breadcrumb__list">
        {trail.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className="usa-breadcrumb__list-item" key={`item-${i}`}>
            {ensureClassName(item)}
          </li>
        ))}
        <li
          key="current"
          className="usa-breadcrumb__list-item usa-current"
          aria-current="page"
        >
          <span>{current}</span>
        </li>
      </ol>
    </nav>
  );
};

const nodeType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

Breadcrumbs.propTypes = {
  trail: PropTypes.arrayOf(nodeType).isRequired,
  current: PropTypes.string.isRequired,
};

export default Breadcrumbs;
