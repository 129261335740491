import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '../../index';
import './WelcomeBanner.scss';

export const WelcomeBanner = ({
  id,
  title,
  message,
  className,
  actionTo1,
  actionTo2,
}) => {
  const classes = classnames('usa-banner afp-welcome-banner', className);
  return (
    <section data-testid={`${id}_wrapper`} className={classes}>
      <div className="grid-container-widescreen">
        <h1
          id={`${id}_title`}
          className="font-sans-2xl afp-welcome-banner__title"
        >
          {title}
        </h1>
        <div className="grid-row margin-bottom-3">
          <div className="desktop:grid-col-6">
            <p className="font-sans-xs" id={`${id}_message`}>
              {message}
            </p>
          </div>
        </div>
        {actionTo1 && (
          <Button
            size="md"
            id={`${id}_action_button_1`}
            onClick={actionTo1.handleClick}
            label={actionTo1.buttonLabel}
          />
        )}
        {actionTo2 && (
          <Button
            size="md"
            id={`${id}_action_button_2`}
            onClick={actionTo2.handleClick}
            label={actionTo2.buttonLabel}
          />
        )}
      </div>
    </section>
  );
};

WelcomeBanner.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  classname: PropTypes.string,
  actionTo1: PropTypes.object,
  actionTo2: PropTypes.object,
};
