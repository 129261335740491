import { useState } from "react";
import { keys } from '../util/keys'

const keyCodes = keys;

export default function useAriaListBox({
    listId = '',
    itemFocusedClass = 'focused'
}) {
    const [activeDescendant, setActiveDescendant] = useState(null);
    
    const listNode = () => { return document.getElementById(listId);  }
    const  allItems = () => { 
        return Array.prototype.slice
        .call(listNode().querySelectorAll('[role="option"]'))
    }
    const isItemVisible = (ele, container) => {
        const eleTop = ele.offsetTop;
        const eleBottom = eleTop + ele.clientHeight;
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.clientHeight;

        return (
            (eleTop >= containerTop && eleBottom <= containerBottom) ||
            // Some part of the element is visible in the container
            (eleTop < containerTop && containerTop < eleBottom) ||
            (eleTop < containerBottom && containerBottom < eleBottom)
        );
    };
    const getActiveDescendant = () => {
        return listNode().getAttribute('aria-activedescendant') || '';
    }
    const getFocused = (withItems = []) => {
        const items = (withItems.length
            ? withItems 
            : allItems())
            .filter(i => i.classList.contains(itemFocusedClass));
        
        return items.length ? items[0] : null;
    }
    const toggle = (item) => {
        if (!item) return;

        const isSelected = item.getAttribute('aria-selected') === 'true';
        item.setAttribute(
            'aria-selected',
             isSelected ? 'false' : 'true'
        );
        listNode().setAttribute('aria-activedescendant', item.id);
        focus(item);
        setActiveDescendant({ element: item, isSelected });
    }
    const deFocus = (item) => {
        if (!item) return;
        item.classList.remove(itemFocusedClass);
    }
    const focus = (item) => {
        if (!item) return;
        
        if (!isItemVisible(item, listNode())) {
            item.scrollIntoView();
        }
        
        deFocus(getFocused());
        item.classList.add(itemFocusedClass);
    }
    const focusFirst = () => {
        const firstItem = listNode().querySelector('[role="option"]');
        if (firstItem) {
            focus(firstItem);
        }
    }
    const previousItem = () => {
        const items = allItems();
        const focused = getFocused(items);
        let prevItem = items.length ? items[0] : null;
        let currentItemIndex = items.map(i => i.id).indexOf(focused?.id);

        if (currentItemIndex > 0) {
            prevItem = items[currentItemIndex - 1];
        }
        return prevItem;
    }
    const nextItem = () => {
        const items = allItems();
        const focused = getFocused(items);
        let nextItem = items.length ? items[0] : null;
        let currentItemIndex = items.map(i => i.id).indexOf(focused?.id);

        if (currentItemIndex > -1 && currentItemIndex < items.length) {
            nextItem = items[currentItemIndex + 1];
        }
        return nextItem;
    }
    const keyDownHandler = (evt) => {
        const activeDescendant = getActiveDescendant();
        const focused = getFocused();
        const key = evt.which || evt.keyCode;

        switch (key) {
            case keyCodes.UP:
            case keyCodes.DOWN:
            case keyCodes.LEFT:
            case keyCodes.RIGHT:
                evt.preventDefault();
                if (focused) {
                    if (
                        key === keyCodes.UP ||
                        key === keyCodes.LEFT) {
                            focus(previousItem());
                    } 
                    if (
                        key === keyCodes.DOWN ||
                        key === keyCodes.RIGHT) {
                            focus(nextItem());
                    }
                    break;
                }

                if (!activeDescendant) {
                    focusFirst();
                } else {
                    const items = allItems().filter(i => i.id === activeDescendant);
                    if (items.length) {
                        focus(items[0]);
                    } else {
                        focusFirst();
                    }
                }
                break;
        
            case keyCodes.ENTER:
                evt.preventDefault();
                toggle(focused);
                break;
            default:
                break;
        }
    }
    const clickOptionHandler = (evt) => {
        evt.preventDefault();
        const item = evt.currentTarget;
        if (item.getAttribute('role') !== 'option') {
            return;
        }
        toggle(item);
    }

    return {
        listBoxKeydown: keyDownHandler,
        listBoxClickOption: clickOptionHandler,
        toggledElement: activeDescendant
    };
};