import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Spinner } from '../..';
import CommentForm from './comment-form';
import Comment from './comment';
import { CommentPropType, PaginationPropType } from './types';

const CommentContent = ({
  comments,
  allowChanges,
  currentUser,
  deleteComment,
  deletingComment,
  submissionStatus,
}) => {
  let deletedGroup = [];
  const elements = [];
  comments.forEach((comment, index) => {
    if (comment.deletedAt !== null) {
      deletedGroup.push(comment);
    } else {
      if (deletedGroup.length) {
        elements.push(
          deletedGroup.length > 1
            ? `${deletedGroup.length} comments have been deleted`
            : `A comment has been deleted`,
        );
        deletedGroup = [];
      }
      elements.push(comment);
    }

    if (index === comments.length - 1 && deletedGroup.length) {
      elements.push(
        deletedGroup.length > 1
          ? `${deletedGroup.length} comments have been deleted`
          : `A comment has been deleted`,
      );
    }
  });

  const hideComments =
    comments.length === 0;
  return !hideComments ? (
    <ul className="add-list-reset">
      {elements.map((element, index) =>
        element.hasOwnProperty('deletedAt') ? (
          <Comment
            comment={element}
            key={index}
            allowChanges={allowChanges}
            currentUser={currentUser}
            deleteComment={deleteComment}
            deletingComment={deletingComment}
            submissionStatus={submissionStatus}
          />
        ) : (
          <li
            key={index}
            className="afp-deleted-comment margin-bottom-4 bg-base-lightest text-italic padding-1"
          >
            {element}
          </li>
        ),
      )}
    </ul>
  ) : (
    ''
  );
};

CommentContent.defaultProps = {
  allowChanges: true,
};

CommentContent.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape(CommentPropType)).isRequired,
  allowChanges: PropTypes.bool,
  currentUser: PropTypes.string.isRequired,
  deleteComment: PropTypes.func.isRequired,
  deletingComment: PropTypes.bool.isRequired,
  submissionStatus: PropTypes.shape({
    action: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
};

const HasMore = ({ onClick, comments }) => {
  const remaining = comments.count - comments.rows.length;
  const text = `Show previous ${remaining} comment${remaining > 1 ? 's' : ''}`;

  return remaining > 0 ? (
    <Button
      type="button"
      onClick={onClick}
      className="margin-bottom-6 display-flex flex-align-center"
      variant="unstyled"
      label={text}
      rightIcon={{name: "expand_more", className: "usa-icon--size-3 text-base"}}
    />
  ) : (
    ''
  );
};

HasMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  comments: PropTypes.shape(PaginationPropType).isRequired,
};

const Comments = ({
  comments,
  allowChanges,
  showAllComments,
  currentUser,
  loadingComments,
  submitComment,
  submittingComment,
  deleteComment,
  deletingComment,
  submissionStatus,
  heading
}) => {
  const ref = useRef();

  return (
    <div className="afp-comments margin-bottom-4" ref={ref}>
      {heading}
      <CommentForm
        submitComment={submitComment}
        submittingComment={submittingComment}
        submissionStatus={submissionStatus}
      />
      {loadingComments ? (
        <Spinner />
      ) : (
        <>
          <CommentContent
            comments={comments.rows}
            allowChanges={allowChanges}
            currentUser={currentUser}
            deleteComment={deleteComment}
            deletingComment={deletingComment}
            submissionStatus={submissionStatus}
          />
          <HasMore
            comments={comments}
            onClick={() => {
              showAllComments(true);
            }}
          />
        </>
      )}
    </div>
  );
};

Comments.defaultProps = {
  allowChanges: true,
  showAllComments: false,
  heading: <h2 className="margin-top-6">Comments</h2>
};

Comments.propTypes = {
  comments: PropTypes.shape(PaginationPropType).isRequired,
  allowChanges: PropTypes.bool,
  showAllComments: PropTypes.func,
  currentUser: PropTypes.string.isRequired,
  loadingComments: PropTypes.bool.isRequired,
  submitComment: PropTypes.func.isRequired,
  submittingComment: PropTypes.bool.isRequired,
  deleteComment: PropTypes.func.isRequired,
  deletingComment: PropTypes.bool.isRequired,
  submissionStatus: PropTypes.shape({
    action: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  heading: PropTypes.element
};

export default Comments;
