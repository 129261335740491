import React from 'react';
import PropTypes from 'prop-types';
import './Link.scss';

const LinkCardHeader = ({ children, titleCasing }) => {
  return (
    <>
      <header
        data-testid="link-card-header-test-id"
        className="link-card__header"
      >
        <h2 className={titleCasing ? 'title-m' : 'title-s-caps'}>{children}</h2>
      </header>
    </>
  );
};

LinkCardHeader.defaultProps = {
  titleCasing: false,
};
LinkCardHeader.propTypes = {
  titleCasing: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default LinkCardHeader;
