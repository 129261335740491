import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Card = ({
    children,
    media,
    className
}) => {

    const classes = classnames(
        'usa-card',
        {
            'usa-card--header-first': media === 'belowHeader',
            'usa-card--flag': ['horizontalLeft', 'horizontalRight'].includes(media),
            'usa-card--media-right': media === 'horizontalRight'
        },
        className,
    );

    return (
        <article className={classes}>
            <div className="usa-card__container">
                {children}
            </div>
        </article>
    );
};

Card.defaultProps = {
    className: '',
};


Card.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    media: PropTypes.oneOf(['belowHeader', 'horizontalRight', 'horizontalLeft']),
    className: PropTypes.string
};

export default Card;