import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

export const RadioButton = ({
  id,
  name,
  className,
  label,
  description,
  disabled,
  inputRef,
  variant, // variant default value
  ...restProps
}) => {
  id = id || `radio_${uniqueId()}`;
  const classes = classnames('usa-radio', className);
  const inputClasses = classnames('usa-radio__input', {
    'usa-radio__input--tile': variant === 'tile',
  });

  return (
    <div data-testid={`${id}_wrapper`} className={classes}>
      <input
        className={inputClasses}
        id={id}
        type="radio"
        name={name}
        disabled={disabled}
        ref={inputRef}
        {...restProps}
      />
      <label className="usa-radio__label" htmlFor={id}>
        {label}
        {description && (
          <span className="usa-checkbox__label-description">{description}</span>
        )}
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node, // TODO ? PropTypes.instanceOf(Element) - further narrow it down
  ]).isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'tile']),
};

RadioButton.defaultProps = {
  variant: 'default',
};
