import React, { useState, useLayoutEffect } from 'react';
import { Button } from '../../atoms/Button/Button';
import useId from '../../lib/hooks/useId';
import './FilterTableFrame.scss';

const FilterTableFrame = (UpLeft, UpRight, LowLeft, LowRight) => {
  return ({
    upLeftProps,
    upRightProps,
    lowLeftProps,
    lowRightProps,
    filterToggle,
    gridContainer,
  }) => {
    const [afpPaddingOffset, setAfpPaddingOffset] = useState(0);
    const [showFilters, setShowFilters] = useState(true);
    const id = useId('filter-table-frame');

    useLayoutEffect(() => {
      const tHead = document.querySelector(`#${id} table thead`);
      const table = document.querySelector(`#${id} table`);
      const filler = document.querySelector(`#${id} .filter-frame-filler`);
      const fp = document.querySelector(`#${id} .filter-panel-subsection`);

      const observer = new ResizeObserver((entries) => {
        const topAlign = !!entries.find((e) => e.target === tHead);
        const bottomAlign = !!entries.find(
          (e) => e.target === table || e.target === fp,
        );

        // Handles top alignment of filter panel (tablet and greater width)
        if (topAlign)
          setAfpPaddingOffset(
            window.matchMedia('(max-width: 640px)').matches
              ? 5
              : tHead.clientHeight - 35,
          );

        // Handles bottom alignment of filter panel
        if (bottomAlign) {
          const fillerHeight =
            table.offsetTop + table.clientHeight - filler.offsetTop;
          filler.style.height = `${fillerHeight > 0 ? fillerHeight : 0}px`;
        }
      });

      observer.observe(table);
      observer.observe(fp);
      observer.observe(tHead);

      return () => {
        observer.disconnect();
      };
    }, []);

    return (
      <div id={id} className={`grid${gridContainer ? ' grid-container' : ''}`}>
        {(UpLeft || UpRight || filterToggle) && (
          <div className="grid-row">
            <div
              data-testid="upper-left"
              className="tablet:grid-col-3 filter-frame-upper-left"
            >
              {filterToggle && (
                <Button
                  variant="outline"
                  label={`${showFilters ? 'Hide' : 'Show'} filters`}
                  leftIcon={{ name: 'filter_alt' }}
                  onClick={() => setShowFilters(!showFilters)}
                  aria-controls="lower-left-container"
                  aria-expanded={showFilters}
                />
              )}
              {UpLeft && <UpLeft {...upLeftProps} />}
            </div>
            <div data-testid="upper-right" className="tablet:grid-col-9">
              {UpRight && <UpRight {...upRightProps} />}
            </div>
          </div>
        )}
        <div className={`${showFilters ? 'grid-row grid-gap-2' : 'grid-row'}`}>
          {!afpPaddingOffset && <div className="tablet:grid-col-3" />}
          <div
            id="lower-left-container"
            aria-hidden={!showFilters}
            data-testid="lower-left"
            style={{
              paddingTop: `${afpPaddingOffset}px`,
              display: afpPaddingOffset ? 'block' : 'none',
            }}
            className={`${
              showFilters ? 'tablet:grid-col-3 filter-panel' : 'filter-panel-hidden'
            }`}
          >
            <div className="filter-panel-subsection" hidden={!showFilters}>
              {LowLeft && <LowLeft {...lowLeftProps} />}
            </div>
            <div className="filter-frame-filler bg-gray-3 display-none tablet:display-block" />
          </div>
          <div
            data-testid="lower-right"
            className={`tablet:grid-col-${showFilters ? '9' : '12'} table-panel`}
          >
            <div className="afp-table-subsection">
              {LowRight && <LowRight {...lowRightProps} />}
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default FilterTableFrame;
