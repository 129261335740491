import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import './Portal.scss';

export default function Portal({ portalEl, children, backdrop }) {
  const [portalElement, setPortalElement] = useState(portalEl);
  useEffect(() => {
    let el;
    if (!portalEl) {
      el = document.createElement('div');
      el.setAttribute('data-id', 'portal-container');
      document.body.appendChild(el);
      setPortalElement(el);
    }
    return () => {
      if (el) {
        el.remove();
      }
    };
  }, [portalEl, setPortalElement]);

  if (portalElement) {
    if (backdrop) {
      return createPortal(
        <div className="portal-backdrop" data-testid="backdrop">
          {children}
        </div>,
        portalElement,
      );
    }
    return createPortal(children, portalElement);
  }
  return null;
}

Portal.propTypes = {
  portalEl: PropTypes.node,
  children: PropTypes.node,
  backdrop: PropTypes.bool,
};
