import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FilterItemClear from './FilterItemClear';
import { useFilter } from './FilterProvider';

const arrayToggle = (array, item) =>
  array?.includes(item) ? array.filter((i) => i !== item) : [...array, item];

// When noChip prop is set to true, no chip will be displayed to clear the selected item
const FilterItemCheckbox = ({ id, options, noChip }) => {
  const { filterState, onFilterItemChange } = useFilter();

  const value = useMemo(() => {
    return filterState[id] ?? [];
  }, [filterState[id]]);

  const handleChange = (v, o) => {
    const newValue = arrayToggle(v, o);
    onFilterItemChange({
      id,
      value: newValue?.length ? newValue : null,
    });
  };

  return (
    <div data-testid="checkbox-wrapper">
      {options.map((option) => (
        <div className="usa-checkbox" key={option}>
          <input
            id={option}
            data-testid={`checkbox-${option?.toLowerCase()}`}
            type="checkbox"
            className="usa-checkbox__input"
            checked={value.includes(option)}
            onChange={() => handleChange(value, option)}
          />
          <label
            data-testid={`checkbox-label-${option?.toLowerCase()}`}
            className="usa-checkbox__label"
            htmlFor={option}
          >
            {option}
          </label>
        </div>
      ))}

      {noChip
        ? null
        : value.map((option) => (
            <FilterItemClear
              key={option}
              value={option}
              onClear={() => handleChange(value, option)}
            />
          ))}
    </div>
  );
};

FilterItemCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FilterItemCheckbox;
