import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';

export const ErrorMessage = ({ children, className, id }) => {
  const classes = classnames('usa-error-message', className);

  return (
    <span
      data-testid={id || `error_${uniqueId()}`}
      className={classes}
      id={id}
      role="alert"
    >
      {children}
    </span>
  );
};
