import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

const Textbox = ({
  id,
  name,
  type,
  disabled,
  variant,
  size,
  className,
  inputRef,
  ...restProps
}) => {
  const element = type === 'textarea' ? 'textarea' : 'input';

  const classes = classnames(
    `usa-${element}`,
    { [`usa-input--${variant}`]: !!variant },
    { [`usa-input--${size}`]: !!size },
    // styles[className], FIXME: styles is undefined, hence breaks the page
    className,
  );

  const props = {
    id,
    name,
    type,
    className: classes,
    disabled,
    ref: inputRef,
    'data-testid': id || `textbox_${uniqueId()}`,
    'aria-describedby': id,
    ...restProps,
  };

  return React.createElement(element, props);
};

Textbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    '',
    'text',
    'number',
    'password',
    'email',
    'url',
    'textarea',
  ]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['', 'success', 'error']),
  size: PropTypes.oneOf(['', 'small', 'medium']),
};

Textbox.defaultProps = {
  id: undefined,
  disabled: false,
  type: 'text',
  size: '',
  className: '',
  variant: '',
};

export default Textbox;
