import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../molecules/TextInput/TextInput';
import { useFilter } from './FilterProvider';

const FilterTextInput = ({ id, className, delay, ariaLabel }) => {
  const { filterState, onFilterItemChange } = useFilter();
  const [userTyped, setUserTyped] = useState(false);
  const [currentValue, setCurrentValue] = useState(filterState[id] ?? '');

  useEffect(() => {
    const timer = setTimeout(() => {
      // Checking userTyped protects onChange from being fired on mount.
      // userTyped is set to true on input's onKeyDown
      if (typeof onFilterItemChange === 'function' && userTyped) {
        onFilterItemChange({ id, value: currentValue });
      }
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [currentValue, userTyped]);

  return (
    <TextInput
      data-testid="filter-item-text-input"
      aria-label={ariaLabel}
      className={className}
      value={currentValue}
      onKeyDown={() => setUserTyped(true)}
      onChange={(e) => {
        setCurrentValue(e.target.value);
      }}
    />
  );
};

FilterTextInput.defaultProps = {
  className: '',
  delay: 500,
};

FilterTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  delay: PropTypes.number,
};

export default FilterTextInput;
