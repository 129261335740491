import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../../atoms/Checkbox/Checkbox';
import FilterItemClear from './FilterItemClear';
import { useFilter } from './FilterProvider';

const arrayToggle = (array, item) =>
  array?.includes(item) ? array.filter((i) => i !== item) : [...array, item];

const FilterMultiSelectDropdown = ({ id }) => {
  const { filterState, onFilterItemChange, filterOptions } = useFilter();

  const value = useMemo(() => {
    return filterState[id] ?? [];
  }, [filterState[id]]);

  const options = useMemo(() => {
    return filterOptions[id] ?? [];
  }, [filterOptions[id]]);

  return (
    <div data-testid="multi-select-checkbox-wrapper">
      {options.map((option) => (
        <Checkbox
          key={option.label}
          name={option.label}
          label={option.label}
          checked={value.includes(option.value)}
          onChange={() => {
            const newValue = arrayToggle(value, option.value);
            onFilterItemChange({
              id,
              value: newValue,
            });
          }}
        />
      ))}

      {value.map((option) => (
        <FilterItemClear
          key={option}
          value={options?.find((o) => o.value === option)?.label ?? option}
          onClear={() => {
            const newValue = arrayToggle(value, option);
            onFilterItemChange({
              id,
              value: newValue?.length ? newValue : null,
            });
          }}
        />
      ))}
    </div>
  );
};

FilterMultiSelectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FilterMultiSelectDropdown;
