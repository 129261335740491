import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../atoms/Button/Button';
import { connectModal, Modal, useModal } from '../../atoms/Modal/Modal';
import Spinner from '../../atoms/Spinner/Spinner';
import { addressShape } from './helpers';
import AddressOption from './AddressOption';

const AddressConfirmationModal = ({
  validating,
  hasValidationError,
  addressToValidate,
  validatedAddress,
  saveButtonText,
  cancelButtonText,
  onSave,
  onCancel, // optional callback function when cancel or close (x) is clicked
}) => {
  const [selectedAddress, setSelectedAddress] = useState(addressToValidate);
  const [isSuggestedSelected, setIsSuggestedSelected] = useState(false);
  const { isOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    setSelectedAddress(validatedAddress || addressToValidate);
    setIsSuggestedSelected(!!validatedAddress && !hasValidationError);
    if (
      addressToValidate?.countryCode === 'US' &&
      (hasValidationError ||
        (validatedAddress && Object.keys(validatedAddress).length))
    ) {
      openModal();
    }
  }, [addressToValidate, validatedAddress, hasValidationError]);

  const saveAndClose = () => {
    onSave(selectedAddress);
    closeModal();
  };

  const cancelAndClose = () => {
    onCancel(addressToValidate, validatedAddress);
    closeModal();
  };

  const onSelectAddress = (address, isSuggested) => {
    setSelectedAddress(address);
    setIsSuggestedSelected(isSuggested);
  };

  const ModalAlert = connectModal(() => {
    return (
      <Modal
        title={
          <h2 style={{ fontSize: '2rem' }}>
            {hasValidationError
              ? "You've entered an address that cannot be verified..."
              : 'Suggested address'}
          </h2>
        }
        onClose={cancelAndClose}
        className="afp-address-confirmation-modal"
        actions={
          <>
            <Button
              style={{ fontSize: '1rem' }}
              variant="unstyled"
              onClick={cancelAndClose}
              label={cancelButtonText}
            />
            <Button
              style={{ fontSize: '1rem', marginLeft: '1rem' }}
              onClick={saveAndClose}
              label={saveButtonText}
            />
          </>
        }
      >
        <div className="margin-bottom-6" style={{ fontSize: '1rem' }}>
          {hasValidationError ? (
            <p>
              You&apos;ve entered a new address on this page that is not
              recognized and cannot be verified by USPS. Are you sure you would
              like to continue?
            </p>
          ) : (
            <p>
              You&apos;ve entered a new address on this page. Please confirm
              your desired address format before continuing.
            </p>
          )}

          <AddressOption
            value="originalEntry"
            selectAddress={(val) => onSelectAddress(val, false)}
            address={addressToValidate}
            label="Use address as was entered:"
            selected={!isSuggestedSelected}
          />
          {!hasValidationError && (
            <AddressOption
              value="suggestedAddress"
              selectAddress={(val) => onSelectAddress(val, true)}
              address={validatedAddress}
              label="Use the USPS verified address:"
              selected={isSuggestedSelected}
            />
          )}
        </div>
      </Modal>
    );
  });

  if (validating)
    return (
      <div role="none" className="afp-modal-overlay">
        <Spinner
          size="large"
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translate(0, -50%)',
          }}
        />
      </div>
    );

  if (!addressToValidate || (!hasValidationError && !validatedAddress))
    return null;

  return <ModalAlert isOpen={isOpen} />;
};

AddressConfirmationModal.defaultProps = {
  validating: false,
  hasValidationError: false,
  validatedAddress: undefined,
  saveButtonText: 'Save',
  cancelButtonText: 'Cancel',
  onCancel: () => undefined,
};

AddressConfirmationModal.propTypes = {
  validating: PropTypes.bool,
  hasValidationError: PropTypes.bool,
  addressToValidate: PropTypes.shape(addressShape).isRequired,
  validatedAddress: PropTypes.shape(addressShape),
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default AddressConfirmationModal;
