const OFFSET = '15px';
const SIZE_OFFSET = '10px';

export const positionToArrowPosition = {
  1: 'arrow-top-left',
  2: 'arrow-top-center',
  3: 'arrow-top-right',
  4: 'arrow-left-center',
  5: 'arrow-top-center',
  6: 'arrow-right-center',
  7: 'arrow-bottom-left',
  8: 'arrow-bottom-center',
  9: 'arrow-bottom-right',
};

export const positionToPopoverTransform = {
  1: `translate(calc(${OFFSET} * -1px), ${SIZE_OFFSET})`,
  2: `translate(-50%, ${SIZE_OFFSET})`,
  3: `translate(calc(-100% + ${OFFSET}), ${SIZE_OFFSET})`,
  4: `translate(${OFFSET}, -50%)`,
  5: `translate(-50%, ${SIZE_OFFSET})`,
  6: `translate(calc(-100% - ${SIZE_OFFSET}), -50% )`,
  7: `translate(-${OFFSET}, calc(-100% - ${SIZE_OFFSET}))`,
  8: `translate(-50%, calc(-100% - ${SIZE_OFFSET}))`,
  9: `translate(calc(-100% + ${OFFSET}), calc(-100% - ${SIZE_OFFSET}))`,
};
