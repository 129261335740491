import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardBody = ({
    children,
    variant,
    className
}) => {

    const classes = classnames(
        'usa-card__body',
        {
            'usa-card__body--exdent': variant === 'exdent'
        },
        className,
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

CardBody.defaultProps = {
    className: ''
};


CardBody.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    variant: PropTypes.oneOf(['exdent']),
    className: PropTypes.string
};

export default CardBody;