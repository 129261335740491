import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import MenuTrigger from './MenuTrigger';
import MenuItem from './MenuItem';
import MenuList from './MenuList';
import useOutsideClick from '../../../lib/hooks/useOutsideClick';
import Icon from '../../../atoms/Icon/Icon';
import iconFinder from '../../../lib/util/iconFinder';
// eslint-disable-next-line import/named
import { menuKeys, useMenu, findMenuItemIndex } from '../hooks/useMenu';
import './UserMenu.scss';

const menuPaths = {
  profile: 'profile',
  adminConsole: 'admin',
  organizationProfile: 'admin/organization-profile',
  signOut: 'logout',
  help: 'help',
};

const signOutIcon = iconFinder({
  iconName: 'signOut',
  width: 20,
  height: 20,
  fill: '#fff',
});

const UserMenu = ({ user, homeAppURL }) => {
  const menuContainerRef = useRef(null);
  const menuTriggerRef = useRef(null);

  const {
    menuItemsRef,
    setMenuItemsCount,
    focusByItemIndex,
    focusKeyDownMap,
    isMenuOpen,
    setsMenuOpen,
  } = useMenu();

  const menuItems = [
    {
      href: menuPaths.profile,
      hasSeparator: true,
      label: 'Profile',
      ariaLabel: 'Open user profile',
      children: (
        <Icon
          iconName="account_circle"
          className="icons user-menu__menuitem-icon"
        />
      ),
    },
    {
      href: menuPaths.adminConsole,
      hasSeparator: false,
      label: 'Admin console',
      ariaLabel: 'Open admin console',
      children: (
        <Icon iconName="group" className="icons user-menu__menuitem-icon" />
      ),
    },
    {
      href: menuPaths.organizationProfile,
      hasSeparator: false,
      label: 'Organization profile',
      ariaLabel: 'Open organization profile',
      children: (
        <Icon iconName="settings" className="icons user-menu__menuitem-icon" />
      ),
    },
    {
      href: menuPaths.help,
      hasSeparator: true,
      label: 'Help',
      ariaLabel: 'Help',
      children: <Icon iconName="help_outline" className="icons user-menu__menuitem-icon" />,
    },
    {
      href: menuPaths.signOut,
      hasSeparator: true,
      label: 'Log out',
      ariaLabel: 'Log out',
      children: signOutIcon,
    },
  ];

  useEffect(() => {
    setMenuItemsCount(menuItems.length + 1); // add 1 for the close button
  }, []);

  const handleMenuClick = () => setsMenuOpen(!isMenuOpen);

  useOutsideClick(menuContainerRef, handleMenuClick);

  const keyDownActionMap = {
    ...focusKeyDownMap,
    [menuKeys.ESCAPE]: () => {
      setsMenuOpen(false);
      menuTriggerRef.current.focus();
    },
    [menuKeys.TAB]: () => {
      setsMenuOpen(false);
    },
  };

  const keyDownHandler = (evt) => {
    const { keyCode } = evt;
    if (keyDownActionMap[`${keyCode}`]) {
      keyDownActionMap[`${keyCode}`]();
      if (keyCode !== menuKeys.TAB) {
        evt.preventDefault();
      }
      return;
    }

    // search item by typed character a-z A-Z
    // the menuItems index 0 is the close button and all the other itemIndex need to + 1
    if (keyCode >= 65 && keyCode <= 90) {
      const itemIndex = findMenuItemIndex({ menuItems, keyCode }) + 1;
      // c char
      if (keyCode === 67) {
        focusByItemIndex(0);
        return;
      }
      if (itemIndex >= 0) {
        focusByItemIndex(itemIndex);
      }
      evt.preventDefault();
    }
  };

  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <div className="user-menu__wrapper">
      <MenuTrigger
        ref={menuTriggerRef}
        onClick={handleMenuClick}
        label={`Opens profile menu for ${fullName}`}
        data-testid="user-menu-trigger"
        className={`user-menu__trigger ${
          isMenuOpen ? 'user-menu__is-open' : ''
        } border-base-lightest`}
        onKeyDown={keyDownHandler}
        aria-haspopup="true"
        aria-expanded={isMenuOpen}
        aria-controls="user-menu-list"
      >
        <Icon
          iconName="account_circle"
          className="usa-icon--size-4 user-menu__trigger-icon"
        />
        <span className="user-menu__trigger-fullname" aria-hidden="true">
          {fullName}
        </span>
        <Icon
          iconName="navigate_next"
          className="usa-icon--size-3 user-menu__trigger-chevron"
        />
      </MenuTrigger>

      {isMenuOpen && (
        <div
          role="none"
          className="usa-overlay user-menu__overlay"
          onClick={handleMenuClick}
        />
      )}

      {isMenuOpen && (
        <MenuList
          ref={menuContainerRef}
          id="user-menu-list"
          className="user-menu__container"
          data-testid="user-menu-list"
        >
          <div className="display-flex flex-column flex-align-center padding-left-2 padding-right-2">
            <div>
              <Icon
                iconName="account_circle"
                className="usa-icon--size-6 text-primary-lighter"
              />
            </div>
            <div>
              <p className="user-menu__title display-flex flex-justify-center">
                {fullName}
              </p>
            </div>
          </div>
          <div
            className="user-menu__list display-flex flex-column flex-align-center"
            role="menu"
          >
            <button
              onClick={handleMenuClick}
              type="button"
              aria-label="Close user menu"
              className="usa-button usa-button--unstyled user-menu__close"
              role="menuitem"
              tabIndex="-1"
              onKeyDown={keyDownHandler}
              // eslint-disable-next-line
              ref={(el) => (menuItemsRef.current[0] = el)}
            >
              <Icon iconName="close" className="usa-icon--size-4" />
            </button>

            {menuItems.map((item, i) => (
              <MenuItem
                className="user-menu__menuitem"
                aria-label={item.ariaLabel}
                key={`menuItems-${item.label}`}
                href={`${homeAppURL}/${item.href}`}
                hasSeparator={item.hasSeparator}
                onKeyDown={keyDownHandler}
                // eslint-disable-next-line
                ref={(el) => (menuItemsRef.current[i + 1] = el)}
              >
                <span>{item.label}</span>
                {item.children || null}
              </MenuItem>
            ))}
          </div>
        </MenuList>
      )}
    </div>
  );
};

UserMenu.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  homeAppURL: PropTypes.string.isRequired, // todo: add custom proptype as url
};

export default UserMenu;
