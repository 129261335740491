import React from 'react';
import TableFilters from './TableFilters';

export default ({
  header,
  rows,
  filters,
  filterState,
  setFilterState,
  onFilterKeyDown,
  showFilterIcon,
}) => {
  const body = () => (
    <tbody>
      {filters ? (
        <TableFilters
          header={header}
          showFilterIcon={showFilterIcon}
          filters={filters}
          filterState={filterState}
          setFilterState={setFilterState}
          onFilterKeyDown={onFilterKeyDown}
        />
      ) : null}
      {rows.map((row, index) => {
        const rowKeys = Object.keys(row);
        return (
          <tr key={index}>
            {rowKeys.map((rowKey) => (
              <td key={rowKey} className={row[rowKey].className}>
                {row[rowKey].value}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );

  return body();
};
