import React from 'react';
import PropTypes from 'prop-types';
import {
  SelectDropdown,
  Typeahead,
  MultiSelect,
  MultiSelectDropdown,
  RadioButton,
  DatePicker,
  DateRangePicker,
  TimePicker,
} from '../..';
import { TextFilterItem } from './TextFilterItem';
import FilterPanelFilterShape from './helpers/FilterPanelFilterShape';
import { useFilterPanel } from './FilterProvider';
import { useFilterTypeaheads } from './FilterTypeaheadProvider';

export default function FilterPanelField({
  filter,
  component,
  showSearchIcon,
}) {
  const {
    key,
    type,
    placeholder,
    noResults,
    options,
    value,
    model,
    label,
    ariaLabel,
    customFieldProps,
  } = filter || {};

  const {
    inputCharNum = 3,
    debounceDelay = 300,
    promptText = 'Please start typing to select an option...',
    showNoResults = true,
    clearPanelFilterOnEmpty = false,
    noResultsText,
    inputType = 'text',
  } = customFieldProps || {};

  const { setFilters, setFiltersAndRefetch, multiSelectChange } =
    useFilterPanel();
  const { passTypeaheadValues, fetchTypeaheadValues } = useFilterTypeaheads();

  if (component) {
    return React.createElement(component, { filter });
  }

  switch (type) {
    case 'typeahead':
      return (
        <Typeahead
          key={key}
          accessor={key}
          ariaLabel={ariaLabel}
          model={model}
          type={type}
          filterValue={value}
          placeholder={placeholder}
          onFilterKeyDown={() => undefined}
          onFilterChange={({ target: { value } }) => {
            if (clearPanelFilterOnEmpty) {
              if (filter.value !== '' && value === '') {
                setFilters({
                  type: 'setOne',
                  fetchNewData: true,
                  filter: { ...filter, value: '' },
                });
              }
            }
          }}
          onOptionEnter={(newValue) => {
            setFilters({
              type: 'setOne',
              fetchNewData: true,
              filter: { ...filter, value: newValue },
            });
          }}
          typeaheadValues={passTypeaheadValues(key)}
          fetchTypeaheadValues={fetchTypeaheadValues}
          inputCharNum={inputCharNum}
          debounceDelay={debounceDelay}
          label={label}
          promptText={promptText}
          showNoResults={showNoResults}
          noResultsText={noResultsText}
          onClear={() => {
            if (clearPanelFilterOnEmpty && filter.value !== '') {
              setFilters({
                type: 'setOne',
                fetchNewData: true,
                filter: { ...filter, value: '' },
              });
            }
          }}
        />
      );
    case 'text':
      return (
        <TextFilterItem
          showSearchIcon={showSearchIcon}
          key={key}
          name={key}
          type={type}
          inputType={inputType}
          value={value}
          placeholder={placeholder}
          onFilterChange={(newValue) => {
            setFilters({
              type: 'setOne',
              fetchNewData: true,
              filter: { ...filter, value: newValue },
            });
          }}
          onFilterKeyDown={(ev) => {
            if (filter.position !== 'top') {
              setFiltersAndRefetch(ev);
            }
          }}
          filter={filter}
        />
      );
    case 'radio': {
      return filter.options.map((option) => {
        const checked = filter.value === option.value;
        return (
          <RadioButton
            key={option.value}
            name={key}
            label={option.label}
            value={option.value}
            checked={checked}
            onChange={(ev) => {
              setFilters({
                type: 'setOne',
                fetchNewData: true,
                filter: { ...filter, value: ev.target.value },
              });
            }}
          />
        );
      });
    }
    case 'select': {
      return (
        <SelectDropdown
          key={key}
          type={type}
          name={key}
          value={value}
          options={options}
          onChange={(ev) => {
            setFilters({
              type: 'setOne',
              fetchNewData: true,
              filter: { ...filter, value: ev.target.value },
            });
          }}
        />
      );
    }
    case 'multiselect':
      return (
        <MultiSelect
          key={key}
          options={options}
          onChange={(val, checked) => {
            multiSelectChange(filter, checked, val);
          }}
          selectedValues={value}
        />
      );
    case 'date':
      return (
        <DatePicker
          key={key}
          id={key}
          name={key}
          {...options}
          onChange={(newValue) => {
            setFilters({
              type: 'setOne',
              fetchNewData: true,
              filter: {
                ...filter,
                value: newValue,
              },
            });
          }}
        />
      );
    case 'time':
      return (
        <TimePicker
          key={key}
          id={key}
          name={key}
          {...options}
          onChange={(newValue) => {
            setFilters({
              type: 'setOne',
              fetchNewData: true,
              filter: {
                ...filter,
                value: newValue,
              },
            });
          }}
        />
      );
    case 'daterange':
      return (
        <DateRangePicker
          key={key}
          id={key}
          name={key}
          startDateLabel={options?.startDate?.label}
          startDateHint={options?.startDate?.hint}
          startDatePickerProps={{
            ...(options?.startDate || {}),
            onChange: (newValue) => {
              setFilters({
                type: 'setOne',
                fetchNewData: true,
                filter: {
                  ...filter,
                  value: {
                    ...filter.value,
                    [filter.options?.startDate?.key || 'startDate']: newValue,
                  },
                },
              });
            },
          }}
          endDateLabel={options?.endDate?.label}
          endDateHint={options?.endDate?.hint}
          endDatePickerProps={{
            ...(options?.endDate || {}),
            onChange: (newValue) => {
              setFilters({
                type: 'setOne',
                fetchNewData: true,
                filter: {
                  ...filter,
                  value: {
                    ...filter.value,
                    [filter.options?.endDate?.key || 'endDate']: newValue,
                  },
                },
              });
            },
          }}
        />
      );
    case 'multiselectdropdown': {
      return (
        <MultiSelectDropdown
          key={key}
          type={type}
          name={key}
          selectedValues={value}
          options={options}
          placeholder={placeholder}
          noResults={noResults}
          onChange={(value) => {
            setFilters({
              type: 'setOne',
              fetchNewData: true,
              filter: { ...filter, value },
            });
          }}
        />
      );
    }
    default:
      return null;
  }
}

FilterPanelField.defaultProps = {
  component: undefined,
};

FilterPanelField.propTypes = {
  component: PropTypes.elementType,
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};
