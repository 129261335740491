import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ActivityCard from './ActivityCard';
import './ActivityTracker.scss';

export const ActivityTracker = ({
  activities,
  variant,
  className,
  ...props
}) => {
  const isHighlight = variant === 'highlight';
  const classes = classnames(
    'contain_activity-trackr',
    { highlight: isHighlight },
    className,
  );

  return (
    <div className={classes} {...props}>
      {activities.map((activity) => {
        return (
          <ActivityCard
            key={activity.id}
            {...activity}
            highlight={isHighlight}
          />
        );
      })}
    </div>
  );
};

ActivityTracker.propTypes = {
  id: PropTypes.string,
  activities: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
  className: PropTypes.string,
};

ActivityTracker.defaultProps = {
  id: undefined,
  activities: [],
  variant: 'page',
  className: '',
};

export default { ActivityTracker }