import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ text }) =>
  text ? <span className="usa-error-message">{text}</span> : '';

ErrorMessage.propTypes = {
  text: PropTypes.string,
};

export default ErrorMessage;
