/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TimePicker as TrussworksTimePicker } from '@trussworks/react-uswds';
import { Label } from '../../atoms/Label/Label';
import { ErrorMessage } from '../../lib/util/index';

// eslint-disable-next-line import/prefer-default-export
export const TimePicker = ({
  key,
  id,
  name,
  onChange,
  onBlur,
  label,
  labelClass,
  defaultValue,
  disabled,
  minTime,
  maxTime,
  step,
  hint,
  className,
  errorMessage,
  required,
  ...props
}) => {
  const timePickerWrapper = useRef(null);
  
  const handleTimerPickerInputBlur = (evt) => {
    if((onBlur && typeof onBlur === 'function')) {
      onBlur(evt);
    }
  };

  useEffect(() => {
    const timePickerInputRef = timePickerWrapper.current.querySelector(
      `#${id}`,
    );
    timePickerInputRef.addEventListener('blur', handleTimerPickerInputBlur);
    return () => {
      timePickerInputRef.removeEventListener(
        'blur',
        handleTimerPickerInputBlur,
      );
    };
  }, []);

  if (label) {
    return (
      <div
        ref={timePickerWrapper}
        className={[
          'usa-form-group',
          errorMessage ? 'margin-left-05 usa-form-group--error' : '',
        ].join(' ')}
      >
        <Label
          htmlFor={id}
          id={`${id}-label`}
          required={required}
          className={labelClass || "text-bold"}
        >
          {label}
        </Label>
        {errorMessage && <ErrorMessage text={errorMessage} />}
        {hint && (
          <div className="usa-hint margin-bottom-neg-2" id={`${id}-hint`}>
            {hint}
          </div>
        )}
        <TrussworksTimePicker
          key={key}
          id={id}
          name={name}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={disabled}
          minTime={minTime}
          maxTime={maxTime}
          required={required}
          step={step}
          className={className}
          {...props}
        />
      </div>
    );
  }
  return (
    <>
      {errorMessage && <ErrorMessage text={errorMessage} />}
      {hint && (
        <div className="usa-hint margin-bottom-neg-2" id={`${id}-hint`}>
          {hint}
        </div>
      )}
      <div ref={timePickerWrapper}>
        <TrussworksTimePicker
          key={key}
          id={id}
          name={name}
          onChange={onChange}
          label={label}
          defaultValue={defaultValue}
          disabled={disabled}
          minTime={minTime}
          maxTime={maxTime}
          step={step}
          className={className}
          {...props}
        />
      </div>
    </>
  );
};

TimePicker.propTypes = {
  key: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  step: PropTypes.number,
  hint: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
};
