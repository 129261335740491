import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectDropdown from '../../atoms/SelectDropdown/SelectDropdown';
import FilterItemClear from './FilterItemClear';
import { useFilter } from './FilterProvider';

const FilterItemDropdown = ({ id, ariaLabel }) => {
  const { filterState, onFilterItemChange, filterOptions } = useFilter();

  const value = useMemo(() => {
    return filterState[id] ?? '';
  }, [filterState[id]]);

  const options = useMemo(() => {
    return filterOptions[id] ?? [];
  }, [filterOptions[id]]);

  const clearText = useMemo(() => {
    let text = '';
    if (options?.length) {
      text = options?.find((o) => o.value === value)?.label ?? '';
    }

    return text;
  }, [value, options]);

  return (
    <>
      <SelectDropdown
        value={value}
        options={options}
        name="" //required in base component
        aria-label={ariaLabel}
        onChange={(e) => {
          onFilterItemChange({ id, value: e?.target?.value });
        }}
      />

      <FilterItemClear
        value={clearText}
        onClear={() => {
          onFilterItemChange({ id, value: null });
        }}
      />
    </>
  );
};

FilterItemDropdown.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FilterItemDropdown;
