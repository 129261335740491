import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fieldset from '../../molecules/Fieldset/Fieldset';
import TextInput from '../../molecules/TextInput/TextInput';
import { ComboBoxWrapper } from './AddressFormComponent';
import { US_COUNTRIES, FORM_DEFAULT } from './helper';
import './AddressFormGroup.scss';

export const AddressFormGroup = ({
  fieldsetLegend,
  inputsDisabled,
  showLabelError,
  hasCountry,
  hasCompany,
  onFieldChange,
  onFieldBlur,
  onFormComplete,
  countryProps,
  companyProps,
  addressLine1Props,
  addressLine2Props,
  cityTownProps,
  stateProps,
  zipCodeProps,
}) => {
  const [formProps, setFormProps] = useState(null);
  const [nonUsaSelected, setNonUsaSelected] = useState(false);

  useEffect(() => {
    const mergeProps = (defaultVal, input) => {
      const {
        name,
        label,
        labelAlt,
        required,
        defaultValue,
        value,
        errorMessage,
        options,
        ...restProps
      } = input;

      return {
        name: name || defaultVal.name,
        label: label || defaultVal.label,
        labelAlt: labelAlt || defaultVal.labelAlt || defaultVal.label,
        required:
          typeof required === 'boolean' ? required : defaultVal.required,
        defaultValue: defaultValue || value || defaultVal.value,
        value: value || defaultVal.value,
        errorMessage: errorMessage || '',
        error: '',
        options: options || defaultVal.options,
        isDirty: false,
        restProps,
      };
    };

    const country = mergeProps(FORM_DEFAULT.country, countryProps);
    setNonUsaSelected(country.value && !US_COUNTRIES.includes(country.value));

    setFormProps({
      country,
      company: mergeProps(FORM_DEFAULT.company, companyProps),
      address1: mergeProps(FORM_DEFAULT.address1, addressLine1Props),
      address2: mergeProps(FORM_DEFAULT.address2, addressLine2Props),
      city: mergeProps(FORM_DEFAULT.city, cityTownProps),
      state: mergeProps(FORM_DEFAULT.state, stateProps),
      zipCode: mergeProps(FORM_DEFAULT.zipCode, zipCodeProps),
    });
  }, [
    countryProps,
    companyProps,
    addressLine1Props,
    addressLine2Props,
    cityTownProps,
    stateProps,
    zipCodeProps,
  ]);

  const doOnBlur = (field, value, isFieldDirty = true) => {
    onFieldBlur(formProps[field]?.name, value);

    // check if all required field are filled
    let isFormComplete = true;
    const isFieldIncomplete = (property) =>
      formProps[property].required &&
      (property === field ? !value : !formProps[property].value);
    for (const property in formProps) {
      switch (property) {
        case 'country':
          isFormComplete = !(hasCountry && isFieldIncomplete(property));
          break;
        case 'company':
          isFormComplete = !(hasCompany && isFieldIncomplete(property));
          break;
        default:
          isFormComplete = !isFieldIncomplete(property);
      }
      if (!isFormComplete) break;
    }
    onFormComplete(isFormComplete);

    // error message for empty required field
    let error = '';
    if (isFieldDirty && !value && formProps[field].required)
      error = `This is a required field`;
    return error;
  };

  const onChange = (field, value, error = '') => {
    onFieldChange(formProps[field].name, value);
    setFormProps((prevState) => {
      return {
        ...prevState,
        [field]: {
          ...prevState[field],
          value,
          error,
          isDirty: true,
        },
      };
    });
  };

  const onBlur = (field, value) => {
    const error = doOnBlur(field, value);
    if (error)
      setFormProps((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          error,
        },
      }));
  };

  const onCountryChange = (value) => {
    const isUSA = !value || US_COUNTRIES.includes(value);

    // simulate onBlur to check required field (ComboBox does not support onBlur)
    const error = doOnBlur('country', value, formProps.country.isDirty);

    onFieldChange(formProps.country.name, value);
    setFormProps((prevState) => {
      const state = { ...prevState.state };
      if (nonUsaSelected !== !isUSA) {
        state.isDirty = false;
        state.value = '';
        state.defaultValue = '';
        state.error = '';
      }
      return {
        ...prevState,
        country: {
          ...prevState.country,
          value,
          error,
          isDirty: true,
        },
        state,
      };
    });

    setNonUsaSelected(!isUSA);
  };

  const onStateChange = (value) => {
    // simulate onBlur to check required field (ComboBox does not support onBlur)
    const error = doOnBlur('state', value, formProps.state.isDirty);

    onChange('state', value, error);
  };

  const genTextInput = (field) => {
    if (!formProps?.[field]) return null;

    const {
      name,
      label,
      labelAlt,
      required,
      value,
      errorMessage,
      error,
      restProps,
    } = formProps[field];

    return (
      <div className="address-form-group">
        <TextInput
          id={name}
          data-testid={name}
          {...restProps}
          name={name}
          label={nonUsaSelected ? labelAlt : label}
          required={required}
          value={value}
          errorMessage={errorMessage || error}
          onChange={(e) => onChange(field, e.target?.value)}
          onBlur={(e) => onBlur(field, e.target?.value)}
          showLabelError={showLabelError}
          disabled={inputsDisabled}
        />
      </div>
    );
  };

  if (!formProps) return null;

  return (
    <Fieldset className="usa-fieldset" legend={fieldsetLegend}>
      {hasCountry && (
        <ComboBoxWrapper
          {...formProps.country}
          onChange={onCountryChange}
          showLabelError={showLabelError}
          inputsDisabled={inputsDisabled}
        />
      )}
      {hasCompany && genTextInput('company')}
      {genTextInput('address1')}
      {genTextInput('address2')}
      <div className="grid-row width-full">
        <div className="grid-col-12 tablet:grid-col-6">
          {genTextInput('city')}
        </div>
        <div className="grid-col-12 tablet:grid-col-5 tablet:grid-offset-1">
          {nonUsaSelected ? (
            genTextInput('state')
          ) : (
            <ComboBoxWrapper
              {...formProps.state}
              onChange={onStateChange}
              showLabelError={showLabelError}
              inputsDisabled={inputsDisabled}
            />
          )}
        </div>
      </div>
      <div className="grid-row width-full">
        <div className="grid-col-12 tablet:grid-col-6 desktop:grid-col-4">
          {genTextInput('zipCode')}
        </div>
      </div>
    </Fieldset>
  );
};

AddressFormGroup.defaultProps = {
  fieldsetLegend: '',
  inputsDisabled: false,
  showLabelError: false,
  hasCountry: true,
  hasCompany: false,
  onFieldChange: () => undefined,
  onFieldBlur: () => undefined,
  onFormComplete: () => undefined,
  countryProps: {},
  companyProps: {},
  addressLine1Props: {},
  addressLine2Props: {},
  cityTownProps: {},
  stateProps: {},
  zipCodeProps: {},
};

AddressFormGroup.propTypes = {
  fieldsetLegend: PropTypes.string,
  inputsDisabled: PropTypes.bool,
  showLabelError: PropTypes.bool,
  hasCountry: PropTypes.bool,
  hasCompany: PropTypes.bool,
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
  onFormComplete: PropTypes.func,
  countryProps: PropTypes.objectOf({}),
  companyProps: PropTypes.objectOf({}),
  addressLine1Props: PropTypes.objectOf({}),
  addressLine2Props: PropTypes.objectOf({}),
  cityTownProps: PropTypes.objectOf({}),
  stateProps: PropTypes.objectOf({}),
  zipCodeProps: PropTypes.objectOf({}),
};
