import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import useDidMountEffect from '../../lib/hooks/useDidMountEffect';

export const FilterContext = createContext();

function FilterProvider({ children, defaultState, onChange, ...filterProps }) {
  const [filterState, setFilterState] = useState(defaultState);
  const [resetTypeAhead, setResetTypeAhead] = useState(false);

  useEffect(() => {
    if (defaultState) {
      setFilterState(defaultState);
    }
  }, [defaultState]);

  const onFilterItemChange = useCallback(({ id, value }) => {
    // If no value is passed from filter item,
    // and filter id is already in the filter state, remove it.
    if (!value) {
      setFilterState((prev) => {
        const { [id]: removed, ...rest } = prev;
        return rest;
      });
    } else {
      setFilterState((prev) => ({ ...prev, [id]: value }));
    }
  }, []);

  // Custom hook, prevents useEffect from
  // firing immediately on first render
  // it only fires when the first change on filterState happens
  useDidMountEffect(() => {
    onChange(filterState);
  }, [filterState]);

  const reset = useCallback(() => {
    setFilterState(defaultState);
    setResetTypeAhead(true)
  }, []);

  return (
    <FilterContext.Provider
      value={{
        filterState,
        onFilterItemChange,
        resetTypeAhead,
        setResetTypeAhead,
        reset,
        ...filterProps,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export default FilterProvider;

FilterProvider.defaultProps = {
  defaultState: {},
  onChange: () => null,
};

FilterProvider.propTypes = {
  defaultState: PropTypes.shape(Object),
  children: PropTypes.element.isRequired,
  onChange: PropTypes.func,
};

// Custom hook that shorthands the context!
export const useFilter = () => useContext(FilterContext);
