import React from 'react';
import Logo from '../Logo/Logo';
import UserMenu from '../UserMenu/components/UserMenu';
import { MegaMenuPrimaryNavigation } from './mega-menu-primary-navigation';
import { GovBanner } from '../GovBanner/GovBanner';
import './AFPHeader.scss';
/**
 * This component refers to AFP Header that will used across all the product web apps
 * This component needs to know HOMEAPPURL to access menu and should be either moved to ui-utils or pass the URL through props
 * @param {*} param0
 * @returns
 */

const AfpHeader = ({ id, menu, user, userType, homeAppUrl }) => {
  return (
    <div>
      <GovBanner />
      <header
        className="usa-header usa-header--extended margin-top-0"
        id={id}
        role="banner"
      >
        <MegaMenuPrimaryNavigation
          logo={() => <Logo url={`${homeAppUrl}/home`} className="flex-fill" />}
          menu={menu}
          userType={userType}
          userMenu={() => (
            <div className="usa-header--user-menu display-flex flex-row">
              {user ? (
                <UserMenu homeAppURL={homeAppUrl} user={user} />
              ) : (
                <div className="usa-nav__secondary-links margin-right-105">
                  <a href={`${homeAppUrl}/signup`}>Create account</a>
                </div>
              )}
            </div>
          )}
        />
      </header>
    </div>
  );
};

export default AfpHeader;
