import React from 'react';
import PropTypes from 'prop-types';

const MenuTrigger = React.forwardRef(
  ({ id, children, onClick, className, label, ...restProps }, ref) => (
    <button
      ref={ref}
      id={id}
      className={`usa-button usa-button--unstyled ${className}`}
      // aria attributes for menu
      // https://www.w3.org/TR/wai-aria-practices-1.2/#menubutton
      // aria-expanded={isExpanded ? true : undefined}
      aria-haspopup="true"
      aria-label={label}
      type="button"
      onClick={onClick}
      data-testid="userMenuTrigger"
      {...restProps}
    >
      {children}
    </button>
  ),
);

MenuTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
};

MenuTrigger.defaultProps = {
  id: null,
  className: null,
};

export default MenuTrigger;
