import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SummaryContext } from './summaryBox';
import { useEffect } from 'react';

const SummaryHeader = ({
    children,
    id,
    className
}) => {

    const { summaryId, setSummaryId } = useContext(SummaryContext) || { setSummaryId: () => void 0 };

    useEffect(() => {
        id && setSummaryId(id);
    }, [id]);

    const classes = classnames(
        'usa-summary-box__heading',
        className,
    );

    return (
        <h3 className={classes} id={id || summaryId}>
            {children}
        </h3>
    );
};

SummaryHeader.defaultProps = {
    className: '',
    id: ''
};


SummaryHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    id: PropTypes.string,
    className: PropTypes.string
};

export default SummaryHeader;