import React from 'react';
import PropTypes from 'prop-types';

export const ColorTokens = ({ palette }) => {
  return (
    <div className="grid-container">
      <h1>Color Tokens</h1>
      <hr />
      {palette === 'text' && (
        <>
          <h2>Text</h2>
          <div className="grid-row grid-gap" data-testid="text">
            <div className="grid-col-2">
              <div className="circle-15 bg-ink" />
              <p>ink</p>
              <p className="font-sans-3xs">#1b1b1b</p>
              <p className="font-sans-3xs text-secondary-dark">
                Slightly lighter than pure black, this should used as black in
                AFP by default
              </p>
            </div>
          </div>
        </>
      )}
      {palette === 'grays' && (
        <>
          <h2>Grays (Base)</h2>
          <div className="grid-row grid-gap" data-testid="grays">
            <div className="grid-col-2">
              <div className="circle-15 bg-gray-3" />
              <p>gray-3</p>
              <p className="font-sans-3xs">#F6F6F6</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-base-lightest" />
              <p>base-lightest</p>
              <p className="font-sans-3xs">#F0F0F0</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-base-lighter" />
              <p>base-lighter</p>
              <p className="font-sans-3xs">#DFE1E2</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-base-light" />
              <p>base-light</p>
              <p className="font-sans-3xs">#A9AEB1</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-base" />
              <p>base</p>
              <p className="font-sans-3xs">#71767A</p>
              <p className="font-sans-3xs text-secondary-dark">
                This is the lightest gray that is still accessible on white
              </p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-base-dark" />
              <p>base-dark</p>
              <p className="font-sans-3xs">#565C65</p>
              <p className="font-sans-3xs text-secondary-dark">
                This is the lightest gray that is still accessible on gray-3 and
                blue-5
              </p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-base-darker" />
              <p>base-darker</p>
              <p className="font-sans-3xs">#3D4551</p>
            </div>
          </div>
        </>
      )}
      {palette === 'blues' && (
        <>
          <h2>Blues (Primary)</h2>
          <div className="grid-row grid-gap grid-gap" data-testid="blues">
            <div className="grid-col-2">
              <div className="circle-15 bg-blue-2" />
              <p>blue-2</p>
              <p className="font-sans-3xs">#F9FBFD</p>
              <p className="font-sans-3xs text-secondary-dark">
                Use only for background color of expanded table row (token not
                in USWDS)
              </p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-primary-lightest" />
              <p>primary-lightest</p>
              <p className="font-sans-3xs">#EFF6FB</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-primary-lighter" />
              <p>primary-lighter</p>
              <p className="font-sans-3xs">#D9E8F6</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-primary-light" />
              <p>primary-light</p>
              <p className="font-sans-3xs">#73B3E7</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-primary" />
              <p>primary</p>
              <p className="font-sans-3xs">#005EA2</p>
              <p className="font-sans-3xs text-secondary-dark">
                Default action color
              </p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-primary-dark" />
              <p>primary-dark</p>
              <p className="font-sans-3xs">#1A4480</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-primary-darker" />
              <p>primary-darker</p>
              <p className="font-sans-3xs">#162E51</p>
            </div>
          </div>
        </>
      )}
      {palette === 'utilities' && (
        <>
          <h2>Utilities</h2>
          <div className="grid-row grid-gap" data-testid="utilities">
            <div className="grid-col-2">
              <div className="circle-15 bg-blue-40v" />
              <p>blue-40v</p>
              <p className="font-sans-3xs">#2491FF</p>
              <p className="font-sans-3xs text-secondary-dark">
                Focus state borders
              </p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-violet-70v" />
              <p>violet-70v</p>
              <p className="font-sans-3xs">#54278f</p>
              <p className="font-sans-3xs text-secondary-dark">Visited links</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-secondary-dark" />
              <p>secondary-dark</p>
              <p className="font-sans-3xs">#B50909</p>
              <p className="font-sans-3xs text-secondary-dark">
                Destructive buttons, error text and indicators
              </p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-gray-60" />
              <p>gray-60</p>
              <p className="font-sans-3xs">#5C5C5C</p>
              <p className="font-sans-3xs text-secondary-dark">
                Text input and textarea border color
              </p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-success-dark" />
              <p>success-dark-vivid</p>
              <p className="font-sans-3xs">#008817</p>
              <p className="font-sans-3xs text-secondary-dark">
                For green/white combinations like "success" buttons or icons
              </p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-yellow-2" />
              <p>yellow-2</p>
              <p className="font-sans-3xs">#FFFBE8</p>
              <p className="font-sans-3xs text-secondary-dark">
                Popover background color (token not in USWDS)
              </p>
            </div>
          </div>
        </>
      )}
      {palette === 'alert' && (
        <>
          <h2>State</h2>
          <h3>Info</h3>
          <div className="grid-row grid-gap" data-testid="state">
            <div className="grid-col-2">
              <div className="circle-15 bg-info-lighter" />
              <p>info-lighter</p>
              <p className="font-sans-3xs">#E7F6F8</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-info" />
              <p>info</p>
              <p className="font-sans-3xs">#00BDE3</p>
            </div>
          </div>
          <hr />
          <h3>Warning</h3>
          <div className="grid-row grid-gap">
            <div className="grid-col-2">
              <div className="circle-15 bg-warning-lighter" />
              <p>warning-lighter</p>
              <p className="font-sans-3xs">#FAF3D1</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-warning" />
              <p>warning</p>
              <p className="font-sans-3xs">#FFBE2E</p>
            </div>
          </div>
          <hr />
          <h3>Error</h3>
          <div className="grid-row grid-gap">
            <div className="grid-col-2">
              <div className="circle-15 bg-error-lighter" />
              <p>error-lighter</p>
              <p className="font-sans-3xs">#F4E3DB</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-error" />
              <p>error</p>
              <p className="font-sans-3xs">#D54309</p>
            </div>
          </div>
          <hr />
          <h3>Success</h3>
          <div className="grid-row grid-gap">
            <div className="grid-col-2">
              <div className="circle-15 bg-success-lighter" />
              <p>success-lighter</p>
              <p className="font-sans-3xs">#ECF3EC</p>
            </div>
            <div className="grid-col-2">
              <div className="circle-15 bg-success" />
              <p>success</p>
              <p className="font-sans-3xs">#00A91C</p>
              <p className="font-sans-3xs text-secondary-dark">
                Be careful with this green - it fails color contrast tests with
                white
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ColorTokens.propTypes = {
  palette: PropTypes.string,
};

export default ColorTokens;
