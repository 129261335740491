import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './StatusBadge.scss';

export const StatusBadge = ({ children, variant, nowrap, ...props }) => {
  const variantColorMap = [
    {
      name: 'Inactive-Gray',
      backgroundColor: 'gray-3',
      dotColor: 'base-light',
    },
    {
      name: 'Inactive-White',
      backgroundColor: 'white',
      dotColor: 'base-light',
    },
    { name: 'Ready-Gray', backgroundColor: 'gray-3', dotColor: 'success' },
    { name: 'Ready-White', backgroundColor: 'white', dotColor: 'success' },
    { name: 'Info-Gray', backgroundColor: 'gray-3', dotColor: 'info' },
    { name: 'Info-White', backgroundColor: 'white', dotColor: 'info' },
    { name: 'Warning-Gray', backgroundColor: 'gray-3', dotColor: 'warning' },
    { name: 'Warning-White', backgroundColor: 'white', dotColor: 'warning' },
    {
      name: 'Urgent-Gray',
      backgroundColor: 'gray-3',
      dotColor: 'secondary-vivid',
    },
    {
      name: 'Urgent-White',
      backgroundColor: 'white',
      dotColor: 'secondary-vivid',
    },
  ];

  let bgColor = 'gray-3';
  let dotColor = 'base-light';

  const existingVariant = variantColorMap.find((x) => x.name === variant);

  if (existingVariant) {
    bgColor = existingVariant.backgroundColor;
    dotColor = existingVariant.dotColor;
  }

  const tagClasses = classnames('afp-status-tag', `bg-${bgColor}`, {
    'text-no-wrap': nowrap,
  });

  const dotClasses = classnames('afp-status-tag__dot', `bg-${dotColor}`);

  return (
    <span className={tagClasses} {...props}>
      <span className={dotClasses} />
      {children}
    </span>
  );
};

StatusBadge.defaultProps = {
  children: undefined,
  variant: undefined,
  nowrap: false,
};

StatusBadge.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  nowrap: PropTypes.bool,
};

export default StatusBadge;
