import React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import './Button.scss';

export const VariantMap = [
  'primary',
  'secondary',
  'base',
  'outline',
  'inverse',
  'unstyled',
];

 /** This Function returns a button with label, left and right icons.
  *   label - label accept string
  *   labelClass - labelClass accept string
  *  leftIcon and rightIcon - ICON's before and after label it accepts the prop with 
     three properties example: {name: '', type: '', className: ''}.
*/


export const Button = ({
  id,
  type,
  disabled,
  onClick,
  variant: variantProp,
  size,
  className,
  label,
  labelClass,       
  leftIcon,      
  rightIcon,     
  inputRef,
  ...buttonProps
}) => {
  const variant = VariantMap.find((v) => v === variantProp)
    ? variantProp
    : 'primary';

  const classes = classnames(
    'usa-button',
    { [`usa-button--${variant}`]: !!variant },
    { [`usa-button--${size}`]: !!size },
    className,
  );

  const IconSizeMap = {
    small: 2,
    medium: 3,
    large: 4,
  }

  const iconSizeClass = classnames(
    {[`usa-icon--size-${IconSizeMap.size}`]: !!size },
  );

  const labelClasses = classnames(
    { 'margin-left-1': leftIcon},
    { [`margin-right-1`]: rightIcon },
    labelClass,
  );

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      data-testid={id || `button_${uniqueId()}`}
      className={classes}
      ref={inputRef}
      {...buttonProps}
    >
      {leftIcon && (
        <Icon
          data-testid="icon-left"
          className={`text-middle ${iconSizeClass} ${leftIcon.className}`}
          iconName={leftIcon.name}
          type={leftIcon.type}
        />
      )}
      {label && (
        <span
          data-testid="button-label-with-span"
          className={labelClasses}
        >
          {label}
        </span>
      )}
      {rightIcon && (
        <Icon
          data-testid="icon-right"
          className={`text-middle ${iconSizeClass} ${rightIcon.className}`}
          iconName={rightIcon.name}
          type={rightIcon.type}
        />
      )}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  leftIcon: PropTypes.shape({
    name: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string
  }),
  rightIcon: PropTypes.shape({
    name: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string
  }),
  className: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLLIElement) }),
  ]),
};

Button.defaultProps = {
  id: undefined,
  size: 'medium',
  type: 'button',
  disabled: false,
  onClick: () => {
    // empty
  },
  label: undefined,
  labelClass: undefined,
  leftIcon: null,
  rightIcon: null,
  className: undefined,
  inputRef: undefined,
  variant: 'primary',
};

export default { Button };
