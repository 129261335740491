import PropTypes from 'prop-types';

const baseShape = {
  title: PropTypes.string,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  permanent: PropTypes.bool,
  operator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  hideClear: PropTypes.bool,
};

const customFieldPropsShape = {
  inputCharNum: PropTypes.number,
};

const FilterPanelFilterShape = {
  ...baseShape,
  filters: PropTypes.arrayOf(PropTypes.shape(baseShape)),
  customFieldProps: PropTypes.shape(customFieldPropsShape),
};

export default FilterPanelFilterShape;
