import React from 'react';
import PropTypes from 'prop-types';
import UTurn from '../../../styles/images/u-turn.svg';

export const NotFound = ({ goBackLink }) => {
  let link = {
    href: '/',
    text: 'Go back to homepage',
  };

  if (goBackLink) {
    link = goBackLink;
  }

  return (
    <div className="grid-row grid-gap margin-b-5">
      <div className="grid-col-8">
        <h1 className="font-sans-2xl">Sorry for the Detour</h1>
        <p className="font-body-lg">
          The page you tried to access may have gone offline or does not exist.
          Let's help you get back on the road.
        </p>

        <div className="margin-t-5">
          <ul className="usa-button-group">
            <li className="usa-button-group__item">
              <a href={link.href} className="usa-button">
                {link.text}
              </a>
            </li>
          </ul>
        </div>

        <p className="margin-bottom-4 margin-top-4">
          If you need assistance, please contact the GSA Fleet technical support
          team at 866-472-6711 or{' '}
          <a href="mailto:fleet.helpdesk@gsa.gov">fleet.helpdesk@gsa.gov</a>.
        </p>

        <p className="text-base">
          <strong>Error code:</strong> 404
        </p>
      </div>
      <div className="grid-col-auto display-flex flex-fill flex-justify-center padding-top-5">
        <img
          className="height-card-lg"
          alt="Image of a U-Turn sign"
          src={UTurn}
        />
      </div>
    </div>
  );
};

NotFound.propTypes = {
  goBackLink: PropTypes.object,
};
