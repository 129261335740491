import React from 'react';
import { useTable, useExpanded } from 'react-table';
import classnames from 'classnames';
import './ResponsiveTable.scss';

const renderRowSubComponent = ({
  row,
  rowProps,
  expandedSubRowClassName,
  headerStructure,
}) => {
  const data = row.original.subRowStructure;
  const dataLength = data.length;
  const numberOfCols = headerStructure.length;
  return (
    <tr {...rowProps} className={expandedSubRowClassName}>
      <td className="afp-table__expander-col"></td>
      {data.map((subRow, ind) => {
        const colSpan =
          ind === dataLength - 1
            ? Math.ceil(numberOfCols / dataLength)
            : Math.floor(numberOfCols / dataLength);
        return (
          <td
            className="afp-table__subrow"
            colSpan={colSpan}
            key={`afp-table__subrow-${ind}`}
          >
            {subRow}
          </td>
        );
      })}
    </tr>
  );
};

function Table({
  columns: userColumns,
  headerStructure,
  data,
  classes,
  expandedRowClassName,
  expandedSubRowClassName,
  collapsedRowClassName,
  getRowProps = () => ({}),
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded,
  );

  return (
    <table {...getTableProps()} className={classes}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const { className: rowClassName, ...rowProps } = row.getRowProps(
            getRowProps(row),
          );
          return (
            <React.Fragment key={rowProps.key}>
              <tr
                {...rowProps}
                className={`${
                  row.isExpanded ? expandedRowClassName : collapsedRowClassName
                } ${rowClassName}`}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
              {row.isExpanded &&
                renderRowSubComponent({
                  row,
                  rowProps,
                  visibleColumns,
                  expandedSubRowClassName,
                  headerStructure,
                })}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

export const ResponsiveTable = ({
  headerStructure,
  tableData,
  iconExpanded,
  iconCollapsed,
  bordered,
  fullWidth,
  expandedRowClassName,
  expandedSubRowClassName,
  collapsedRowClassName,
  useMemoDependencies,
  getRowProps,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <span className="display-flex" {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? iconExpanded : iconCollapsed}
          </span>
        ),
        SubCell: () => null,
      },
      ...headerStructure,
    ],
    [...useMemoDependencies],
  );

  const classes = classnames('usa-table', {
    'usa-table--borderless': !bordered,
    'usa-table--full-width': fullWidth,
  });

  return (
    <Table
      columns={columns}
      headerStructure={headerStructure}
      data={tableData}
      renderRowSubComponent={renderRowSubComponent}
      classes={classes}
      expandedRowClassName={expandedRowClassName}
      expandedSubRowClassName={expandedSubRowClassName}
      collapsedRowClassName={collapsedRowClassName}
      getRowProps={getRowProps}
    />
  );
};

ResponsiveTable.defaultProps = {
  bordered: false,
  fullWidth: false,
  expandedRowClassName: 'afp-table__expanded-row',
  expandedSubRowClassName: 'afp-table__expanded-subrow',
  collapsedRowClassName: '',
  useMemoDependencies: [],
  getRowProps: () => {
    // default prop
  },
};
