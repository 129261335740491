import React from 'react';
import PropTypes from 'prop-types';
import './NotificationCenter.scss';
import { Notification } from '../../molecules/Notification/Notification';

function Heading({ headingLevel, children }) {
  return React.createElement(headingLevel, {
    className: 'afp-notification-center__heading font-sans-xl',
    children,
  });
}

export const NotificationCenter = ({
  id,
  notifications,
  headingLevel = 'h3',
  children,
  noNotificationsMessage,
}) => {
  return (
    <section data-testid={`${id}_wrapper`} className="afp-notification-center">
      <div className="grid-row">
        <Heading headingLevel={headingLevel}>Notifications</Heading>
      </div>

      {notifications && !notifications.length && (
        <div
          className="grid-row font-sans-xs"
          data-testid="afp-notification-center-empty-message"
        >
          {noNotificationsMessage}
        </div>
      )}

      {notifications &&
        Boolean(notifications.length) &&
        notifications.map((notification, index) => (
          <div key={index} className="grid-row">
            <Notification
              key={`afp-notification-${index}`}
              id={notification.id}
              description={notification.description}
              notificationTimeOffset={notification.notificationTimeOffset}
              hasAction={notification.hasAction}
              isNewAlert={notification.isNewAlert}
              notificationAction={notification.notificationAction}
            />
          </div>
        ))}
      {children}
    </section>
  );
};

NotificationCenter.defaultProps = {
  noNotificationsMessage: "You don't have any notifications right now.",
};

NotificationCenter.propTypes = {
  id: PropTypes.string.isRequired,
  notifications: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  noNotificationsMessage: PropTypes.string,
};
