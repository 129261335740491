import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SummaryFooter = ({
    children,
    className
}) => {

    const classes = classnames(
        'afp-summary-box__footer',
        className,
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

SummaryFooter.defaultProps = {
    className: ''
};


SummaryFooter.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string
};

export default SummaryFooter;