import { useRef } from 'react';

const useId = (prefix) => {
  const { current: id } = useRef(
    `${prefix}-${Math.random().toString(36).substr(2, 9)}`,
  );
  return id;
};

export default useId;
